import * as Yup from 'yup';

const SchedulingUpdateBookingSchema = Yup.object().shape({
  pickupTime: Yup.string()
    .matches(
      /^[a-zA-Z0-9 :-]+$/g,
      'Alphabets, Numbers and Special characters (:-) are allowed',
    )
    .max(100, 'Too Long!'),
  pickupLocation: Yup.string()
    .matches(
      /^[a-zA-Z0-9 ,:&\\/]+$/g,
      'Alphabets, Numbers and Special characters (\'&:) are allowed',
    )
    .max(200, 'Too Long!'),
});

export default SchedulingUpdateBookingSchema;
