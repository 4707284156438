import { gql } from '@apollo/client';

const Aggregates = gql`
query HAPPENED($startDate: date! , $endDate: date!) {
  happeningToday: boatnew_booking(where: {tour_date: {_gte: $startDate , _lte: $endDate  }}){
    pax
    source
    status
    bookingUnits: booking_units{
      quantity
      unit{
        label
        info
      }
    }
    product{
     citiesProduct:cities_products{
        city{
          name
        }
      }
    }
  },
 completed: boatnew_booking_aggregate(where:{tour_date:{_gte:$startDate , _lte: $endDate},status:{_eq:"COMPLETED"}}){
    aggregate{
    count
  }
  }
cancelled: boatnew_booking_aggregate(where:{tour_date:{_gte:$startDate , _lte: $endDate},status:{_eq:"CANCELLED"}}){
    aggregate{
    count
  }
  }
 allBookings: boatnew_booking_aggregate(where:{tour_date:{_gte:$startDate , _lte: $endDate} status: {_nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"]}}){
    aggregate{
    count
  }
  }
  rezdyTodayHappening: boatnew_booking_aggregate(where:{source:{_eq:"REZDY"},tour_date:{_gte:$startDate , _lte: $endDate} status: {_nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"]}}){
    aggregate{
      count
    }
  }
  netbookings: boatnew_booking_aggregate(
    where: {
      tour_date: { _gte: $startDate, _lte: $endDate },
      status: { _nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"] }
    }
  ) {
    aggregate {
      count
    }
  }
  websiteTodayHappening: boatnew_booking_aggregate(where:{source:{_eq:"WEBSITE"},tour_date:{_gte:$startDate , _lte: $endDate} status: {_nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"]}}){
    aggregate{
      count
    }
  }
}
`;
export interface Total {
  aggregate:{
    count:number
  }
}
export interface AGGREGATETYPES {
  [x: string]: any;
  aggregates:[{
    bookingUnits:[{
      quantity:number
      unit:{
        info:string
        label:string
      }
    }],
    pax:number,
    source: string,
    product:{
      citiesProduct:[{
        city:{
          name:string
        }
      }]
    }
  }],
  happeningToday:[{
    source: any;
    bookingUnits:[{
      quantity:number
      unit:{
        info:string
        label:string
      }
    }],
    pax:number,
    product:{
      citiesProduct:[{
        city:{
          name:string
        }
      }]
    }
  }],
  completed:Total,
  cancelled:Total,
  allBookings:Total,
  netbookings:Total,
  rezdyTodayHappening:Total,
  websiteTodayHappening:Total,
  rezdyTodayBooked:Total,
  websiteTodayBooked:Total
  bookingsCompleted:Total
  bookingsCancelled:Total
}
export default Aggregates;
