/*eslint-disable*/
import React from 'react';
import '../../styles/Notes.scss';

const NotesEditForm = ({
  updateNote,
  clickedNote,
  setClickedNote,
  setShowOptions,
  setShowEditForm,
}) => (
  <form
    className="Notes__note-edit-form"
    onSubmit={(e) => {
      e.preventDefault();
      updateNote({
        variables: {
          id: clickedNote?.noteId,
          input: {
            note: clickedNote?.noteVal.trim(),
          },
        },
      });
      setShowEditForm(false);
      setShowOptions(false);
    }}
  >
    <input
      value={clickedNote?.noteVal}
      onChange={(e) => {
        setClickedNote({ ...clickedNote, noteVal: e.target.value });
      }}
      autoFocus
    />
    <div className="Notes__note-edit-form-btn">
      <button type="submit">
        <i className="fas fa-save" />
      </button>
      <button
        type="button"
        onClick={() => {
          setShowEditForm(false);
          setShowOptions(false);
        }}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  </form>
);

export default NotesEditForm;
