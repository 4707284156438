import React, { Suspense, useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import {
  FETCH_PRODUCTS,
  FETCH_AVAILABILITY_SERIES,
  FETCH_AVAILABILITY_VIEW,
} from "../../api/calendar";
import styles from "./Sessions.module.scss";
import Calendar from "../../components/Calendar/Calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Header from "../../components/Header/Header";
import SeriesInfo from "./SeriesInfo";
import axios from "axios";
import { FETCH_VEHICLES_BY_CITY } from "../../api/Vehicles";
import EditSession from "../../components/EditSession/EditSession";

function Sessions() {
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery(FETCH_PRODUCTS);
  const [productsSelected, setProductsSelected] = useState(null);
  const [getAvailabilitySeries, availabilitySeries] = useLazyQuery(
    FETCH_AVAILABILITY_SERIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getAvailabilityView, availabilityView] = useLazyQuery(
    FETCH_AVAILABILITY_VIEW
  );
  const [getVehicles, vehicles] = useLazyQuery(FETCH_VEHICLES_BY_CITY);
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    sessionSelected: "",
  });
  const [monthSelected, setMonthSelected] = useState(new Date());
  const [editingSession, setEditingSession] = useState(null);
  const [initialSession, setInitialSession] = useState(null);

  const [viewState, setViewState] = useState("");
  const [availabilities, setAvailabilities] = useState([]);
  const [productCode, setProductCode] = useState([]);
  const getCurrentDateTime = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    const seconds = String(today.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  };

  const [startDateState, setStartDateState] = useState(getCurrentDateTime());
  const [endDateState, setEndDateState] = useState(getCurrentDateTime());
  const [dateState, setDate] = useState(getCurrentDateTime());

  const handleSelectProduct = (values) => {
    setProductsSelected(values);
  };
  const handleCloseModal = () =>
    setOpenModal({ isOpen: false, sessionSelected: "" });

  const fetchRun = async (date) => {
    let startDate1, endDate1;

    switch (viewState) {
      case "month":
        startDate1 = dayjs(dateState).startOf("month").toDate();
        endDate1 = dayjs(dateState).endOf("month").toDate();
        break;
      case "week":
        startDate1 = dayjs(dateState).startOf("week").toDate();
        endDate1 = dayjs(dateState).endOf("week").toDate();
        break;
      case "day":
        startDate1 = dayjs(dateState).startOf("day").toDate();
        endDate1 = dayjs(dateState).endOf("day").toDate();
        break;
      default:
        startDate1 = dayjs(dateState).startOf("month").toDate();
        endDate1 = dayjs(dateState).endOf("month").toDate();
    }
    if (!dayjs.isDayjs(startDate1)) {
      startDate1 = dayjs(startDate1);
    }
    if (!dayjs.isDayjs(endDate1)) {
      endDate1 = dayjs(endDate1);
    }

    const formattedStartDate = startDate1.format("YYYY-MM-DDTHH:mm:ss[Z]");
    const formattedEndDate = endDate1.format("YYYY-MM-DDTHH:mm:ss[Z]");

    setStartDateState(formattedStartDate);
    setEndDateState(formattedEndDate);
    if (typeof date !== "undefined") {
      setMonthSelected(date);
    }
    const availabilities = [];
    /* @ts-ignore */
    productsSelected?.forEach((product) =>
      product?.availabilities?.forEach((availability) => {
        availabilities.push(availability);
      })
    );

    // Update state variables

    const inputDate = new Date(date);
    const lastDayOfMonth = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth() + 1,
      0
    );

    // Add one day to the last day of the month
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);

    const beginningOfMonth = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      1
    ).toISOString();
    const endOfMonth = lastDayOfMonth.toISOString();

    if (productsSelected) {
      await getAvailabilitySeries({
        variables: {
          filters: {
            _or: availabilities?.map((avail) => ({
              parent_id: { _eq: avail.id },
            })),
            start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
          },
        },
      });
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchRun(monthSelected);
  }, [productsSelected, viewState, monthSelected]);

  const onClickEventHandler = (event, mouseClickEvent) => {
    if (mouseClickEvent.target.className.includes("Mui")) return;
    getAvailabilityView({
      variables: {
        id: event?.id,
      },
      onCompleted: (data) => {
        setEditingSession(null);
        setOpenModal({ isOpen: true, sessionSelected: event?.id });
      },
    });
  };

  const handleCloseEditingSession = () => {
    setEditingSession(null);
  };

  const fetchAvailability = async () => {
    const productCodes = productsSelected
      ?.map((product) => product.productCode)
      .join(",");
    setProductCode(productCodes);
    try {
      if (productCodes) {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/boat/availability?externalProductCode=${productCodes}&apiKey=8h3GjF6pR2qL9sT5@k7EaY4wX1zU2vN&startTime=${startDateState}&endTime=${endDateState}`
        );
        setAvailabilities(response.data.sessions);
      }
    } catch (error) {
      console.error("An error occurred while fetching availability data.");
    }
  };

  const createEvents = () => {
    const events = [];

    const productCodesArray =
      productCode?.length > 0
        ? productCode?.split(",")?.map((code) => code?.trim())
        : [];

    // Iterate over each product code in the array
    productCodesArray.forEach((productCode) => {
      // Find the availabilities object for the current product code
      const productAvailabilitiesObject = availabilities?.find((item) =>
        item.hasOwnProperty(productCode)
      );

      if (!productAvailabilitiesObject) {
        console.warn(
          `No availabilities found for product code: ${productCode}`
        );
        return;
      }

      // Access the availability array for the current product code
      const productAvailabilities = productAvailabilitiesObject[productCode];

      // Iterate over each availability object for the current product code
      productAvailabilities.forEach((availability) => {
        // Initialize variables
        let totalRemaining = availability.seats - availability.seatsAvailable;
        let color = "";
        let totalSeats = availability.seats;
        let availableseats = availability.seatsAvailable;

        // Determine the color based on the product index
        productsSelected.forEach((product, index) => {
          if (product.productCode === productCode) {
            if (index % 4 === 0) {
              color = "#ff6262";
            } else if (index % 4 === 1) {
              color = "#87CEEB";
            } else if (index % 4 === 2) {
              color = "#fdad5c";
            } else if (index % 4 === 3) {
              color = "#CBC3E3";
            }
          }
        });

        // Handle exception for total remaining seats
        if (availability.exception) {
          if (Object.keys(availability.exception).includes("total_remaining")) {
            totalRemaining = parseInt(
              availability.exception.total_remaining,
              10
            );
          }
        } else {
          totalRemaining = availability.seats - availability.seatsAvailable;
        }

        // Create the event object
        events.push({
          start: new Date(availability.startTime),
          end: new Date(availability.endTime),
          id: availability.id,
          totalRemaining,
          totalSeats,
          color,
          productsSelected,
          monthSelected,
          availableseats,
        });
      });
    });

    return events;
  };

  useEffect(() => {
    fetchAvailability();
  }, [productsSelected, startDateState, endDateState]);

  useEffect(() => {
    if (productsError) {
      console.error("Error fetching products:", productsError);
    }
  }, [productsError]);

  useEffect(() => {
    if (productCode?.length > 0) {
      createEvents();
    }
  }, [availabilities, productCode]);

  return (
    <>
      <Header />
      <main className={styles.sessions}>
        <Autocomplete
          multiple
          size="small"
          disablePortal
          options={productsData ? productsData.products : []}
          loading={productsLoading}
          sx={{ width: "50%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Products"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {productsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionLabel={(option) => option.name}
          groupBy={(option) => option.type}
          onChange={(event, values) => setProductsSelected(values)}
          value={productsSelected || []}
          disabled={editingSession !== null}
        />

        <div className={styles.calendarContainer}>
          <div className={styles.calendar}>
            <Calendar
              onSelectEvent={onClickEventHandler}
              events={createEvents() ?? []}
              fetchRun={fetchRun}
              getAvailabilitySeries={getAvailabilitySeries}
              monthSelected={monthSelected}
              productsSelected={productsSelected}
              setMonthSelected={setMonthSelected}
              setDate={setDate}
              setViewState={setViewState}
            />
          </div>
          {editingSession === null ? null : (
            <EditSession
              vehicles={vehicles}
              editingSession={editingSession}
              setEditingSession={setEditingSession}
              initialSession={initialSession}
              fetchRun={fetchRun}
              handleCloseEditingSession={handleCloseEditingSession}
              productsSelected={productsSelected}
              monthSelected={monthSelected}
              getAvailabilitySeries={getAvailabilitySeries}
            />
          )}
        </div>
      </main>
      <Dialog open={openModal?.isOpen} onClose={handleCloseModal}>
        <div className={styles.seriesInfo}>
          <Suspense fallback={<CircularProgress />}>
            <SeriesInfo
              availabilityView={availabilityView?.data?.availabilityView}
              handleCloseModal={handleCloseModal}
              fetchRun={fetchRun}
              monthSelected={monthSelected}
              productsSelected={productsSelected}
              getAvailabilitySeries={getAvailabilitySeries}
              setEditingSession={setEditingSession}
              setInitialSession={setInitialSession}
              editingSession={editingSession}
              availabilitySeries={availabilitySeries?.data?.availabilitiesSeries?.find(
                (series) => series?.id === openModal.sessionSelected
              )}
              getVehicles={getVehicles}
            />
          </Suspense>
        </div>
      </Dialog>
    </>
  );
}

export default Sessions;
