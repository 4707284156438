import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import './ContextMenu.scss';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

type ContextMenuProps = {
  contextMenu: {
    mouseX: number;
    mouseY: number;
  } | null;
  handleClose: () => void;
  handleShowDeleteConfirmation: () => void;
  handleShowBlockConfirmation: () => void;
};

export default function ContextMenu({
  contextMenu,
  handleClose,
  handleShowDeleteConfirmation,
  handleShowBlockConfirmation,
}: ContextMenuProps) {
  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
      contextMenu !== null
        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
        : undefined
      }
      classes={{ list: 'event-context-menu' }}
    >
      <MenuItem onClick={handleShowDeleteConfirmation}>
        <DeleteIcon className="list-icon" />
        Delete Session
      </MenuItem>
      <MenuItem onClick={handleShowBlockConfirmation}>
        <RemoveCircleIcon className="list-icon" />
        Block session
      </MenuItem>
    </Menu>
  );
}
