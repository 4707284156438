import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { FETCH_CITIES } from "../../api/city";
import Header from "../../components/Header/Header";
import "./cities.scss";

function Cities() {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, refetch } = useSubscription(FETCH_CITIES);

  const filteredCities =
    data?.boatnew_cities?.filter((city) => city.trash !== true) || [];

  const getHeader = () => {
    let header = "";
    if (location.pathname.includes("edit")) {
      header = (
        <h2 className="heading_styles">
          <i
            className="far fa-arrow-left"
            onClick={() => navigate("/cities")}
          />
          <span>Edit City</span>
        </h2>
      );
    } else if (location.pathname.includes("create")) {
      header = (
        <h2 className="heading_styles">
          <i
            className="far fa-arrow-left"
            onClick={() => navigate("/cities")}
          />
          <span>Create City</span>
        </h2>
      );
    } else {
      header = <h2 className="heading_styles">Cities</h2>;
    }
    return header;
  };

  return (
    <>
      <Header />
      <div className="Vehicles__container">
        <div className="card">
          <div className="card-header">{getHeader()}</div>
          <main className="Vehicles__main">
            <Outlet context={{ filteredCities, refetch }} />
          </main>
        </div>
      </div>
    </>
  );
}

export default Cities;
