import { gql } from '@apollo/client';
import { FRAGMENT_GUIDES } from './fragments';

export const FETCH_USER_BY_ID = gql`
query FETCH_USER_BY_ID($id:String!) {
  user: boatnew_users_by_pk(id: $id){
    id
    email
    name
    phone
    role
    created_at
    permissions
  }
}
${FRAGMENT_GUIDES}
`;

export const FETCH_USERS_COUNT_BY_EMAIL = gql`
query GetUserCountByEmail($email: String!) {
  users_aggregate: boatnew_users_aggregate(
    where: {
      email: {_eq: $email}
    }
  ) {
    aggregate {
      count
    }
  }
}
`;

export const FETCH_GUIDES = gql`
query FETCH_GUIDES {
  guides: boatnew_guides {
    id
    fullName: full_name
    status
    created_at
  }
}
`;

export const FETCH_ACTIVE_GUIDES = gql`
query FETCH_GUIDES {
  guides: boatnew_guides (where: {
    status: { _eq: "active" },
    trash: { _eq: false }
  }) {
    id
    fullName: full_name
    status
    created_at
  }
}
`;

export const SUBSCRIBE_USERS = gql`
subscription SUBSCRIBE_USERS {
  users: boatnew_users (
    order_by: { created_at: desc },
  ) {
    id
    email
    fullName: name
    phone
    role
    created_at
    permissions
  }
}
`;

export const SUBSCRIBE_ACTIVE_GUIDES = gql`
subscription SUBSCRIBE_GUIDES {
  guides: boatnew_guides (
    order_by: { created_at: desc },
    where: { trash: { _eq: false }, status: { _eq: "active" } }
  ) {
    id
    fullName: full_name
    status
    created_at
    email_personal
    last_deactivated
    phone
    cities_guides {
      city {
        name
      }
    }
  }
}
`;

export const ACTIVE_GUIDE = gql`
mutation INSERT_GUIDE($guideId: String!, $status: String!, $timestamp: timestamptz!) {
  updateGuideStatus: update_boatnew_guides_by_pk(pk_columns: {id: $guideId}, _set: {
    status: $status,
    last_deactivated: $timestamp
  }) {
    id
  }
}
`;

export const DEACTIVE_GUIDE = gql`
mutation INSERT_GUIDE($guideId: String!, $status: String!) {
  updateGuideStatus: update_boatnew_guides_by_pk(pk_columns: {id: $guideId}, _set: {
    status: $status
  }) {
    id
  }
}
`;

export const MOVE_TO_TRASH = gql`
mutation GUIDE_TO_TRASH($guideId: String!) {
  guideToTrash: update_boatnew_guides_by_pk(pk_columns: {id: $guideId}, _set: {
    trash: true
  }) {
    id
  }
}
`;

export const INSERT_USER = gql`
mutation INSERT_USER($input: boatnew_users_insert_input!) {
  insertUsers: insert_boatnew_users_one(object: $input) {
    id
  }
}
`;

export const INSERT_GUIDE_CITY = gql`
mutation INSERT_GUIDE_CITY($input: boatnew_cities_guides_insert_input!) {
  insertGuidesCity: insert_boatnew_cities_guides_one(object: $input) {
    city_id
  }
}
`;

export const EDIT_USER = gql`
  mutation EDIT_USER($userId: String!, $input: boatnew_users_set_input! ) {
    editUser: update_boatnew_users_by_pk(pk_columns: {id: $userId}, _set: $input) {
      id
    }
  }
`;

export default FETCH_GUIDES;
