import React, { useContext, useState } from 'react';
import { TabPane } from 'react-bootstrap';
import BookingContext from '../../context/booking';
import Details from '../../pages/Bookings/components/Modal/components/Details';
import EditBooking from '../../pages/Bookings/components/Modal/components/EditBooking/EditBooking';

function BookingInfo() {
  const booking = useContext(BookingContext);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <TabPane
      eventKey="Booking Info"
      title="Booking Info"
    >
      <div className="BookingModal__container">
        { booking
          && (isEditing ? (
            <EditBooking
              clickedBooking={booking}
              setIsEditing={setIsEditing}
            />
          )
            : (
              <Details
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                clickedBooking={booking}
              />
            ))}
      </div>
    </TabPane>
  );
}

export default BookingInfo;
