import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { TabPane } from 'react-bootstrap';
import BookingContext from '../../context/booking';
import Reservation from '../../pages/Bookings/components/Modal/components/Reservations/Reservation';
import { FETCH_BOOKING_BY_ID } from '../../api/bookings';
import { UPDATE_RESERVATION_STATUS } from '../../api/reservations';

function BookingReservations() {
  const booking = useContext(BookingContext);
  const { reservations } = booking;
  const [updateReservationStatus] = useMutation(UPDATE_RESERVATION_STATUS);

  console.log(reservations);

  return (
    <TabPane
      eventKey="Reservations"
      title="Reservations"
    >
      <div className="BookingModal__container">
        { booking
          && (
            <Reservation
              updateReservationStatus={updateReservationStatus}
              clickedBooking={booking}
            />
          )}
      </div>
    </TabPane>
  );
}

export default BookingReservations;
