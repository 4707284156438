import React, { createContext, useEffect, useState } from "react";
import { gql, useLazyQuery, useSubscription } from "@apollo/client";
import { Auth } from "aws-amplify";

// Define your GraphQL query to fetch user permissions
const GET_USER_BY_EMAIL = gql`
  subscription GetUserByEmail($email: String!) {
    user: boatnew_users(where: { email: { _eq: $email } }) {
      id
      name
      email
      role
      permissions
    }
  }
`;

export const UserDocContext = createContext(undefined);

function AccessControl({ children }) {
  const [userEmail, setUserEmail] = useState("");
  const [userDoc, setUserDoc] = useState(null);
  const { data, loading } = useSubscription(GET_USER_BY_EMAIL, {
    variables: { email: userEmail },
  });

  useEffect(async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log(user, user.attributes.email);
    setUserEmail(user.attributes.email);
  }, []);

  useEffect(async () => {
    setUserDoc(data?.user[0]);
  }, [data]);

  return (
    <UserDocContext.Provider value={userDoc}>
      {children}
    </UserDocContext.Provider>
  );
}

export default AccessControl;
