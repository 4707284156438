import lodash from 'lodash';

const getOperator = (initialOperator) => {
  switch (initialOperator) {
    case 'contains':
    case 'startsWith':
    case 'endsWith':
      return '_ilike';
    case 'notContains':
      return '_nilike';
    default:
      return 'hi there';
  }
};

const getFilterObject = (initialObject) => {
  switch (initialObject.type) {
    case 'contains':
    case 'notContains':
      return { [getOperator(initialObject.type)]: `%${initialObject.filter}%` };
    case 'startsWith':
      return { [getOperator(initialObject.type)]: `${initialObject.filter}%` };
    case 'endsWith':
      return { [getOperator(initialObject.type)]: `%${initialObject.filter}` };
    default:
      return null;
  }
};

const bookingsFilterFormat = (filters) => {
  const newFilters = {};
  Object.entries(filters).forEach((array) => {
    const key = array[0];
    const value = array[1];
    switch (key) {
      case 'tour':
        if (!value.filterModels[0]) {
          return false;
        }
        if (Object.prototype?.hasOwnProperty?.call(value.filterModels[0], 'operator')) {
          return false;
        }
        newFilters.product = {
          name: getFilterObject(value.filterModels[0]),
        };
        return newFilters;
      default:
        return false;
    }
  });

  return newFilters;
};

export default bookingsFilterFormat;
