import { gql } from '@apollo/client';

const DELETE_ABONDONED = gql`
  mutation DELETE_ABONDONED($id: Int!) {
    delete_logs_abandoned_checkouts(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export default DELETE_ABONDONED;
