/* eslint-disable */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import star from '../../assets/star-half.png';
import avatar from '../../assets/avatar.png';
import styles from './Sessions.module.scss';

interface AvailabilitySlot {
  time: string;
  available: number;
  seats: number;
}

interface Guide {
  id: string;
  name: string;
  blocked: boolean;
  rating: string;
  availabilities: AvailabilitySlot[];
}

interface Availability {
  date: string;
  guides: Guide[];
}

interface AvailabilityPopupProps {
  open: boolean;
  onClose: () => void;
  availabilityData: Availability[];
  selectedTime: string; // New prop to specify the selected time
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const AvailabilityPopup: React.FC<AvailabilityPopupProps> = ({ open, onClose, availabilityData, selectedTime }) => {
  console.log('availabilty:', availabilityData);
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      PaperProps={{ className: styles.dialogPaper }}
      BackdropProps={{ style: { opacity: 0.5 } }}
    >
      <DialogTitle className={styles.dialogTitle}>Availability Details</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {availabilityData && availabilityData.length > 0 ? (
          <div>
            {availabilityData.map((availability) => (
              <div key={availability.date} className={styles.availabilityContainer}>
                <div className={styles.availabilityHeader}>
                  <h4 className={styles.availabilityTitle}>{capitalizeFirstLetter(selectedTime) }</h4>
                  <span className={styles.availabilityDate}>{availability.date}</span>
                </div>
                {availability.guides.length > 0 ? (
                  availability.guides.map((guide) => (
                    <div key={guide.id} className={styles.guideContainer}>
                      {guide.availabilities
                        .filter(slot => slot.time === selectedTime)
                        .map((slot, index) => (
                          <div key={index} className={styles.slotContainer}>
                            <div className={styles.guideInfo}>
                              <div className={styles.guideName}>
                                <img src={avatar} height={32} alt="avatar" />
                                {guide.name}
                                {guide.blocked}
                              </div>
                              <div className={styles.availableSeats}>
                                : Available seats {slot.available}
                              </div>
                            </div>
                            <div className={styles.ratingContainer}>
                              <div><img src={star} alt='rating' /></div>
                              <div className={styles.ratingText}>
                                {parseFloat(guide.rating).toFixed(1)}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p>No guides are available for this shift</p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No availability data</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={styles.closeButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AvailabilityPopup;
