import React, { createContext, useState, useMemo } from 'react';

export const NavbarContext = createContext();

export function NavbarProvider({ children }) {
  const [showNavbar, setShowNavbar] = useState(false);

  const contextValue = useMemo(() => ({
    showNavbar,
    setShowNavbar,
  }), [showNavbar]);

  return (
    <NavbarContext.Provider value={contextValue}>
      {children}
    </NavbarContext.Provider>
  );
}
