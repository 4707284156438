import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import Nav from './components/Nav/Nav';
import SiteRoutes from './utils/SiteRoutes';
import './App.scss';
import './Auth.scss';
import 'react-toastify/dist/ReactToastify.css';
import { NavbarContext } from './context/navbar.context';
import ScrollToTop from './pages/Bookings/utils/ScrollToTop';
import NewsSlider from './components/NewsSlider/NewsSlider';

Amplify.configure({
  // All options here:
  // https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
  region: process.env.REACT_APP_USERPOOL_REGION,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
  mandatorySignIn: true,
  cookieStorage: {
    domain: process.env.NODE_ENV.toUpperCase() === 'PRODUCTION' ? window.location.host : 'localhost',
    path: '/',
    expires: 2,
    sameSite: 'lax',
    secure: true,
  },
});

function App() {
  const { showNavbar } = useContext(NavbarContext);
  return (
    <Router>
      <Nav />
      <ScrollToTop />
      <div className={`App__wrapper-${showNavbar ? 'sidebar-open' : 'sidebar-closed'}`}>
        <SiteRoutes />
      </div>
      <NewsSlider />
      <ToastContainer />
    </Router>
  );
}

export default App;
