import React, { memo } from 'react';
import SubtaskAddForm from './SubtaskAddForm';
import Subtask from './Subtask';
import '../../styles/Subtask.scss';

const Subtasks = ({
  clickedBooking,
  deleteTask,
  updateTask,
  addTask,
}) => (
  <div className="Subtask__wrapper">
    <p className="BookingModal__header">Subtasks</p>
    {clickedBooking.subtasks.map((subtask) => (
      <div className="Subtask__task" key={subtask.subtaskId}>
        <Subtask
          subtask={subtask}
          deleteTask={deleteTask}
          updateTask={updateTask}
        />
      </div>
    ))}
    <div className="Subtask__task-add-form">
      <SubtaskAddForm
        addTask={addTask}
        bookingId={clickedBooking.id}
      />
    </div>
  </div>
);

export default memo(Subtasks);
