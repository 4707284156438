import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function Page6({
  formik,
}) {
  const { values, errors, handleChange } = formik;
  return (
    <div className="EditForm__form-page6" style={{ padding: '0px 0px 30px 0px' }}>
      <Form.Group>
        <Form.Label>Rezdy ID</Form.Label>
        <InputGroup className={errors.rezdy_id ? 'is-invalid' : ''}>
          <Form.Control
            id="rezdy_id"
            name="rezdy_id"
            type="text"
            placeholder="Enter Rezdy Id"
            onChange={handleChange}
            value={values.rezdy_id}
            className={errors.rezdy_id ? 'is-invalid' : ''}
          />
          <InputGroup.Text
            onClick={() => {
              formik.setFieldValue('rezdy_id', '');
            }}
          >
            <i className="far fa-times" />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export default Page6;
