import React, { useState } from 'react';
import dayjs from 'dayjs';
import NotesEditForm from '../Notes/NotesEditForm';
import ConfirmationPrompt from './ConfirmationPrompt';

function GuideNote({
  note,
  deleteNote,
  updateNote,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [clickedNote, setClickedNote] = useState({
    noteId: '',
    noteVal: '',
  });

  const handleDeleteNote = () => {
    deleteNote({ variables: { id: clickedNote.noteId } });
  };

  return (
    <>
      {(showEditForm && note.id === clickedNote.noteId)
        ? (
          <NotesEditForm
            updateNote={updateNote}
            setShowEditForm={setShowEditForm}
            setShowOptions={setShowOptions}
            clickedNote={clickedNote}
            setClickedNote={setClickedNote}
          />
        )
        : (
          <>
            <div className="Notes__note-container" key={note.id}>
              <div className="Notes__note-msg">
                <span className="Notes__note-msg-date">
                  {`Updated at: ${dayjs(note.updatedAt).format('MMM DD, YYYY')} `}
                  {`Created at: ${dayjs(note.createdAt).format('MMM DD, YYYY')}`}
                </span>
                {note.note}
              </div>
            </div>

            <div
              className="Notes__note-options"
              onMouseEnter={(e) => {
                setClickedNote({
                  ...clickedNote,
                  noteId: e.target.getAttribute('id'),
                  noteVal: e.target.getAttribute('data-value'),
                });
                setShowOptions(true);
              }}
              onMouseLeave={() => {
                setShowOptions(false);
              }}
            >
              {(showOptions && note.id === clickedNote.noteId)
                ? (
                  <div className={`Notes__note-options-container ${showOptions && 'open'}`}>
                    <button
                      type="button"
                      onClick={() => {
                        setShowEditForm(true);
                      }}
                    >
                      <i className="fas fa-pen" />
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowConfirmation(true)}
                    >
                      <i className="fas fa-trash" />
                    </button>
                  </div>
                )
                : (
                  <i
                    className="Notes__note-options-btn fal fa-ellipsis-v"
                    id={note.id}
                    data-value={note.note}
                  />
                )}
            </div>
          </>
        )}
      <ConfirmationPrompt
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        handleDeleteNote={handleDeleteNote}
      />
    </>
  );
}

export default GuideNote;
