import { gql } from '@apollo/client';

const FETCH_BOOKINGS_SCHEDULE = gql`
subscription FETCH_BOOKINGS_SCHEDULE($date: date!, $city: String!) {
  bookings: boatnew_v_scheduling(where: {
      tour_date: {_eq: $date},
      product: {cities_products: {city_id: {_eq: $city}}},
      status: {_neq: "CANCELLED"}
    }, order_by: [
      {tour_time: asc},
      {product: {name: asc}},
      {pickup_location: asc}
    ]) {
    id
    status
    customer {
      contactName: contact_name
    }
    timeGroup: time_group
    product {
      id
      name
    }
    tourTime: tour_time
    pickupLocation:pickup_location
    pickupTime:pickup_time
    pickupLocation: pickup_location
    tourTime: tour_time
    assignedGuide: assigned_guide
    bookingUnits: booking_units {
      quantity
      unit {
        id
        label
      }
    }
    bookingVehicles: booking_vehicles {
      vehicleId: vehicle_id
      guideId: guide_id
    }
  }
}
`;

const UPDATE_BOOKINGS_ASSIGN_VEHICLE = gql`
mutation UPDATE_BOOKINGS_ASSIGN_VEHICLE(
  $deleteObj: [boatnew_booking_vehicles_bool_exp!]!,
  $insertObj: [boatnew_booking_vehicles_insert_input!]!
) {
  delete_boatnew_booking_vehicles(where: 
   {_or: $deleteObj
  }) {
    affected_rows
  }
  insert_boatnew_booking_vehicles(
    objects: $insertObj,
    on_conflict: {
      constraint: booking_vehicles_pkey, update_columns: [vehicle_id, guide_id]
      }) {
    affected_rows
  }
}
`;

export {
  FETCH_BOOKINGS_SCHEDULE,
  UPDATE_BOOKINGS_ASSIGN_VEHICLE,
};
