/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import ListGroup from 'react-bootstrap/ListGroup';
import './PageStyling/Page8.scss';
import { number } from 'yup';
import { isNumber } from 'lodash';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import useAxios from '../../../../hooks/firestore/fetch-data'; 
import { ADD_ROUTE_PLAN_URL } from '../../../../api/Product';
import { useMutation } from '@apollo/client';
import 'react-toastify/dist/ReactToastify.css';

import { createPlacesObject } from '../../utils/itinerary';

function Page8({ formik }) {
  const { values, errors, handleChange } = formik;

  const {
    data,
    loading,
    error: axiosError,
  } = useAxios(
    `https://us-central1-see-sight-tours-backend.cloudfunctions.net/boat/product/${values?.id}/itenary`,
  );
  const { data: attractionData } = useAxios(
    `https://us-central1-staging-c0818.cloudfunctions.net/v1/cities/attractions/${values?.locations?.[0]?.city_id}`,
  );
  useEffect(() => {
    console.log(attractionData);
  }, [attractionData]);
  const [placesInfo, setPlacesInfo] = useState([]);
  const [iframeSrc, setIframeSrc] = useState('');
  const [lastIndexLongitude, setLastIndexLongitude] = useState('');
  const [waypoint, setWayPoint] = useState('');
  const [lastIndexlatitude, setLastIndexlatitude] = useState('');
  const [error, setError] = useState(null);
  const [startPointIndex, setStartPointIndex] = useState(0);
  const [endPointIndex, setEndPointIndex] = useState(0);
  const [dataFromFirestore, setDataFromFirestore] = useState([]);
  const [defaultUrl, setDefaultUrl] = useState('');
  const [selectedAttractions, setSelectedAttractions] = useState([]);
  const [initialAttractions, setInitialAttractions] = useState([]);
  const [customStopFields, setCustomStopFields] = useState(
    Array.from({ length: placesInfo.length }, () => ({
      name: '',
      operationalHours: '',
      bannerURL: '',
    })),
  );
  const [typData, setTypData] = useState([]);
  const [mainUrl, setMainUrl] = useState(data?.data?.routePlanUrl ?? '');
  useEffect(() => {
    const initialTypData = data?.data?.routePlan?.map((e) => e.type?.label);
    if (initialTypData && initialTypData.length > 0) {
      setTypData(initialTypData);
    }
  }, [data]);

  useEffect(() => {
    if (typData && typData.length > 0) {
      setSelectedAttractions(typData);
    }
  }, [typData]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [urlChanged, setUrlChanged] = useState(false);
  const [attractionErrors, setAttractionErrors] = useState(
    Array.from({ length: placesInfo.length }, () => false),
  );

  const [customStopNames, setCustomStopNames] = useState(
    Array.from({ length: placesInfo.length }, () => ''),
  );
  const [customStopOperationalHours, setCustomStopOperationalHours] = useState(
    Array.from({ length: placesInfo.length }, () => ''),
  );
  const [customStopBannerURLs, setCustomStopBannerURLs] = useState(
    Array.from({ length: placesInfo.length }, () => ''),
  );
  const [attractionTime, setAttractionTimes] = useState(
    Array.from({ length: placesInfo.length }, () => ''),
  );

  const handleAttractionChange = (index, value) => {
    setSelectedAttractions((prevAttractions) => {
      const isUnique = !prevAttractions
        .filter((attraction) => attraction.name !== 'Custom Stop') // Ignore "Custom Stop"
        .some((attraction) => attraction.name === value);

      if (!isUnique) {
        toast.error('Attraction names must be unique');
        return prevAttractions;
      }

      const newAttractions = [...prevAttractions];
      newAttractions[index] = value;

      // Reset custom stop fields if 'Custom Stop' is selected
      if (value === 'Custom Stop') {
        setCustomStopNames((prevNames) => {
          const newNames = [...prevNames];
          newNames[index] = '';
          return newNames;
        });
        setCustomStopOperationalHours((prevHours) => {
          const newHours = [...prevHours];
          newHours[index] = '';
          return newHours;
        });
        setCustomStopBannerURLs((prevURLs) => {
          const newURLs = [...prevURLs];
          newURLs[index] = '';
          return newURLs;
        });
        setTypData((prevTypData) => {
          const newTypData = [...prevTypData];
          newTypData[index] = {
            id: `Custom Stop_${index}`,
            name: 'Custom Stop',
          }; // Or add other necessary fields
          return newTypData;
        });
      }

      // Reset attraction time
      setAttractionTimes((prevAttractionTimes) => {
        const newAttractionTimes = [...prevAttractionTimes];
        newAttractionTimes[index] = '';
        return newAttractionTimes;
      });

      // Update the selected attraction data
      const selectedAttraction = attractionData.find(
        (attraction) => attraction.name === value,
      );
      if (selectedAttraction) {
        const newTypData = [...typData];
        newTypData[index] = { id: selectedAttraction.id, name: value };
        setTypData(newTypData);
      }

      // Update attraction errors
      setAttractionErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = value === 'Please select an option';
        return newErrors;
      });

      return newAttractions;
    });
  };

  // Assuming handleAttractionTimeChange is defined elsewhere and cannot be edited directly
  const handleAttractionTimeChange = (index, value) => {
    setAttractionTimes((prevAttractionTimes) => {
      const newAttractionTimes = [...prevAttractionTimes];
      newAttractionTimes[index] = value;
      return newAttractionTimes;
    });
  };

  useEffect(() => {
    if (data?.data?.routePlan && data?.data?.routePlan.length > 0) {
      const initialAttractionTimes = Array.from(
        { length: placesInfo.length },
        () => '',
      );
      data?.data?.routePlan.forEach((stop) => {
        const index = stop.id; // Assuming stop.id is the correct index in your routePlan array
        initialAttractionTimes[index] = stop.attractionTime || '';
      });
      setAttractionTimes(initialAttractionTimes);
    }
  }, [data, placesInfo]);

  const handleCustomStopNameChange = (index, name) => {
    console.log(`Custom Stop Name changed at index ${index} to:`, name);
    setCustomStopNames((prevNames) => {
      const newNames = [...prevNames];
      newNames[index] = name;
      return newNames;
    });
  };

  // Update custom stop operational hours
  const handleCustomStopOperationalHoursChange = (index, hours) => {
    console.log(
      `Custom Stop Operational Hours changed at index ${index} to:`,
      hours,
    );
    setCustomStopOperationalHours((prevHours) => {
      const newHours = [...prevHours];
      newHours[index] = hours;
      return newHours;
    });
  };

  // Update custom stop banner URL
  const handleCustomStopBannerURLChange = (index, url) => {
    console.log(`Custom Stop Banner URL changed at index ${index} to:`, url);
    setCustomStopBannerURLs((prevURLs) => {
      const newURLs = [...prevURLs];
      newURLs[index] = url;
      return newURLs;
    });
  };

  useEffect(() => {
    setDefaultUrl(data?.data?.routePlanUrl);
  }, [iframeSrc]);
  const [submitted, setSubmitted] = useState(false);
  const initialUrls = data?.data?.routePlanUrl ?? [{ id: 1, value: '' }];
  const [urls, setUrls] = useState(initialUrls);
  const [key, setKey] = useState(0);
  const [addRoutePlanUrl, { data: addRoutePlanUrlData, error: addRoutePlanUrlError }] = useMutation(ADD_ROUTE_PLAN_URL);
  const handleSubmit = async () => {
    // Validate dropdowns
    const selects = document.querySelectorAll('select[required]');
    let invalidSelect = false;

    selects.forEach((select) => {
      if (select.value === '') {
        invalidSelect = true;
        select.setCustomValidity('Please select an option for this field.');
      } else {
        select.setCustomValidity('');
      }
    });

    if (invalidSelect) {
      return;
    }

    // Rest of Logic
    try {
      setLoadingSubmit(true);

      // Check if custom stop fields are provided for any selected custom stops
      const customStopFieldsProvided = selectedAttractions.some(
        (attraction, index) => attraction === 'Custom Stop'
          && (!customStopNames[index]
            || customStopNames[index].trim() === ''
            || customStopOperationalHours[index].trim() === ''
            || customStopBannerURLs[index].trim() === ''),
      );

      if (customStopFieldsProvided) {
        setSubmitted(true);
        toast.error(
          'Please enter a name, operational hours, and banner for all custom stops.',
        );
        setLoadingSubmit(false);
        return;
      }

      // Check if any attraction time is empty
      const invalidAttractionTime = attractionTime.some((time, index) => {
        if (
          selectedAttractions[index]?.name !== 'Custom Stop'
          && (time === '' || time === undefined)
        ) {
          toast.error(
            `Attraction time is required for place at index ${index}`,
          );
          return true;
        }
        return false;
      });

      if (invalidAttractionTime) {
        setLoadingSubmit(false);
        return;
      }

      const updatedPlacesInfo = placesInfo.map((place, index) => {
        if (selectedAttractions[index]?.name === 'Custom Stop') {
          return {
            ...place,
            CustomStop: {
              name: customStopNames[index],
              operationalHours: customStopOperationalHours[index],
              bannerURL: customStopBannerURLs[index],
            },
          };
        }
        return place;
      });

      const mainUrls = urls.map((url) => url.value);
      if (mainUrls.includes('')) {
        toast.error('URLs cannot be empty');
        setLoadingSubmit(false); // Update key to trigger re-render
        return;
      }

      // const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?(dir\/[\d.,@z!]+)?$/;
      // if (mainUrls.some((url) => !urlPattern.test(url))) {
      //   toast.error('Error: Invalid URL format.');
      //   setLoadingSubmit(false);
      //   return;
      // }

      const urlSet = new Set(mainUrls);
      if (urlSet.size !== mainUrls.length) {
        toast.error('Duplicate URLs are not allowed');
        setLoadingSubmit(false);
        return;
      }

      const allUrlsValid = mainUrls.every((url) => url.includes('google') && url.includes('maps'));
      if (!allUrlsValid) {
        toast.error('Not a valid google url');
        setLoadingSubmit(false);
        return;
      }

      const selectedAttractionsNames = selectedAttractions
        .filter((item) => item.name !== 'Custom Stop') // Exclude custom stops
        .map((item) => item.name);

      const seenNames = new Set();
      let hasDuplicates = false;

      selectedAttractionsNames.forEach((name) => {
        if (seenNames.has(name)) {
          hasDuplicates = true;
        }
        seenNames.add(name);
      });

      if (hasDuplicates) {
        toast.error('You cannot select the same attraction more than once');
        setLoadingSubmit(false);
        return;
      }

      const onlyPositiveTime = attractionTime
        .filter((time, index) => selectedAttractions[index].name !== 'Custom Stop')
        .every((time) => time > 0);
      console.log('onlyPositiveTime', onlyPositiveTime);
      if (!onlyPositiveTime) {
        toast.error('Attraction time must be greater than 0');
        setLoadingSubmit(false);
        return;
      }

      addRoutePlanUrl({
        variables: {
          id: values?.id,
          route_plan_url: mainUrls.toString(),
        },
      });

      const endpoint1 = `https://us-central1-see-sight-tours-backend.cloudfunctions.net/boat/product/${values?.id}/itenary`;
      const endpoint2 = `https://us-central1-staging-c0818.cloudfunctions.net/boat/product/${values?.id}/itenary`;

      const payload = {
        routePlanUrl: mainUrls,
        routePlan: updatedPlacesInfo.map((place, index) => {
          const isCustomStop = selectedAttractions[index].name === 'Custom Stop';
          const isAttraction = !isCustomStop;
          const isStartPoint = index === 0;
          const isEndPoint = index === updatedPlacesInfo.length - 1;
      
          return {
            id: index,
            attractionId: isCustomStop ? null : selectedAttractions[index].id,
            label: place?.Label[0],
            customStopName: place.CustomStop?.name,
            operationalHours: place.CustomStop?.operationalHours,
            attractionTime: attractionTime[index],
            bannerURL: place.CustomStop?.bannerURL,
            coordinates: [
              place.Coordinates.longitude,
              place.Coordinates.latitude
            ],
            type: {
              label: selectedAttractions[index],
              startPoint: isStartPoint,
              endPoint: isEndPoint,
              attraction: isAttraction && (isStartPoint || isEndPoint || (!isStartPoint && !isEndPoint)),
              custom: isCustomStop && (isStartPoint || isEndPoint || (!isStartPoint && !isEndPoint)),
            },
          };
        }),
      };
      
      
      axios.all([
        axios.put(endpoint1, payload),
        axios.put(endpoint2, payload)
      ])
        .then(axios.spread((response1, response2) => {
          console.log('Response from endpoint 1:', response1.data);
          console.log('Response from endpoint 2:', response2.data);
        }))
        .catch(errors => {
          console.error('Error in requests:', errors);
        });

      toast.success('Route plan updated successfully!');
      setLoadingSubmit(false);
      console.log('Updated Route Plan:', updatedPlacesInfo);
    } catch (error) {
      console.error('Error updating route plan:', error);
      toast.error('Failed to update route plan. Please try again.');
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataFromFirestore(data?.data?.routePlan);
      setMainUrl(data?.data?.routePlanUrl);
      const initialUrls = (data?.data?.routePlanUrl?.map((url) => ({ id: uuidv4(), value: url })) ?? [{ id: uuidv4(), value: '' }]) || [];
      setUrls(initialUrls);

      const placesData = [];
      initialUrls.forEach((urlObj) => {
        const extractedData = createPlacesObject(urlObj.value);
        if (extractedData) {
          placesData.push(...extractedData);
        }
      });
      setUrlChanged(true);

      if (placesData.length > 0) {
        setPlacesInfo(placesData);
        setError(null);
      } else {
        setPlacesInfo([]);
        setError('Invalid Google Maps URL');
      }
    }
    if (axiosError) {
      console.error(axiosError);
    }
  }, [data, axiosError]);
  useEffect(() => {
    if (placesInfo.length > 0) {
      const lastIndex = placesInfo.length - 1;
      const lastPlaceInfo = placesInfo[lastIndex];
      setLastIndexLongitude(lastPlaceInfo?.Coordinates?.longitude);
      setLastIndexlatitude(lastPlaceInfo?.Coordinates?.latitude);
      setEndPointIndex(lastIndex);
    }
  }, [placesInfo]);
  useEffect(() => {
    if (placesInfo.length > 2) {
      const lastIndex = placesInfo.length - 1;
      const lastPlaceInfo = placesInfo[lastIndex];
      setLastIndexLongitude(lastPlaceInfo?.Coordinates?.longitude);
      setLastIndexlatitude(lastPlaceInfo?.Coordinates?.latitude);
      setEndPointIndex(lastIndex);
      const coordinatesBetweenIndices = placesInfo
        .slice(1, lastIndex)
        .map((place) => place.Coordinates);
      const waypoints = coordinatesBetweenIndices
        .map((coord) => `${coord.longitude},${coord.latitude}`)
        .join('|');
      setIframeSrc(
        `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY}&origin=${placesInfo[0]?.Coordinates?.longitude},${placesInfo[0]?.Coordinates?.latitude}&destination=${lastIndexLongitude},${lastIndexlatitude}&waypoints=${waypoints}`,
      );
    } else {
      const originCoordinates = placesInfo[0]?.Coordinates;
      const destinationCoordinates = placesInfo[1]?.Coordinates;
      setIframeSrc(
        `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY}&origin=${originCoordinates?.longitude},${originCoordinates?.latitude}&destination=${destinationCoordinates?.longitude},${destinationCoordinates?.latitude}`,
      );
    }
  }, [placesInfo, lastIndexLongitude, endPointIndex]);

  const [initialCustomValues, setInitialCustomValues] = useState({
    customStopNames: [],
    customStopOperationalHours: [],
    customStopBannerURLs: [],
  });
  useEffect(() => {
    if (data?.data?.routePlan && data?.data?.routePlan.length > 0) {
      const initialCustomStopNames = Array.from(
        { length: placesInfo.length },
        () => '',
      );
      const initialCustomStopOperationalHours = Array.from(
        { length: placesInfo.length },
        () => '',
      );
      const initialCustomStopBannerURLs = Array.from(
        { length: placesInfo.length },
        () => '',
      );

      data?.data?.routePlan.forEach((stop) => {
        if (stop.type.label.name === 'Custom Stop') {
          const index = stop.id;
          initialCustomStopNames[index] = stop.customStopName || '';
          initialCustomStopOperationalHours[index] = stop.operationalHours || '';
          initialCustomStopBannerURLs[index] = stop.bannerURL || '';
        }
      });

      setCustomStopNames(initialCustomStopNames);
      setCustomStopOperationalHours(initialCustomStopOperationalHours);
      setCustomStopBannerURLs(initialCustomStopBannerURLs);

      // Set initial values for custom stops
      setInitialCustomValues({
        customStopNames: initialCustomStopNames,
        customStopOperationalHours: initialCustomStopOperationalHours,
        customStopBannerURLs: initialCustomStopBannerURLs,
      });
    }
  }, [data]); // Empty dependency array to run this effect only once
  const [hasInvalidUrlError, setHasInvalidUrlError] = useState(false);
  const handleUrlChange = (event, id) => {
    setHasInvalidUrlError(false);
    const { value } = event ? event.target : { value: '' };

    const updatedUrls = urls.map((url) => (url.id === id ? { ...url, value } : url));
    setUrls(updatedUrls);

    // Reset necessary state values
    setMainUrl(value);
    setError(null);
    setPlacesInfo([]);
    setUrlChanged(true);

    // Parse the URLs to extract places data
    const placesData = [];
    const coordinatePattern = /^\d{2}\.\d{7}-\d{2}\.\d{7}$/;

    updatedUrls.forEach((urlObj) => {
      if (urlObj.value) {
        try {
          const extractedData = createPlacesObject(urlObj.value);
          if (extractedData) {
            const hasInvalidLabel = extractedData.some((item) => coordinatePattern.test(item.Label[0]));

            if (hasInvalidLabel) {
              setHasInvalidUrlError(true);
              toast.error('Invalid label: Labels cannot include coordinates');
              setLoadingSubmit(false);
              return;
            }

            placesData.push(...extractedData);
          }
        } catch (error) {
          console.error('Error processing URL:', error);
          toast.error('Invalid URL');
          setLoadingSubmit(false);
          setHasInvalidUrlError(true);
        }
      }
    });

    if (placesData.length > 0) {
      setPlacesInfo(placesData);
    } else {
      setError('Invalid Google Maps URL');
    }
  };

  const addUrlField = () => {
    setUrls([...urls, { id: urls.length + 1, value: '' }]);
  };

  const removeUrlField = (id) => {
    const updatedUrls = urls.filter((url) => url.id !== id);
    setUrls(updatedUrls);
    // Remove the corresponding selected attraction name
    const updatedSelectedAttractions = selectedAttractions.filter((attraction) => attraction.id !== id);
    setSelectedAttractions(updatedSelectedAttractions);
    // Reset necessary state values
    setError(null);
    setPlacesInfo([]);
    setUrlChanged(true);

    // Parse the remaining URLs to extract places data
    const placesData = [];
    updatedUrls.forEach((urlObj) => {
      if (urlObj.value) {
        console.log('Processing urlObj:', urlObj);
        const extractedData = createPlacesObject(urlObj.value);
        console.log('Extracted data:', extractedData);
        if (extractedData) {
          placesData.push(...extractedData);
          console.log('placesData after push:', placesData);
        }
      }
    });
    console.log('placesData', placesData);
    if (placesData.length > 0) {
      setPlacesInfo(placesData);
    } else {
      setError('Invalid Google Maps URL');
    }
  };
console.log('selectedAttraction', selectedAttractions);
  return (
    <div
      className="EditForm__form-page6"
      style={{ padding: '0px 0px 30px 0px' }}
    >
      <div>
        <Form.Group>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Label>Google Map URLs</Form.Label>
            {placesInfo.length === 0 && <Form.Label style={{ color: 'red' }}>Add an Itinerary (Mandatory)</Form.Label>}
          </div>
          {urls.map((url, index) => (
            <InputGroup key={url.id} className="mb-3">
              <Form.Control
                type="text"
                placeholder={`Enter Google Url ${index + 1}`}
                value={url.value}
                onChange={(event) => handleUrlChange(event, url.id)}
                className={errors[`GoogleUrl${url.id}`] ? 'is-invalid' : ''}
              />
              {urls.length > 1 && (
                <Button
                  variant="danger"
                  onClick={() => removeUrlField(url.id)}
                  className="ml-2"
                  style={{
                    backgroundColor: '#e05f63', color: 'white', fontSize: '20px', marginLeft: '10px',
                  }}
                >
                  -
                </Button>
              )}
            </InputGroup>
          ))}
          <Button variant="primary" onClick={addUrlField} style={{ backgroundColor: '#002b49', color: 'white', fontSize: '20px' }}>
            +
          </Button>
          {placesInfo.length >= 10 && (
            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
          )}
        </Form.Group>
      </div>
      {/* <ToastContainer position="top-center" autoClose={5000} hideProgressBar /> */}
      <div className="coord-container">
        <div className="coordinates">
          {placesInfo ? (
            placesInfo.some((place) => place.Label.includes('Your Location')) ? (
              <div className="error-message">
                Instead of 'Your Location,' choose the name of your location
              </div>
            ) : (
              <div className="coordinates">
                <ListGroup>
                  {placesInfo.map((place, index) => (
                    <ListGroup.Item key={index}>
                      <div className="internal-container">
                        <div className="places">
                          <div>
                            <h6>{place.Label}</h6>
                            <span>{`lat: ${place.Coordinates.longitude}, lng: ${place.Coordinates.latitude}`}</span>
                          </div>
                          <div>
                            {index === 0 && (
                              <Button disabled size="small" variant="outlined">
                                Start Point
                              </Button>
                            )}
                            {index === placesInfo.length - 1 && (
                              <Button disabled size="small" variant="outlined">
                                End Point
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="drop-down">
                          <div>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => handleAttractionChange(index, e.target.value)}
                              className={
                                submitted && attractionErrors[index]
                                  ? 'is-invalid'
                                  : ''
                              }
                              required
                              value={
                                typData[index] && typData[index].name
                                  ? typData[index].name
                                  : ''
                              }
                            >
                              <option value="" disabled>
                                Please select an option
                              </option>
                              <option key="Custom Stop" value="Custom Stop">
                                Custom Stop
                              </option>
                              {attractionData?.map(
                                (attraction, attractionIndex) => (
                                  <option
                                    key={attractionIndex}
                                    value={attraction.name}
                                  >
                                    {attraction.name}
                                  </option>
                                ),
                              )}
                            </Form.Select>
                            {selectedAttractions[index]?.name
                              !== 'Custom Stop' && (
                                <Form.Control
                                  id="attraction_time"
                                  name="attractionTime"
                                  type="number"
                                  placeholder="Add duration in minutes"
                                  onChange={(e) => handleAttractionTimeChange(
                                    index,
                                    e.target.value,
                                  )}
                                  value={attractionTime[index]}
                                  style={{ marginTop: '10px' }}
                                  className={
                                    errors?.GoogleUrl ? 'is-invalid' : ''
                                  }
                                  required
                                />
                              )}
                          </div>

                          <div className="main-custom-stops-container">
                            {selectedAttractions[index]?.name
                              === 'Custom Stop' && (
                                <div className="custom-stops-container">
                                  <div className="s">
                                    <div className="label mt">
                                      <Form.Control
                                        type="text"
                                        placeholder="name"
                                        value={customStopNames[index]}
                                        onChange={(e) => handleCustomStopNameChange(
                                          index,
                                          e.target.value,
                                        )}
                                        required
                                      />
                                    </div>
                                    <div className="operational-hours mt">
                                      <Form.Control
                                        type="text"
                                        placeholder="Operational Hours"
                                        value={customStopOperationalHours[index]}
                                        onChange={(e) => handleCustomStopOperationalHoursChange(
                                          index,
                                          e.target.value,
                                        )}
                                        required
                                      />
                                    </div>
                                  </div>
                                  {submitted && attractionErrors[index] && (
                                    <div className="invalid-feedback">
                                      Please select an attraction
                                    </div>
                                  )}
                                  <div>
                                    <div className="banner-url mt">
                                      <Form.Control
                                        type="text"
                                        placeholder="Banner URL"
                                        value={customStopBannerURLs[index]}
                                        onChange={(e) => handleCustomStopBannerURLChange(
                                          index,
                                          e.target.value,
                                        )}
                                        // onBlur={() => handleBannerUrlBlur(customStopBannerURLs[index], index)}
                                        required
                                      />
                                    </div>
                                    {/* {bannerPreviewUrls[index] && (
                              <img src={bannerPreviewUrls[index]} alt="Banner Preview" />
                              )}
                              {imageloading && (
                              <div className="loading">Loading...</div>
                              )} */}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        {/* Conditionally show Operational Hours and Banner URL */}
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                <div className="map">
                  <iframe
                    title="Google Map"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0 }}
                    src={iframeSrc}
                    allowFullScreen
                  />
                </div>
              </div>
            )
          ) : (
            <div className="error-message">Provide a correct URL</div>
          )}
        </div>
      </div>

      <div className="EditForm__buttons" style={{ marginTop: '20px' }}>
        <button
          type="button"
          className="EditForm__buttons-cancel"
          id="cancel"
          onClick={() => window.location.reload(false)}
        >
          Cancel
        </button>
        <button
          className="EditForm__buttons-submit"
          type="submit"
          id="submit"
          disabled={
            !urlChanged
            || loadingSubmit
            || placesInfo.some((place) => place.Label.includes('Your Location')) || hasInvalidUrlError
          }
          onClick={handleSubmit}
        >
          {loadingSubmit ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </div>
  );
}

export default Page8;
