import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import '../../Users.scss';
import UserForm from '../UserForm';
import UserSchema from '../../../../yup/user';
import { FETCH_USERS_COUNT_BY_EMAIL, INSERT_USER } from '../../../../api/users';

function CreateUser() {
  const navigate = useNavigate();

  const [insertUser] = useMutation(INSERT_USER, {
    onCompleted: () => {
      toast.success('User created.', {
        autoClose: 3000,
        position: 'bottom-right',
      });
      navigate('/users');
    },
  });
  const [getUserCountByEmail] = useLazyQuery(FETCH_USERS_COUNT_BY_EMAIL);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      role: 'Super_Admin',
      permissions: '"*"',
    },
    validationSchema: UserSchema,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        // Check for duplicate guide by email
        console.log(values);
        const emailCountResult = await getUserCountByEmail({
          variables: { email: values.email.trim() },
        });
        const { data: { users_aggregate: { aggregate: { count } } } } = emailCountResult;

        if (count > 0) {
          setFieldError('email', 'A user with the same email already exists.');
          setSubmitting(false);
          return;
        }
        // If guide doesn't exist, insert new guide
        const { data: { insertUsers: { id } } } = await insertUser({
          variables: {
            input: {
              name: values.name.trim(),
              phone: values?.phone?.trim(),
              email: values.email.trim(),
              role: values.role.trim(),
              permissions: values.permissions.trim(),
            },
          },
        });

        const cognito = new CognitoIdentityServiceProvider({
          region: 'us-east-2',
          credentials: {
            accessKeyId: 'AKIA5X6NMAE4GPYVQUM2',
            secretAccessKey: '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',
          },
        });

        // Create a new user in AWS Cognito user pool
        await cognito.adminCreateUser({
          UserPoolId: process.env.REACT_APP_USERPOOL_ID,
          Username: values.email.trim(),
          TemporaryPassword: generateTemporaryPassword(),
          UserAttributes: [
            {
              Name: 'custom:role',
              Value: 'tech',
            },
            {
              Name: 'email_verified',
              Value: 'true',
            },
            {
              Name: 'email',
              Value: values.email.trim(),
            },
          ],
        }).promise();

        navigate('/users');
      } catch (error) {
        if (error.message.includes('duplicate key value violates unique constraint')) {
          setFieldError('email', 'A user with the same email already exists.');
        } else {
          alert(error);
          console.error(error);
        }
      }
      setSubmitting(false);
    },
  });

  const generateTemporaryPassword = () => {
    const length = 16;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let temporaryPassword = '';
    for (let i = 0; i < length; i += 1) {
      temporaryPassword += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return temporaryPassword;
  };

  return (
    <div className="Vehicles__editVehicle">
      <UserForm formik={formik} />
    </div>
  );
}

export default CreateUser;
