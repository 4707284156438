import React, { useEffect, useRef, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dayjs from 'dayjs';

function DateRange({ setDateRange, createdAt }) {
  const [value, onChange] = useState([new Date(), new Date()]);
  const count = useRef(0);
  useEffect(() => {
    count.current += 1;
    if (value && count.current >= 0) {
      const startDate = dayjs(value[0]).format('YYYY-MM-DD');
      const endDate = createdAt ? dayjs(value[1]).format('YYYY-MM-DD') : dayjs(value[1]).format('YYYY-MM-DD');
      setDateRange([`${startDate}`, `${endDate}`]);
      return;
    }
    if (value && count.current >= 1) {
      const startDate = dayjs(value[0]).format('YYYY-MM-DD');
      const endDate = dayjs(value[1]).format('YYYY-MM-DD');
      setDateRange([`${startDate}`, `${endDate}`]);
    }
  }, [value]);

  return (
    <div className="d-flex">
      <span className="m-1">Select Date</span>
      {' '}
      <DateRangePicker onChange={onChange} value={value} />
    </div>
  );
}
export default DateRange;
