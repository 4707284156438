import React, { useState } from 'react';
import '../../styles/ToursDropdown.scss';

function ToursDropdown({
  name,
  formik,
  toursList,
  toursLoading,
  toursError,
}) {
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Choose product...');
  const toggleDropdown = () => setOpen(!isOpen);

  const handleClick = (e, timeZone) => {
    formik.setFieldValue('product_id', e.target.id);
    formik.setFieldValue('product_type', e.target.getAttribute('type'));
    formik.setFieldValue('time_zone', timeZone);
    setSelectedItem(e.target.getAttribute('name'));
    setOpen(false);
  };
  let displayTours = toursList?.map((city) => (
    <span key={city.name}>
      <span className="BookingModal__tour-dropdown-city">{city.name}</span>
      {city.cities_products?.map((tour) => (
        <div
          key={tour.product.id}
          id={tour.product.id}
          name={tour.product.name}
          type={tour.product.type}
          className="BookingModal__tour-dropdown-item"
          onClick={(e) => handleClick(e, city.timeZone)}
        >
          {tour.product.name}
        </div>
      ))}
    </span>
  ));

  if (toursLoading) {
    displayTours = (
      <div className="BookingModal__tour-dropdown-loader">
        <div>
          <i className="fas fa-cog fa-spin" />
        </div>
        <h3>LOADING...</h3>
      </div>
    );
  }
  if (toursError) displayTours = `Sonething went wrong ${toursError.message}`;

  return (
    <label
      htmlFor={name.toLowerCase()}
      className="BookingModal__info-edit-input"
    >
      <span>{name}</span>
      <div id={name.toLowerCase()} className="BookingModal__tour-dropdown">
        <div className="BookingModal__tour-dropdown-header" onClick={toggleDropdown}>
          {selectedItem}
          <i className={`fa fa-chevron-right BookingModal__tour-dropdown-icon ${isOpen && 'open'}`} />
        </div>
        <div className={`BookingModal__tour-dropdown-body ${isOpen && 'open'}`}>
          {displayTours}
        </div>
      </div>
    </label>
  );
}

export default ToursDropdown;
