import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';

function Column1({
  setSumChecked,
  activeReservation,
  reservation,
  sumChecked,
  reservations,
  chosenBookings,
  setShowEmailTemplate,
  date,
  showEmailTemplate,
  formik,
}) {
  const handleSumCheck = (e) => {
    if (sumChecked === e.target.id) {
      setSumChecked('');
    } else {
      setSumChecked(e.target.id);
    }
  };

  const getSalutation = (contactList) => {
    if (!formik.values.contact) return '';
    let result = '';

    formik.values.contact.map((email, i) => {
      contactList.map((c) => {
        if (c.email === email) {
          result += ` ${
            i === formik.values.contact.map.length - 1 ? '' : ' &'
          } ${c.name}`;
        }
      });
    });
    return result;
  };

  const handleEmailTemplate = (id) => {
    const chosenReservation = reservations.reservations.filter(
      (reservation) => reservation.id === id,
    );

    let info = `<p><b>${dayjs(date).format('dddd, MMMM D, YYYY')}</b> <br />
    <b>${chosenReservation?.[0]?.attraction}</b></p>`;

    if (chosenReservation?.length > 0) {
      const output = _.map(
        _.groupBy(chosenBookings, 'startTime'),
        (obj, key) => {
          const temp = {};
          temp[key] = _.groupBy(obj, 'guide');
          return temp;
        },
      );

      output.map((value) => {
        const time = Object.keys(value)[0];
        const bookingsGroups = Object.values(value)[0];
        const groups = Object.entries(bookingsGroups);

        info += `<p><b>${dayjs(`1/1/2022 ${time}`).format(
          'hh:mm A',
        )} </b> <br /></p>`;
        groups.map((group) => {
          const guide = group[0];
          const bookingsList = group[1];

          const units = new Map();
          let pax = '';
          function totalPax(val, key) {
            pax += `${val} ${key} `;
          }

          if (sumChecked) {
            bookingsList.map((booking) => {
              let group = '';
              booking.units.map((unit) => {
                group
                  += group === ''
                    ? `${unit.quantity} ${unit.unit.label} `
                    : `+ ${unit.quantity} ${unit.unit.label} `;
              });
              pax += `${group}/ `;
            });
          } else {
            bookingsList.map((booking) => {
              booking.units.map((unit) => {
                if (!units.has(unit.unit.label)) {
                  units.set(unit.unit.label, unit.quantity);
                } else {
                  units.set(
                    unit.unit.label,
                    Number(units.get(unit.unit.label)) + unit.quantity,
                  );
                }
              });
            });

            units.forEach(totalPax);
          }

          info += `<p>${pax} | Guide: ${guide} </p>`;
        });
      });

      formik.setFieldValue('id', chosenReservation[0].id);
      formik.setFieldValue('contactCC', 'jay@seesight-tours.com, chris@seesight-tours.com, adrian@seesight-tours.com');
      formik.setFieldValue(
        'subject',
        `Reservations (${dayjs(date).format('dddd, MMMM D, YYYY')})`,
      );
      formik.setFieldValue('contactList', chosenReservation[0].contact.email);
      formik.setFieldValue(
        'body',
        `Hello${getSalutation(chosenReservation[0].contact.email)}, ${
          chosenReservation[0].template.header
        } ${info} ${chosenReservation[0].template.footer}`,
      );

      setShowEmailTemplate(true);
    }
  };

  useEffect(() => {
    if (showEmailTemplate && chosenBookings?.length > 0) {
      handleEmailTemplate(activeReservation);
    }
  }, [chosenBookings, sumChecked, formik.values.contact]);

  useEffect(() => {
    if (showEmailTemplate && chosenBookings?.length > 0) {
      formik.setFieldValue('contact', '');
      formik.setFieldValue('contactCC', '');
    }
  }, [activeReservation]);

  return (
    <td className="td-column1">
      <div className="td-column1-scroll">
        <div className="td-column1-sticky">
          {`${reservation.attraction} (${reservation.id})`}
          <br />

          {
            reservation?.contact?.email?.length > 0 && (
            <button
              disabled={activeReservation !== reservation.id}
              type="button"
              onClick={() => handleEmailTemplate(reservation.id)}
            >
              Open Editor
            </button>
            )
          }

          {reservation.portal?.link && (
          <button
            type="button"
            onClick={() => window.open(reservation.portal?.link)}
          >
            Open Portal
          </button>
          )}

          <label htmlFor={reservation.id} className="td-column1-sumCheck">
            <input
              type="checkbox"
              checked={sumChecked === reservation.id}
              id={reservation.id}
              disabled={activeReservation !== reservation.id}
              onChange={(e) => handleSumCheck(e)}
            />
            Separate groups
          </label>
        </div>
      </div>
    </td>
  );
}

export default Column1;
