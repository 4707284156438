import { gql } from "@apollo/client";
import {
  FRAGMENT_BOOKING,
  FRAGMENT_BOOKING_LATEST_STATUS,
  FRAGMENT_BOOKING_STATUS,
  FRAGMENT_ONGOING_BOOKINGS,
} from "./fragments";

const FETCH_BOOKINGS = gql`
  query FETCH_BOOKINGS($filters: boatnew_booking_bool_exp) {
    bookings: boatnew_booking(where: $filters, order_by: { updated_at: desc }) {
      ...FragmentBooking
    }
  }
  ${FRAGMENT_BOOKING}
`;
const FETCH_LAST_UPDATED_BOOKING = gql`
  subscription FETCH_BOOKINGS_STATUS(
    $filters: boatnew_booking_status_sort_bool_exp
  ) {
    bookings: boatnew_booking_status_sort(
      where: $filters
      order_by: { updated_at: desc }
      limit: 3
    ) {
      ...FragmentBookingStatus
    }
  }
  ${FRAGMENT_BOOKING_STATUS}
`;
const FETCH_BOOKINGS_STATUS_QUERY = gql`
  query FETCH_BOOKINGS_STATUS($filters: boatnew_booking_status_sort_bool_exp) {
    bookings: boatnew_booking_status_sort(where: $filters) {
      ...FragmentBookingStatus
    }
  }
  ${FRAGMENT_BOOKING_STATUS}
`;
const FETCH_BOOKINGS_STATUS = gql`
  subscription FETCH_BOOKINGS_STATUS(
    $filters: boatnew_booking_status_sort_bool_exp
  ) {
    bookings: boatnew_booking_status_sort(where: $filters) {
      ...FragmentBookingStatus
    }
  }
  ${FRAGMENT_BOOKING_STATUS}
`;
const FETCH_ONGOING_BOOKINGS = gql`
  subscription FETCH_ONGOING_BOOKINGS(
    $filters: boatnew_ongoing_bookings_bool_exp
  ) {
    bookings: boatnew_ongoing_bookings(where: $filters) {
      ...FragmentOngoingBookings
    }
  }
  ${FRAGMENT_ONGOING_BOOKINGS}
`;

const FETCH_BOOKINGS_LATEST_STATUS = gql`
  subscription FETCH_BOOKINGS_STATUS(
    $filters: boatnew_booking_latest_status_sort_bool_exp
  ) {
    bookings: boatnew_booking_latest_status_sort(where: $filters) {
      ...FragmentBookingLatestStatus
    }
  }
  ${FRAGMENT_BOOKING_LATEST_STATUS}
`;

const FETCH_BOOKINGS_LATEST_STATUS_STREAM = gql`
  subscription FETCH_BOOKINGS_STATUS(
    $filters: boatnew_booking_latest_status_sort_bool_exp
    $startDate: date
  ) {
    bookings: boatnew_booking_latest_status_sort_stream(
      where: $filters
      batch_size: 5
      cursor: { initial_value: { tour_date: $startDate } }
    ) {
      ...FragmentBookingLatestStatus
    }
  }
  ${FRAGMENT_BOOKING_LATEST_STATUS}
`;

const FETCH_BOOKINGS_STATUS_IN_CHUNKS = gql`
  query FETCH_BOOKINGS_STATUS_IN_CHUNKS(
    $filters: boatnew_booking_status_sort_bool_exp
    $limit: Int
    $offset: Int
  ) {
    bookings: boatnew_booking_status_sort(
      where: $filters
      limit: $limit
      offset: $offset
    ) {
      ...FragmentBookingStatus
    }
  }
  ${FRAGMENT_BOOKING_STATUS}
`;
const FETCH_BOOKING_BY_ID = gql`
  subscription FETCH_BOOKING_BY_ID($id: String!) {
    booking: boatnew_booking_by_pk(id: $id) {
      subtasks: booking_subtasks(order_by: { created_at: asc }) {
        subtaskId: id
        status
        task
      }
      transactions(order_by: { created_at: asc }) {
        type
        cardType: card_type
        cardValue: card_value
        id: stripe_id
        date
        value
        reason
        paymentMethod: payment_method
      }
      product {
        pickupBounds: pickup_bounds
        pickupRequired: pickup_required
        rezdy {
          rezdy_id
          created_at
        }
      }
      reservations: booking_reservations {
        log: reservations_log {
          id
          date
          content
          createdAt: created_at
          updatedAt: updated_at
          time
          status
        }
        createdAt: created_at
        updatedAt: updated_at
        id: reservation_id
        pax
        status
        reservation {
          attraction
        }
      }
      ...FragmentBooking
    }
  }
  ${FRAGMENT_BOOKING}
`;
const UPDATE_ROW = gql`
  mutation updateField(
    $customerId: String!
    $customer: boatnew_customers_set_input!
    $scheduling: boatnew_booking_vehicles_insert_input!
    $id: String!
    $booking: boatnew_booking_set_input!
  ) {
    updateCustomer: update_boatnew_customers_by_pk(
      pk_columns: { id: $customerId }
      _set: $customer
    ) {
      id
    }
    updateBooking: update_boatnew_booking_by_pk(
      pk_columns: { id: $id }
      _set: $booking
    ) {
      id
    }
    insert_boatnew_booking_vehicles(
      objects: [$scheduling]
      on_conflict: {
        constraint: booking_vehicles_pkey
        update_columns: [vehicle_id, guide_id]
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_BOOKINGS = gql`
  mutation UPDATE_BOOKINGS(
    $id: String!
    $customerId: String!
    $booking_input: boatnew_booking_set_input
    $customer_input: boatnew_customers_set_input
  ) {
    update_boatnew_booking_by_pk(
      pk_columns: { id: $id }
      _set: $booking_input
    ) {
      id
    }
    update_boatnew_customers_by_pk(
      pk_columns: { id: $customerId }
      _set: $customer_input
    ) {
      id
    }
  }
`;
const UPDATE_BOOKING_CUSTOMER = gql`
  mutation UPDATE_BOOKING($bookingId: String!, $customerId: String!) {
    update_boatnew_booking_by_pk(
      pk_columns: { id: $bookingId }
      _set: { customer_id: $customerId }
    ) {
      id
      customer_id
    }
  }
`;
const UPDATE_BOOKING_UNITS = gql`
  mutation MyMutation($units: [boatnew_booking_units_insert_input!]!) {
    insert_boatnew_booking_units(
      objects: $units
      on_conflict: { update_columns: quantity, constraint: booking_units_pkey }
    ) {
      affected_rows
    }
  }
`;
const UPDATE_STATUS = gql`
  mutation UPDATE_STATUS(
    $id: String!
    $booking_input: boatnew_booking_set_input
  ) {
    update_boatnew_booking_by_pk(
      pk_columns: { id: $id }
      _set: $booking_input
    ) {
      status
    }
  }
`;
const UPDATE_PICKUP = gql`
  mutation UPDATE_PICKUP(
    $id: String!
    $pickup_time: String!
    $pickup_location: String!
  ) {
    update_boatnew_booking_by_pk(
      pk_columns: { id: $id }
      _set: { pickup_time: $pickup_time, pickup_location: $pickup_location }
    ) {
      pickup_time
      pickup_location
    }
  }
`;
const DELETE_TASK = gql`
  mutation DELETE_TASK($id: String!) {
    delete_boatnew_subtasks_by_pk(id: $id) {
      id
    }
  }
`;
const UPDATE_TASK = gql`
  mutation UPDATE_TASK($id: String!, $input: boatnew_subtasks_set_input!) {
    update_boatnew_subtasks_by_pk(pk_columns: { id: $id }, _set: $input) {
      status
      task
    }
  }
`;
const ADD_TASK = gql`
  mutation ADD_TASK($input: boatnew_subtasks_insert_input!) {
    insert_boatnew_subtasks_one(object: $input) {
      id
      task
      status
    }
  }
`;
const ADD_TRANSACTION = gql`
  mutation ADD_TRANSACTION($input: boatnew_transactions_insert_input!) {
    insert_boatnew_transactions_one(object: $input) {
      stripe_id
    }
  }
`;
const ADD_BOOKING = gql`
  mutation ADD_BOOKING($input: boatnew_booking_insert_input!) {
    insert_boatnew_booking_one(object: $input) {
      id
    }
  }
`;
export {
  FETCH_BOOKINGS,
  UPDATE_BOOKINGS,
  UPDATE_ROW,
  FETCH_BOOKING_BY_ID,
  DELETE_TASK,
  UPDATE_TASK,
  ADD_TASK,
  ADD_TRANSACTION,
  UPDATE_STATUS,
  ADD_BOOKING,
  UPDATE_BOOKING_CUSTOMER,
  UPDATE_BOOKING_UNITS,
  FETCH_BOOKINGS_STATUS_IN_CHUNKS,
  FETCH_BOOKINGS_STATUS,
  UPDATE_PICKUP,
  FETCH_BOOKINGS_LATEST_STATUS,
  FETCH_BOOKINGS_LATEST_STATUS_STREAM,
  FETCH_ONGOING_BOOKINGS,
  FETCH_LAST_UPDATED_BOOKING,
  FETCH_BOOKINGS_STATUS_QUERY,
};
