import React from 'react';
import { useSubscription } from '@apollo/client';
import {
  Modal, Tab, Nav, TabContent,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import {
  useNavigate, useParams, useLocation,
} from 'react-router-dom';
import { FETCH_BOOKING_BY_ID } from '../../api/bookings';
import BookingContext from '../../context/booking';
import './Popup.scss';

const tabKeys = {
  '#info': 'Booking Info',
  '#notes': 'Notes',
  '#status': 'Status',
  '#reservations': 'Reservations',
  '#payment': 'Payment',
};

type Props = {
  children: React.ReactNode
};

function Popup({ children }: Props) {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const { hash } = useLocation();

  const { data } = useSubscription(FETCH_BOOKING_BY_ID, {
    variables: { id: bookingId },
    fetchPolicy: 'network-only',
  });

  const handleClose = () => navigate('..');
  return (
    <Modal
      backdrop
      size="lg"
      scrollable
      show={data?.booking}
      onHide={handleClose}
    >
      <ModalHeader closeButton>
        {`Booking ID: ${data?.booking?.id}`}
      </ModalHeader>

      <ModalBody className="BookingModal__body">
        <Tab.Container
          defaultActiveKey={tabKeys[hash as keyof typeof tabKeys] ?? 'Booking Info'}
        >
          {/* use React.Children api to generate tab entries; */}
          <Nav as="ul" justify variant="tabs">
            {Object.entries(tabKeys).map(([key, value]) => (
              <Nav.Item as="li" key={value}>
                <Nav.Link as="button" className="BookingModal__tab" eventKey={value} href={key}>
                  {value}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <TabContent>
            <BookingContext.Provider value={data?.booking}>
              {children}
            </BookingContext.Provider>
          </TabContent>
        </Tab.Container>
      </ModalBody>
    </Modal>
  );
}

export default Popup;
