import { Authenticator } from '@aws-amplify/ui-react';
import React, { useContext, useEffect, memo } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { NavbarContext } from '../../context/navbar.context';
import './Header.scss';
import { UserDocContext } from '../AccessControl/AccessControl';
import usePermissions from '../../hooks/usePermissions';

function Header() {
  const { showNavbar, setShowNavbar } = useContext(NavbarContext);
  const { signedUser } = usePermissions();
  const apolloClient = useApolloClient();
  const toggleModal = () => {
    setShowNavbar(!showNavbar);
  };

  const handleSignOut = async () => {
    await apolloClient.clearStore();
    await Auth.signOut();
  };

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="icons_cont justify-content-between">
          <div>
            <button
              type="button"
              className="icons topNavbarIcons"
              onClick={() => toggleModal()}
            >
              <i className="fas fa-bars icons" />
            </button>
            <button
              type="button"
              className="icons topNavbarIcons"

            >
              <i className="fas fa-sync-alt icons" />
            </button>
            <Link to="/bookings" className="icons topNavbarIcons">
              <i className="fas fa-home" />
            </Link>
          </div>
          <Authenticator>
            {/* {({ signOut, user }) => ( */}
            {signedUser && (
            <div className="user-info">
              <p>
                {/* Signed in as */}
                {/* {' '} */}
                {/* {user.attributes.email.split('@')[0]} */}
                {signedUser?.name}
              </p>
              <Button onClick={handleSignOut}>Log out</Button>
            </div>
            )}
            {/* )} */}
          </Authenticator>
        </div>
      </div>
    </header>
  );
}

export default memo(Header);
