import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "../pages/Homepage/index";
import Dashboard from "../pages/Dashboard/index";
import Bookings from "../pages/Bookings/Bookings";
import Products from "../pages/Products/Products";
import Scheduling from "../pages/Scheduling/Scheduling";
import Reservations from "../pages/Reservations/Reservations";
import Vehicles from "../pages/Vehicles/Vehicles";
import ProductWizardCreate from "../pages/Products/ProductWizard/Create/ProductWizardCreate";
import ProductWizardEdit from "../pages/Products/ProductWizard/Edit/ProductWizardEdit";
import ShowProducts from "../pages/Products/DisplayProducts/DisplayProducts";
import DisplayVehicles from "../pages/Vehicles/components/DisplayVehicles";
import EditVehicle from "../pages/Vehicles/components/EditVehicels/EditVehicle";
import CreateVehicle from "../pages/Vehicles/components/CreateVehicles/CreateVehicle";
import DisplayGuides from "../pages/Guides/components/DisplayGuides";
import EditGuide from "../pages/Guides/components/EditGuides/EditGuide";
import CreateGuide from "../pages/Guides/components/CreateGuides/CreateGuide";
import CreateBooking from "../pages/Bookings/components/Modal/components/CreateBooking/NewCreateBooking";
import Popup from "../components/Popup/Popup";
import BookingInfo from "../components/Tabs/BookingInfo";
import ReviewGuide from "../components/Tabs/GuideReview";
import BookingNotes from "../components/Tabs/BookingNotes";
import BookingStatus from "../components/Tabs/BookingStatus";
import BookingReservations from "../components/Tabs/BookingReservations";
import BookingPayment from "../components/Tabs/BookingPayment";
import Guides from "../pages/Guides/Guides";
import Sessions from "../pages/Sessions/Sessions";
import GuideAvailability from "../pages/guide_availability/Sessions";
// import SessionsNew from '../pages/SessionsNew/Sessions';
import News from "../pages/News/News";
import Users from "../pages/Users/Users";
import DisplayUsers from "../pages/Users/components/DisplayUsers";
import CreateUser from "../pages/Users/components/CreateUser/CreateUser";
import EditUser from "../pages/Users/components/EditUser/EditUser";
import usePermissions from "../hooks/usePermissions";
import Loader from "../components/Loader/Loader";
import DisplayLogs from "../pages/Logs/components/DisplayLogs";
import Logs from "../pages/Logs/Logs";
import GuidePopup from "../components/Popup/Guide/GuidePopup";
import DisplaySessionBookings from "../pages/Sessions/bookings/DisplaySessionBookings";
// import Reports from '../pages/Reports/Reports';
import Cities from "../pages/Cites/Cities";
import CreateCity from "../pages/Cites/components/CreateCities/CreateCities";
import DisplayCities from "../pages/Cites/components/DisplayCities";
import EditCities from "../pages/Cites/components/EditCities/EditCities";
function SiteRoutes() {
  const { signedUser, doesHavePermission } = usePermissions();

  function ProtectedRoute({ path, component: Component }) {
    if (!signedUser) {
      return <Loader />;
    }
    if ((signedUser && doesHavePermission(path)) || !signedUser) {
      return <Component />;
    }
    return <Navigate to="/" />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute path="/" component={Homepage} />}
      />
      <Route
        path="/dashboard"
        element={<ProtectedRoute path="/dashboard" component={Dashboard} />}
      />
      <Route path="/" element={<Navigate to="bookings" />} />
      {/* <Route path="/reports" element={<Reports />} /> */}
      <Route path="bookings" element={<Bookings />}>
        <Route
          path=":bookingId"
          element={
            <Popup>
              <BookingInfo />
              <BookingNotes />
              <BookingStatus />
              <BookingReservations />
              <BookingPayment />
            </Popup>
          }
        />
        <Route path="create" element={<CreateBooking />} />
      </Route>
      <Route
        path="products"
        element={<ProtectedRoute path="/products" component={Products} />}
      >
        <Route index element={<ShowProducts />} />
        <Route path="edit/:productId" element={<ProductWizardEdit />} />
        <Route path="create" element={<ProductWizardCreate />} />
      </Route>
      <Route
        path="reservations"
        element={
          <ProtectedRoute path="/reservations" component={Reservations} />
        }
      >
        <Route
          path=":bookingId"
          element={
            <Popup>
              <BookingInfo />
              <BookingNotes />
              <BookingStatus />
              <BookingReservations />
              <BookingPayment />
            </Popup>
          }
        />
      </Route>
      <Route
        path="scheduling"
        element={<ProtectedRoute path="/scheduling" component={Scheduling} />}
      >
        <Route
          path=":bookingId"
          element={
            <Popup>
              <BookingInfo />
              <BookingNotes />
              <BookingStatus />
              <BookingReservations />
              <BookingPayment />
            </Popup>
          }
        />
      </Route>
      {/* <Route path="sessions" element={<Sessions />} /> */}
      <Route path="vehicles" element={<Vehicles />}>
        <Route index element={<DisplayVehicles />} />
        <Route path="edit/:vehicleId" element={<EditVehicle />} />
        <Route path="create" element={<CreateVehicle />} />
      </Route>

      <Route path="cities" element={<Cities />}>
        <Route index element={<DisplayCities />} />
        <Route path="edit/:cityId" element={<EditCities />} />
        <Route path="create" element={<CreateCity />} />
      </Route>
      {/* <Route path="guides" element={<ProtectedRoute path="/guides" component={Guides} />}> */}
      <Route path="guides" element={<Guides />}>
        <Route index element={<Navigate to="/guides/list" />} />
        <Route path="list" element={<DisplayGuides />}>
          <Route
            path=":guideId"
            element={
              <GuidePopup>
                <ReviewGuide />
              </GuidePopup>
            }
          />
        </Route>
        <Route path="edit/:guideId" element={<EditGuide />} />
        <Route path="create" element={<CreateGuide />} />
      </Route>
      <Route path="sessions" element={<Sessions />} />
      <Route path="/guide-availability" element={<GuideAvailability />} />
      <Route path="sessionbooking/:id" element={<DisplaySessionBookings />}>
        <Route
          path=":bookingId"
          element={
            <Popup>
              <BookingInfo />
              <BookingNotes />
              <BookingStatus />
              <BookingReservations />
              <BookingPayment />
            </Popup>
          }
        />
      </Route>
      <Route
        path="users"
        element={<ProtectedRoute path="/users" component={Users} />}
      >
        <Route index element={<DisplayUsers />} />
        <Route path="edit/:userId" element={<EditUser />} />
        <Route path="create" element={<CreateUser />} />
      </Route>
      <Route
        path="/news"
        element={<ProtectedRoute path="/news" component={News} />}
      />
      <Route
        path="/logs"
        element={<ProtectedRoute path="/logs" component={Logs} />}
      >
        <Route index element={<DisplayLogs />} />
      </Route>
      {/* <Route path="news" element={<News />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default SiteRoutes;
