import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { FETCH_VEHICLES_BY_ID, UPDATE_VEHICLE } from '../../../../api/Vehicles';
import EditVehicleForm from './EditVehicleForm';
import { positiveNum0 } from '../../../../utils/Regex';
import '../../Vehicles.scss';

function EditVehicle() {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState('');

  const { data: vehicle, loading } = useQuery(FETCH_VEHICLES_BY_ID, {
    variables: { id: vehicleId },
  });

  const [updateVehicle] = useMutation(UPDATE_VEHICLE, {
    variables: { id: vehicleId },
    refetchQueries: [
      {
        query: FETCH_VEHICLES_BY_ID,
        variables: { id: vehicleId },
      },
    ],
    onCompleted: () => {
      toast.success('Vehicle updated!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000,
      });
      navigate('/vehicles');
    },
  });

  useEffect(() => {
    if (vehicle) {
      setInitialValues({
        id: vehicle?.vehicle?.id || '',
        name: vehicle?.vehicle?.name || '',
        capacity: vehicle?.vehicle?.capacity || '',
        city_id: vehicle?.vehicle?.cityId || '',
        make_model: vehicle?.vehicle?.model || '',
        plate_number: vehicle?.vehicle?.plateNumber || '',
        vin_number: vehicle?.vehicle?.vinNumber || '',
        year: vehicle?.vehicle?.year || '',
        colour: vehicle?.vehicle?.colour || '',
        insurance: vehicle?.vehicle?.insurance || '',
        ownership: vehicle?.vehicle?.ownership || '',
        maintenence: vehicle?.vehicle?.notes || '',
      });
    }
  }, [vehicle]);

  const validate = (values) => {
    const errors = {};
    if (!values.name || !values.name.trim()) {
      errors.name = 'Required';
    }
    if (!values.capacity) {
      errors.capacity = 'Required';
    } else if (!positiveNum0.test(values.capacity)) {
      errors.capacity = 'Enter valid number';
    }
    if (values.year !== '' && !positiveNum0.test(values.year)) {
      errors.year = 'Enter valid year';
    }
    return errors;
  };

  return (
    !loading && initialValues
    && (
      <div className="Vehicles__editVehicle">
        <EditVehicleForm
          vehicle={vehicle?.vehicle}
          initialValues={initialValues}
          cities={vehicle?.cities}
          updateVehicle={updateVehicle}
          validate={validate}
        />
      </div>
    ));
}

export default EditVehicle;
