import { gql } from '@apollo/client';

const ABONDONED = gql`
query ABONDONED ($startTime: timestamptz!) {
  abandoned:logs_abandoned_checkouts(where: {created_at: {_gte: $startTime}}) {
    customer_name
    id
    pax
    email
    phone
    tour_date
    product {
      name
    }
  }
  }
`;
interface ABONDONEDINTERFACE {
  abandoned:[{
    id:string,
    customer_name:string,
    pax:number,
    tour_date:string,
    email:string,
    phone:string,
    product:{
      name:string
    }
  }]
}
export default ABONDONED;
export type { ABONDONEDINTERFACE };
