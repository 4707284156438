import { gql } from '@apollo/client';

const Aggregates = gql`
query Aggregates($startDate: timestamptz! , $endDate: timestamptz! $date: date! ) {
  aggregates: boatnew_booking(where: {created_at: {_gte: $startDate , _lte: $endDate}}){
    pax
    source
    status
   bookingUnits: booking_units{
      quantity
      unit{
        label
        info
      }
    }
    product{
     citiesProduct:cities_products{
        city{
          name
        }
      }
    }
  }
  
  rezdyBooking: boatnew_booking_aggregate(
    where: {
      created_at: { _gte: $startDate, _lte: $endDate },
      source: { _eq: "REZDY" },
    }
  ) {
    aggregate {
      count
    }
    nodes {
      product {
        citiesProduct: cities_products {
          city {
            name
          }
        }
      }
    }
  }
  
  websiteBooking: boatnew_booking_aggregate(
    where: {
      created_at: { _gte: $startDate, _lte: $endDate },
      source: { _eq: "WEBSITE" },
    }
  ) {
    aggregate {
      count
    }
    nodes {
      product {
        citiesProduct: cities_products {
          city {
            name
          }
        }
      }
    }
  }
  

bookingsCompleted: boatnew_booking_aggregate(where:{created_at:{_gte:$startDate , _lte: $endDate},status:{_eq:"COMPLETED"}}){
    aggregate{
    count
  }
  }
bookingsCancelled: boatnew_booking_aggregate(where:{created_at:{_gte:$startDate , _lte: $endDate},status:{_eq:"CANCELLED"}}){
    aggregate{
    count
  }
  }

  netbookings: boatnew_booking_aggregate(
    where: {
      created_at: { _gte: $startDate, _lte: $endDate },
      status: { _nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"] }
    }
  ) {
    aggregate {
      count
    }
  }
 
 allBookings: boatnew_booking_aggregate(where:{tour_date:{_eq:$date}}){
    aggregate{
    count
  }
  }
  rezdyTodayHappening: boatnew_booking_aggregate(where:{source:{_eq:"REZDY"},tour_date:{_eq:$date}}){
    aggregate{
      count
    }
  }
  websiteTodayHappening: boatnew_booking_aggregate(where:{source:{_eq:"WEBSITE"},tour_date:{_eq:$date}}){
    aggregate{
      count
    }
  }
  rezdyTodayBooked: boatnew_booking_aggregate(where:{source:{_eq:"REZDY"},created_at:{_gte:$startDate , _lt: $endDate},status: {_nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"]}}){
    aggregate{
      count
    }
  }
  websiteTodayBooked: boatnew_booking_aggregate(where:{source:{_eq:"WEBSITE"},created_at:{_gte:$startDate , _lt: $endDate}, status: {_nin: ["CANCELLED", "CANCELLED - OPS", "REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"]}}){
    aggregate{
      count
    }
  }
  bookingsPending: boatnew_booking_aggregate(where:{status:{_eq:"PENDING"} , created_at: {_gte: $startDate , _lt: $endDate} }){
    aggregate{
      count
    }
  }
}
`;
export interface Total {
  aggregate:{
    count:number
  }
}
export interface AGGREGATETYPES {
  aggregates:[{
    bookingUnits:[{
      quantity:number
      unit:{
        info:string
        label:string
      }
    }],
    pax:number,
    source:string,
    status: string,
    product:{
      citiesProduct:[{
        city:{
          name:string
        }
      }]
    }
  }],
  happeningToday:[{
    bookingUnits:[{
      quantity:number
      unit:{
        info:string
        label:string
      }
    }],
    pax:number,
    product:{
      citiesProduct:[{
        city:{
          name:string
        }
      }]
    }
  }],
  completed:Total,
  cancelled:Total,
  allBookings:Total,
  netbookings:Total,
  rezdyTodayHappening:Total,
  websiteTodayHappening:Total,
  rezdyTodayBooked:Total,
  websiteTodayBooked:Total
  bookingsCompleted:Total
  bookingsCancelled:Total
  bookingsPending:Total
  rezdyBooking:Total
  websiteBooking:Total
}
export default Aggregates;
