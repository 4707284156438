import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import Header from '../../components/Header/Header';
import './Users.scss';
import { SUBSCRIBE_USERS } from '../../api/users';

function Users() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, refetch, loading } = useSubscription(SUBSCRIBE_USERS);

  const getHeader = () => {
    let header = '';
    if (location.pathname.includes('edit')) {
      header = (
        <h2 className="heading_styles">
          <i className="far fa-arrow-left" onClick={() => navigate('/users')} />
          <span>Edit User</span>
        </h2>
      );
    } else if (location.pathname.includes('create')) {
      header = (
        <h2 className="heading_styles">
          <i className="far fa-arrow-left" onClick={() => navigate('/users')} />
          <span>Create User</span>
        </h2>
      );
    } else {
      header = (
        <h2 className="heading_styles">
          Users, Roles & Permissions
        </h2>
      );
    }
    return header;
  };

  return (
    <>
      <Header />
      <div className="Vehicles__container">
        <div className="card">
          <div className="card-header">
            {getHeader()}
          </div>
          <main className="Vehicles__main">
            <Outlet context={{ data, refetch, loading }} />
          </main>
        </div>
      </div>
    </>
  );
}

export default Users;
