import React, { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import VehiclesHeader from './VehiclesHeader';
import GridComponent from '../../../components/GridComponent/GridComponent';
import { ACTIVE_VEHICLE, DEACTIVE_VEHICLE, MOVE_TO_TRASH } from '../../../api/Vehicles';
import Confirm from './confirm';

const width = Math.ceil(window.outerWidth / 7);

function EditHandler({ id, status = '' }) {
  const { refetch, data } = useOutletContext();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [activeVehicle] = useMutation(ACTIVE_VEHICLE);
  const [deactiveVehicle] = useMutation(DEACTIVE_VEHICLE);
  const [moveVehcleToTrash] = useMutation(MOVE_TO_TRASH);

  const handleStatusChange = async () => {
    if (status === 'active') {
      await activeVehicle({
        variables: {
          vehicleId: id,
          status: 'deactive',
          timestamp: moment().format(),
        },
      });
    } else {
      await deactiveVehicle({
        variables: {
          vehicleId: id,
          status: 'active',
        },
      });
    }
  };

  const moveToTrash = async () => {
    await moveVehcleToTrash({
      variables: {
        vehicleId: id,
      },
    });
  };

  const handleTrigger = async () => {
    if (modal === 'status') { await handleStatusChange(); } else if (modal === 'trash') { await moveToTrash(); }
  };

  const handleAction = () => {
    switch (modal) {
      case 'status':
        return status;
      case 'trash':
        return 'trash';
      default:
        return '';
    }
  };

  return (
    id ? (
      <>
        <Link className="Vehicles__editBtn" to={`/vehicles/edit/${id}`}>Edit</Link>
        <Link className="Vehicles__editBtn ms-3" to="/vehicles" onClick={() => setModal('status')}>
          {status === 'active' ? 'Deactivate' : 'Activate'}
        </Link>
        <Link className="Vehicles__editBtn ms-3" to="/vehicles" onClick={() => setModal('trash')}>
          Trash
        </Link>
        <Confirm
          trigger={handleTrigger}
          visibility={modal !== ''}
          setVisibility={(v = false) => setModal(v === false ? '' : v)}
          action={handleAction()}
        />
      </>
    ) : null
  );
}

function StatusRenderer({ status }) {
  return (
    <p className="text-capitalize">
      {(status)}
      <span className={`ms-1 badge badge-secondary p-1 pb-0 rounded-circle ${status === 'active' ? 'bg-success text-success' : 'bg-danger text-danger'}`}>o</span>
    </p>
  );
}

const columnDefs = [
  {
    cellRenderer: (params) => (<EditHandler id={params?.data?.id} status={params?.data?.status} />),
    filter: () => null,
    resizable: true,
    width: 200,
  },
  {
    field: 'id',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'Status',
    valueGetter: (params) => params?.data?.status,
    cellRenderer: (params) => <StatusRenderer status={params?.data?.status} />,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'city',
    valueGetter: (params) => params?.data?.city?.name,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'name',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'model',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'capacity',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'vinNumber',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'plateNumber',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'year',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'Last Deactivated',
    valueGetter: (params) => (params?.data?.last_deactivated !== null ? moment(params?.data?.last_deactivated).format('ddd, MMM DD, YYYY') : ''),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'colour',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
  {
    field: 'notes',
    filter: 'agTextColumnFilter',
    resizable: true,
  },
];

function DisplayVehicles() {
  const { data } = useOutletContext();
  return (
    data?.vehicles ? (
      <GridComponent
        gridTitle="vehicles"
        data={data.vehicles}
        columnDefs={columnDefs}
      >
        <VehiclesHeader />
      </GridComponent>
    )
      : null
  );
}

export default DisplayVehicles;
