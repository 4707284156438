import React from 'react';
import dayjs from 'dayjs';

function ReservationLogs({ data, error }) {
  if (error) {
    return (
      <div className="Reservations__table-error">
        <h2>Something went wrong...</h2>
        <p>{`Error: ${error?.message || ''}`}</p>
      </div>
    );
  }

  return (
    data?.logs?.length === 0
      ? <p className="Reservations__logsNotFound">No logs to display</p>
      : (
        <div className="Reservations__logsList">
          {data?.logs?.length > 0 && data?.logs.map((log) => (
            <div key={log.id} className="Reservations__logsList-log">
              <div className="Reservations__logsList-log-status">{`Email Status: ${log.status}`}</div>
              <div>{dayjs(`${log.date} ${log.time}`).format('YYYY-MM-DD HH:mm')}</div>
              <div>{`To: ${log.content?.contact}`}</div>
              {log.content?.contactCC && <div>{`Cc: ${log.content?.contactCC}`}</div>}
              <div>{log.content?.subject}</div>
              <div>{log.content?.body}</div>
            </div>
          ))}
        </div>
      )
  );
}

export default ReservationLogs;
