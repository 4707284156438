import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDraggable } from '@dnd-kit/core';
import './Draggable.scss';
import { stringToColour } from '../../../../utils/helpers';

function Draggable({
  booking, inVehicle, disabled = false,
  handleModalOpen, screenSize,
}) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);

  function startTimer() {
    setTimer(setTimeout(() => {
      if (screenSize === 'md') { openEditModal(); }
    }, 700));
  }

  function cancelTimer() {
    clearTimeout(timer);
    setTimer(null);
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useDraggable({
    id: booking.id,
    disabled,
  });

  const openEditModal = () => {
    if (handleModalOpen) { handleModalOpen(booking); }
  };

  const backgroundColor = stringToColour(booking?.product?.id, 0.25) || 'white';

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    width: '190px',
  } : {};
  const displayPax = (units) => {
    if (!units) return null;
    let pax = '';
    units.map((unit) => {
      pax += `${unit.quantity}${unit.unit.label.slice(0, 1)} `;
    });
    return pax.trim();
  };

  const handlePopupOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/scheduling/${booking.id}`);
  };

  return (
    <div
      className={`Scheduling__draggable 
      ${inVehicle ? 'inVehicle' : 'inRoot'}
      ${screenSize === 'md' && inVehicle ? 'w-100' : ''}
      ${transform ? 'transform' : ''}
      ${disabled ? 'disabled' : ''}
      `}
      onDoubleClick={openEditModal}
      onTouchStart={startTimer}
      onTouchEnd={cancelTimer}
      ref={setNodeRef}
      style={{ ...style, backgroundColor }}
      {...(screenSize === 'lg' ? listeners : {})}
      {...(screenSize === 'lg' ? attributes : {})}
    >
      <div className="Scheduling__draggable-info">
        <div className="Scheduling__draggable-info-container">
          <div className={`Scheduling__draggable-info-status ${booking?.status?.toLowerCase().replace(/ /g, '')}`} />
          <span className="Scheduling__draggable-info-id" id="popupOpen" onClick={handlePopupOpen}>{booking?.id}</span>
          <span className="Scheduling__draggable-info-units_1">{displayPax(booking?.bookingUnits)}</span>
        </div>
      </div>
      <span className="Scheduling__draggable-info-contact">{booking?.customer?.contactName}</span>
      <span className="Scheduling__draggable-info-units_2">{displayPax(booking?.bookingUnits)}</span>
      <span className="hideoverflow">
        <span id="tourTime">{booking.tourTime?.slice(0, 5)}</span>
        <span>
          {booking?.product?.name}
        </span>
      </span>
      <span className="hideoverflow">
        {`${booking.pickupTime ? `${booking.pickupTime} ` : ''}${booking.pickupLocation || ''}`}
      </span>
    </div>
  );
}

export default Draggable;
