import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { UPDATE_ROW } from "../../../../api/bookings";
import useRecentUpdates from "./useRecentUpdates";
import {
  UPDATE_CUSTOMER_NAME,
  UPDATE_CUSTOMER_EMAIL,
  UPDATE_CUSTOMER_PHONE,
  UPDATE_CUSTOMER_SECONDARY_EMAIL,
  UPDATE_CUSTOMER_SECONDARY_PHONE,
} from "../../../../api/cell/customer";
import {
  UPDATE_PICKUP_LOCATION,
  UPDATE_PICKUP_TIME,
} from "../../../../api/cell/bookings";
import {
  UPDATE_ASSIGNED_GUIDE,
  UPDATE_ASSIGNED_VEHICLE,
  UPDATE_GROUP,
} from "../../../../api/cell/booking_vehicle";

function useCellEditing({ content, methods }) {
  const { refetchBookings } = methods;
  let updateInput = {};

  const [newValue, setNewValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const getNewValue = () => newValue;
  useEffect(() => console.log(newValue), [newValue]);

  const { registerUpdate, isRecentlyUpdated } = useRecentUpdates();

  const [updateCustomerName] = useMutation(UPDATE_CUSTOMER_NAME);
  const [updateCustomerEmail] = useMutation(UPDATE_CUSTOMER_EMAIL);
  const [updateCustomerPhone] = useMutation(UPDATE_CUSTOMER_PHONE);
  const [updateCustomerSecEmail] = useMutation(UPDATE_CUSTOMER_SECONDARY_EMAIL);
  const [updateCustomerSecPhone] = useMutation(UPDATE_CUSTOMER_SECONDARY_PHONE);
  const [updatePickupTime] = useMutation(UPDATE_PICKUP_TIME);
  const [updatePickupLocation] = useMutation(UPDATE_PICKUP_LOCATION);
  const [updateAssignedGuide] = useMutation(UPDATE_ASSIGNED_GUIDE);
  const [updateAssignedVehicle] = useMutation(UPDATE_ASSIGNED_VEHICLE);
  const [updateGroup] = useMutation(UPDATE_GROUP);

  const mutations = {
    UPDATE_CUSTOMER_NAME: updateCustomerName,
    UPDATE_CUSTOMER_EMAIL: updateCustomerEmail,
    UPDATE_CUSTOMER_PHONE: updateCustomerPhone,
    UPDATE_CUSTOMER_SECONDARY_EMAIL: updateCustomerSecEmail,
    UPDATE_CUSTOMER_SECONDARY_PHONE: updateCustomerSecPhone,
    UPDATE_PICKUP_TIME: updatePickupTime,
    UPDATE_PICKUP_LOCATION: updatePickupLocation,
    UPDATE_ASSIGNED_GUIDE: updateAssignedGuide,
    UPDATE_ASSIGNED_VEHICLE: updateAssignedVehicle,
    UPDATE_GROUP: updateGroup,
  };

  const callMutation = (input) => {
    const { action, variables } = input;
    console.log(input);
    const mutation = mutations[action];
    if (!mutation) {
      return Promise.reject(new Error(`Unknown mutation: ${action}`));
    }
    return mutation({ variables }).then((result) => {
      // handle the mutation result
      let a = 0;
      a += 1;
      return result;
    });
  };

  const [updateRow] = useMutation(UPDATE_ROW);

  function onCellEditingStarted(params) {
    const { rowIndex } = params;
    const columnKey = params.column.colId;
    const newValue = params.value;
    setIsEditing(true);
    setNewValue({ column: columnKey, value: newValue });
  }

  function onCellEditingStopped(params) {
    const { rowIndex } = params;
    console.log(params);
    const columnKey = params.column.colId;
    const newValue = params.data[columnKey];

    // if (
    //   newValue !== null
    //   && ['assignedVehicle', 'assignedGuide', 'group'].includes(columnKey)
    // ) {
    //   console.log('onCellEditingStopped', columnKey, newValue, params);

    //   // Set the new value in state
    //   const updatedValue = { column: columnKey, value: newValue };
    //   setNewValue(updatedValue);
    // }

    // setIsEditing(false);

    // if (['assignedVehicle', 'assignedGuide', 'group'].includes(columnKey))
    // onCellValueChangeCommitted(params);
    // if (columnKey === 'assignedVehicle' || columnKey === 'assignedGuide')
    // onCellValueChangeCommitted(params);
  }

  function onCellValueChangeCommitted(params, updatedValue = newValue) {
    try {
      const { data } = params;
      console.log(data);
      const { id, customerId, customer } = data;

      let { contactName, email, phone, secondaryEmail, secondaryPhone } =
        customer;
      let { pickupTime, pickupLocation, booking_vehicles, group } = data;
      // let assignedVehicle = booking_vehicles?.assignedVehicle || 'TBD';
      // let assignedGuide = booking_vehicles?.assignedGuide || 'TBD';
      if (updatedValue === null) {
        return;
      }
      // console.log(params, updatedValue, newValue);

      // Get the new value from state
      const { column, value } = updatedValue;
      // data[column] = value;
      console.log({ column, value });

      // Updating the values
      switch (column) {
        case "customerName":
          updateInput = {
            action: "UPDATE_CUSTOMER_NAME",
            variables: {
              id: customerId,
              val: value,
            },
          };
          contactName = value;
          break;
        case "customerEmail":
          updateInput = {
            action: "UPDATE_CUSTOMER_EMAIL",
            variables: {
              id: customerId,
              val: value,
            },
          };
          email = value;
          break;
        case "customerPhone":
          updateInput = {
            action: "UPDATE_CUSTOMER_PHONE",
            variables: {
              id: customerId,
              val: value,
            },
          };
          phone = value;
          break;
        case "secondaryEmail":
          updateInput = {
            action: "UPDATE_CUSTOMER_SECONDARY_EMAIL",
            variables: {
              id: customerId,
              val: value,
            },
          };
          secondaryEmail = value;
          break;
        case "secondaryPhone":
          updateInput = {
            action: "UPDATE_CUSTOMER_SECONDARY_PHONE",
            variables: {
              id: customerId,
              val: value,
            },
          };
          secondaryPhone = value;
          break;
        case "pickupTime":
          updateInput = {
            action: "UPDATE_PICKUP_TIME",
            variables: {
              id,
              val: value,
            },
          };
          pickupTime = value;
          break;
        case "pickupLocation":
          updateInput = {
            action: "UPDATE_PICKUP_LOCATION",
            variables: {
              id,
              val: value,
            },
          };
          pickupLocation = value;
          break;
        case "assignedVehicle":
          updateInput = {
            action: "UPDATE_ASSIGNED_VEHICLE",
            variables: {
              booking_id: id,
              vehicle_id: value,
            },
          };
          // assignedVehicle = value;
          break;
        case "assignedGuide":
          updateInput = {
            action: "UPDATE_ASSIGNED_GUIDE",
            variables: {
              booking_id: id,
              guide_id: value,
            },
          };
          // assignedGuide = value;
          break;
        case "group":
          updateInput = {
            action: "UPDATE_GROUP",
            variables: {
              booking_id: id,
              group: value,
            },
          };
          console.log("==================>", value);
          group = value;
          break;
        default:
          break;
      }

      // const updateInput = {
      //   variables: {
      //     id,
      //     customerId,
      //     scheduling: {
      //       booking_id: id,
      //       vehicle_id: (assignedVehicle || 'TBD'),
      //       guide_id: (assignedGuide || 'TBD'),
      //       group: (group || 'A'),
      //     },
      //     customer: {
      //       contact_name: contactName,
      //       email,
      //       phone,
      //       secondary_email: secondaryEmail,
      //       secondary_phone: secondaryPhone,
      //     },
      //     booking: {
      //       pickup_time: pickupTime,
      //       pickup_location: pickupLocation,
      //     },
      //   },
      // };

      // console.log(updateInput);

      // console.log(params);
      // console.log(assignedVehicle, assignedGuide);
      // Make the Hasura request
      // updateRow(updateInput)
      callMutation(updateInput)
        .then((result) => {
          console.log(result.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong. Try again!", {
            autoClose: 8000,
            position: "bottom-right",
          });
        });

      setIsEditing(false);
      setNewValue(null);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", {
        autoClose: 8000,
        position: "bottom-right",
      });
    }
  }

  function onCellValueChange(event) {
    const {
      event: { key },
    } = event;

    console.log("onCellValueChange", event);

    if (key === "Enter") {
      onCellValueChangeCommitted(event);
    } else if (key === "Escape") {
      setIsEditing(false);
      setNewValue(null);
      event.api.stopEditing();
    }
  }

  const onCellFocused = (event) => {
    const { columnApi } = event.api;
    const column = columnApi?.getColumn(event.column.getId());
    if (column?.isEditable()) {
      event.api.stopEditing();
    }
  };

  return {
    stateAndActions: {
      data: {
        newValue,
        setNewValue,
        getNewValue,
        isEditing,
        setIsEditing,
      },
      actions: {
        onCellEditingStarted,
        onCellEditingStopped,
        onCellValueChangeCommitted,
        onCellValueChange,
        onCellFocused,
        refetchBookings,
      },
    },
  };
}

export default useCellEditing;
