import React from 'react';
import {
  Calendar as CalendarComponent,
  momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';

const localizer = momentLocalizer(moment);

const Calendar = ({ events, onSelectEvent }) => {
  const eventPropGetter = (event) => {
    let className = '';
    if (event.title.includes('Morning')) {
      className = 'rbc-event-yellow';
    } else if (event.title.includes('Afternoon')) {
      className = 'rbc-event-orange';
    } else if (event.title.includes('Evening')) {
      className = 'rbc-event-blue';
    }
  
    return { className };
  };

  return (
    /* @ts-ignore */
    <CalendarComponent
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onDoubleClickEvent={(event, mouseClickEvent) => onSelectEvent(event, mouseClickEvent)}
      showAllEvents
      eventPropGetter={eventPropGetter}
    />
  );
};

export default React.memo(Calendar);
