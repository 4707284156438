import { gql } from '@apollo/client';

const FETCH_TABLE_VIEWS = gql`
query fetchTableViews ($tableName:String!, $userId:String!) {
  views: boatnew_table_settings(
    where:{
      _and: [
        { table:{ _eq: $tableName } },
        { _or: [
          { user_id:{ _eq: $userId } },
          { user_id:{ _eq: "default" } }
        ]}
      ]
    }
  ) {
    id
    table
    settings
    filters
    userID:user_id
    viewName:view_name
  }
}
`;

const SUBSCRIBE_TABLE_VIEWS = gql`
subscription fetchTableViews ($tableName:String!, $userId:String!) {
  views: boatnew_table_settings(
    where:{
      _and: [
        { table:{ _eq: $tableName } },
        { _or: [
          { user_id:{ _eq: $userId } },
          { user_id:{ _eq: "default" } }
        ]}
      ]
    }
  ) {
    id
    table
    settings
    filters
    userID:user_id
    viewName:view_name
  }
}
`;

const ADD_TABLE_SETTINGS = gql`
mutation ADD_TABLE_SETTINGS ($input:boatnew_table_settings_insert_input!) {
  views: insert_boatnew_table_settings_one(object: $input) {
    id
  }
}
`;

const UPDATE_TABLE_SETTINGS = gql`
  mutation UPDATE_TABLE_SETTINGS($id: String!, $input:boatnew_table_settings_set_input!) {
    views: update_boatnew_table_settings_by_pk(pk_columns:{id:$id}, _set:$input) {
      id
      table
    }
}
`;

const DELETE_TABLE_SETTINGS = gql`
  mutation DELETE_TABLE_SETTINGS($id: String!) {
    views: delete_boatnew_table_settings_by_pk(id:$id) {
      id
      name:view_name
    }
}
`;

export {
  FETCH_TABLE_VIEWS,
  ADD_TABLE_SETTINGS,
  UPDATE_TABLE_SETTINGS,
  DELETE_TABLE_SETTINGS,
  SUBSCRIBE_TABLE_VIEWS,
};
