import { gql } from '@apollo/client';

const FETCH_UNITS = gql`
query FETCH_UNITS {
  units: boatnew_units(order_by: {label: asc}) {
    id
    label
  }
}
`;

export default FETCH_UNITS;
