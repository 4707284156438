import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ADD_AVAILABILITY,
  UPDATE_PRODUCT,
  UPDATE_CITIES_PRODUCTS,
  ADD_AVAILABILITY_SERIES_VEHICLES,
  FETCH_PRODUCT,
  FETCH_AVAILABILITY,
  FETCH_AVAILABILITY_SERIES,
  UPDATE_PRODUCT_ATTRACTIONS,
  UPDATE_PRODUCT_UNITS,
  ADD_REZDY_PRODUCT,
  DELETE_REZDY_PRODUCT,
  FETCH_PRODUCT_PRICE,
  UPDATE_PRODUCT_PRICE,
  SUBSCRIBE_PRODUCTS,
} from '../../../../api/Product';
import FETCH_UNITS from '../../../../api/Units';
import { validTime, positiveNum, validPrice } from '../../../../utils/Regex';
import EditForm from './EditForm';
import './ProductWizardEdit.scss';

dayjs.extend(timezone);
dayjs.extend(utc);

function ProductWizard() {
  const { productId } = useParams();
  const [page, setPage] = useState(1);
  const [initialValues, setInitialValues] = useState('');
  const [getSeries, { data: series }] = useLazyQuery(
    FETCH_AVAILABILITY_SERIES,
  );
  const { loading: pricingLoading, error: pricingError, data: pricingData } = useQuery(
    FETCH_PRODUCT_PRICE,
    {
      fetchPolicy: 'network-only',
      variables: {
        productId,
      },
    },
  );
  const locations = [];
  const productUnits = [];
  // const attractions = [];
  const attractions = ''
  const productPrice = pricingData?.boat_products_by_pk?.price;
  const {
    data, loading, error, refetch,
  } = useQuery(FETCH_PRODUCT, {
    variables: { id: productId },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      data?.product?.citiesProducts?.map((product) => {
        locations.push({ city_id: product.city.id, product_id: productId });
      });
      // data?.product?.productsAttractions?.map((attraction) => {
      //   attractions.push({ attraction_id: attraction.attraction.id, product_id: productId });
      // });
      data?.product?.productUnits?.map((unit) => {
        productUnits.push({ unit_id: unit.unit_id, product_id: productId });
      });
      data?.product?.product_prices?.[0]?.prices.map((item) => {});
    },
  });
  const { data: units } = useQuery(FETCH_UNITS);

  const { data: availabilitiesList } = useQuery(FETCH_AVAILABILITY, {
    variables: { id: data?.product?.citiesProducts[0]?.city.id },
  });

  const today = dayjs.utc().tz(data?.product?.citiesProducts[0]?.city?.timeZone).format('YYYY-MM-DD');

  useEffect(() => {
    console.log('data', data);
    if (data) {
      const newPrices = {
        ...pricingData?.boatnew_products_by_pk,
        advertised_price: data?.product?.advertisedPrice || '',
        quantity_max: data?.product?.quantityMax || '',
        quantity_min: data?.product?.quantityMin || '',
        ...(data?.product?.product_prices?.[0]?.prices),
      };
      setInitialValues({
        id: data?.product?.id,
        name: data?.product?.name,
        code: data?.product?.code,
        type: data?.product?.type,
        slug: data?.product?.slug,
        locations,
        pickup_bounds: data?.product?.pickupBounds || null,
        booking_cutoff: data?.product?.bookingCutoff?.slice(0, 2).replace('0', '') || '',
        bookingCutoffUnits: 'hours',
        cancel_cutoff: data?.product?.cancelCutoff?.slice(0, 2).replace('0', '') || '',
        cancelCutoffUnits: 'hours',
        duration: data?.product?.duration || '',
        advertised_price: data?.product?.advertisedPrice || '',
        active: data?.product?.active || false,
        paxMax: data?.product?.quantityMax || '',
        paxMin: data?.product?.quantityMin || '',
        quantity: '',
        start_time: '',
        start_date: '',
        end_date: '',
        juggle: false,
        prices: { ...newPrices },
        total_seats: '',
        adults: '0',
        children: '0',
        infants: '0',
        students: '0',
        teenagers: '0',
        group: '0',
        vehicles: '',
        product_units: productUnits,
        shared: false,
        shared_id: '',
        inRange: true,
        attractions,
        rezdy_id: data?.product?.rezdy?.rezdy_id || '',
        pickup_required: data?.product?.pickupRequired,
      });
    }
  }, [data, pricingData]);

  const [updateProductPrice] = useMutation(UPDATE_PRODUCT_PRICE, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
    onCompleted: async () => {
      const a = await refetch();
    },
  });

  const [addAvailabilitySeriesVehicles] = useMutation(ADD_AVAILABILITY_SERIES_VEHICLES, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
      {
        query: FETCH_AVAILABILITY,
        variables: { id: data?.product?.citiesProducts[0]?.city.id },
      },
    ],
  });

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    variables: { id: productId },
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
    onCompleted: async () => {
      const a = await refetch();
    },
  });

  const [updatePrice] = useMutation(UPDATE_PRODUCT_PRICE, {
    variables: { id: productId },
    refetchQueries: [
      {
        query: FETCH_PRODUCT_PRICE,
        variables: { id: productId },
      },
    ],
    onCompleted: async () => {
      const a = await refetch();
    },
  });

  const [updateAttractions] = useMutation(UPDATE_PRODUCT_ATTRACTIONS, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
  });

  const [updateCitiesProduct] = useMutation(UPDATE_CITIES_PRODUCTS, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
  });

  const [updateProductUnits] = useMutation(UPDATE_PRODUCT_UNITS, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
  });
  const [addRezdyProduct] = useMutation(ADD_REZDY_PRODUCT, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
  });

  const [addAvailability] = useMutation(ADD_AVAILABILITY, {
    onCompleted: (data) => {
      addAvailabilitySeriesVehicles({
        variables: {
          objects:
            availabilitiesSeriesVehicles(
              data.insert_boatnew_availabilities.returning[0].availabilities_series,
              data.insert_boatnew_availabilities.returning[0].availabilities_vehicles,
            ),
        },
      })
        .catch((e) => {
          alert(e);
          console.log(e);
        });
    },
  });

  const availabilitiesSeriesVehicles = (series, vehiclesList) => {
    const arr = [];
    series?.map((s) => vehiclesList?.map((v) => {
      const result = data?.vehicles?.filter((vehicle) => (
        vehicle.id === v.vehicle_id
      ));
      arr.push({
        availabilities_series_id: s.id,
        vehicle_id: v.vehicle_id,
        product_id: productId,
        total_seats: result[0].capacity,
      });
    }));
    return arr;
  };
  const [deleteRezdyProduct] = useMutation(DELETE_REZDY_PRODUCT, {
    variables: { id: initialValues?.rezdy_id },
    refetchQueries: [
      {
        query: FETCH_PRODUCT,
        variables: { id: productId },
      },
    ],
  });
  const availabilitiesSeries = (
    startDate,
    endDate,
    startTime,
    duration,
    sharedParentid,
    timeZone,
    result = [],
  ) => {
    const data = [...result];
    const start = dayjs(`${startDate} ${startTime}`);
    const end = start.add(Number(duration), 'minute');

    if (sharedParentid) {
      const sharedId = series?.boatnewAvailabilitiesSeries.filter((s) => s.startTime.includes(start.format('YYYY-MM-DD')));
      const obj = {
        start_time: start.format('YYYY-MM-DDTHH:mm:ss'),
        end_time: end.format('YYYY-MM-DDTHH:mm:ss'),
        shared_id: sharedId[0]?.id || null,
      };
      data.push(obj);
    } else {
      const obj = {
        start_time: start.format('YYYY-MM-DDTHH:mm:ss'),
        end_time: end.format('YYYY-MM-DDTHH:mm:ss'),
      };
      data.push(obj);
    }
    if (startDate === endDate) return data;
    return availabilitiesSeries(start.add(1, 'day').format('YYYY-MM-DD'), endDate, startTime, duration, sharedParentid, timeZone, data);
  };

  const validate = {
    1: (values) => {
      const errors = {};
      if (!values.name || !values.name.trim()) {
        errors.name = 'Required';
      }
      if (!values.locations || values.locations.length === 0) {
        errors.locations = 'Required';
      }
      if (!values.code || !values.code.trim()) {
        errors.code = 'Required';
      }
      if (!values.type) {
        errors.type = 'Required';
      }
      if (values.slug) {
        if (!/^[a-zA-Z0-9-]+$/.test(values.slug)) {
          errors.slug = 'Slug can only contain alphabets, numbers, and hyphens (e.g., masterpieces-of-the-art-gallery-of-ontario-a-guided-tour-through-art)';
        } else if (!/-/.test(values.slug)) {
          errors.slug = 'Slug must include a hyphen (e.g., masterpieces-of-the-art-gallery-of-ontario-a-guided-tour-through-art)';
        }
      }
      return errors;
    },
    2: (values) => {
      const errors = {};
      if (!values.booking_cutoff || !positiveNum.test(values.booking_cutoff)) {
        errors.booking_cutoff = 'Required';
      }
      if (!values.cancel_cutoff || !positiveNum.test(values.cancel_cutoff)) {
        errors.cancel_cutoff = 'Required';
      }
      if (!values.duration || !positiveNum.test(values.duration)) {
        errors.duration = 'Required';
      }
      if (!values.advertised_price || !validPrice.test(values.advertised_price)) {
        errors.advertised_price = 'Required';
      }
      if (!values.product_units || values.product_units.length === 0) {
        errors.product_units = 'Required';
      }
      if (!values.paxMin || !positiveNum.test(values.paxMin)) {
        errors.paxMin = 'Required';
      } else if (Number(values.paxMin) > Number(values.paxMax)) {
        errors.paxMin = 'Min number cannot be less than Max number';
      }
      if (!values.paxMax || !positiveNum.test(values.paxMax)) {
        errors.paxMax = 'Required';
      }
      return errors;
    },
    3: (values) => {
      const errors = {};
      if (!values.pickup_bounds) {
        errors.pickup_bounds = 'Required';
      }
      return errors;
    },
    4: (values) => {
      const errors = {};
      if (values.start_time && !validTime.test(values.start_time)) {
        errors.start_time = 'Enter valid time in HH:MM (24h) format';
      } else if (!values.start_time) {
        errors.start_time = 'Required';
      }
      if (!values.start_date) {
        errors.start_date = 'Required';
      } else if (values.start_date > values.end_date) {
        errors.start_date = 'Invalid date range';
      } else if (values.start_date < today) {
        errors.start_date = 'Invalid date';
      }
      if (!values.end_date) {
        errors.end_date = 'Required';
      } else if (values.end_date < today) {
        errors.end_date = 'Invalid date';
      }
      if (!values.total_seats || !positiveNum.test(values.total_seats)) {
        errors.total_seats = 'Required';
      }
      if (!values.adults || !validPrice.test(values.adults)) {
        errors.adults = 'Required';
      }
      if (!values.children || !validPrice.test(values.children)) {
        errors.children = 'Required';
      }
      if (!values.infants || !validPrice.test(values.infants)) {
        errors.infants = 'Required';
      }
      if (!values.teenagers || !validPrice.test(values.teenagers)) {
        errors.teenagers = 'Required';
      }
      if (!values.students || !validPrice.test(values.students)) {
        errors.students = 'Required';
      }
      if (!values.group || !validPrice.test(values.group)) {
        errors.group = 'Required';
      }
      if (!values.vehicles || values.vehicles.length === 0) {
        errors.vehicles = 'Required';
      }
      if (!values.shared_id && values.shared) {
        errors.shared_id = 'Required';
      }
      if (!values.inRange) {
        errors.shared_id = 'Selected dates are not in the date range of selected availability ';
      }
      return errors;
    },
    5: (values) => {
      const errors = {};
      if (!values.attractions || values.attractions.length === 0) {
        errors.attractions = 'Required';
      }
      return errors;
    },
    7: (values) => {
      const errors = {};
      if (!values.prices) {
        errors.prices = 'Required';
      } else {
        if (!values.prices.advertised_price || !validPrice.test(values.prices.advertised_price)) {
          errors.advertised_price = 'Required';
        }
        if (!values.prices.quantity_min || !positiveNum.test(values.prices.quantity_min)) {
          errors.quantity_min = 'Required';
        }
        if (!values.prices.quantity_max || !positiveNum.test(values.prices.quantity_max)) {
          errors.quantity_max = 'Required';
        }

        if (values.prices.quantity_min > values.prices.quantity_max) {
          errors.quantity_max = 'Maximum quantity cannot be less than minimum quantity';
        }

        if (values.prices.quantity_max < values.prices.quantity_min) {
          errors.quantity_min = 'Minimum quantity cannot be greater than maximum quantity';
        }
      }
      return errors;
    },

  };

  return (
    !loading && !error && initialValues && (
      <EditForm
        page={page}
        setPage={setPage}
        validate={validate}
        product={data?.product}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        cities={data?.cities}
        addAvailability={addAvailability}
        updateProduct={updateProduct}
        updateCitiesProduct={updateCitiesProduct}
        updateProductUnits={updateProductUnits}
        updatePrice={updatePrice}
        updateAttractions={updateAttractions}
        updateProductPrice={updateProductPrice}
        availabilitiesSeries={availabilitiesSeries}
        units={units}
        availabilitiesList={availabilitiesList?.availabilities}
        getSeries={getSeries}
        addRezdyProduct={addRezdyProduct}
        deleteRezdyProduct={deleteRezdyProduct}
      />
    )
  );
}

export default ProductWizard;
