import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';
import GuideForm from '../GuideForm';
import GuideSchema from '../../../../yup/guide';
import { FETCH_GUIDES_COUNT_BY_EMAIL } from '../../../../api/Guides';

function EditGuideForm({
  guide,
  cities,
  editGuide,
  initialValues,
  insertGuideCity,
}) {
  const navigate = useNavigate();
  const [getGuideCountByEmail] = useLazyQuery(FETCH_GUIDES_COUNT_BY_EMAIL);

  const getUpdatedValues = (values, initialValuesFormik, result) => {
    const initValues = Object.values(initialValuesFormik);
    for (const [key, value] of Object.entries(values)) {
      if (!initValues.includes(value)) {
        result[key] = value;
      }
    }
  };

  const formik = useFormik({
    initialValues,
    // validate,
    validationSchema: GuideSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const result = {};
      getUpdatedValues(values, formik.initialValues, result);
      console.log('Values from the form', values);

      // Check if the email already exists
      const { data: { guides_aggregate: { aggregate: { count } } } } = await getGuideCountByEmail({
        variables: { email: values.email_personal.trim() },
      });
      console.log(count);

      if (
        (values?.email_personal !== initialValues.email_personal && count > 0)
        || (values?.email_personal === initialValues.email_personal && count > 1)
      ) {
        formik.setFieldError('email_personal', 'A guide with the same email already exists.');
        return;
      }

      const input = {
        full_name: values?.name || '',
        phone: values?.phone || '',
        airtable_id: (values?.airtable_id || '') === '' ? null : values?.airtable_id,
      };

      // Only pass email_personal in the mutation if it has changed
      if (values.email_personal !== formik.initialValues.email_personal) {
        input.email_personal = values?.email_personal || '';
      }

      editGuide({
        variables: {
          guideId: values?.id,
          input,
          cityId: values?.city_id || '',
        },
      })
        .then((result) => {
          const { data: { updateCity: { affected_rows: affectedRows } } } = result;
          if (affectedRows === 0) {
            insertGuideCity({
              variables: {
                input: {
                  city_id: values?.city_id.trim(),
                  guide_id: values?.id,
                },
              },
            });
          }
        })
        .catch((e) => {
          alert('Error: The Airtable ID you have entered already exists. Please enter a different Airtable ID and try again');
          console.log(e);
        });
    },
  });

  return (
    <GuideForm formik={formik} cities={cities} guide={guide} />
  );
}

export default EditGuideForm;
