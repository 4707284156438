import { gql } from '@apollo/client';

const FETCH_RESERVATION_TABLE = gql`
subscription FETCH_RESERVATION_TABLE($date: date!, $city: String!) {
  reservations: boatnew_reservations(where: {city_id: {_eq: $city}}) {
    id
    attraction
    portal
    contact
    template
    productReservations: products_reservations {
      startingTime: starting_time
      product {
        id
        name
        bookings: booking_status_sort(where: {tour_date: {_eq: $date}, status: {_neq: "CANCELLED"}}) {
          id
          status
          timeGroup: time_group
          tourDate: tour_date
          tourTime: tour_time
          booking_vehicles {
            guide {
              name: full_name
            }
          }
          product {
            id
            name
          }
          bookingUnits: booking_units(order_by: {unit: {label: asc}}) {
            quantity
            unit {
              id
              label
            }
          }
          bookingReservations: booking_reservations {
            status
            reservationId: reservation_id
            pax
          }
        }
      }
    }
  }
}
`;

const FETCH_RESERVATION_LOGS = gql`
query FETCH_RESERVATION_LOGS($date: date!) {
  logs: logs_reservations_logs(where: {date: {_eq: $date}}) {
    id
    status
    content
    date
    time
    createdAt: created_at
  }
}
`;

const ADD_BOOKING_RESERVATIONS = gql`
mutation ADD_BOOKING_RESERVATIONS(
  $objects: [boatnew_booking_reservations_insert_input!]!,
  $input: logs_reservations_logs_insert_input!) {
  insert_logs_reservations_logs_one(object: $input) {
    id
  }
  insert_boatnew_booking_reservations(
    objects: $objects,
    on_conflict: {constraint: booking_reservations_pkey, update_columns: log_id}){
    affected_rows
  }
}
`;

const UPDATE_RESERVATION_STATUS = gql`
mutation UPDATE_RESERVATION_STATUS($bookingId: String!, $reservationId: String!, $status: String!) {
  update_boatnew_booking_reservations_by_pk(pk_columns: {booking_id: $bookingId, reservation_id: $reservationId}, _set: {status: $status}) {
    status
  }
}

`;

export {
  FETCH_RESERVATION_TABLE,
  ADD_BOOKING_RESERVATIONS,
  FETCH_RESERVATION_LOGS,
  UPDATE_RESERVATION_STATUS,
};
