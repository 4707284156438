import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { FETCH_CITIES_BY_ID, UPDATE_CITY } from "../../../../api/city";
import EditCitiesForm from "./EditCitiesForm";

import "../../cities.scss";

function EditCity() {
  const { cityId } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState("");

  const { data: city, loading } = useQuery(FETCH_CITIES_BY_ID, {
    variables: { id: cityId },
  });
  console.log(city, "city id");
  const [updateCity] = useMutation(UPDATE_CITY, {
    variables: { id: cityId },
    refetchQueries: [
      {
        query: FETCH_CITIES_BY_ID,
        variables: { id: cityId },
      },
    ],
    onCompleted: () => {
      toast.success("City updated!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000,
      });
      navigate("/cities");
    },
  });

  useEffect(() => {
    if (city) {
      let c = city?.boatnew_cities_by_pk;
      setInitialValues({
        name: c?.name || "",
        coordinates: c?.coordinates || "", // or adjust based on the actual structure
        timeSlots: c?.timeSlots || [], // assuming timeSlots is an array
        locale: c?.locale || "",
        country: c?.country || "",
        longitude: c?.coordinates?.lng || "",
        latitude: c?.coordinates?.lat || "",
        time_zone: c?.time_zone || "",
        // Add any other city-specific fields you need
      });
    }
  }, [city]);

  const validate = (values) => {
    const errors = {};
    if (!values.name || !values.name.trim()) {
      errors.name = "Required";
    }
    if (!values.country) {
      errors.country = "Required";
    }
    if (!values.longitude) {
      errors.longitude = "Required";
    }
    if (!values.latitude) {
      errors.latitude = "Required";
    }

    return errors;
  };

  return (
    // !loading &&
    initialValues && (
      <div className="Vehicles__editVehicle">
        <EditCitiesForm
          city={city?.boatnew_cities_by_pk}
          initialValues={initialValues}
          updateCity={updateCity}
          validate={validate}
        />
      </div>
    )
  );
}

export default EditCity;
