import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';
import { Form, InputGroup } from 'react-bootstrap';
import isBetween from 'dayjs/plugin/isBetween';
import { useQuery } from '@apollo/client';
import { FETCH_VEHICLES_BY_CITY } from '../../../../api/Vehicles';

dayjs.extend(isBetween);

function Page4({
  formik,
  availabilities,
  units,
  selectedUnits,
  availabilitiesList,
  getSeries,
}) {
  const { values, errors, handleChange } = formik;
  const [options, setOptions] = useState('');
  const [showForm, setShowForm] = useState(false);

  const getCitiesId = () => {
    const citiesId = [];
    values.locations?.map((city) => {
      citiesId.push(city.city_id);
    });
    return citiesId;
  };

  const { data: vehicles, refetch } = useQuery(FETCH_VEHICLES_BY_CITY, {
    variables: { citiesId: getCitiesId() },
  });

  useEffect(() => {
    const optionsArr = vehicles?.vehicles?.map((vehicle) => ({
      value: vehicle.id,
      label: vehicle.name,
    }));
    setOptions(optionsArr);
  }, [vehicles]);

  const handleVahicleChange = (value) => {
    const vehicles = [];
    value.map((vehicle) => {
      vehicles.push({ vehicle_id: vehicle.value });
    });
    formik.setFieldValue('vehicles', vehicles);
  };

  const handleShowForm = () => {
    if (values.duration && values.product_units.length > 0) {
      values.type.includes('Private')
        ? formik.setFieldValue('group', '')
        : selectedUnits?.map((unit) => {
          formik.setFieldValue(unit.label.toLowerCase(), '');
        });
      setShowForm(true);
    } else {
      alert('Duration and at least one type of passengers must be configured before adding the availability');
    }
  };

  const getLabel = (unitID) => {
    const unitLabel = units?.filter((unit) => unit.id === unitID);
    return unitLabel[0].label.toLowerCase();
  };

  const checkDateRange = () => {
    const availability = availabilitiesList?.filter((a) => a.id === values.shared_id);
    availability && formik.setFieldValue('inRange', (dayjs(values.start_date).isBetween(availability[0]?.start_date, availability[0]?.end_date, null, '[]')
      || dayjs(values.end_date).isBetween(availability?.start_date, availability?.end_date, null, '[]')
      || dayjs(availability[0]?.start_date).isBetween(values.start_date, values.end_date, null, '[]')
      || dayjs(availability[0]?.end_date).isBetween(values.start_date, values.end_date, null, '[]')));
  };

  useEffect(() => {
    values.shared_id && checkDateRange();
  }, [values.start_date, values.end_date, values.shared_id, values.shared]);

  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setFullYear(currentDate.getFullYear() - 5);
  const maxDate = new Date(currentDate);
  maxDate.setFullYear(currentDate.getFullYear() + 5);

  return (
    <div className="EditForm__form-page4">
      {availabilities.length > 0
        && (
          <table>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Start Time</th>
                <th>Total Seats</th>
              </tr>
            </thead>
            <tbody>
              {availabilities?.map((availability) => (
                <tr key={availability.id}>
                  <td>{availability.start_date}</td>
                  <td>{availability.end_date}</td>
                  <td>{availability.start_time.slice(0, 5)}</td>
                  <td>{availability.total_seats}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      {showForm
        ? (
          <div className="EditForm__form-page4-new">
            <Form.Group>
              <Form.Label>Start Time (24 Hour)</Form.Label>
              <Form.Control
                id="start_time"
                name="start_time"
                placeholder="Start Time"
                type="time"
                onChange={handleChange}
                value={values.start_time}
                className={errors.start_time ? 'is-invalid' : ''}
              />
              {errors.start_time ? <div className="EditForm__error">{errors.start_time}</div> : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Dates</Form.Label>
              <div className="EditForm__form-page4-dates">
                <InputGroup>
                  <InputGroup.Text>From</InputGroup.Text>
                  <Form.Control
                    id="start_date"
                    name="start_date"
                    type="date"
                    placeholder="yyyy-mm-dd"
                    onChange={handleChange}
                    value={values.start_date}
                    className={errors.start_date ? 'is-invalid' : ''}
                    min={minDate.toISOString().split('T')[0]}
                    max={maxDate.toISOString().split('T')[0]}
                  />
                  {errors.start_date ? <div className="EditForm__error">{errors.start_date}</div> : null}
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>To</InputGroup.Text>
                  <Form.Control
                    id="end_date"
                    name="end_date"
                    type="date"
                    placeholder="yyyy-mm-dd"
                    onChange={handleChange}
                    value={values.end_date}
                    className={errors.end_date ? 'is-invalid' : ''}
                    min={minDate.toISOString().split('T')[0]}
                    max={maxDate.toISOString().split('T')[0]}
                  />
                  {errors.end_date ? <div className="EditForm__error">{errors.end_date}</div> : null}
                </InputGroup>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Total Seats</Form.Label>
              <Form.Control
                id="total_seats"
                name="total_seats"
                placeholder="Total seats"
                type="number"
                onChange={handleChange}
                value={values.total_seats}
                className={errors.total_seats ? 'is-invalid' : ''}
              />
              <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
            </Form.Group>
            <Form.Check
              label="Juggle Resoursces"
              id="juggle"
              name="juggle"
              placeholder="Juggle Resoursces"
              type="checkbox"
              onChange={handleChange}
              value={!values.juggle}
              className="EditForm__form-page4-juggle"
            />
            <Form.Group>
              <Form.Label>Prices</Form.Label>
              <div className="EditForm__form-page4-price">
                {values.type.includes('Private')
                  ? (
                    <InputGroup>
                      <InputGroup.Text className="EditForm__form-page4-price-label">
                        Group
                      </InputGroup.Text>
                      <Form.Control
                        id="group"
                        name="group"
                        type="number"
                        placeholder="Group"
                        onChange={(e) => {
                          formik.setFieldValue('prices', { ...values.prices, group: e.target.value });
                          formik.setFieldValue('group', e.target.value);
                        }}
                        value={values.group}
                        className={errors.group ? 'is-invalid' : ''}
                      />
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                    </InputGroup>
                  )
                  : values.product_units.map((unit) => {
                    const label = getLabel(unit.unit_id);
                    return (
                      <InputGroup key={unit.unit_id}>
                        <InputGroup.Text className="EditForm__form-page4-price-label">
                          {label.charAt(0).toUpperCase() + label.slice(1)}
                        </InputGroup.Text>
                        <Form.Control
                          id={label}
                          name={label}
                          type="number"
                          onChange={(e) => {
                            formik.setFieldValue('prices', { ...values.prices, [label]: e.target.value });
                            formik.setFieldValue(label, e.target.value);
                          }}
                          value={values[label]}
                          className={errors[label] ? 'is-invalid' : ''}
                        />
                        <InputGroup.Text>US$</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                      </InputGroup>
                    );
                  })}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Vehicles
                <button
                  className="EditForm__form-page4-refreshBtn"
                  type="button"
                  onClick={() => refetch()}
                >
                  <i className="fas fa-redo" />
                </button>
              </Form.Label>
              <Select
                closeMenuOnSelect
                isMulti
                options={options}
                onChange={(value) => handleVahicleChange(value)}
              />
              {errors.vehicles ? <div className="EditForm__error">{errors.vehicles}</div> : null}
            </Form.Group>
            <Form.Check
              label="Shared"
              id="shared"
              name="shared"
              type="checkbox"
              onChange={() => {
                formik.setFieldValue('shared_id', '');
                formik.setFieldValue('shared', !values.shared);
              }}
              value={!values.shared}
              className="EditForm__form-page4-shared"
            />
            {values.shared
              && (
                <Form.Group className="EditForm__form-page4-shared-select">
                  <Form.Select
                    defaultValue="default"
                    onChange={(e) => {
                      formik.setFieldValue('shared_id', e.target.value);
                      getSeries({ variables: { parentId: e.target.value } });
                    }}
                  >
                    <option value="default" disabled>Choose availability</option>
                    {availabilitiesList.map((availability) => (
                      <option
                        value={availability.id}
                        key={availability.id}
                      >
                        {`${availability.product.name} (${availability.start_date}/${availability.end_date}) ${availability.start_time.slice(0, 5)}. `}
                        {`Vehicles: ${availability?.availabilities_vehicles?.map((vehicle) => ` ${vehicle?.vehicle?.name}`)}`}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.shared_id ? <div className="EditForm__error">{errors.shared_id}</div> : null}
                </Form.Group>
              )}
          </div>
        )
        : (
          <button className="EditForm__form-page4-showBtn" type="button" onClick={() => handleShowForm()}>
            Add Availability
          </button>
        )}
    </div>
  );
}

export default Page4;
