import React, { useState } from 'react';
import { statusList } from '../../../../utils/Variables';

const SubtaskAddForm = ({
  addTask,
  bookingId,
}) => {
  const [showAddForm, setShowAddForm] = useState();
  const [newTaskVal, setNewTaskVal] = useState('');
  const [newTaskStatus, setNewTaskStatus] = useState('pending');

  const handleSubmit = (e) => {
    e.preventDefault();
    addTask({
      variables: {
        input: {
          task: newTaskVal.trim(),
          status: newTaskStatus,
          booking_id: bookingId,
        },
      },
    });
    setShowAddForm(false);
    setNewTaskVal('');
  };

  return (
    <div className="Subtask__task">
      {showAddForm
        ? (
          <form
            className="Subtask__task-edit-form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <select
              value={newTaskStatus}
              onChange={(e) => setNewTaskStatus(e.target.value)}
            >
              {statusList.slice(0, 4).map((status) => (
                <option value={status} key={status}>
                  {status === 'needsreview'
                    ? 'Needs Review'
                    : status.charAt(0).toUpperCase() + status.slice(1)}
                </option>
              ))}
            </select>
            <input
              required
              value={newTaskVal}
              onChange={(e) => setNewTaskVal(e.target.value)}
            />
            <div className="Subtask__task-edit-form-btn">
              <button type="submit">
                <i className="fas fa-save" />
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setNewTaskVal('');
                }}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          </form>
        )
        : (
          <div
            className="Subtask__task-add-form-info"
            onClick={(e) => {
              setShowAddForm(true);
            }}
          >
            <div className="Subtask__task-status Status-display__cancelled">
              <i className="fas fa-plus Subtask__task-status-check-mark" />
            </div>
            Add new subtask
          </div>
        )}
    </div>
  );
};

export default SubtaskAddForm;
