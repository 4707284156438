import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {
  ADD_TRANSACTION,
  UPDATE_BOOKING_CUSTOMER,
} from "../../../../../../api/bookings";
import PaymentHistory from "./PaymentHistory";
import fetchFromAPI from "../../../../../../utils/helpers";
import ChargeNewCard from "./ChargeNewCard";
import ChargeExistingCard from "./ChargeExistingCard";
import "../../styles/PaymentInfo.scss";
import PaymentConfirmation from "./PaymentConfirmation";
import Refund from "./Refund";
import { INSERT_CUSTOMER } from "../../../../../../api/customers";

const API = process.env.REACT_APP_BOAT_API;

function Payment({ clickedBooking }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [formOpen, setFormOpen] = useState("refund");
  const [formMsg, setFormMsg] = useState("");
  const [paymentsList, setPaymentsList] = useState("");
  const [checked, setChecked] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState();
  const [stripeCustomerId, setStripeCustomerId] = useState(
    clickedBooking?.customer?.customerId
  );

  const paymentIntentsList = async (body) => {
    const res = await fetchFromAPI(API, "create-payment-intents-list", {
      body,
    });
    setPaymentsList(
      res.paymentIntentsList.data.filter(
        (record) => record.last_payment_error === null
      )
    );
  };

  const paymentList = async (body) => {
    const res = await fetchFromAPI(API, "payment-options", {
      body,
    });
    setPaymentOptions(res);
  };

  const [insertCustomer] = useMutation(INSERT_CUSTOMER);

  const [updateBookingCustomer] = useMutation(UPDATE_BOOKING_CUSTOMER);

  const [addTransaction] = useMutation(ADD_TRANSACTION, {
    onCompleted: () => {
      setShowConfirmation(false);
      const body = {
        customer: stripeCustomerId,
      };
      paymentIntentsList(body);
      paymentList(body);
      setConfirmed(false);
      setFormMsg("");
      setChecked(false);
    },
  });

  useEffect(() => {
    const body = {
      customer: clickedBooking.customer.customerId,
    };
    paymentIntentsList(body);
    paymentList(body);
  }, [clickedBooking]);

  return (
    <div className="BookingModal__payment-info">
      <div className="BookingModal__payment-info-container">
        <PaymentHistory
          clickedBooking={clickedBooking}
          paymentsList={paymentsList}
        />
      </div>
      <div className="BookingModal__payment-info-forms">
        <Accordion defaultActiveKey="0">
          <Refund
            clickedBooking={clickedBooking}
            setShowConfirmation={setShowConfirmation}
            confirmed={confirmed}
            addTransaction={addTransaction}
            setFormOpen={setFormOpen}
            formOpen={formOpen}
            setFormMsg={setFormMsg}
            paymentsList={paymentsList}
            checked={checked}
          />
          <ChargeExistingCard
            clickedBooking={clickedBooking}
            setShowConfirmation={setShowConfirmation}
            confirmed={confirmed}
            addTransaction={addTransaction}
            setFormOpen={setFormOpen}
            formOpen={formOpen}
            setFormMsg={setFormMsg}
            paymentOptions={paymentOptions}
          />
          <ChargeNewCard
            clickedBooking={clickedBooking}
            setFormOpen={setFormOpen}
            addTransaction={addTransaction}
            insertCustomer={insertCustomer}
            updateBookingCustomer={updateBookingCustomer}
            stripeCustomerId={stripeCustomerId}
            setStripeCustomerId={setStripeCustomerId}
          />
        </Accordion>
      </div>
      <PaymentConfirmation
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        setConfirmed={setConfirmed}
        formOpen={formOpen}
        formMsg={formMsg}
        clickedBooking={clickedBooking}
        checked={checked}
        setChecked={setChecked}
      />
    </div>
  );
}

export default Payment;
