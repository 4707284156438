import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import EditUserForm from './EditUserForm';
import '../../Users.scss';
import { EDIT_USER, FETCH_USER_BY_ID } from '../../../../api/users';

function EditUser() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState('');
  const [location, setLocation] = useState(null);

  const { data: user, loading } = useQuery(FETCH_USER_BY_ID, {
    variables: { id: userId },
  });

  const [editUser] = useMutation(EDIT_USER, {
    onCompleted: () => {
      toast.success('User updated.', {
        autoClose: 3000,
        position: 'bottom-right',
      });
      navigate('/users');
    },
  });

  useEffect(() => {
    console.log(user);
    if (user) {
      setInitialValues({
        id: user?.user?.id || '',
        name: user?.user?.name || '',
        phone: user?.user
          ?.phone || '',
        email: user?.user?.email || '',
        role: user?.user?.role || '',
        permissions: user?.user?.permissions || '',
      });
    }
  }, [user]);

  return (
    !loading && initialValues
      ? (
        <div className="Vehicles__editVehicle">
          <EditUserForm
            initialValues={initialValues}
            editUser={editUser}
          />
        </div>
      ) : (
        <center>
          <div className="spinner-grow" />
        </center>
      ));
}

export default EditUser;
