/* eslint-disable no-param-reassign */
import { LazyQueryExecFunction, OperationVariables, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  DialogActions,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FETCH_AVAILABILITY_SERIES, FETCH_AVAILABILITY_VIEW } from '../../api/calendar';
import { DELETE_SESSION } from '../../api/Sessions';
import styles from './Sessions.module.scss';
import {
  AvailabilityType,
  AvailabilityView,
  EditingSession,
  ProductType,
  SeriesType,
} from './types';
import { getAvailability } from '../../components/EditSession/EditSession';

type ModalViewProps = {
  availabilityView: AvailabilityView[];
  handleCloseModal: () => void;
  monthSelected: number;
  productsSelected: ProductType[] | null;
  getAvailabilitySeries: LazyQueryExecFunction<any, OperationVariables>;
  setEditingSession: Dispatch<SetStateAction<EditingSession | null>>;
  setInitialSession: Dispatch<SetStateAction<EditingSession | null>>;
  editingSession: EditingSession | null;
  availabilitySeries: SeriesType;
  fetchRun: any;
  getVehicles: LazyQueryExecFunction<any, OperationVariables>;
};
const deleteConfirmationStyles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function SeriesInfo({
  availabilityView,
  handleCloseModal,
  fetchRun,
  monthSelected,
  productsSelected,
  getAvailabilitySeries,
  setEditingSession,
  setInitialSession,
  editingSession,
  availabilitySeries,
  getVehicles,
}: ModalViewProps) {
  const [totalBooked, setTotalBooked] = React.useState<number>(0);
  const [totalRemaining, setTotalRemaining] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [deleteSession] = useMutation(DELETE_SESSION);
  const navigate = useNavigate();
  console.log('availabities data===>', availabilitySeries);
  React.useEffect(() => {
    setLoading(true);
    if (!availabilityView) {
      setLoading(false);
      return;
    }
    // set total booked
    availabilityView?.forEach((vehicle) => {
      setTotalBooked((prevTotal) => prevTotal + vehicle.totalBooked);
    });
    // if theres a total remaining exception use that otherwise use all vehicles remaining combined
    console.log('remaining is ', availabilitySeries?.exception?.total_remaining);
    if (availabilitySeries?.exception?.total_remaining) {
      setTotalRemaining(parseInt(availabilitySeries?.exception?.total_remaining, 10));
    } else {
      availabilityView?.forEach((vehicle) => {
        setTotalRemaining((prevRemaining) => prevRemaining + vehicle.totalRemaining);
      });
    }
    setLoading(false);
  }, [availabilityView]);

  React.useEffect(() => {
    // get string array of city ids then get them
    const cities: string[] = [];
    editingSession?.series.availability
      .product.citiesProducts.forEach((city) => {
        cities.push(city?.cityId);
      });
    getVehicles({
      variables: {
        citiesId: cities,
      },
    });
  }, [editingSession]);

  const handleHideModal = () => {
    setDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    setDeleteModal(true);
  };
  const handleHideDelete = () => {
    setDeleteModal(false);
    handleCloseModal();
  };
  const [preventReOpen, setPreventReOpen] = useState(true);
  const handleDeleteSession = () => {
    if (availabilitySeries?.id) {
      handleHideDelete();
      const availabilities: AvailabilityType[] = [];
      productsSelected
        ?.forEach((product) => product?.availabilities?.forEach((availability) => {
          availabilities.push(availability);
        }));
      const beginningOfMonth = dayjs().month(monthSelected).startOf('month');
      const endOfMonth = dayjs().month(monthSelected).endOf('month');
      deleteSession({
        variables: {
          id: availabilitySeries?.id,
        },
        fetchPolicy: 'network-only',
        refetchQueries: [
          {
            query: FETCH_AVAILABILITY_SERIES,
            variables: {
              filters: {
                _or: availabilities.map((avail) => (
                  { parent_id: { _eq: avail.id } }
                )),
                start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
              },
            },
          },
          {
            query: FETCH_AVAILABILITY_VIEW,
            variables: {
              id: availabilitySeries?.id,
            },
          },
        ],
        onCompleted: () => {
          alert('Session deleted successfullty');
          setPreventReOpen(false);
          fetchRun();
          handleHideDelete();
        },
      });
    }
  };
  const handleEditSession = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (availabilitySeries) {
      setEditingSession({
        series: availabilitySeries,
        view: availabilityView,
        availability: getAvailability(availabilityView, availabilitySeries),
      });
      setInitialSession({
        series: availabilitySeries,
        view: availabilityView,
        availability: getAvailability(availabilityView, availabilitySeries),
      });
    }
    handleCloseModal();
  };

  const navigateToDisplayBookings = (id: any) => {
    console.log('booking id', id);
    navigate(`/sessionbooking/${id}`);
  };

  if (loading) return <h2>loading...</h2>;
  return (
    availabilityView
      ? (
        <div
          className={styles.info}
          key={availabilitySeries?.id}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h4 style={{ textAlign: 'center' }}>{availabilitySeries?.availability?.product?.name}</h4>
            <CloseIcon onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
          </div>

          <h4>{`Date: ${dayjs(availabilitySeries?.startTime).format('LL')}`}</h4>

          <h6>{`Time: ${dayjs(availabilitySeries?.startTime).format('LT')}`}</h6>

          <h6>{`Booked: ${totalBooked}`}</h6>

          <h6>{`Remaining: ${totalRemaining}`}</h6>

          {/*
            availabilitySeries?.availability?.prices?.group
              ? (
                <p>
                  Group: $
                  { availabilitySeries?.availability?.prices?.group }
                </p>
              )
              : (
                <h6>
                  Adult: $
                  {availabilitySeries?.availability?.prices?.adults}
                  ,
                  Children: $
                  {availabilitySeries?.availability?.prices?.children}
                  ,
                  Infants: $
                  {availabilitySeries?.availability?.prices?.infants}
                </h6>
              )
              */}
          <hr />
          { availabilityView?.map((availability:AvailabilityView) => (
            <div className={styles.availabilityVehicle}>
              <div className={styles.availabilityHeader}>
                {/* <h4>
                  {`Vehicle: ${availability?.vehicle?.name}
                   (${availability.totalSeats
                    - availability.totalRemaining}/${availability?.totalSeats})`}
                </h4> */}
                <h4>
                  {`Vehicle: ${availability?.vehicle?.name}`}
                </h4>
                <Button
                  variant="contained"
                  onClick={() => navigateToDisplayBookings(availabilitySeries?.id)}
                  className={styles.seriesInfoBookingsButton}
                >
                  Bookings
                </Button>

              </div>
              <hr />
            </div>
          )) ?? (
          <p>
            Loading Vehicles...
          </p>
          ) }
          <DialogActions
            className={styles.seriesActionButtons}
          >
            <Button variant="outlined" disableRipple onClick={handleCloseModal}>Cancel</Button>
            <Button variant="contained" id={availabilitySeries?.id} disableRipple onClick={handleEditSession}>Edit Session</Button>
            <Button color="error" variant="contained" className={styles.deleteSessionButton} onClick={handleConfirmDelete}>Delete session</Button>
          </DialogActions>
          <Modal
            open={deleteModal}
            onClose={handleHideDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={deleteConfirmationStyles}>
              <h3 className={styles.deleteConfirmationHeader}>
                Are you sure you wish to delete this session?
              </h3>
              <div className={styles.deleteConfirmationButtons}>
                <Button variant="contained" color="error" onClick={handleDeleteSession}>Delete</Button>
                <Button variant="outlined" onClick={handleHideDelete}>Cancel</Button>
              </div>
            </Box>
          </Modal>
        </div>
      )
      : <p>Problem getting availability viewavailabilityView...</p>

  );
}

export default SeriesInfo;
