import React, { useContext } from 'react';
import { TabPane } from 'react-bootstrap';
import BookingContext from '../../context/booking';
import Payment from '../../pages/Bookings/components/Modal/components/Payment/Payment';

function BookingPayment() {
  const booking = useContext(BookingContext);
  return (
    <TabPane
      eventKey="Payment"
      title="Payment"
    >
      <div className="BookingModal__container">
        { booking
          && (
            <Payment
              clickedBooking={booking}
            />
          )}
      </div>
    </TabPane>
  );
}

export default BookingPayment;
