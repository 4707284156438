import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { ADD_PRODUCT, FETCH_PRODUCTS } from "../../../../api/Product";
import { FETCH_CITIES } from "../../../../api/city";
import Page1 from "../ProductWizardPages/Page1";
import "./ProductWizardCreate.scss";

const validate = {
  1: (values) => {
    const errors = {};
    if (!values.name || !values.name.trim()) {
      errors.name = "Required";
    }
    if (!values.locations || values.locations.length === 0) {
      errors.locations = "Required";
    }
    if (!values.code || !values.code.trim()) {
      errors.code = "Required";
    }
    if (!values.type) {
      errors.type = "Required";
    }
    if (values.slug) {
      if (!/^[a-zA-Z0-9-]+$/.test(values.slug)) {
        errors.slug =
          "Slug can only contain alphabets, numbers, and hyphens (e.g., masterpieces-of-the-art-gallery-of-ontario-a-guided-tour-through-art)";
      } else if (!/-/.test(values.slug)) {
        errors.slug =
          "Slug must include a hyphen (e.g., masterpieces-of-the-art-gallery-of-ontario-a-guided-tour-through-art)";
      }
    }

    return errors;
  },
};

function ProductWizardCreate() {
  const navigate = useNavigate();

  const [selectedValues, setSelectedValues] = useState(null);

  const [addProduct] = useMutation(ADD_PRODUCT, {
    onCompleted: (data) => {
      navigate(`/products/edit/${data.insert_boatnew_products_one.id}`);
    },
    refetchQueries: [{ query: FETCH_PRODUCTS }],
  });

  const { data: cities } = useSubscription(FETCH_CITIES);

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      type: "",
      locations: "",
      slug: "",
      pickup_required: true,
    },
    validate: validate[1],
    validateOnChange: false,
    onSubmit: (values) => {
      addProduct({
        variables: {
          input: {
            name: values.name.trim(),
            code: values.code.trim(),
            type: values.type,
            slug: values.slug,
            pickup_required: values.pickup_required,
            cities_products: {
              data: values.locations,
            },
          },
        },
      }).catch((e) => {
        e.message.includes("Uniqueness violation")
          ? alert(`Product with code ${values.code} already exists`)
          : alert(e);
      });
    },
  });

  return (
    <div className="CreateForm">
      <form onSubmit={formik.handleSubmit}>
        <Page1
          formik={formik}
          cities={cities?.boatnew_cities}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
        <div className="CreateForm__buttons">
          <button
            type="button"
            id="cancel"
            onClick={() => window.location.reload(false)}
            className="CreateForm__buttons-cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            id="submit"
            className="CreateForm__buttons-submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductWizardCreate;
