import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';

function Confirm({
  trigger, visibility, setVisibility, action,
}) {
  const [disable, setDisable] = useState(false);
  const handleConfirm = async () => {
    if (action === '') return;
    setDisable(true);
    await trigger();
    setVisibility(false);
    toast.success(
      action === 'trash'
        ? 'Guide moved to trash.'
        : `Guide has been ${action === 'active' ? 'deactivated' : 'activated'}.`,
      {
        autoClose: 8000,
        position: 'bottom-right',
      },
    );
    setDisable(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={visibility}
      onClose={() => setVisibility(false)}
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          <center>
            {['active', 'deactive'].includes(action) && 'Confirmation'}
            {action === 'trash' && 'Move to Trash?'}
          </center>
        </Typography>
        <Typography id="modal-modal-description" sx={{ my: 2 }}>
          {['active', 'deactive'].includes(action)
          && <center>Are you sure?</center>}
          {action === 'trash' && <center>The guide will be deleted, but associated data will remain.</center>}
        </Typography>

        <div className="d-flex justify-content-center">
          <button disabled={disable} type="button" onClick={handleConfirm} className="Guides__buttons-save">Confirm</button>
          <button type="button" onClick={() => setVisibility(false)} className="Guides__buttons-cancel">Cancel</button>
        </div>
      </Box>
    </Modal>
  );
}

export default Confirm;
