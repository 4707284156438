/* eslint-disable guard-for-in */
import {
  makeVar, useQuery, useReactiveVar, useSubscription,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { addDays } from 'date-fns';
import bookings from '../../../../utils/defaultGridValues';
import {
  FETCH_BOOKINGS_STATUS_QUERY,
  FETCH_LAST_UPDATED_BOOKING,
  FETCH_ONGOING_BOOKINGS,
} from '../../../../api/bookings';
import useRecentUpdates from './useRecentUpdates';
import normalizeData from '../../../../utils/normalizeData';

export default function useBookings() {
  const [data, setData] = useState(null);
  const { recentUpdates, registerUpdate, isRecentlyUpdated } = useRecentUpdates();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 13),
      key: 'selection',
    },
  ]);

  function flattenObject(nestedObject) {
    const flattenedObject = {};
    function flattenHelper(obj, prefix = '') {
      for (const key in obj) {
        const flatKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          flattenHelper(obj[key], flatKey);
        } else if (Array.isArray(obj[key])) {
          for (let i = 0; i < obj[key].length; i += 1) {
            const arrayKey = `${flatKey}[${i}]`;
            if (typeof obj[key][i] === 'object') {
              flattenHelper(obj[key][i], arrayKey);
            } else {
              flattenedObject[arrayKey] = obj[key][i];
            }
          }
        } else {
          flattenedObject[flatKey] = obj[key];
        }
      }
    }
    flattenHelper(nestedObject);
    return flattenedObject;
  }

  function unflattenObject(flattenedObject) {
    const originalObject = {};
    for (const key in flattenedObject) {
      const value = flattenedObject[key];
      const keyParts = key.split('.');
      let temp = originalObject;
      for (let i = 0; i < keyParts.length - 1; i += 1) {
        let currentKey = keyParts[i];
        if (currentKey.endsWith(']')) {
          const openingBracketIndex = currentKey.lastIndexOf('[');
          const arrayIndex = currentKey.slice(openingBracketIndex + 1, -1);
          currentKey = currentKey.slice(0, openingBracketIndex);
          if (!(currentKey in temp)) {
            temp[currentKey] = [];
          }
          if (!temp[currentKey][arrayIndex]) {
            temp[currentKey][arrayIndex] = {};
          }
          temp = temp[currentKey][arrayIndex];
        } else {
          if (!(currentKey in temp)) {
            temp[currentKey] = {};
          }
          temp = temp[currentKey];
        }
      }
      let lastKey = keyParts[keyParts.length - 1];
      if (lastKey.endsWith(']')) {
        const openingBracketIndex = lastKey.lastIndexOf('[');
        const arrayIndex = lastKey.slice(openingBracketIndex + 1, -1);
        lastKey = lastKey.slice(0, openingBracketIndex);
        if (!(lastKey in temp)) {
          temp[lastKey] = [];
        }
        temp[lastKey][arrayIndex] = value;
      } else {
        temp[lastKey] = value;
      }
    }
    return originalObject;
  }

  const processBookingsFromSubscription = (bookings = []) => {
    // if (bookings === []) return;

    let processedBookings = [];

    if (data?.bookings?.length === 0) {
      /* Add all new bookings */
      // console.log('Bookings initial assignment');
      processedBookings = [...bookings];
    } else {
      /* Compare and update bookings */
      console.log('Bookings after update', bookings);

      // Step 1: Map over the previous bookings array and update relevant fields if necessary
      processedBookings = bookings?.map((booking) => {
        // console.log('============');
        const existingBooking = data?.bookings?.find((b) => b.id === booking.id);
        // console.log('existingBooking', existingBooking);

        if (booking.id === existingBooking.id) {
          // Step 2: Check if any booking fields updated
          const updatedFields = Object.keys(booking).filter(
            (field) => JSON.stringify(booking[field]) !== JSON.stringify(existingBooking[field]),
          );
            // if (JSON.stringify(booking[field]) !==
            // JSON.stringify(existingBooking[field]) || booking.id === 'SFYW39B')
            // console.log(booking[field], '!==', existingBooking[field],
            // JSON.stringify(booking[field]) !== JSON.stringify(existingBooking[field]));

          if (updatedFields.length > 0) {
            console.log(booking.id, updatedFields);
            /*
             Step 3: Update only relevant fields for the booking
             and register the update with the recent updates object
            */
            const updatedBookingFields = {};
            updatedFields.forEach((field) => {
              const isUpdatedRecently = isRecentlyUpdated(booking.id, field);
              if (!isUpdatedRecently) {
                updatedBookingFields[field] = booking[field];
                registerUpdate(booking.id, field);
              }
            });
            return { ...booking, ...updatedBookingFields };
          }
        }
        // Step 4: Return the booking object unchanged if it was not updated or was updated recently
        return booking;
      });
    }

    console.log('processedBookings', processedBookings);
    // console.log('processedBookings', { bookings: [...processedBookings] });
    setData({ bookings: [...processedBookings] });
  };

  const validateBookings = () => {};

  const [initialFilterState, setInitialFilterState] = useState({
    filters: {
      tour_date: {
        _gte: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
        _lte: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
      },
    },
  });

  useEffect(() => {
    setData(null);
    setInitialFilterState({
      filters: {
        tour_date: {
          _gte: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
          _lte: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
        },
      },
    });
  }, [dateRange]);

  const initialFilterVar = makeVar(initialFilterState);
  const initialFilter = useReactiveVar(initialFilterVar);

  const {
    loading: queriedLoading, error: queriedError, refetch: refetchBookings,
  } = useQuery(FETCH_BOOKINGS_STATUS_QUERY, {
    variables: initialFilter,
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      const refinedData = await normalizeData(data?.bookings, bookings);
      setData({ bookings: [...refinedData] });
    },
  });

  const { data: recentlyChanged, error: subError } = useSubscription(FETCH_LAST_UPDATED_BOOKING, {
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
    variables: initialFilter,
  });

  useEffect(() => {
    if (data === null) return;
    const mergedData = {};

    if (data && data.bookings && Array.isArray(data.bookings) && data.bookings.length > 0) {
      mergedData.bookings = data.bookings.slice(); // Use slice to create a new copy of the array
    } else {
      mergedData.bookings = [];
    }

    if (
      recentlyChanged
      && recentlyChanged.bookings
       && Array.isArray(recentlyChanged.bookings)
        && recentlyChanged.bookings.length > 0
    ) {
      for (let i = 0; i < recentlyChanged.bookings.length; i += 1) {
        const newObj = recentlyChanged.bookings[i];

        if (newObj && newObj.id) {
          const existingObj = mergedData.bookings.find((obj) => obj.id === newObj.id);

          if (!existingObj) {
            mergedData.bookings.push(newObj);
          }
        }
      }
    }

    setData(mergedData);
  }, [recentlyChanged]);

  useEffect(() => setLoading(queriedLoading), [queriedLoading]);
  useEffect(() => setError(queriedError), [queriedError]);

  return {
    data,
    loading,
    error,
    dateRange,
    setDateRange,
    initialFilterState,
    setInitialFilterState,
    refetchBookings,
  };
}
