/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Link, useOutletContext, useNavigate, Outlet, Navigate,
} from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GuidesHeader from './GuidesHeader';
import GridComponent from '../../../components/GridComponent/GridComponent';
import {
  ACTIVE_GUIDE, DEACTIVE_GUIDE, MOVE_TO_TRASH, APPROVE_GUIDE,
} from '../../../api/Guides';
import Confirm from './confirm';

const width = Math.ceil(window.outerWidth / 7);
function EditHandler({ id, status = '', approved }) {
  const { refetch, data } = useOutletContext();
  const [open, setOpen] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('pending');
  const [modal, setModal] = useState('');
  const [activeGuide] = useMutation(ACTIVE_GUIDE);
  const [deactiveGuide] = useMutation(DEACTIVE_GUIDE);
  const [moveGuideToTrash] = useMutation(MOVE_TO_TRASH);
  const [approveGuide] = useMutation(APPROVE_GUIDE);

  const handleStatusChange = async () => {
    if (status === 'active') {
      await activeGuide({
        variables: {
          guideId: id,
          status: 'deactive',
          timestamp: moment().format(),
          android_device_token: null,
          ios_device_token: null,
        },
      });
    } else {
      await deactiveGuide({
        variables: {
          guideId: id,
          status: 'active',
        },
      });
    }
  };

  const moveToTrash = async () => {
    await moveGuideToTrash({
      variables: {
        guideId: id,
      },
    });
  };

  const handleTrigger = async () => {
    if (modal === 'status') { await handleStatusChange(); } else if (modal === 'trash') { await moveToTrash(); }
  };

  const handleAction = () => {
    switch (modal) {
      case 'status':
        return status;
      case 'trash':
        return 'trash';
      default:
        return '';
    }
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const guideApproval = async () => {
    try {
      const response = await approveGuide({
        variables: {
          guideId: id,
          approved: true,
        },
      });
      console.log('Mutation Response:', response);
      setOpen(false);
    } catch (error) {
      console.error('Error approving guide:', error);
    }
  };
  return (
    id ? (
      <>
        <Link className="Vehicles__editBtn" to={`/guides/edit/${id}`}>Edit</Link>
        {
          approved ? (
            <Button
              style={{
                color: '#ff6262', marginLeft: '-20px', textTransform: 'none', fontSize: '12px', fontWeight: 550, backgroundColor: 'transparent', border: 'none', cursor: 'pointer',
              }}
              className="ms-3"
              to="/guides"
              onClick={() => setModal('status')}
            >
              {status === 'active' ? 'Deactivate' : 'Activate'}
            </Button>
          ) : (
            <Button
              className="ms-3"
              style={{
                color: '#ff6262', fontSize: '12px', textTransform: 'none', fontWeight: 550, backgroundColor: 'transparent', border: 'none', cursor: 'pointer',
              }}
              to="/guides"
              onClick={() => {
                console.log(`Selected Guide ID: ${id}`);
                // setOpen(true);
                navigate(`/guides/list/${id}`);
              }}
            >
              Approve
            </Button>
          )
        }
        <Button
          style={{
            color: '#ff6262', textTransform: 'none', fontSize: '12px', fontWeight: 550, backgroundColor: 'transparent', border: 'none', cursor: 'pointer',
          }}
          className=""
          to="/guides"
          onClick={() => setModal('trash')}
        >
          Trash
        </Button>

        <Confirm
          trigger={handleTrigger}
          visibility={modal !== ''}
          setVisibility={(v = false) => setModal(v === false ? '' : v)}
          action={handleAction()}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ textAlign: 'center' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Contracted Guide Approval
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to approve this contracted guide?
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Button style={{ backgroundColor: '#706e6e' }} variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={guideApproval}>
                Approve
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    ) : null
  );
}

function StatusRenderer({ status }) {
  return (
    <p className="text-capitalize">
      {(status)}
      <span className={`ms-1 badge badge-secondary p-1 pb-0 rounded-circle ${status === 'active' ? 'bg-success text-success' : (status === 'pending' ? 'bg-warning text-warning' : 'bg-danger text-danger')}`}>o</span>
    </p>
  );
}
const columnDefs = [
  {
    // eslint-disable-next-line max-len
    cellRenderer: (params) => (<EditHandler id={params?.data?.id} status={params?.data?.status} approved={params?.data?.approved} />),
    filter: () => null,
    width,
  },
  {
    field: 'id',
    resizable: true,
    filter: 'agTextColumnFilter',
    width,
  },
  {
    field: 'Name',
    valueGetter: (params) => params?.data?.fullName,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Email',
    valueGetter: (params) => params?.data?.email_personal,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Phone',
    valueGetter: (params) => params?.data?.phone,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'City',
    valueGetter: (params) => params?.data?.cities_guides?.city?.name || '',
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Status',
    valueGetter: (params) => params?.data?.status,
    cellRenderer: (params) => <StatusRenderer status={params?.data?.status} />,
    keyCreator: (params) => params.value,
    filter: 'agSetColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Last Deactivated',
    valueGetter: (params) => (params?.data?.last_deactivated !== null ? moment(params?.data?.last_deactivated).format('ddd, MMM DD, YYYY') : ''),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Joining Date',
    valueGetter: (params) => moment(params?.data?.created_at).format('ddd, MMM DD, YYYY'),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Joining Time',
    valueGetter: (params) => moment(params?.data?.created_at).format('HH:mm:ss'),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Approval',
    valueGetter: (params) => {
      const isApproved = params?.data?.approved;
      return isApproved ? 'Approved' : 'Pending';
    },
    keyCreator: (params) => params.value,
    resizable: true,
    filter: 'agTextColumnFilter',
    width,
  },
  {
    field: 'Role',
    valueGetter: (params) => {
      const role = params?.data?.role?.role;
      return role === 'CONTRACTED_GUIDE' ? 'PARTNER_GUIDE' : role;
    },
    keyCreator: (params) => params.value,
    resizable: true,
    filter: 'agTextColumnFilter',
    width,
  },
];

function DisplayVehicles() {
  const { data } = useOutletContext();
  const navigate = useNavigate();
  const handleReviewClick = (params) => {
    console.log(params.data.id);
    navigate(`/guides/${params?.data?.id}`);
  };
  const columnDefs = [
    {
      // eslint-disable-next-line max-len
      cellRenderer: (params) => (<EditHandler id={params?.data?.id} status={params?.data?.status} approved={params?.data?.approved} />),
      filter: () => null,
      width,
    },
    {
      field: 'id',
      hide: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      width,
    },
    {
      field: 'Name',
      valueGetter: (params) => params?.data?.fullName,
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      headerName: 'Documents',
      cellRenderer: (params) => {
        const role = params?.data?.role?.role;
        if (role === 'CONTRACTED_GUIDE') {
          return <Link className="Vehicles__editBtn" to={`/guides/list/${params?.data?.id}`}>View</Link>;
        }
        return 'N/A';
      },
      resizable: true,
      width,
    },
    {
      field: 'Email',
      valueGetter: (params) => params?.data?.email_personal,
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'Phone',
      valueGetter: (params) => params?.data?.phone,
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'City',
      valueGetter: (params) => params?.data?.cities_guides?.city?.name || '',
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'Status',
      valueGetter: (params) => params?.data?.status,
      cellRenderer: (params) => <StatusRenderer status={params?.data?.status} />,
      keyCreator: (params) => params.value,
      filter: 'agSetColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'Last Deactivated',
      valueGetter: (params) => (params?.data?.last_deactivated !== null ? moment(params?.data?.last_deactivated).format('ddd, MMM DD, YYYY') : ''),
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'Joining Date',
      valueGetter: (params) => moment(params?.data?.created_at).format('ddd, MMM DD, YYYY'),
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'Joining Time',
      valueGetter: (params) => moment(params?.data?.created_at).format('HH:mm:ss'),
      keyCreator: (params) => params.value,
      filter: 'agTextColumnFilter',
      resizable: true,
      width,
    },
    {
      field: 'Approval',
      valueGetter: (params) => {
        const isApproved = params?.data?.approved;
        return isApproved ? 'Approved' : 'Pending';
      },
      keyCreator: (params) => params.value,
      resizable: true,
      filter: 'agTextColumnFilter',
      width,
    },
    {
      field: 'Role',
      valueGetter: (params) => {
        const role = params?.data?.role?.role;
        return role === 'CONTRACTED_GUIDE' ? 'PARTNER_GUIDE' : role;
      },
      keyCreator: (params) => params.value,
      resizable: true,
      filter: 'agTextColumnFilter',
      width,
    },
  ];
  function StatusRenderer({ status }) {
    return (
      <p className="text-capitalize">
        {(status)}
        <span className={`ms-1 badge badge-secondary p-1 pb-0 rounded-circle ${status === 'active' ? 'bg-success text-success' : 'bg-danger text-danger'}`}>o</span>
      </p>
    );
  }
  return (
    data?.guides ? (
      <>
        <GridComponent
          gridTitle="guides"
          data={data?.guides}
          columnDefs={columnDefs}
        >
          <GuidesHeader />
        </GridComponent>
        <Outlet />
      </>
    )
      : null
  );
}

export default DisplayVehicles;
