import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Map from "../../../components/CentralPickup/Map";
import moment from "moment-timezone";
import axios from "axios";
function CityForm({ formik, cities, vehicle }) {
  const loadScript = (url, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const { values, errors, handleChange, touched, setFieldValue } = formik;

  const [query, setQuery] = useState("");
  const [countryQuery, setCountryQuery] = useState("");
  const [location, setLocation] = useState(null);

  const autoCompleteRef = useRef(null);
  const countryRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [localeOptions, setLocalOptions] = useState([]);
  const handleScriptLoad = () => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: [],
      }
    );
    const autoCompleteCountry = new window.google.maps.places.Autocomplete(
      countryRef.current,
      {
        types: [],
      }
    );

    autoComplete.addListener("place_changed", async () => {
      const addressObject = autoComplete.getPlace();
      const query = addressObject?.formatted_address || "";
      setQuery(query);

      const latLng = {
        lat: addressObject?.geometry?.location?.lat(),
        lng: addressObject?.geometry?.location?.lng(),
      };

      if (latLng.lat && latLng.lng) {
        setMapLocation({
          lat: parseFloat(values?.latitude),
          lng: parseFloat(values?.longitude),
        });
        setLocation({ location: query, coordinates: latLng });
        setFieldValue("name", query); // Update Formik state
        setFieldValue("latitude", latLng.lat);
        setFieldValue("longitude", latLng.lng);
      } else {
        toast.error("Invalid coordinates. Please select a valid location.");
      }
    });
    autoCompleteCountry.addListener("place_changed", async () => {
      const addressObject = autoCompleteCountry.getPlace();
      const countryQuery = addressObject?.formatted_address || "";

      setCountryQuery(countryQuery);

      setFieldValue("country", countryQuery); // Update Formik state
    });
  };
  const formatLocale = (locale) => {
    if (!locale?.startsWith("en-")) {
      const parts = locale?.split("-");
      if (parts.length > 1) {
        return `en-${parts[1].toUpperCase()}`;
      }
      return `en-${locale.toUpperCase()}`; // Add "en-" and capitalize the country code
    }
    return locale; // Return as is if it starts with "en-"
  };

  const handleInputFocus = () => {
    if (!scriptLoaded) {
      const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      loadScript(scriptUrl, handleScriptLoad);
      setScriptLoaded(true);
    }
  };

  const [mapLocation, setMapLocation] = useState({
    lat: parseFloat(values?.latitude) || 37.7749, // Default to San Francisco if no latitude provided
    lng: parseFloat(values?.longitude) || -122.4194, // Default to San Francisco if no longitude provided
  });

  useEffect(() => {
    if (values?.latitude && values?.longitude) {
      setMapLocation({
        lat: parseFloat(values?.latitude),
        lng: parseFloat(values?.longitude),
      });
    }
  }, [values?.latitude, values?.longitude]);

  useEffect(() => {
    if (countryQuery || values?.country) {
      axios
        .get(
          countryQuery
            ? `${process.env.REACT_APP_BASE_API_URL}/boat/country-timezone?countryName=${countryQuery}`
            : `${process.env.REACT_APP_BASE_API_URL}/boat/country-timezone?countryName=${values.country}`
        )
        .then((response) => {
          // Update timezone options with the API response
          setTimezoneOptions(
            response.data.timezones.map((tz) => ({
              value: tz,
              label: tz.replace("_", " "),
            }))
          );

          let loc = [response?.data?.locale];
          setLocalOptions(
            loc.map((l) => ({
              value: formatLocale(l),
              label: formatLocale(l).replace("_", " "),
            }))
          );
        })
        .catch((error) => {
          console.error("Error fetching time zones:", error);
        });
    }
  }, [countryQuery, values.country]);
  return (
    <form onSubmit={formik.handleSubmit} className="Vehicles__form">
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>City Name</Form.Label>
            <InputGroup className={errors.name ? "is-invalid" : ""}>
              <Form.Control
                id="name"
                name="name"
                type="text"
                className={errors.name && touched.name ? "is-invalid" : ""}
                ref={autoCompleteRef}
                onChange={(event) => {
                  setQuery(event.target.value); // Update local state
                  setFieldValue("name", event.target.value); // Update Formik state
                }}
                onFocus={handleInputFocus}
                placeholder="Enter City Name"
                value={query || values?.name}
              />
            </InputGroup>
            {/* <InputGroup className={errors.name ? "is-invalid" : ""}>
             <Form.Control
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values?.name}
                className={errors.name ? "is-invalid" : ""}
                placeholder="Enter City Name" 
              />
            </InputGroup> */}
            {errors.name && touched.name && (
              <div className="Vehicles__error">{errors.name}</div>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Country</Form.Label>
            <InputGroup className={errors.country ? "is-invalid" : ""}>
              <Form.Control
                id="country"
                name="country"
                type="text"
                className={
                  errors.country && touched.country ? "is-invalid" : ""
                }
                ref={countryRef}
                onChange={(event) => {
                  setCountryQuery(event.target.value); // Update local state
                  setFieldValue("country", event.target.value); // Update Formik state
                }}
                onFocus={handleInputFocus}
                placeholder="Enter country Name"
                value={countryQuery || values?.country}
              />
            </InputGroup>
            {errors.country && touched.country && (
              <div className="Vehicles__error">{errors.country}</div>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Time Zone</Form.Label>
            <div style={{ position: "relative", zIndex: 10 }}>
              <Select
                closeMenuOnSelect
                options={timezoneOptions}
                placeholder="Select Time Zone"
                value={
                  timezoneOptions.find(
                    (option) => option.value === values?.time_zone
                  ) || values.time_zone
                }
                onChange={(value) => {
                  formik.setFieldValue("time_zone", value?.value);
                }}
                isDisabled={!values?.country} // Disable if no country is selected
              />
            </div>
            {errors.time_zone && (
              <div className="Vehicles__error">{errors.time_zone}</div>
            )}
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>Longitude</Form.Label>
            <InputGroup className={errors.longitude ? "is-invalid" : ""}>
              <Form.Control
                id="longitude"
                name="longitude"
                type="text"
                onChange={handleChange}
                value={values?.longitude}
                className={
                  errors.longitude && touched.longitude ? "is-invalid" : ""
                }
                placeholder="Enter longitude"
              />
            </InputGroup>
            {errors.longitude && touched.longitude && (
              <div className="Vehicles__error">{errors.longitude}</div>
            )}
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>Latitude</Form.Label>
            <InputGroup className={errors.latitude ? "is-invalid" : ""}>
              <Form.Control
                id="latitude"
                name="latitude"
                type="text"
                onChange={handleChange}
                value={values?.latitude}
                className={
                  errors.latitude && touched.latitude ? "is-invalid" : ""
                }
                placeholder="Enter latitude"
              />
            </InputGroup>
            {errors.latitude && touched.latitude && (
              <div className="Vehicles__error">{errors.latitude}</div>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Locale</Form.Label>
            <div style={{ position: "relative", zIndex: 3 }}>
              <Select
                closeMenuOnSelect
                options={localeOptions}
                placeholder="Select Locale"
                value={
                  localeOptions.find(
                    (option) =>
                      formatLocale(option.value) ===
                      formatLocale(values?.locale)
                  ) || values.locale
                }
                onChange={(value) =>
                  formik.setFieldValue("locale", formatLocale(value?.value))
                }
                isDisabled={!values?.country}
              />
            </div>
            {errors.locale && touched.locale && (
              <div className="Vehicles__error">{errors.locale}</div>
            )}
          </Form.Group>
        </Col>
      </Row>
      <div className="" style={{ height: "28rem", overflow: "hidden" }}>
        <Row>
          <Col md={12} style={{ height: "100%" }}>
            <Map selectedLocation={mapLocation} style={{ height: "100%" }} />
          </Col>
        </Row>
      </div>

      <div className="Vehicles__buttons">
        <button
          type="button"
          className="Vehicles__buttons-cancel"
          style={{ marginRight: "20px" }}
          onClick={() => (window.location.href = "/cities")}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="Vehicles__buttons-save"
          disabled={!formik.dirty}
        >
          Save
        </button>
      </div>
    </form>
  );
}

export default CityForm;
