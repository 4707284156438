import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import CityForm from "../CityForm";
import CitySchema from "../../../../yup/city";
function EditCityForm({ city, updateCity, initialValues, validate }) {
  const navigate = useNavigate();
  const { cityId } = useParams();
  const getUpdatedValues = (values, initialValuesFormik, result) => {
    const initValues = Object.values(initialValuesFormik);
    for (const [key, value] of Object.entries(values)) {
      if (!initValues.includes(value)) {
        result[key] = value;
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: CitySchema,
    onSubmit: (values) => {
      console.log(values, "v");

      const result = {};
      getUpdatedValues(values, formik.initialValues, result);
      console.log(result, "r");
      updateCity({
        variables: {
          id: cityId,
          input: {
            name: values.name,
            country: values.country,
            coordinates: { lat: values.latitude, lng: values.longitude },
            locale: values.locale,
            time_zone: values.time_zone,
          },
        },
      }).catch((e) => {
        alert(e);
      });
    },
  });

  return <CityForm formik={formik} cities={city} />;
}

export default EditCityForm;
