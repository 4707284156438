import { gql } from '@apollo/client';

export const SUBSCRIBE_LOGS = gql`
subscription SUBSCRIBE_LOGS {
  logs: logs_changelogs(order_by: {
    created_at: desc
  }, limit: 500){
    id
    oldValue: old_value
    newValue: new_value
    table
    field
    type
    recordId: record_id
    createdAt: created_at
  }
}
`;

export default SUBSCRIBE_LOGS;
