import { gql } from "@apollo/client";

const FETCH_AVAILABILITY_SERIES = gql`
  query FETCH_AVAILABILITY_SERIES(
    $filters: boatnew_availabilities_series_bool_exp
  ) {
    availabilitiesSeries: boatnew_availabilities_series(where: $filters) {
      exception
      id
      startTime: start_time
      endTime: end_time
      parent_id
      availability {
        endDate: end_date
        product {
          name
          id
          citiesProducts: cities_products {
            cityId: city_id
          }
        }
      }
      bookings {
        id
        status
      }
    }
  }
`;

const FETCH_AVAILABILITY_VIEW = gql`
  query FETCH_AVAILABILITY_VIEW($id: String!) {
    availabilityView: boatnew_v_availability(
      where: { availabilities_series_id: { _eq: $id } }
    ) {
      totalRemaining: total_remaining
      totalOthers: total_others
      totalBooked: total_booked
      startTime: start_time
      sharedId: shared_id
      productId: product_id
      date
      vehicleId: vehicle_id
      exception
      availabilitiesSeriesId: availabilities_series_id
      vehicle {
        name
        bookings {
          id
          status
        }
      }
      availabilitySeries: availability_series {
        parentId: parent_id
        bookings {
          status
          id
        }
        availability {
          prices
        }
      }
      product {
        name
        duration
      }
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query FETCH_PRODUCTS {
    products: boatnew_products(
      order_by: { active: desc, updated_at: desc }
      where: { active: { _eq: true } }
    ) {
      id
      name
      productCode: code
      active
      type
      productUnits: products_units {
        unit {
          id
          label
        }
      }
      cities_products {
        city_id
      }
      availabilities {
        id
        startDate: start_date
        endDate: end_date
        startTime: start_time
        totalSeats: total_seats
      }
    }
  }
`;

const FETCH_ALL_AVAILABILITIES = gql`
  query FETCH_ALL_AVAILABILITIES {
    availabilities: boatnew_availabilities {
      id
      product {
        name
      }
    }
  }
`;

const UPDATE_VEHICLE_AVAILABILITY = gql`
  mutation UPDATE_VEHICLE_AVAILABILITY($vehicleId: String!, $totalSeats: Int!) {
    update_boatnew_availabilities_series_vehicles(
      where: { vehicle_id: { _eq: $vehicleId } }
      _set: { total_seats: $totalSeats }
    ) {
      totalSeats: total_seats
      vehicleId: vehicle_id
    }
  }
`;

const UPDATE_AVAILABILITIES_SERIES = gql`
  mutation UPDATE_AVAILABILITIES_SERIES(
    $id: String!
    $set: boatnew_availabilities_series_set_input
  ) {
    update_boatnew_availabilities_series(
      where: { id: { _eq: $id } }
      _set: $set
    ) {
      affected_rows
    }
  }
`;

const INSERT_AVALABILITY_SERIES_VEHICLE = gql`
  mutation INSERT_AVALABILITY_SERIES_VEHICLE(
    $vehicle: boatnew_availabilities_series_vehicles_insert_input!
  ) {
    insert_boatnew_availabilities_series_vehicles_one(object: $vehicle) {
      availabilitiesSeriedId: availabilities_series_id
    }
  }
`;
const DELETE_AVAILABILITY_SERIES_VEHICLES = gql`
  mutation DELETE_AVAILABILITY_SERIES_VEHICLES(
    $availabiltiesSeriesId: String!
    $vehicleId: String!
  ) {
    delete_boatnew_availabilities_series_vehicles(
      where: {
        _and: {
          vehicle_id: { _eq: $vehicleId }
          availabilities_series_id: { _eq: $availabiltiesSeriesId }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export {
  FETCH_AVAILABILITY_SERIES,
  FETCH_PRODUCTS,
  FETCH_ALL_AVAILABILITIES,
  FETCH_AVAILABILITY_VIEW,
  // DELETE_SERIES_VEHICLE,
  UPDATE_VEHICLE_AVAILABILITY,
  INSERT_AVALABILITY_SERIES_VEHICLE,
  DELETE_AVAILABILITY_SERIES_VEHICLES,
  UPDATE_AVAILABILITIES_SERIES,
};
