import React, { useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import GridComponent from '../../../components/GridComponent/GridComponent';
import { ACTIVE_GUIDE, DEACTIVE_GUIDE, MOVE_TO_TRASH } from '../../../api/Guides';
import Confirm from './confirm';
import Loader from '../../../components/Loader/Loader';
import LogsHeader from './LogsHeader';

const width = Math.ceil(window.outerWidth / 7);

const columnDefs = [
  {
    field: 'id',
    resizable: true,
    width,
  },
  {
    field: 'Type',
    valueGetter: (params) => params?.data?.type,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Table',
    valueGetter: (params) => params?.data?.table,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Field',
    valueGetter: (params) => params?.data?.field || 'NULL',
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Old Value',
    valueGetter: (params) => params?.data?.oldValue || 'NULL',
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'New Value',
    valueGetter: (params) => params?.data?.newValue || 'NULL',
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Ref Id',
    valueGetter: (params) => params?.data?.recordId,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Created At',
    valueGetter: (params) => moment(params?.data?.createdAt).format('ddd, MMM DD, YYYY'),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
];

function DisplayLogs() {
  const { data } = useOutletContext();

  return (
    data?.logs && width > 0 ? (
      <GridComponent
        gridTitle="changelogs"
        data={data?.logs}
        columnDefs={columnDefs}
      >
        <LogsHeader />
      </GridComponent>
    )
      : <Loader />
  );
}

export default DisplayLogs;
