/* global google */
import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
} from '@react-google-maps/api';
import '../styles/Map.scss';

const libraries = ['places', 'geometry'];
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const containerStyle = {
  width: '100%',
  height: '300px',
};

function Map({
  clickedBooking,
  formik,
}) {
  const [autocomplete, setAutocomplete] = useState(null);
  const [pickupCoords, setPickupCoords] = useState(null);
  const [address, setAddress] = useState(clickedBooking.pickupLocation || '');
  const [center, setCenter] = useState({});

  const { pickupBounds } = clickedBooking.product;
  const autocompleteBounds = pickupBounds ? pickupBounds[0] : null;

  useEffect(() => {
    if (pickupCoords) {
      setCenter(pickupCoords);
    } else {
      setCenter(clickedBooking?.product?.citiesProducts[0]?.city?.coordinates);
    }
  }, [clickedBooking, pickupCoords]);

  const checkValidity = (result, initialLoad = false) => {
    const coords = result?.geometry?.location;
    if (pickupBounds) {
      const polygon = new google.maps.Polygon({ paths: pickupBounds });
      const contains = window.google.maps.geometry.poly.containsLocation(
        coords,
        polygon,
      );

      if (!initialLoad) {
        formik.setFieldValue(
          'pickup_location',
          result?.name && !result?.formatted_address.includes(result?.name)
            ? `${result.name}, ${result?.formatted_address}`
            : result?.formatted_address,
        );
        setAddress(result.formatted_address);
      }
      formik.setFieldValue('valid_location', contains);
    } else {
      formik.setFieldValue('valid_location', true);
    }
  };

  const getCoords = (latLng) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { location: latLng },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          setPickupCoords(results[0].geometry?.location);
          checkValidity(results[0]);
        }
      },
    );
  };

  const handleLoad = () => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: formik.values.pickup_location },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          setPickupCoords(results[0].geometry.location);
          checkValidity(results[0], true);
        }
      },
    );
  };

  const handleChange = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place?.geometry?.location;
      
      if (location) {
        // Set the pickup coordinates as a JSON object { lat, lng }
        const coordsJson = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setPickupCoords(location);
        formik.setFieldValue('pickup_coordinates', coordsJson);  // Set the coordinates in the form
  
        // Update the pickup location validity
        checkValidity(place);
  
        // Set the address
        if (place.formatted_address.includes(place.name)) {
          setAddress(place.formatted_address);
        } else {
          setAddress(`${place.name}, ${place.formatted_address}`);
        }
      }
    }
  };
  

  return (
    <div className="BookingModal__map">
      <LoadScript
        googleMapsApiKey={googleMapsApiKey}
        libraries={libraries}
        onLoad={handleLoad}
      >
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={handleChange}
          bounds={autocompleteBounds && {
            north: Number(autocompleteBounds.lat) + 0.2,
            south: Number(autocompleteBounds.lat) - 0.2,
            east: Number(autocompleteBounds.lng) + 0.2,
            west: Number(autocompleteBounds.lng) - 0.2,
          }}
          className="address-input"
        >
          <label
            htmlFor="pickupLocation"
            className="BookingModal__info-edit-input"
          >
            <span>Pickup Location</span>
            <input
              id="pickupLocation"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              placeholder="Search Address"
              className="BookingModal__search_input"
            />
          </label>
        </Autocomplete>
        {formik.errors.valid_location ? <div className="BookingModal__info-edit-error">{formik.errors.valid_location}</div> : null}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center || { lat: 43.66230053055229, lng: -79.38171745836675 }}
          zoom={13}
          onClick={(e) => {
            const { latLng } = e;
            getCoords(latLng);
          }}
        >
          {pickupBounds
            && (
              <Polygon
                paths={pickupBounds}
                options={{
                  fillColor: 'red',
                  fillOpacity: 0.20,
                  strokeColor: 'red',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
                onClick={(e) => {
                  const { latLng } = e;
                  getCoords(latLng);
                }}
              />
            )}
          {pickupCoords
            && (
              <Marker
                position={{
                  lat: pickupCoords.lat(),
                  lng: pickupCoords.lng(),
                }}
              />
            )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default Map;
