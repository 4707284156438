import React from 'react';

import Subtask from './subtask';

function Tasks({
  filterItem,
  activeDiv,
  subTaskOpenHandler,
  isSubTaskOpen,
  textHandler,
  unFocused,
  setActiveInput,
  setShowListHandler,
  statusHandler,
  addSubtask,
  active,
  activeInput,
  showList,
}) {
  return (
    <>
      {filterItem.map((item, idx) => {
        let checkedItem = 0;
        active.forEach((a) => {
          if (a === item.name) {
            checkedItem = 1;
          }
        });
        return (
          <>
            <div
              className="row TaskDashBoard__tasks"
              onClick={() => activeDiv(item.name)}
            >
              <div className="col-md-7 d-flex first">
                <div className="d-flex">
                  {' '}
                  <div className="taskIcon">
                    {checkedItem ? (
                      <i className="fas fa-check-circle" />
                    ) : (
                      <i className="far fa-circle" />
                    )}
                  </div>
                  {/* <div
                    className={`statusStyle${
                      item.status === 'Done'
                        ? '__green'
                        : item.status === 'Pending'
                          ? '__orange'
                          : '__red'
                    }`}
                  /> */}
                  <div className="taskName">
                    {item.name}
                    {' '}
                    <span>{item.timeStamp}</span>
                  </div>
                </div>
                <div className="firstClassIconDiv">
                  {item.subtasks.length !== 0
                    && `${item.subtasks.length} Subtasks`}
                  <i
                    className="fal fa-chevron-down"
                    onClick={() => subTaskOpenHandler(item.name)}
                  />
                </div>
              </div>
              <div className="col-md-3 second">{item.city}</div>
              <div className="col-md-2 third">{item.type}</div>
            </div>
            {isSubTaskOpen === item.name && (
              <Subtask
                item={item}
                idx={idx}
                isSubTaskOpen={isSubTaskOpen}
                textHandler={textHandler}
                activeInput={activeInput}
                unFocused={unFocused}
                setActiveInput={setActiveInput}
                setShowListHandler={setShowListHandler}
                statusHandler={statusHandler}
                addSubtask={addSubtask}
                showList={showList}
              />
            )}
          </>
        );
      })}
    </>
  );
}

export default Tasks;
