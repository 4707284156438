/* eslint-disable react/prop-types */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Modal } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import CancelIcon from '@mui/icons-material/Cancel';
import _, { isEqual } from 'lodash';
import {
  LazyQueryExecFunction,
  OperationVariables,
  QueryResult,
  useMutation,
} from '@apollo/client';
import styles from './EditSession.module.scss';
import {
  EditingSession,
  AvailabilityView,
  Vehicle,
  ProductType,
  AvailabilityType,
  SeriesType,
} from '../../pages/Sessions/types';
import {
  DELETE_AVAILABILITY_SERIES_VEHICLES,
  FETCH_AVAILABILITY_SERIES,
  FETCH_AVAILABILITY_VIEW,
  INSERT_AVALABILITY_SERIES_VEHICLE,
  UPDATE_AVAILABILITIES_SERIES,
} from '../../api/calendar';

const style = {
  position: 'relative' as 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  minHeight: 360,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type EditSessionProps = {
  editingSession: EditingSession;
  vehicles: QueryResult<any, OperationVariables>;
  handleCloseEditingSession: () => void;
  setEditingSession: Dispatch<SetStateAction<EditingSession | null>>;
  initialSession: EditingSession | null;
  productsSelected: ProductType[] | null;
  monthSelected: number;
  fetchRun: any,
  getAvailabilitySeries: LazyQueryExecFunction<any, OperationVariables>;
};
// user-define type guard, used for checking if vehicle is
// from list of vehicles, or is an availabilityview vehicle
const isNewVehicle = (vehicle: AvailabilityView | Vehicle):
vehicle is Vehicle => (vehicle as Vehicle)?.name !== undefined;

export const getAvailability = (
  view: (AvailabilityView | Vehicle)[],
  series: SeriesType,
): number => {
  // go through total remainings or capacities,
  // if one is higher, set availability to it,
  let availability = 0;
  console.log('series is ', series);
  if (series?.exception?.total_remaining) {
    return parseInt(series?.exception?.total_remaining, 10);
  }
  view?.forEach((vehicle) => {
    if (isNewVehicle(vehicle)) {
      if (vehicle?.capacity > availability) {
        availability = vehicle?.capacity;
      }
    }
    // else statement wont work here due to "no-lonely-if" lint
    if (!isNewVehicle(vehicle)) {
      if (vehicle?.totalRemaining > availability) {
        availability = vehicle?.totalRemaining;
      }
    }
  });
  return availability;
};
const sortVehicles = (vehicleList: (AvailabilityView | Vehicle)[])
  : (AvailabilityView | Vehicle)[] => {
  const sortedVehicleList = vehicleList?.sort((a, b) => {
    let nameA;
    let nameB;
    if (isNewVehicle(a)) {
      nameA = a?.name;
    } else {
      nameA = a?.vehicle?.name;
    }
    if (isNewVehicle(b)) {
      nameB = b?.name;
    } else {
      nameB = b?.vehicle?.name;
    }
    if (nameA < nameB) {
      return -1; // nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });
  return sortedVehicleList;
};
export default function EditSession({
  vehicles,
  editingSession,
  handleCloseEditingSession,
  fetchRun,
  setEditingSession,
  initialSession,
  productsSelected,
  monthSelected,
  getAvailabilitySeries,
}: EditSessionProps) {
  const [
    availableVehicles,
    setAvailableVehicles,
  ] = useState<(Vehicle | AvailabilityView)[] | null>(null);
  console.log('monthSelected', monthSelected);
  const [isMakingChanges, setIsMakingChanges] = useState<boolean>(false);
  const [saveChanges, setSaveChanges] = useState<boolean>(false);
  const [deleteVehicle] = useMutation(DELETE_AVAILABILITY_SERIES_VEHICLES);
  const [insertVehicle] = useMutation(INSERT_AVALABILITY_SERIES_VEHICLE);
  const [updateTotalRemaining, { data }] = useMutation(UPDATE_AVAILABILITIES_SERIES);
  const [isMakingException, setIsMakingException] = useState<boolean>(false);
  // remove vehicles being used in session from list of city's vehicles when loaded
  const checkForChanges = (): void => {
    if (initialSession && editingSession) {
      const withSortedInitialView: EditingSession = {
        availability: initialSession?.availability,
        series: initialSession?.series,
        view: sortVehicles([...initialSession.view]),
      };
      const withSortedCurrentView: EditingSession = {
        availability: editingSession?.availability,
        series: editingSession?.series,
        view: sortVehicles([...editingSession.view]),
      };
      if (_.isEqual(withSortedInitialView, withSortedCurrentView)) {
        setIsMakingChanges(false);
      } else {
        setIsMakingChanges(true);
      }
    }
  };
  useEffect(() => {
    if (vehicles) {
      const selectedVehicles: string[] = [];
      editingSession?.view.forEach((selectedVehicle) => {
        if (!isNewVehicle(selectedVehicle)) {
          selectedVehicles.push(selectedVehicle?.vehicleId);
        }
      });
      setAvailableVehicles(
        vehicles.data?.vehicles
          .filter((vehicle: Vehicle) => !selectedVehicles.includes(vehicle?.id)),
      );
    }
  }, [vehicles]);

  // change totalRemaining based on all vehicles capacity/totalRemaining
  useEffect(() => {
    setEditingSession({
      ...editingSession,
      availability: getAvailability(editingSession?.view, editingSession?.series),
    });
  }, [editingSession?.view]);
  useEffect(() => {
    checkForChanges();
  }, [editingSession]);
  const handleAddVehicle = (vehicle: Vehicle | AvailabilityView) => {
    if (availableVehicles !== null && availableVehicles) {
      setAvailableVehicles(
        availableVehicles
          ?.filter((availableVehicle: Vehicle | AvailabilityView) => {
            if (isNewVehicle(vehicle)) {
              if (isNewVehicle(availableVehicle)) {
                return availableVehicle?.id !== vehicle?.id;
              }
              return availableVehicle?.vehicleId !== vehicle?.id;
            }
            if (isNewVehicle(availableVehicle)) {
              return availableVehicle?.id !== vehicle?.vehicleId;
            }
            return availableVehicle?.vehicleId !== vehicle?.vehicleId;
          }),
      );
    }
    setEditingSession({ ...editingSession, view: [...editingSession.view, vehicle] });
  };

  const handleRemoveVehicle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditingSession({
      ...editingSession,
      view:
      editingSession.view.filter((vehicle) => {
        if (isNewVehicle(vehicle)) {
          if (vehicle?.id === event?.currentTarget?.id) {
            if (availableVehicles !== null) {
              setAvailableVehicles([...availableVehicles, vehicle]);
            } else {
              setAvailableVehicles([vehicle]);
            }
            return false;
          }
          return true;
        }
        if (vehicle.vehicleId === event.currentTarget.id) {
          if (availableVehicles !== null) {
            setAvailableVehicles([...availableVehicles, vehicle]);
          } else {
            setAvailableVehicles([vehicle]);
          }
          return false;
        }
        return true;
      }),
    });
  };
  const handleAddTotalRemaining = () => {
    if (editingSession?.availability !== null && editingSession?.view?.length !== 0) {
      setIsMakingException(true);
      setEditingSession({ ...editingSession, availability: editingSession.availability + 1 });
    }
  };
  const handleSubtractTotalRemaining = () => {
    if (editingSession?.availability !== null && editingSession?.availability !== 0) {
      setIsMakingException(true);
      setEditingSession({ ...editingSession, availability: editingSession.availability - 1 });
    }
  };
  const handleSaveChanges = () => {
    setSaveChanges(true);
  };
  const editSession = () => {
    const availabilities: AvailabilityType[] = [];
    console.log('availabilies', availabilities);
    productsSelected
      ?.forEach((product) => product?.availabilities?.forEach((availability) => {
        availabilities.push(availability);
      }));
    const beginningOfMonth = dayjs().month(monthSelected).startOf('month');
    const endOfMonth = dayjs().month(monthSelected).endOf('month');
    console.log('begning of month', beginningOfMonth);
    console.log('end of month', endOfMonth);
    editingSession?.view?.forEach((vehicle) => {
      if (!initialSession?.view?.includes(vehicle)) {
        if (isNewVehicle(vehicle)) {
          insertVehicle({
            variables: {
              vehicle: {
                vehicle_id: vehicle?.id,
                availabilities_series_id: editingSession?.series?.id,
                product_id: editingSession?.series?.availability?.product?.id,
                total_seats: vehicle?.capacity,
              },
            },
            onCompleted: (insertedVehicle) => {
              console.log('vehicle inserted, ', insertedVehicle);
              setSaveChanges(false);
            },
            refetchQueries: [
              {
                fetchPolicy: 'network-only',
                query: FETCH_AVAILABILITY_SERIES,
                variables: {
                  filters: {
                    _or: availabilities.map((avail) => (
                      { parent_id: { _eq: avail.id } }
                    )),
                    start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
                  },
                },
              },
              {
                fetchPolicy: 'network-only',
                query: FETCH_AVAILABILITY_VIEW,
                variables: {
                  id: editingSession?.series?.id,
                },
              },
            ],
          });
        }
      }
    });
    initialSession?.view?.forEach((vehicle) => {
      if (!editingSession?.view?.includes(vehicle)) {
        console.log('deleting...');
        if (!isNewVehicle(vehicle)) {
          deleteVehicle({
            variables: {
              availabiltiesSeriesId: editingSession?.series?.id,
              vehicleId: vehicle?.vehicleId,
            },
            onCompleted: (deletedVehicle) => {
              console.log('vehicle deleted, ', deletedVehicle);
              setSaveChanges(false);
            },
            refetchQueries: [
              {
                fetchPolicy: 'network-only',
                query: FETCH_AVAILABILITY_SERIES,
                variables: {
                  filters: {
                    _or: availabilities.map((avail) => (
                      { parent_id: { _eq: avail.id } }
                    )),
                    start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
                  },
                },
              },
              {
                query: FETCH_AVAILABILITY_VIEW,
                variables: {
                  id: editingSession?.series?.id,
                },
              },
            ],
          })
            .then(() => {
              console.log('After availailities fetched');
              fetchRun();
              setSaveChanges(false);
              alert('Availability Deleted successfully!');
            })
            .catch((error) => {
              console.error('Error updating availability:', error);
            });
        }
      }
    });
    // properly checks if user wants to make an exception instead of availability
    // changing from vehicles being removed/added
    if (isMakingException && initialSession?.availability !== editingSession?.availability) {
      if (updateTotalRemaining) {
        console.log('Before refetching availabilities');
        updateTotalRemaining({
          variables: {
            id: editingSession?.series?.id,
            set: {
              exception: { total_remaining: editingSession?.availability },
            },
          },
          refetchQueries: [
            {
              fetchPolicy: 'network-only',
              query: FETCH_AVAILABILITY_SERIES,
              variables: {
                filters: {
                  _or: availabilities.map((avail) => (
                    { parent_id: { _eq: avail.id } }
                  )),
                  start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
                },
              },
            },
            {
              fetchPolicy: 'network-only',
              query: FETCH_AVAILABILITY_VIEW,
              variables: {
                id: editingSession?.series?.id,
              },
            },
          ],
        })
          .then(() => {
            console.log('After availailities fetched');
            fetchRun();
            handleCloseEditingSession();
            setSaveChanges(false);
            alert('Availability updated successfully!');
          })
          .catch((error) => {
            console.error('Error updating availability:', error);
          });
      }
    }
  };

  const handleCloseChanges = () => setSaveChanges(false);
  return (
    <div className={styles.editSession}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3 className={styles.sessionHeader}>
          {editingSession?.series?.availability?.product?.name}
        </h3>
        <CancelIcon onClick={handleCloseEditingSession} style={{ cursor: 'pointer', marginLeft: '40px' }} />
      </div>
      <div className={styles.sessionActivity}>
        <p>
          {`Active until ${dayjs(editingSession?.series?.availability?.endDate).format('LL').toString()}`}
        </p>
      </div>
      <h5> Vehicles Selected for this session </h5>
      <div className={styles.sessionVehicles}>
        <div className={styles.selectedVehicles}>
          {editingSession?.view?.map((view: AvailabilityView | Vehicle) => (
            isNewVehicle(view)
              ? (
                <button type="button" key={view?.id} id={view.id} onClick={handleRemoveVehicle} className={styles.selectedVehicle}>
                  <p className={styles.selectedVehicleName}>{view?.name}</p>
                  <CloseIcon className={styles.selectedVehicleIcon} />
                </button>
              )
              : (
                <button type="button" key={view?.vehicleId} id={view.vehicleId} onClick={handleRemoveVehicle} className={styles.selectedVehicle}>
                  <p className={styles.selectedVehicleName}>{view?.vehicle?.name}</p>
                  <CloseIcon className={styles.selectedVehicleIcon} />
                </button>
              )
          ))}
        </div>
        {
          vehicles
            ? (
              <div className={styles.availableVehiclesContainer}>
                <h6>Local vehicles</h6>
                <div className={styles.availableVehicles}>
                  {availableVehicles?.map((availableVehicle) => (
                    isNewVehicle(availableVehicle)
                      ? (
                        <button type="button" key={availableVehicle?.id} id={availableVehicle?.id} onClick={() => handleAddVehicle(availableVehicle)} className={styles.availableVehicle}>
                          <AddIcon className={styles.availableVehicleIcon} />
                          {availableVehicle?.name}
                        </button>
                      )
                      : (
                        <button type="button" key={availableVehicle?.vehicleId} id={availableVehicle?.vehicleId} onClick={() => handleAddVehicle(availableVehicle)} className={styles.availableVehicle}>
                          <AddIcon className={styles.availableVehicleIcon} />
                          {availableVehicle?.vehicle?.name}
                        </button>
                      )
                  ))}
                </div>
              </div>
            )
            : null
        }
      </div>
      <h5>Make an exception</h5>
      <div className={styles.totalRemaining}>
        <p className={styles.totalRemainingHeader}>Availability</p>
        <div className={styles.totalRemainingEditor}>
          <button type="button" onClick={handleSubtractTotalRemaining} className={styles.totalRemainingSubtract}>-</button>
          <p className={styles.totalRemainingNumber}>{editingSession?.availability}</p>
          <button type="button" onClick={handleAddTotalRemaining} className={styles.totalRemainingAdd}>+</button>
        </div>
      </div>
      <div className={styles.editSessionActions}>
        <Button onClick={handleCloseEditingSession} variant="outlined">Cancel</Button>
        <Button onClick={handleSaveChanges} disabled={!isMakingChanges} variant="contained" color="success">Save</Button>
      </div>
      <Modal
        open={saveChanges}
        onClose={handleCloseChanges}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            cursor: 'pointer',
            marginLeft: '50px',
          }}
          >
            <h4 className={styles.sessionChangesHeader}>
              Are you sure you wish to make these changes?
            </h4>
            <CloseIcon onClick={handleCloseChanges} />
          </div>
          <div className={styles.sessionChanges}>
            <div className={styles.sessionBefore}>
              <h5 className={styles.sessionHeaderBefore}>Before</h5>
              {
                initialSession && editingSession && isEqual(
                  sortVehicles([...initialSession.view]),
                  sortVehicles([...editingSession.view]),
                ) ? null : (
                  <div className={styles.initialVehicles}>
                    {initialSession?.view?.map((vehicle) => (
                      <div className={styles.initialVehicle}>
                        {isNewVehicle(vehicle) ? vehicle?.name : vehicle?.vehicle?.name}
                      </div>
                    ))}
                  </div>
                  )
              }
              {
                editingSession?.availability === initialSession?.availability ? null : (
                  <div className={styles.initialAvailability}>
                    <p className={styles.initialAvailabilityText}>Availability</p>
                    <p>{initialSession?.availability}</p>
                  </div>
                )
              }
            </div>
            <div className={styles.sessionEastIcon}>
              <EastIcon />
            </div>
            <div className={styles.sessionAfter}>
              <h5 className={styles.sessionHeaderAfter}>After</h5>
              {
                initialSession && editingSession && isEqual(
                  sortVehicles([...initialSession.view]),
                  sortVehicles([...editingSession.view]),
                ) ? null : (
                  <div className={styles.afterVehicles}>
                    {
                      editingSession?.view?.map((vehicle) => (
                        <div className={styles.afterVehicle}>
                          {isNewVehicle(vehicle) ? vehicle?.name : vehicle?.vehicle?.name}
                        </div>
                      ))
                    }
                  </div>
                  )
              }
              {
                editingSession?.availability === initialSession?.availability ? null : (
                  <div className={styles.afterAvailability}>
                    <p className={styles.afterAvailabilityText}>Availability</p>
                    <p>{editingSession?.availability}</p>
                  </div>
                )
              }
            </div>
          </div>
          <div className={styles.sessionSaveButtons}>
            <Button onClick={handleCloseChanges} variant="outlined">Cancel</Button>
            <Button onClick={editSession} variant="contained" color="success">
              Save
            </Button>

          </div>
        </Box>
      </Modal>
    </div>
  );
}
