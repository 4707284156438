/* eslint-disable guard-for-in */
const getNestedDefault = (defaultValues, path) => {
  const keys = path.split('.');
  let currentDefault = defaultValues;

  for (const key of keys) {
    currentDefault = currentDefault?.[key];
    if (currentDefault === undefined) {
      break;
    }
  }

  return currentDefault;
};

// Recursive function to refine a row of data.
const refineRow = (row, defaultValues, prefix = '') => {
  const newRow = Array.isArray(row) ? [] : {};

  if (row === null || row === undefined || Object.keys(row).length === 0) {
    const defaultObject = getNestedDefault(defaultValues, prefix);
    if (defaultObject !== undefined) {
      return defaultObject;
    }
  }

  for (const key in row) {
    const newKey = prefix ? `${prefix}.${key}` : key;
    const defaultValue = getNestedDefault(defaultValues, newKey);
    if (defaultValue !== undefined && (row[key] === null || row[key] === undefined || row[key] === '')) {
      newRow[key] = defaultValue;
    } else if (typeof row[key] === 'object' && row[key] !== null) {
      newRow[key] = refineRow(row[key], defaultValues, newKey);
    } else {
      newRow[key] = row[key];
    }
  }

  return newRow;
};

// Main function to refine the data.
const normalizeData = (data, defaultValues) => data.map((item) => refineRow(item, defaultValues));

export default normalizeData;
