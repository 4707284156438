import React, { useState } from 'react';
import {
  Modal,
  Button,
} from 'react-bootstrap';

function ConfirmationPopup({
  setConfirmation,
  confirmation,
  formik,
}) {
  const handleClose = () => {
    setConfirmation({ show: false, confirmed: false });
  };

  return (
    <Modal show={confirmation.show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Confirm Email Send
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="BookingModal__payment-confirmation">
          Please click confirm button if you would like to send the email
          and create the reservation
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={formik.handleSubmit}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationPopup;
