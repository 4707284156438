import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Select, MenuItem, InputAdornment } from "@mui/material"; // Import MenuItem and InputAdornment
import SearchIcon from "@mui/icons-material/Search";
import "../Products.scss";
import Autocomplete from "@mui/material/Autocomplete";
function ProductHeader({
  nameCodeSearch,
  setNameCodeSearch,
  typeSearch,
  setTypeSearch,
  availabilityFilter,
  setAvailabilityFilter,
  activeStatusFilter,
  setActiveStatusFilter,
  itineraryStatusFilter,
  setItineraryStatusFilter,
  cityFilter,
  setCityFilter,
  cities,
}) {
  const navigate = useNavigate();

  const sanitizeInput = (input) => input.replace(/\s+/g, " ").trim();

  const handleNameCodeSearch = (event) => {
    const sanitizedInput = event.target.value.replace(/\s+/g, " ");
    setNameCodeSearch(sanitizedInput);
  };

  const handleTypeSearch = (event) => {
    const sanitizedInput = sanitizeInput(event.target.value);
    setTypeSearch(sanitizedInput);
  };

  const handleAvailabilityChange = (event) => {
    setAvailabilityFilter(event.target.value);
  };

  const handleActiveStatusChange = (event) => {
    setActiveStatusFilter(event.target.value);
  };

  const handleItineraryStatusFilter = (event) => {
    setItineraryStatusFilter(event.target.value);
  };
  const handleCityFilter = (event, newValue) => {
    if (newValue) {
      setCityFilter(newValue.id); // Set the city ID as the filter
    } else {
      setCityFilter("all"); // Reset the filter to "All"
    }
  };

  useEffect(() => {
    setCityFilter("all");
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  // Filter cities based on the search term
  const filteredCities = [{ id: "all", name: "All" }, ...cities].filter(
    (city) => city.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="Products__header">
        <div>
          <button
            type="button"
            className="Products__header_create-btn"
            onClick={() => {
              navigate("/products/create");
            }}
          >
            Create New Product
          </button>
        </div>
        <div style={{ marginLeft: "50px" }}>
          <h6>Search by Name or Code</h6>
          <TextField
            className="text_field"
            value={nameCodeSearch}
            placeholder="Search by Name or Code"
            variant="outlined"
            onChange={handleNameCodeSearch}
          />
        </div>
        <div style={{ marginLeft: "50px" }}>
          <h6>Search by Type</h6>
          <Select
            style={{ width: "180px", height: "40px" }}
            value={typeSearch}
            onChange={handleTypeSearch}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="daytour">Day Tour</MenuItem>
            <MenuItem value="privatedaytour">Private Day Tour</MenuItem>
            <MenuItem value="multidaytour">Multi-Day Tour</MenuItem>
          </Select>
        </div>
        <div style={{ marginLeft: "50px" }}>
          <h6>Search by Availability</h6>
          <Select
            style={{ width: "150px", height: "40px" }}
            value={availabilityFilter}
            onChange={handleAvailabilityChange}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="yes">Available</MenuItem>
            <MenuItem value="no">Not Available</MenuItem>
          </Select>
        </div>
        <div style={{ marginLeft: "50px" }}>
          <h6>Search by Status</h6>
          <Select
            style={{ width: "130px", height: "40px" }}
            value={activeStatusFilter}
            onChange={handleActiveStatusChange}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="yes">Active</MenuItem>
            <MenuItem value="no">Inactive</MenuItem>
          </Select>
        </div>
        <div style={{ marginLeft: "50px" }}>
          <h6>Search by Itinerary</h6>
          <Select
            style={{ width: "130px", height: "40px" }}
            value={itineraryStatusFilter}
            onChange={handleItineraryStatusFilter}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="yes">Completed</MenuItem>
            <MenuItem value="no">Empty</MenuItem>
          </Select>
        </div>
        <div style={{ marginLeft: "50px", marginTop: "32px" }}>
          <h6>Search by City</h6>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px", // Reduce width of container
            }}
          >
            {/* <Select
              value={cityFilter}
              onChange={handleCityFilter}
              variant="outlined"
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200, // Adjust the height of the dropdown menu
                    "& .MuiMenuItem-root": {
                      height: 36, // Reduce height of each MenuItem
                    },
                  },
                },
              }}
              sx={{
                width: "100%", // Ensure full width of container
                height: "36px", // Reduce height
                "& .MuiOutlinedInput-root": {
                  height: "100%", // Match height
                  "& fieldset": {
                    borderTop: "none", // Remove top border
                  },
                },
              }}
            >
              {filteredCities.map((city) => (
                <MenuItem key={city.id} value={city.id}>
                  {city.name}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              size="small"
              disablePortal
              options={filteredCities ? filteredCities : []}
              sx={{ width: "200px", marginBottom: "30px" }}
              defaultValue={filteredCities[0]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              getOptionLabel={(option) => option.name}
              groupBy={(option) => option.type}
              onChange={handleCityFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductHeader;
