import * as Yup from 'yup';

const newsSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
    .transform((value, originalValue) => originalValue.trim())
    .min(10, 'Length must be greater than 10'),
  news: Yup.string().required('News is required')
    .transform((value, originalValue) => originalValue.trim())
    .min(10, 'Length must be greater than 10'),
});

export default newsSchema;
