import React from 'react';
import { useDroppable } from '@dnd-kit/core';

function Droppable({
  id, children, screenSize, vehicle,
}) {
  const { is, setNodeRef } = useDroppable({
    id,
  });

  if (vehicle && vehicle?.status === 'deactive') {
    return (
      <div
        className={`Scheduling__droppables-droppable
        ${id === 'root' ? 'root' : 'vehicles'}
        ${id !== 'root' && screenSize === 'md' ? 'flex-column' : ''}`}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      className={`Scheduling__droppables-droppable
        ${id === 'root' ? 'root' : 'vehicles'}
        ${id !== 'root' && screenSize === 'md' ? 'flex-column' : ''}`}
    >
      {children}
    </div>
  );
}

export default Droppable;
