import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import ToursDropdown from './ToursDropdown';
import InputComponent from '../EditBooking/InputComponent';
import fetchFromAvailabilityAPI from '../../../../utils/helpers';
import Map from '../Map';
import '../../styles/CreateBooking.scss';

dayjs.extend(isBetween);
dayjs.extend(timezone);

function CreateBookingForm({
  validate,
  toursList,
  toursError,
  toursLoading,
  productUnits,
  getProductUnits,
  addBooking,
  productBounds,
}) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [calendarDates, setCalendarDates] = useState([]);
  const [timeOptions, setTimeOptions] = useState(null);

  const getStatus = () => {
    const fullDate = `${formik.values.tour_date} ${formik.values.tour_time}`;
    dayjs.tz.setDefault(formik.values.time_zone);

    const tourDate = dayjs.tz(fullDate);
    const today = dayjs.tz(new Date());
    const result = tourDate.diff(today, 'hours');

    let status;
    if (result < 48) {
      status = 'URGENT';
    } else {
      status = 'PENDING';
    }
    return status;
  };

  const formik = useFormik({
    initialValues: {
      product_id: '',
      tour_date: null,
      tour_time: '',
      adults: '',
      children: '',
      infants: '',
      students: '',
      teenagers: '',
      group: '',
      contact_name: '',
      phone: '',
      email: '',
      // availability_series_id: '',
      // maxPax: null,
      // assigned_vehicle: '',
      units: [],
      // allow_overbooking: false,
      time_zone: '',
      valid_location: false,
      pickup_location: '',
    },
    validate,
    validateOnChange: false,
    onSubmit: (values) => {
      addBooking({
        variables: {
          input: {
            product_id: values.product_id,
            tour_date: values.tour_date,
            tour_time: values.tour_time,
            // availability_series_id: values.availability_series_id,
            // assigned_vehicle: values.assigned_vehicle,
            status: getStatus(),
            source: 'BOATAPP',
            pickup_location: values.pickup_location,
            customer: {
              data: {
                contact_name: values.contact_name.trim(),
                phone: values.phone.trim(),
                email: values.email.trim(),
              },
            },
            booking_units: {
              data: formik.values.units,
            },
          },
        },
      })
        .catch((e) => {
          alert(e);
          console.log(e);
        });
    },
  });

  // const getAvailableDates = async () => {
  //   const res = await fetchFromAvailabilityAPI(`calendarCheck/${formik.values.product_id}`, {
  //     method: 'GET',
  //   });
  //   setCalendarDates(res);
  // };

  useEffect(() => {
    if (formik.values.product_id) {
      getProductUnits({ variables: { id: formik.values.product_id } });
      // getAvailableDates();
      // formik.setFieldValue('availability_series_id', '');
      // formik.setFieldValue('assigned_vehicle', '');
      // formik.setFieldValue('tour_date', null);
      // formik.setFieldValue('tour_time', 'default');
      // formik.setFieldValue('units', []);
      // setTimeOptions(null);
    }
  }, [formik.values.product_id]);

  const handleDateChange = async (value) => {
    formik.setFieldValue('tour_date', value?.format('YYYY-MM-DD'));
    // const body = {
    //   productId: formik.values.product_id,
    //   date: value?.format('YYYY-MM-DD'),
    // };

    // if (calendarDates.includes(value.format('YYYY-MM-DD'))) {
    //   const res = await fetchFromAvailabilityAPI('availabilityCheck', {
    //     body,
    //     method: 'POST',
    //   });
    //   if (!res.error) setTimeOptions(res);
    // } else {
    //   setTimeOptions(null);
    // }
  };

  const handlePaxChange = (e) => {
    const arr = formik.values.units;
    const quantity = Number(e.target.value);
    if (arr.some((unit) => unit.unit_id === e.target.id)) {
      const result = arr.map((unit) => (
        unit.unit_id === e.target.id ? { ...unit, quantity } : unit
      ));
      formik.setFieldValue('units', result);
    } else {
      arr.push({ unit_id: e.target.id, quantity });
      formik.setFieldValue('units', arr);
    }
    formik.setFieldValue(e.target.getAttribute('name'), Number(e.target.value));
  };

  // const disableDates = (date) => {
  //   const formattedDate = date.format('YYYY-MM-DD');
  //   if (calendarDates.includes(formattedDate)) return false;
  //   return true;
  // };

  const handleTourTimeChange = (e) => {
    const availability = timeOptions.filter((a) => a.start_time === e.target.value);
    formik.setFieldValue('availability_series_id', availability[0].availability_series_id);
    formik.setFieldValue('assigned_vehicle', availability[0].assigned_vehicle);
    formik.setFieldValue('maxPax', availability[0].maxPax);
    formik.setFieldValue('tour_time', e.target.value);
  };

  return (
    <div className="BookingModal__createBooking-container">
      <div className="BookingModal__info-edit-container">
        <h2>New Booking</h2>
        <form
          className="BookingModal__info-edit-form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <ToursDropdown
            toursList={toursList}
            toursLoading={toursLoading}
            toursError={toursError}
            name="Tour"
            formik={formik}
          />
          {formik.errors.product_id ? <div className="BookingModal__info-edit-error">{formik.errors.product_id}</div> : null}
          {/* <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="Tour Date"
              inputFormat="YYYY-MM-DD"
              disablePast
              // shouldDisableDate={disableDates}
              value={formik.values.tour_date}
              // disabled={!formik.values.product_id}
              mask="____-__-__"
              onChange={(value) => handleDateChange(value)}
              renderInput={(params) => (
                <TextField
                  sx={{
                    marginTop: 3,
                    marginBottom: 1,
                    '& .MuiInputLabel-root': {
                      fontSize: '0.9rem',
                      lineHeight: '0.95rem',
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: '0.85rem',
                      height: '2.8rem',
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider> */}
          <Form.Control
            className="form-input"
            id="tour_date"
            name="tour_date"
            type="date"
            placeholder="yyyy-mm-dd"
            onChange={(e) => formik.setFieldValue('tour_date', e.target.value)}
            value={formik.values.tour_date}
          />
          {formik.errors.tour_date ? <div className="BookingModal__info-edit-error">{formik.errors.tour_date}</div> : null}
          {/* <label
            htmlFor="tourTime"
            className="BookingModal__info-edit-input"
          >
            <span>Tour Time</span>
            <select
              id="tourTime"
              name="tourTime"
              className="BookingModal__createBooking-dropdown"
              value={formik.values.tour_time}
              onChange={(e) => handleTourTimeChange(e)}
            >
              <option disabled value="default">Choose tour time...</option>
              {timeOptions && timeOptions?.map((option) => (
                <option
                  value={option.start_time}
                  key={option.start_time}
                >
                  {option.start_time.slice(0, 5)}
                </option>
              ))}
            </select>
          </label> */}
          <InputComponent
            name="Tour Time"
            value={formik.values.tour_time}
            handleChange={formik.handleChange}
          />
          {formik.errors.tour_time ? <div className="BookingModal__info-edit-error">{formik.errors.tour_time}</div> : null}
          {/* {formik.values.maxPax !== null
            && (
              <div className="BookingModal__createBooking-capacity">
                {`Seats available for one booking: ${formik.values.maxPax}`}
                {formik.errors.maxPax ? <div className="BookingModal__info-edit-error">
                {formik.errors.maxPax}</div> : null}
              </div>
            )} */}
          {productUnits?.map((unit) => (
            <div key={unit.unit.id}>
              <InputComponent
                id={unit.unit.id}
                name={unit.unit.label}
                value={formik.values[unit.unit.label.toLowerCase()]}
                handleChange={(e) => handlePaxChange(e)}
                type="number"
                min={0}
              />
              {formik.errors[unit.unit.label.toLowerCase()] ? <div className="BookingModal__info-edit-error">{formik.errors[unit.unit.label.toLowerCase()]}</div> : null}
            </div>
          ))}
          <InputComponent
            name="Contact Name"
            value={formik.values.contact_name}
            handleChange={formik.handleChange}
            type="text"
          />
          {formik.errors.contact_name ? <div className="BookingModal__info-edit-error">{formik.errors.contact_name}</div> : null}
          <InputComponent
            name="Phone"
            value={formik.values.phone}
            handleChange={formik.handleChange}
            type="text"
          />
          {formik.errors.phone ? <div className="BookingModal__info-edit-error">{formik.errors.phone}</div> : null}
          <InputComponent
            name="Email"
            value={formik.values.email}
            handleChange={formik.handleChange}
            type="text"
          />
          {formik.errors.email ? <div className="BookingModal__info-edit-error">{formik.errors.email}</div> : null}
          {/* <label
            htmlFor="allow_overbooking"
            className="BookingModal__createBooking-overbooking"
          >
            <input
              type="checkbox"
              id="allow_overbooking"
              name="allow_overbooking"
              value={formik.values.allow_overbooking}
              onChange={formik.handleChange}
            />
            <span>Allow overbooking</span>
          </label> */}
          {productBounds && (
            <Map
              clickedBooking={productBounds}
              formik={formik}
            />
          )}
          <div className="BookingModal__info-edit-buttons">
            <button type="submit">Save</button>
            <button
              type="button"
              onClick={() => {
                navigate(`/bookings${search}`);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateBookingForm;
