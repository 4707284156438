import React from 'react';
import './Modal.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

function Modal({
  action, open, handleClose, handleConfirm,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="text-center">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to
          {' '}
          {action}
          {' '}
          this news?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="button" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button className="button" onClick={handleConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
