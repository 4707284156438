import * as Yup from 'yup';

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[a-zA-Z ]+$/g,
      'Name can only contain alphabets.',
    )
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Must be a valid email')
    .required('Required'),
  phone: Yup.string()
    .matches(
      /^[0-9 +]+$/g,
      'Only and numbers, space and special characters (+) are allowed',
    )
    .max(15, 'Too Long!'),
  role: Yup.string()
    .required('Required'),
  permissions: Yup.string()
    .required('Required'),
});

export default UserSchema;
