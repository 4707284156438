import { gql } from '@apollo/client';

export const SUBSCRIBE_NEWS = gql`
subscription SUBSCRIBE_NEWS {
    news: boatnew_news(where: {
        deleted: { _eq: false }
    }, order_by: {created_at: desc}) {
      id
      title
      description
      status
      created_at
      updated_at
      deleted
    }
  }
`;

export const SUBSCRIBE_SLIDER_NEWS = gql`
subscription SUBSCRIBE_SLIDER_NEWS {
  news: boatnew_news(where: {
      deleted: { _eq: false },
      status: { _eq: "ACTIVE" }
  }) {
    id
    title
    description
    status
    created_at
    updated_at
    deleted
  }
}
`;

export const CREATE_NEWS_MUTATION = gql`
mutation CREATE_NEWS_MUTATION($title: String!, $news: String!) {
  insert_boatnew_news(objects: { title: $title, description: $news }) {
    affected_rows
  }
}
`;

export const EDIT_NEWS_MUTATION = gql`
mutation CREATE_NEWS_MUTATION($newsId: Int!, $title: String!, $news: String!) {
    update_boatnew_news_by_pk(pk_columns:{id: $newsId}, _set:{
        title: $title,
        description: $news,
    }) {
      id
    }
  }
`;

export const DELETE_NEWS = gql`
mutation ACTIVATE_NEWS($newsId: Int!) {
  update_boatnew_news_by_pk(pk_columns:{id: $newsId}, _set:{deleted: true}) {
    id
  }
}
`;

export const ACTIVATE_NEWS = gql`
mutation ACTIVATE_NEWS($newsId: Int!) {
  update_boatnew_news_by_pk(pk_columns:{id: $newsId}, _set:{status: "ACTIVE"}) {
    id
  }
}
`;

export const ARCHIVE_NEWS = gql`
mutation ACTIVATE_NEWS($newsId: Int!) {
  update_boatnew_news_by_pk(pk_columns:{id: $newsId}, _set:{status: "ARCHIVED"}) {
    id
  }
}
`;
