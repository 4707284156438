import React from 'react';

function Availability({ availabilities }) {
  return (
    <div className="product-availability">
      {availabilities.map((availability) => (
        <div key={availability.id}>{`${availability.startDate}/${availability.endDate} ${availability.startTime.slice(0, 5)}`}</div>
      ))}
    </div>
  );
}

export default Availability;
