import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Form from 'react-bootstrap/Form';
import { FETCH_RESERVATION_LOGS } from '../../../../api/reservations';
import ReservationLogs from './ReservationLogs';
import './ReservationLogs.scss';

function DisplayLogs({ logsDate, setLogsDate }) {
  const [showLogs, setShowLogs] = useState(false);
  const [getLogs, { data: logs, error: errorLogs }] = useLazyQuery(FETCH_RESERVATION_LOGS);

  useEffect(() => {
    if (logs) setShowLogs(true);
  }, [logs]);

  const handleLogsSubmit = (e) => {
    e.preventDefault();
    getLogs({ variables: { date: logsDate } });
    setShowLogs(true);
  };

  return (
    <div className="Reservations__logs">
      <h2>Logs</h2>
      <form className="Reservations__logs-form" onSubmit={(e) => handleLogsSubmit(e)}>
        <Form.Control
          className="form-input"
          id="logs_date"
          name="logs_date"
          type="date"
          placeholder="yyyy-mm-dd"
          onChange={(e) => setLogsDate(e.target.value)}
          value={logsDate}
        />
        <div className="Reservations__logs-form-btns">
          <button
            disabled={logsDate === ''}
            className="Reservations__logs-form-btns-search"
            type="submit"
          >
            Search
          </button>
          <button
            className="Reservations__logs-form-btns-hide"
            type="button"
            disabled={!logs || !showLogs}
            onClick={() => setShowLogs(false)}
          >
            Hide
          </button>
        </div>
      </form>
      {showLogs && <ReservationLogs data={logs} error={errorLogs} />}
    </div>
  );
}

export default DisplayLogs;
