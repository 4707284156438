import { gql } from "@apollo/client";

const FETCH_CITIES = gql`
  subscription FetchCities {
    boatnew_cities {
      id
      name
      coordinates
      locale
      trash
      country
      time_zone
      timeSlots: cities_timeslots {
        group
        startTime: start_time
        endTime: end_time
      }
    }
  }
`;

const FETCH_CITIES_WITH_RESERVATIONS = gql`
  query FETCH_CITIES_WITH_RESERVATION {
    cities: boatnew_cities(
      where: { reservations: {} }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;
const ADD_CITIES = gql`
  mutation ADD_CITIES($input: boatnew_cities_insert_input!) {
    insert_boatnew_cities_one(object: $input) {
      id
    }
  }
`;

const MOVE_TO_TRASH = gql`
  mutation CITIES_TO_TRASH($cityId: String!) {
    cityToTrash: update_boatnew_cities_by_pk(
      pk_columns: { id: $cityId }
      _set: { trash: true }
    ) {
      id
    }
  }
`;

const FETCH_CITIES_BY_ID = gql`
  query GET_CITY($id: String!) {
    boatnew_cities_by_pk(id: $id) {
      id
      name
      country
      coordinates
      locale
      time_zone
    }
  }
`;

const UPDATE_CITY = gql`
  mutation UPDATE_CITY($id: String!, $input: boatnew_cities_set_input!) {
    update_boatnew_cities_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      name
      country
      coordinates
      locale
      time_zone
    }
  }
`;

export {
  FETCH_CITIES,
  FETCH_CITIES_WITH_RESERVATIONS,
  ADD_CITIES,
  MOVE_TO_TRASH,
  UPDATE_CITY,
  FETCH_CITIES_BY_ID,
};
