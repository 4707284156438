import React, { useState, useEffect } from 'react';
import {
  DndContext, PointerSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { Form } from 'react-bootstrap';
import Draggable from './Draggable/Draggable';
import Droppable from './Droppable/Droppable';
import ConfirmationPopup from './Confirmation/ConfirmationPopup';
import './Droppable/Droppable.scss';
import EditBooking from './EditBookings';
import VehiclePane from './VehiclePane/VehiclePane';

class CollapseButton extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: (e) => {
        if (e.target.id === 'popupOpen') return false;
        return true;
      },
    },
  ];
}

// Set active button to 0 on large screens
const lgScreen = window.matchMedia('(min-width: 992px)');
// Set active button to 1 on medium screens
const mdScreen = window.matchMedia('(max-width: 991px)');

let prevActiveTab = lgScreen.matches ? 0 : 1;

function Container({
  timeGroup,
  vehicles,
  updateBookingsVehicles,
  noTimeGroup,
  locations, selectedLocation, setSelectedLocation, guides, products,
  selectedProduct, setSelectedProduct,
}) {
  const mouseSensor = useSensor(CollapseButton);
  const sensors = useSensors(mouseSensor);

  // Set active button to 0 on large screens
  const lgScreen = window.matchMedia('(min-width: 992px)');
  // Set active button to 1 on medium screens
  const mdScreen = window.matchMedia('(max-width: 991px)');

  const [items, setItems] = useState({ root: [] });
  const [confirmation, setConfirmation] = useState(false);
  const [input, setInput] = useState({ deleteInput: [], insertInput: [] });
  const [vehiclesGuides, setVehiclesGuides] = useState(vehicles?.vehicles);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [activeTab, setActiveTab] = useState(prevActiveTab);
  // const [prevActiveTab, setPrevActiveTab] = useState(lgScreen.matches ? 0 : 1);
  const [screenSize, setScreenSize] = useState(lgScreen.matches ? 'lg' : 'md'); // Initialize screen size state to 'lg'
  const [visibleSections, setVisibleSections] = useState({});

  useEffect(() => console.log(items), [items]);

  useEffect(() => {
    setActiveTab(prevActiveTab);
  }, [prevActiveTab, activeTab]);

  function toggleSection(index) {
    setVisibleSections({
      ...visibleSections,
      [index]: !visibleSections[index],
    });
  }

  const removeItemById = (id) => {
    const newItems = [];
    Object.keys(items).forEach((key) => {
      // Remove the object with the specified ID from the array
      newItems[key] = items[key].filter((item) => item.id !== id);
    });
    return newItems;
  };

  function moveBooking(props) {
    const { oldId, newId, bookingId } = props;
    /* Get Booking */
    const booking = timeGroup.find(({ id }) => id === bookingId) || null;
    if (booking === null) return;

    const newItems = removeItemById(bookingId);

    /* Add in new vehicle */
    if (newId === 'unassigned') {
      // Unassigned vehcile to this booking
      newItems.root.push(booking);
    } else if (newItems[newId]) {
      // Assigned booking to vehicle
      newItems[newId].push(booking);
    }

    setItems(newItems);
  }

  const handleTabChange = (index) => {
    prevActiveTab = index;
    setActiveTab(prevActiveTab);
  };

  const clearModal = () => {
    setInitialValues(null);
  };

  const handleModalOpen = ({
    pickupTime, id, assignedGuide, pickupLocation,
  }) => {
    setInitialValues({
      pickupTime,
      bookingId: id,
      guideId: assignedGuide,
      pickupLocation,
    });
    setEditModalOpen(true);
  };

  const handleModalClose = async () => {
    clearModal();
    setEditModalOpen(false);
  };

  useEffect(() => {
    const vehiclesGuidesArray = [];
    const vehiclesItems = Object.keys(items).filter((key) => key !== 'root');
    vehiclesItems.forEach((vehicleId) => {
      const vehicleBookings = items[vehicleId];
      if (vehicleBookings.length > 0) {
        const { bookingVehicles } = vehicleBookings[0];
        vehiclesGuidesArray.push(bookingVehicles);
      }
    });
    setVehiclesGuides(vehiclesGuidesArray);
  }, []);

  const getPax = (units) => {
    let pax = 0;
    units.map((p) => {
      pax += p.quantity;
    });
    return pax;
  };
  const getGuide = (vehicle_id) => {
    try {
      if (typeof vehiclesGuides === 'object' && vehiclesGuides.length > 0) {
        const suspect = vehiclesGuides
          .filter((e) => e !== null)
          .filter(({ vehicleId = '' }) => vehicleId === vehicle_id);
        if (suspect.length > 0) {
          return suspect[0].guideId || '';
        }
        return '';
      }
      return '';
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    const newItems = {
      ...items,
      root: timeGroup.filter((booking) => !booking?.bookingVehicles?.vehicleId),
    };

    vehicles?.vehicles?.map((vehicle) => {
      newItems[vehicle.id] = timeGroup.filter((booking) => (
        booking?.bookingVehicles?.vehicleId === vehicle.id
      ));
    });

    setItems(newItems);
  };

  useEffect(() => {
    handleReset();
  }, [vehicles, timeGroup]);

  useEffect(() => {
    const deleteInput = [];
    const insertInput = [];

    Object.entries(items).map(([vehicleId, bookings]) => {
      bookings.map((booking) => {
        if (!booking?.bookingVehicles?.vehicleId && vehicleId === 'root') return null;

        vehicleId === 'root'
          ? deleteInput.push({
            booking_id: { _eq: booking.id },
            vehicle_id: { _eq: booking?.bookingVehicles?.vehicleId },
            guide_id: { _eq: booking?.guide_id },
          })
          : insertInput.push({
            booking_id: booking.id,
            vehicle_id: vehicleId,
            quantity: getPax(booking.bookingUnits),
            guide_id: getGuide(vehicleId),
          });
      });
    });
    setInput({ deleteInput, insertInput });
  }, [items, vehiclesGuides]);

  // Listen for screen size changes and set active button accordingly

  const handleWindowResize = () => {
    if (mdScreen.matches && screenSize === 'md') return;
    if (lgScreen.matches) {
      handleTabChange(0);
      setScreenSize('lg');
    } else if (mdScreen.matches) {
      handleTabChange(1);
      setScreenSize('md');
    }
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(
    () => {
      handleWindowResize();
      // setActiveTab(prevActiveTab);
    },
    [screenSize],
  );

  // Determine the margin and padding to apply based on the screen size
  const section2Margin = screenSize === 'lg' ? '1rem' : '0';
  const section2Padding = screenSize === 'lg' ? '1rem' : '0.5rem';

  function handleDragEnd(event) {
    const { over, active } = event;

    if (!over || items[over.id].filter((i) => i.id === active.id).length > 0) return;

    // Check if the draggable element wasn't moved to a new droppable container
    if (vehicles?.vehicles?.find(({ id }) => id === over?.id)?.status === 'deactive') {
      console.log('Element was not moved to a new container');
      return;
    }

    let newArr;

    // remove from an old list
    Object.entries(items).map(([id, arr]) => {
      if (arr.filter((booking) => booking.id === active.id).length > 0) {
        newArr = { ...items, [id]: arr.filter((booking) => booking.id !== active.id) };
      }
    });

    // add to a new list
    const activeDraggable = timeGroup.find((b) => b.id === active.id);
    if (activeDraggable) {
      newArr = { ...newArr, [over.id]: [activeDraggable, ...newArr[over.id]] };
    }

    setItems(newArr);
  }

  const getVehicleCapacity = (vehicle) => {
    let pax = 0;
    if (items[vehicle.id]?.length > 0) {
      items[vehicle.id].map((booking) => (
        booking?.bookingUnits?.map((unit) => {
          pax += unit.quantity;
        })
      ));
    }
    if (!vehicle.capacity) return null;

    return (
      <p className={`${pax > vehicle?.capacity ? 'Scheduling__droppables-droppable-capacityOver' : ''}`}>
        {`${pax}/${vehicle?.capacity}`}
      </p>
    );
  };

  const handleScheduleUpdate = () => {
    setConfirmation(false);
    updateBookingsVehicles(
      {
        variables: {
          deleteObj: input.deleteInput.length > 0 ? input.deleteInput : [],
          insertObj: input.insertInput.length > 0 ? input.insertInput : [],
        },
      },
    );
  };

  return (
    items.root && (
      <div className="Scheduling__container mt-0">
        <div className="Scheduling__topBtns">
          <div className="d-block d-sm-none" key={0}>
            <button
              type="button"
              className="btn btn-secondary btn-sm btn-block mt-3 p-0"
              style={{ outline: 'none', boxShadow: 'none' }}
              onClick={() => toggleSection(0)}
            >
              {visibleSections[0] ? (
                <i className="fas fa-angle-up" />
              ) : (
                <i className="fas fa-angle-down" />
              )}
            </button>
          </div>

          {((visibleSections[0] === true && screenSize === 'md') || screenSize === 'lg') && (
          <>
            <Form.Select
              className="form-input"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              <option value="">All Locations</option>
              {locations.map((time) => (
                <option value={time}>{time.length > 100 ? `${time.substr(0, 100)} ... ` : time}</option>
              ))}
            </Form.Select>

            <Form.Select
              className="form-input"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <option value="">All Products</option>
              {products.map((prod) => (
                <option value={prod}>{prod}</option>
              ))}
            </Form.Select>

            <button
              type="button"
              disabled={!input.deleteInput.length > 0 && !input.insertInput.length > 0}
              className="Scheduling__container-btn-saveChanges"
              onClick={() => setConfirmation(true)}
            >
              Save Changes
            </button>

            <button
              type="button"
              className="Scheduling__container-btn-reset"
              onClick={handleReset}
            >
              Reset
            </button>
          </>
          )}
        </div>

        <div className="Scheduling__container-main">
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <div className="Scheduling__droppables">
              <section className={`d-flex flex-grow-1 ${activeTab === 2 ? 'd-none' : 'd-flex'}`}>
                <Droppable id="root">
                  <div className="Scheduling__container-wrapper" style={{ minHeight: items.root.length === 0 ? '30vh' : 0 }}>
                    {/* {noTimeGroup?.length > 0 && noTimeGroup
                      .map((booking) => (
                        <Draggable
                          key={booking.id}
                          booking={booking}
                          disabled
                          handleModalOpen={handleModalOpen}
                        />
                      ))}
                    {noTimeGroup?.length > 0 && <hr />} */}
                    {items.root
                      .filter(({ pickupLocation, product: { name } }) => ((selectedLocation === '' || selectedLocation === pickupLocation) && (selectedProduct === '' || selectedProduct === name)))
                      .map((booking) => (
                        <Draggable
                          key={booking.id}
                          booking={booking}
                          handleModalOpen={handleModalOpen}
                          screenSize={screenSize}
                        />
                      ))}
                  </div>
                </Droppable>
              </section>
              <section className={`d-flex flex-grow-1 ${activeTab === 1 ? 'd-none' : 'd-flex'}`} style={{ marginLeft: section2Margin, padding: section2Padding }}>
                {Object.keys(items).length > 1 && vehicles?.vehicles
                  .map((vehicle) => (
                    <Droppable
                      key={vehicle.id}
                      id={vehicle.id}
                      screenSize={screenSize}
                      vehicle={vehicle}
                    >
                      <VehiclePane {...{
                        vehicle,
                        getVehicleCapacity,
                        items,
                        guides,
                        vehiclesGuides,
                        setVehiclesGuides,
                        handleModalOpen,
                        screenSize,
                      }}
                      />
                    </Droppable>
                  ))}
              </section>
            </div>
          </DndContext>
        </div>

        {/* Bottom tab bar for tablet and smaller devices */}
        <nav className="navbar fixed-bottom navbar-light bg-light d-lg-none">
          <div className="container-fluid">
            <ul className="nav justify-content-around w-100">
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link btn ${activeTab === 1 ? 'active' : ''}`}
                  onClick={() => handleTabChange(1)}
                >
                  {/* <FaIcon1 /> */}
                  <i className="fas fa-calendar" />
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link btn ${activeTab === 2 ? 'active' : ''}`}
                  onClick={() => handleTabChange(2)}
                >
                  {/* <FaIcon2 /> */}
                  <i className="fas fa-car" />
                </button>
              </li>
            </ul>
          </div>
        </nav>

        {confirmation && (
          <ConfirmationPopup
            setConfirmation={setConfirmation}
            confirmation={confirmation}
            handleScheduleUpdate={handleScheduleUpdate}
          />
        )}

        {initialValues && (
        <EditBooking {...{
          screenSize,
          editModalOpen,
          handleModalClose,
          initialValues,
          vehicles: vehicles?.vehicles,
          moveBooking,
          timeGroup,
          items,
        }}
        />
        )}

      </div>
    )
  );
}

export default Container;
