const Items = [
  {
    name: 'John Wick',
    timeStamp: 'May 24,2021 10:00',
    city: 'Niagara Falls ON',
    type: 'Booking',
    status: 'Urgent',
    subtasks: [],
  },
  {
    name: 'Kim Jong Un',
    timeStamp: 'Jul 4,2020 16:00',
    city: 'Niagara Falls ON',
    type: 'Reservation',
    status: 'Done',
    subtasks: [],
  },
  {
    name: 'Tim Cook',
    timeStamp: 'Nov 24,2021 09:00',
    city: 'Cummington Square',
    type: 'Reservation',
    status: 'Pending',
    subtasks: [],
  },
  {
    name: 'Amit Bhardwaj',
    timeStamp: 'Apr 14,2022 20:00',
    city: 'Cummington Square',
    type: 'Booking',
    status: 'Need Attention',
    subtasks: [],
  },
  {
    name: 'Elon Musk',
    timeStamp: 'Dec 13,2019 14:00',
    city: 'Niagara Parkway',
    type: 'Booking',
    status: 'Done',
    subtasks: [],
  },
  {
    name: 'Barack Obama',
    timeStamp: 'Jan 23,2021 19:00',
    city: 'Niagara Parkway',
    type: 'Reservation',
    status: 'Pending',
    subtasks: [],
  },
  {
    name: 'Bill Clinton',
    timeStamp: 'Feb 01,2022 12:00',
    city: 'Holy Trinity',
    type: 'Booking',
    status: 'Pending',
    subtasks: [],
  },
  {
    name: 'Narender Modi',
    timeStamp: 'May 10,2022 23:00',
    city: 'Holy Trinity',
    type: 'Reservation',
    status: 'Need Attention',
    subtasks: [],
  },
];

export default Items;
