import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Calendar as CalendarComponent,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.scss";
import dayjs from "dayjs";
import MonthEvent from "../../pages/Sessions/Event";

const localizer = momentLocalizer(moment);

function Calendar({
  events,
  onSelectEvent,
  getAvailabilitySeries,
  monthSelected,
  productsSelected,
  setMonthSelected,
  fetchRun,
  setDate,
  setViewState,
}) {
  const getMonthsAvailabilities = async (date) => {
    setMonthSelected(dayjs(date).month());
    const availabilities = [];
    /* @ts-ignore */
    productsSelected?.forEach((product) =>
      product?.availabilities?.forEach((availability) => {
        availabilities.push(availability);
      })
    );
    const inputDate = new Date(date);
    const lastDayOfMonth = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth() + 1,
      0
    );

    // Add one day to the last day of the month
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);

    const beginningOfMonth = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      1
    ).toISOString();
    const endOfMonth = lastDayOfMonth.toISOString();

    if (productsSelected) {
      const a = await getAvailabilitySeries({
        variables: {
          filters: {
            _or: availabilities?.map((avail) => ({
              parent_id: { _eq: avail.id },
            })),
            start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
          },
        },
      });
    }
  };
  const handleViewChange = (view) => {
    setViewState(view);
  };

  const handleNavigate = (date) => {
    fetchRun(date);
    setDate(date);
  };
  console.log(events, "events");
  return (
    <CalendarComponent
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onDoubleClickEvent={(event, mouseClickEvent) =>
        onSelectEvent(event, mouseClickEvent)
      }
      showAllEvents
      components={{
        event: MonthEvent,
      }}
      onNavigate={handleNavigate}
      onView={handleViewChange}
    />
  );
}
export default React.memo(Calendar);
