import React, { useRef, useCallback, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { TextField } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./GridComponent.scss";
import "ag-grid-enterprise";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useMutation } from "@apollo/client";
import ViewOptions from "./ViewOptions/ViewOptions";
import bookingsFilterFormat from "../Filters/helpers";
import useDebounce from "../../utils/hooks";

const debounceMs = process.env.NODE_ENV === "development" ? 1000 : 2000;
const savedFilters = null;

const defaultColumnDef = {
  floatingFilter: true,
  sortable: true,
  resizable: true,
  enableRowGroup: true,
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear", "apply", "reset", "cancel"],
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear", "apply", "reset", "cancel"],
        },
      },
    ],
    debounceMs,
  },
  cellStyle: { fontSize: 13 },
};

function GridComponent({
  data,
  isLoading,
  columnDefs,
  gridTitle,
  onRowClickHandler,
  updating,
  children,
  viewOptions,
  getContextMenuItems,
  stateAndActions = {},
}) {
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue, debounceMs);

  const { actions = {} } = stateAndActions;
  const {
    refetchBookings = () => {},
    onCellEditingStarted = () => {},
    onCellEditingStopped = () => {},
    onCellValueChange = () => {},
    onCellFocused = () => {},
  } = actions;

  const convertAgGridFiltersToGqlFilters = useCallback((event) => {
    const filterModel = event.api.getFilterModel();
    const filter = bookingsFilterFormat(filterModel);
  }, []);

  const gridRef = useRef();

  function handleGridDidMount(params) {
    gridRef.current = params;
  }

  const applySettingsHandler = useCallback((state) => {
    if (gridRef?.current?.type === "gridReady") {
      gridRef.current.columnApi.resetColumnState();
      gridRef.current.columnApi.applyColumnState({ state, applyOrder: true });
    }
  }, []);

  const handleRowGroupOpened = () => {
    if (gridRef.current) {
      setTimeout(() => {
        gridRef?.current?.api?.refreshClientSideRowModel("aggregate");
      }, 300);
    }
  };

  const getRowFieldObjPath = (input) => {
    if (typeof input !== "object") return;
    const { table = "", field = "" } = input;
    let path = "";

    if (table === "bookings") {
      switch (field) {
        case "city":
          path = "product.citiesProducts.0.city.name";
          break;
        case "status":
          path = "status";
          break;
        case "REZDY":
          path = "REZDY.reseller";
          break;
        case "tour":
          path = "Product.tourName";
          break;
        case "source":
          path = "source";
          break;
        case "assignedGuide":
          path = "booking_vehicles.guide.name";
          break;
        case "assignedVehicle":
          path = "booking_vehicles.vehicle.name";
          break;
        case "group":
          path = "booking_vehicles.group";
          break;
        default:
          break;
      }
    }

    return path;
  };

  function getValue(obj, path = "") {
    const parts = path.split(".");
    let current = obj;
    for (const part of parts) {
      if (current[part] === undefined || current[part] === null) {
        return null; // Or any value you want to return when the path is not valid
      }
      current = current[part];
    }
    return current;
  }

  const applyFiltersHandler = (filters = savedFilters) => {
    if (filters && gridRef.current?.api) {
      // Deep copy the filters object
      const finalFilters = JSON.parse(JSON.stringify(filters));

      // Iterate over each filter in the filters object
      for (const colId in filters) {
        if (filters[colId].values) {
          // Get unique values of the corresponding column from the grid data
          const uniqueValues = getUniqueValuesFromGridData(
            data,
            getRowFieldObjPath({
              table: "bookings",
              field: colId,
            })
          );

          // Keep only the values that are present in uniqueValues
          finalFilters[colId].values = finalFilters[colId].values.filter(
            (value) => value !== null && uniqueValues.includes(String(value))
          );
        }
      }

      // Set the final filters on the grid
      gridRef.current.api.setFilterModel(finalFilters);
      // refetchBookings();
    }
  };

  const getUniqueValuesFromGridData = (gridData = [], colId = "") => {
    let colValues = gridData.map((row) => getValue(row, colId));
    colValues = new Set(colValues);
    return [...new Set(colValues)];
  };

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.setQuickFilter(debouncedValue);
    }
  }, [debouncedValue, gridRef]);

  useEffect(() => {
    if (!gridRef?.current?.api) {
      return undefined;
    }
    if (updating) {
      gridRef.current.api.showLoadingOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }
    return undefined;
  }, [updating]);

  const getRowStyle = (params) => {
    if (params === undefined) return;
    const status = params?.data?.status;
    if (["CANCELLED", "CANCELLED - OPS"].includes(status))
      return { background: "#f7a5a566" };
    if (["REFUND_GS", "REFUND_OPS", "REFUND_ATTRACTION"].includes(status))
      return { background: "#ffc8a0" };
    return null;
  };

  return (
    <div className="gridContainer">
      <div className="toolsContainer">
        <div className="toolsContainer-search">
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            id="filter-text-box"
            size="small"
            label="Quick Search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {viewOptions === false ? (
            ""
          ) : (
            <ViewOptions
              applySettingsHandler={applySettingsHandler}
              applyFiltersHandler={applyFiltersHandler}
              gridRef={gridRef}
              gridTitle={gridTitle}
              isLoading={isLoading}
            />
          )}
        </div>
        {children}
      </div>
      <div className="ag-theme-alpine gridComponent">
        <AgGridReact
          rowHeight={32}
          getRowId={(params) => params.data.id}
          rowModelType="clientSide"
          serverSideStoreType="partial"
          enableCellChangeFlash
          suppressRowGroupHidesColumns
          ref={gridRef}
          rowData={data}
          defaultColDef={defaultColumnDef}
          columnDefs={columnDefs}
          rowGroupPanelShow="always"
          alwaysMultiSort
          groupIncludeFooter
          animateRows
          getContextMenuItems={getContextMenuItems}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onCellKeyPress={onCellValueChange}
          onCellFocused={onCellFocused}
          groupDefaultExpanded={-1}
          getRowStyle={getRowStyle}
          overlayNoRowsTemplate={(() =>
            isLoading
              ? `Loading ${gridTitle} ...`
              : `<span style="font-size: 1.4rem;">No ${gridTitle} to display</span>`)()}
          gridOptions={{
            singleClickEdit: false,
            onGridReady: (params) => {
              handleGridDidMount(params);
            },
            onRowClicked: (event) => {
              if (onRowClickHandler) {
                onRowClickHandler(event);
              }
            },
            onModelUpdated: (event) => {
              event.api.getDisplayedRowCount() === 0
                ? event.api.showNoRowsOverlay()
                : event.api.hideOverlay();
            },
          }}
        />
      </div>
    </div>
  );
}

export default GridComponent;
