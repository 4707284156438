import React, { useEffect, useRef, useState } from "react";

function CustomTextField(props) {
  const { stateAndActions, field, value: oldValue } = props;
  const [value, setValue] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // if (value !== null) return;
    setValue({ column: field, value: oldValue });
  }, [oldValue]);

  const {
    data: { setNewValue },
  } = stateAndActions;

  const handleChange = (event) => {
    setValue({ column: field, value: event.target.value });
    setNewValue({ column: field, value: event.target.value });
  };
  useEffect(() => console.log(value), [value]);

  return (
    <input
      className="w-100"
      autoFocus
      ref={inputRef}
      type="text"
      value={value?.value}
      onChange={handleChange}
    />
  );
}

export default CustomTextField;
