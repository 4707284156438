import React from 'react';
import { statusList } from '../../../../utils/Variables';
import '../../styles/Subtask.scss';

const SubtaskEditForm = ({
  updateTask,
  clickedTask,
  setClickedTask,
  setShowOptions,
  setShowEditForm,
}) => (
  <form
    className="Subtask__task-edit-form"
    onSubmit={(e) => {
      e.preventDefault();
      updateTask({
        variables: {
          id: clickedTask.taskId,
          input: {
            task: clickedTask.taskVal.trim(),
            status: clickedTask.taskStatus,
          },
        },
      });
      setShowEditForm(false);
      setShowOptions(false);
    }}
  >
    <select
      value={clickedTask.taskStatus}
      onChange={(e) => {
        setClickedTask({ ...clickedTask, taskStatus: e.target.value });
      }}
    >
      {statusList.slice(0, 4).map((status) => (
        <option value={status} key={status}>
          {status === 'needsreview'
            ? 'Needs Review'
            : status.charAt(0).toUpperCase() + status.slice(1)}
        </option>
      ))}
    </select>
    <input
      value={clickedTask.taskVal}
      onChange={(e) => {
        setClickedTask({ ...clickedTask, taskVal: e.target.value });
      }}
    />
    <div className="Subtask__task-edit-form-btn">
      <button type="submit">
        <i className="fas fa-save" />
      </button>
      <button
        type="button"
        onClick={() => {
          setShowEditForm(false);
          setShowOptions(false);
        }}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  </form>
);

export default SubtaskEditForm;
