import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import './Products.scss';
import '../../styles/_style.scss';
import '../../components/Nav/Nav.scss';

function Products() {
  const location = useLocation();
  const navigate = useNavigate();

  const getHeader = () => {
    let header = '';
    if (location.pathname.includes('edit')) {
      header = (
        <h2 className="heading_styles">
          <i className="far fa-arrow-left" onClick={() => navigate('/products')} />
          <span>Edit Product</span>
        </h2>
      );
    } else if (location.pathname.includes('create')) {
      header = (
        <h2 className="heading_styles">
          <i className="far fa-arrow-left" onClick={() => navigate('/products')} />
          <span>Create Product</span>
        </h2>
      );
    } else {
      header = (
        <h2 className="heading_styles">
          Products
        </h2>
      );
    }
    return header;
  };

  return (
    <>
      <Header />
      <div className="Products__container">
        <div className="card">
          <div className="card-header">
            {getHeader()}
          </div>
          <main className="Products__main">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}

export default Products;
