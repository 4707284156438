import React from "react";
import { useFormik } from "formik";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import { FETCH_CITIES } from "../../../../api/city";
import { ADD_VEHICLE } from "../../../../api/Vehicles";
import { positiveNum0 } from "../../../../utils/Regex";
import "../../Guides.scss";
import GuideForm from "../GuideForm";
import {
  FETCH_GUIDES_COUNT_BY_EMAIL,
  INSERT_GUIDE,
  INSERT_GUIDE_CITY,
} from "../../../../api/Guides";
import GuideSchema from "../../../../yup/guide";

function CreateGuide() {
  const navigate = useNavigate();
  // const { refetch } = useOutletContext();
  console.log(FETCH_CITIES, "FETCH_CITIES");
  const { data } = useSubscription(FETCH_CITIES);
  const [insertGuide] = useMutation(INSERT_GUIDE);
  const [getGuideCountByEmail] = useLazyQuery(FETCH_GUIDES_COUNT_BY_EMAIL);

  const [insertGuideCity] = useMutation(INSERT_GUIDE_CITY, {
    onCompleted: () => {
      toast.success("Guide created.", {
        autoClose: 8000,
        position: "bottom-right",
      });
      // refetch();
      navigate("/guides");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      city_id: "",
      airtable_id: "",
      email_personal: "",
    },
    validationSchema: GuideSchema,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        // Check for duplicate guide by email
        const emailCountResult = await getGuideCountByEmail({
          variables: { email: values.email_personal.trim() },
        });
        const {
          data: {
            guides_aggregate: {
              aggregate: { count },
            },
          },
        } = emailCountResult;

        if (count > 0) {
          setFieldError(
            "email_personal",
            "A guide with the same email already exists."
          );
          setSubmitting(false);
          return;
        }

        // If guide doesn't exist, insert new guide
        const {
          data: {
            insertGuides: { id },
          },
        } = await insertGuide({
          variables: {
            input: {
              full_name: values.name.trim(),
              phone: values?.phone?.trim(),
              email_personal: values.email_personal.trim(),
              // eslint-disable-next-line max-len
              // airtable_id: (values.airtable_id.trim() || '') === '' ? null : values.airtable_id.trim(),
            },
          },
        });
        await insertGuideCity({
          variables: {
            input: {
              city_id: values.city_id.trim(),
              guide_id: id,
            },
          },
        });
      } catch (error) {
        if (
          error.message.includes(
            "duplicate key value violates unique constraint"
          )
        ) {
          setFieldError(
            "email_personal",
            "A guide with the same email already exists."
          );
        } else {
          alert(error);
          console.error(error);
        }
      }
      setSubmitting(false);
    },
  });

  return (
    <div className="Vehicles__editVehicle">
      <GuideForm formik={formik} cities={data?.boatnew_cities} guide={null} />
    </div>
  );
}

export default CreateGuide;
