import React, { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import UsersHeader from './UsersHeader';
import GridComponent from '../../../components/GridComponent/GridComponent';
import { ACTIVE_GUIDE, DEACTIVE_GUIDE, MOVE_TO_TRASH } from '../../../api/Guides';
import Confirm from './confirm';
import { getPermissionsString } from '../../../utils/users';

const width = Math.ceil(window.outerWidth / 7);

function EditHandler({ id, status = '' }) {
  const { refetch, data, loading } = useOutletContext();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [activeGuide] = useMutation(ACTIVE_GUIDE);
  const [deactiveGuide] = useMutation(DEACTIVE_GUIDE);
  const [moveGuideToTrash] = useMutation(MOVE_TO_TRASH);

  const handleStatusChange = async () => {
    if (status === 'active') {
      await activeGuide({
        variables: {
          guideId: id,
          status: 'deactive',
          timestamp: moment().format(),
        },
      });
    } else {
      await deactiveGuide({
        variables: {
          guideId: id,
          status: 'active',
        },
      });
    }
  };

  const moveToTrash = async () => {
    await moveGuideToTrash({
      variables: {
        guideId: id,
      },
    });
  };

  const handleTrigger = async () => {
    if (modal === 'status') { await handleStatusChange(); } else if (modal === 'trash') { await moveToTrash(); }
  };

  const handleAction = () => {
    switch (modal) {
      case 'status':
        return status;
      case 'trash':
        return 'trash';
      default:
        return '';
    }
  };

  return (
    id ? (
      <>
        <Link className="Vehicles__editBtn" to={`/users/edit/${id}`}>Edit</Link>
        {/* <Link className="Vehicles__editBtn ms-3" to="/guides"
         onClick={() => setModal('status')}>
          {status === 'active' ? 'Deactivate' : 'Activate'}
        </Link>
        <Link className="Vehicles__editBtn ms-3" to="/guides" onClick={() => setModal('trash')}>
          Trash
        </Link>
        <Confirm
          trigger={handleTrigger}
          visibility={modal !== ''}
          setVisibility={(v = false) => setModal(v === false ? '' : v)}
          action={handleAction()}
        /> */}
      </>
    ) : null
  );
}

function StatusRenderer({ status }) {
  return (
    <p className="text-capitalize">
      {(status)}
      <span className={`ms-1 badge badge-secondary p-1 pb-0 rounded-circle ${status === 'active' ? 'bg-success text-success' : 'bg-danger text-danger'}`}>o</span>
    </p>
  );
}

const columnDefs = [
  {
    cellRenderer: (params) => (<EditHandler id={params?.data?.id} status={params?.data?.status} />),
    filter: () => null,
    width: 100,
  },
  {
    field: 'id',
    resizable: true,
    width,
  },
  {
    field: 'Name',
    valueGetter: (params) => params?.data?.fullName,
    keyCreator: (params) => params.value,
    resizable: true,
    filter: 'agTextColumnFilter',
    width,
  },
  {
    field: 'Email',
    valueGetter: (params) => params?.data?.email,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Phone',
    valueGetter: (params) => params?.data?.phone,
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Permissions',
    valueGetter: (params) => getPermissionsString(params?.data?.permissions),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Role',
    valueGetter: (params) => params?.data?.role?.split('_').join(' '),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
  {
    field: 'Joining Date',
    valueGetter: (params) => moment(params?.data?.created_at).format('ddd, MMM DD, YYYY'),
    keyCreator: (params) => params.value,
    filter: 'agTextColumnFilter',
    resizable: true,
    width,
  },
];

function DisplayUsers() {
  const { data, loading } = useOutletContext();

  return (
    <GridComponent
      gridTitle="users"
      data={data?.users}
      columnDefs={columnDefs}
      isLoading={loading}
    >
      <UsersHeader />
    </GridComponent>
  );
}

export default DisplayUsers;
