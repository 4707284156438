import { gql } from '@apollo/client';
import { FRAGMENT_VEHICLES } from './fragments';

const FETCH_VEHICLES = gql`
  query FETCH_VEHICLES {
    vehicles: boatnew_vehicles {
      ...FragmentVehicles
    }
  }
  ${FRAGMENT_VEHICLES}
`;

const FETCH_VEHICLES_BY_ID = gql`
query FETCH_VEHICLES_BY_ID($id:String!) {
    vehicle: boatnew_vehicles_by_pk(id: $id){
      ...FragmentVehicles
  }
    cities: boatnew_cities(order_by: {name: asc}) {
        id
        name
        coordinates
      }
}
${FRAGMENT_VEHICLES}
`;

const SET_GUIDE_TO_VEHICLE = gql`
query FETCH_VEHICLES_BY_ID($id:String!) {
    vehicle: boatnew_vehicles_by_pk(id: $id){
      ...FragmentVehicles
  }
  cities: boatnew_cities(order_by: {name: asc}) {
    id
    name
    coordinates
  }
}
`;

const SUBSCRIBE_VEHICLES = gql`
subscription SUBSCRIBE_VEHICLES {
  vehicles: boatnew_vehicles (
    order_by: { created_at: desc },
    where: { trash: { _eq: false } }
  ) {
    id
    name
    capacity
    city {
      name
      id
    }
    model: make_model
    plateNumber: plate_number
    vinNumber: vin_number
    year
    colour
    status
    last_deactivated
    insurance
    ownership
    notes: maintenence
    cityId: city_id
    created_at
  }
}
`;

const SUBSCRIBE_AVAILABLE_VEHICLES = gql`
subscription SUBSCRIBE_VEHICLES {
  vehicles: boatnew_vehicles (
    order_by: { created_at: desc },
    where: { trash: { _eq: false }, status: { _eq: "active" } }
  ) {
    id
    name
    capacity
    city {
      name
      id
    }
    model: make_model
    plateNumber: plate_number
    vinNumber: vin_number
    year
    colour
    status
    last_deactivated
    insurance
    ownership
    notes: maintenence
    cityId: city_id
    created_at
  }
}
`;

const FETCH_VEHICLES_BY_CITY = gql`
  query FETCH_VEHICLES_BY_CITY($citiesId: [String!]) {
    vehicles: boatnew_vehicles(
      where: {
        city_id: { _in: $citiesId }
        trash: { _eq: false }
      }
      order_by: [
        { name: desc }
      ]
    ) {
      id
      name
      office
      capacity
      status
    }
  }
`;

const ACTIVE_VEHICLE = gql`
mutation INSERT_VEHICLE($vehicleId: String!, $status: String!, $timestamp: timestamptz!) {
  updateVehicleStatus: update_boatnew_vehicles_by_pk(pk_columns: {id: $vehicleId}, _set: {
    status: $status,
    last_deactivated: $timestamp
  }) {
    id
  }
}
`;

const DEACTIVE_VEHICLE = gql`
mutation INSERT_VEHICLE($vehicleId: String!, $status: String!) {
  updateVehicleStatus: update_boatnew_vehicles_by_pk(pk_columns: {id: $vehicleId}, _set: {
    status: $status
  }) {
    id
  }
}
`;

const MOVE_TO_TRASH = gql`
mutation VEHICLE_TO_TRASH($vehicleId: String!) {
  vehicleToTrash: update_boatnew_vehicles_by_pk(pk_columns: {id: $vehicleId}, _set: {
    trash: true
  }) {
    id
  }
}
`;

const UPDATE_VEHICLE = gql`
 mutation UPDATE_VEHICLE($id: String!, $input: boatnew_vehicles_set_input) {
  update_boatnew_vehicles_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
  }
}
`;

const ADD_VEHICLE = gql`
mutation ADD_VEHICLE(
  $input: boatnew_vehicles_insert_input!) {
  insert_boatnew_vehicles_one(object: $input) {
    id
  }
}
`;

export {
  FETCH_VEHICLES,
  FETCH_VEHICLES_BY_CITY,
  FETCH_VEHICLES_BY_ID,
  UPDATE_VEHICLE,
  ADD_VEHICLE,
  ACTIVE_VEHICLE,
  DEACTIVE_VEHICLE,
  MOVE_TO_TRASH,
  SUBSCRIBE_VEHICLES,
  SUBSCRIBE_AVAILABLE_VEHICLES,
};
