import React, { useState } from "react";
import "./NewsSlider.scss";
import { useSubscription } from "@apollo/client";
import { SUBSCRIBE_SLIDER_NEWS } from "../../api/news";

function NewsSlider() {
  const [isVisible, setIsVisible] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [animateButton, setAnimateButton] = useState(false);

  const { data, error, loading } = useSubscription(SUBSCRIBE_SLIDER_NEWS, {
    shouldResubscribe: true,
  });

  const handleHide = () => {
    setIsVisible(false);
    setIsButtonVisible(true);
    setAnimateButton(true);
  };

  const handleShowButton = () => {
    setIsVisible(true);
    setIsButtonVisible(false);
    setAnimateButton(true);
  };

  return (
    <div className="news-slider-container">
      {!loading && data?.news?.length > 0 && isVisible && (
        <div className="news-slider">
          <marquee
            behavior="scroll"
            direction="left"
            className="slider-content"
          >
            {data?.news?.map(({ description }) => description)?.join(" ⬤ ")}
          </marquee>
          <button onClick={handleHide} className="hide-button">
            <i className="fa fa-times" />
          </button>
        </div>
      )}
      {!loading && data?.news?.length > 0 && !isVisible && isButtonVisible && (
        <button
          className={`show-news-button ${animateButton ? "animate" : ""}`}
          onClick={handleShowButton}
          onAnimationEnd={() => setAnimateButton(false)}
        >
          News <i className="fa fa-arrow-up" />
        </button>
      )}
    </div>
  );
}

export default NewsSlider;
