import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Dayjs from 'dayjs';
import { DateRangePicker } from 'react-date-range';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { addDays } from 'date-fns';
import { FaFilter } from 'react-icons/fa';
import moment from 'moment';
import styles from './BookingHeader.module.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

function BookingHeader({ setInitialFilterState, dateRange, setDateRange }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [rangeSelection, setRangeSelection] = useState(null);

  const setToAppliedRange = () => setRangeSelection(dateRange);

  const handleDefault = () => setRangeSelection([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 13),
      key: 'selection',
    },
  ]);

  const handleApply = () => {
    setAnchorEl(null);
    setDateRange(rangeSelection);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(setToAppliedRange, 1000);
  };

  useEffect(setToAppliedRange, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={styles.Bookings__header}>

      <button
        type="button"
        aria-describedby={id}
        onClick={handleClick}
        className={styles['Bookings__header-filterBtn']}
      >
        <FaFilter />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="d-flex flex-column"
      >
        {rangeSelection && (
        <>
          <DateRangePicker
            onChange={(item) => setRangeSelection([item.selection])}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={rangeSelection}
            direction="horizontal"
          />

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className={styles['Bookings__header-createBtn']}
              onClick={handleDefault}
            >
              Default
            </button>
            <button
              type="button"
              className={styles['Bookings__header-createBtn']}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </>
        )}
      </Popover>

      <button
        type="button"
        className={styles['Bookings__header-createBtn']}
        onClick={() => navigate(`/bookings/create${location.search}`)}
      >
        Create Booking
      </button>
    </div>
  );
}

export default BookingHeader;
