const headerOptions = [
  'City',
  'Types',
  'All Active Products',
  'Availability Group',
];

const typeArray = [
  'Day Tour',
  'Multi-day Tour',
  'Private Tour',
  'Airport Transfer',
  'Custom Experience',
];
const activeArray = ['Active', 'Inactive'];
export { headerOptions, typeArray, activeArray };

export const createProductFields = [
  { label: 'Name', name: 'name', type: 'text' },
  { label: 'Location', name: 'city', type: 'select' },
  { label: 'Code', name: 'productCode', type: 'text' },
];

export const formatCutoffTime = (time, units) => {
  if (units === 'hours') {
    return (
      time.toString().indexOf(':') !== -1
        ? `${time}:00`
        : `${time}:00:00`
    );
  }
  if (units === 'days') {
    return `${time * 24}:00:00`;
  }
};
