import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { TabPane } from 'react-bootstrap';
import BookingContext from '../../context/booking';
import Messaging from '../../pages/Bookings/components/Modal/components/Confirmation/Messaging';
import { FETCH_BOOKING_BY_ID, UPDATE_STATUS } from '../../api/bookings';

function BookingStatus() {
  const booking = useContext(BookingContext);

  const [updateStatus] = useMutation(UPDATE_STATUS);

  return (
    <TabPane
      eventKey="Status"
      title="Status"
    >
      <div className="BookingModal__container">
        { booking
          && (
            <Messaging
              clickedBooking={booking}
              updateStatus={updateStatus}
            />
          )}
      </div>
    </TabPane>
  );
}

export default BookingStatus;
