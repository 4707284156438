import React, { useContext, useState } from 'react';
import { TabPane } from 'react-bootstrap';

function ReviewGuide() {
  return (
    <TabPane
      eventKey="Review Guide"
      title="Review Guide"
    >
      <div className="BookingModal__container">
        Review Guide
      </div>
    </TabPane>
  );
}

export default ReviewGuide;
