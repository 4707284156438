import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function NoteConfirmation({
  showConfirmation,
  setShowConfirmation,
  handleDeleteNote,
}) {
  const handleClick = () => {
    handleDeleteNote();
    setShowConfirmation(false);
  };

  const handleClose = () => setShowConfirmation(false);

  return (
    <div>
      <Modal show={showConfirmation} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm action
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="BookingModal__payment-confirmation">
            Are you sure you want to delete this note?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClick}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NoteConfirmation;
