import React, { useEffect, useState } from 'react';
import './ViewOptions.scss';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TableViewIcon from '@mui/icons-material/TableView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useSearchParams, useLocation, useParams } from 'react-router-dom';
import lodash from 'lodash';
import {
  Modal,
  Button as BootButton,
} from 'react-bootstrap';
import {
  ADD_TABLE_SETTINGS, FETCH_TABLE_VIEWS, DELETE_TABLE_SETTINGS,
  UPDATE_TABLE_SETTINGS, SUBSCRIBE_TABLE_VIEWS,
} from '../../../api/tableViews';
import Alert from '../../Alert/Alert';
import usePermissions from '../../../hooks/usePermissions';

export default function ViewOptions({
  applySettingsHandler,
  applyFiltersHandler,
  gridRef,
  gridTitle,
  isLoading,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [settings, setSettings] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [viewOperation, setViewOperation] = useState(null);
  const [showTextfield, setShowTextfield] = useState(false);
  const location = useLocation();
  const params = useParams();
  const { signedUser } = usePermissions();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const viewData = useSubscription(SUBSCRIBE_TABLE_VIEWS, {
    variables: {
      tableName: gridTitle,
      userId: signedUser?.id || 'null',
    },
  });
  function formatSettingsObject(settings) {
    const newSettings = lodash.cloneDeep(settings);
    lodash.unset(newSettings, 'viewName');
    lodash.unset(newSettings, 'id');
    lodash.unset(newSettings, 'userID');
    lodash.unset(newSettings, '__typename');
    return { ...newSettings, user_id: signedUser?.id || null };
  }

  const [firstAssignment, setFirstAssignment] = useState(true);
  useEffect(() => {
    if (typeof viewData?.data !== 'object' || !firstAssignment) return;
    // eslint-disable-next-line no-prototype-builtins
    if (!params.hasOwnProperty('viewId') && window.location.search.length === 0) setSettings(viewData?.data?.views?.find(({ userID }) => userID === 'default') || {});
    setFirstAssignment(false);
  }, [viewData?.data]);
  useEffect(() => {
    const viewId = searchParams.get('viewId');
    if (viewId && viewData?.data) {
      const viewObj = viewData?.data?.views.find((view) => (view.id === viewId));
      if (viewObj && !settings) {
        setSettings(viewObj);
        applySettingsHandler(viewObj.settings);
        if (viewObj?.filters) {
          applyFiltersHandler(viewObj.filters);
        } else {
          applyFiltersHandler(null);
        }
      }
    }
  }, [applyFiltersHandler, applySettingsHandler, searchParams, settings, viewData?.data]);

  useEffect(() => {
    if (settings && searchParams.get('viewID') !== settings.id && location.hash === '') {
      setSearchParams({ viewId: settings.id });
    }
  }, [location.hash, searchParams, setSearchParams, settings]);

  const [addTableSettings, addSettingsResponse] = useMutation(ADD_TABLE_SETTINGS, {
    refetchQueries: [
      {
        query: FETCH_TABLE_VIEWS,
        variables: { tableName: gridTitle },
      },
    ],
    onCompleted: () => { setSnackbarOpen(true); },
  });

  const [updateTableSettings] = useMutation(UPDATE_TABLE_SETTINGS, {
    refetchQueries: [
      {
        query: FETCH_TABLE_VIEWS,
        variables: { tableName: gridTitle },
      },
    ],
    onCompleted: () => {
      setSnackbarOpen(true);
      if (viewOperation === 'write') {
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    },
  });

  const [deleteSettings, deleteData] = useMutation(DELETE_TABLE_SETTINGS, {
    refetchQueries: [
      {
        query: FETCH_TABLE_VIEWS,
        variables: { tableName: gridTitle },
      },
    ],
    onCompleted: () => {
      setSnackbarOpen(true);
      if (viewOperation === 'delete') {
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const onDeleteSettingsHandler = (id) => {
    setViewOperation('delete');
    deleteSettings({
      variables: {
        id,
      },
    });
  };

  const onSaveTableSettingsHandler = () => {
    setViewOperation('write');
    if (settings) {
      const newSettings = formatSettingsObject(settings);
      newSettings.view_name = settings.viewName;
      newSettings.settings = gridRef.current.api.getColumnDefs();
      newSettings.filters = gridRef.current.api.getFilterModel();
      updateTableSettings({
        variables: {
          input: newSettings,
          id: settings.id,
        },
      });
    } else {
      addTableSettings({
        variables: {
          input: {
            table: gridTitle,
            settings: gridRef.current.api.getColumnDefs(),
            filters: gridRef.current.api.getFilterModel(),
          },
        },
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDuplicateViewHandler = () => {
    if (settings) {
      const newSettings = formatSettingsObject(settings);
      newSettings.view_name = `${settings.viewName} copy`;
      console.log(newSettings);
      addTableSettings({
        variables: {
          input: newSettings,
        },
      });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'table-view-options' : undefined;

  const onChangeValueHandler = (event, value) => {
    if (value === null || typeof value !== 'object') return;
    applySettingsHandler(value.settings);
    setSettings(value);
    setAnchorEl(null);
    if (value?.filters) {
      applyFiltersHandler(value.filters);
    } else {
      applyFiltersHandler(null);
    }
  };

  useEffect(() => {
    const t = setTimeout(() => {
      if (gridRef.refreshClientSideRowModel) gridRef.refreshClientSideRowModel('aggregate');
      onChangeValueHandler(null, settings);
    }, 0);
    return () => clearTimeout(t);
  }, [isLoading]);

  const saveNewViewnameHandler = () => {
    setAnchorEl(null);
    setShowTextfield(false);
    if (settings) {
      const newSettings = formatSettingsObject(settings);
      newSettings.view_name = settings.viewName;
      updateTableSettings({
        variables: {
          input: newSettings,
          id: settings.id,
        },
      });
    }
  };

  const onViewameChangeHandler = (e) => {
    setSettings((prevState) => ({
      ...prevState,
      viewName: e.target.value,
    }));
  };

  useEffect(() => {
    if (addSettingsResponse?.data?.addTableSettings && !settings) {
      const settingsObj = viewData.data.views
        .find((obj) => obj.id === addSettingsResponse.data.addTableSettings.id);
      if (settingsObj) {
        setSettings(settingsObj);
      }
    }
  }, [addSettingsResponse?.data?.addTableSettings, settings, viewData?.data?.views]);

  return (
    <div className="viewOptions-container">

      {
        showTextfield ? (
          <>
            <TextField
              value={settings?.viewName ?? ''}
              onChange={onViewameChangeHandler}
              label="New view name"
              variant="outlined"
              size="small"
              autoFocus
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  saveNewViewnameHandler();
                }
              }}
            />
            <Button
              onClick={saveNewViewnameHandler}
              sx={{
                textTransform: 'none', color: 'inherit', fontWeight: 600, fontSize: '0.9rem',
              }}
              disableRipple
              variant="outlined"
              startIcon={<SaveIcon />}
            >
              Save view name
            </Button>
          </>
        )
          : (
            <>
              <Button
                onClick={handleClick}
                sx={{
                  textTransform: 'none', color: 'inherit', fontWeight: 600, fontSize: '0.9rem',
                }}
                disableRipple
                variant="text"
                startIcon={<TableViewIcon />}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              >
                {settings ? settings.viewName : 'Default View'}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className="table-view-options-container" style={{ height: '75vh' }}>
                  {viewData?.loading ? <p>Loading</p>
                    : (
                      <>
                        <div className="views-selector-container">
                          {viewData?.data && (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={viewData.data.views}
                              sx={{ width: 300 }}
                              renderInput={(params) => <TextField {...params} label="Select a view" />}
                              getOptionLabel={(option) => option.viewName}
                              value={settings}
                              onChange={onChangeValueHandler}
                              disableClearable
                              // ListboxProps={{ style: { maxHeight: '150px' } }}
                            />
                          )}
                        </div>
                        <Divider />
                        <List disablePadding dense>
                          <ListItem disablePadding>
                            <ListItemButton
                              disabled={!settings || settings?.userID === 'default'}
                              onClick={onSaveTableSettingsHandler}
                            >
                              <ListItemIcon>
                                <SaveIcon />
                              </ListItemIcon>
                              <ListItemText primary={settings ? 'Update View' : 'Save view'} />
                            </ListItemButton>
                          </ListItem>

                          <ListItem disablePadding>
                            <ListItemButton disabled={!settings} onClick={onDuplicateViewHandler}>
                              <ListItemIcon>
                                <ContentCopyIcon />
                              </ListItemIcon>
                              <ListItemText primary="Duplicate view" />
                            </ListItemButton>
                          </ListItem>

                          <ListItem disablePadding>
                            <ListItemButton
                              disabled={!settings || settings?.userID === 'default'}
                              onClick={() => setShowTextfield(true)}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Rename view" />
                            </ListItemButton>
                          </ListItem>
                          <Divider />

                          <ListItem sx={{ color: '#f82b60', svg: { fill: '#f82b60' } }} disablePadding>
                            <ListItemButton
                              disabled={(!settings) || (settings?.userID === 'default')}
                              onClick={() => {
                                setShowConfirmation(true);
                                handleClose();
                              }}
                            >
                              <ListItemIcon>
                                <SaveIcon />
                              </ListItemIcon>
                              <ListItemText disable primary="Delete view" />
                            </ListItemButton>
                          </ListItem>

                        </List>
                      </>
                    )}
                </div>
              </Popover>

            </>
          )
      }
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Success!
          {['write', 'delete'].includes(viewOperation) && ' Reloading in 3 sec.'}
        </Alert>
      </Snackbar>

      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            View Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="BookingModal__payment-confirmation">
            Are you sure you want to delete this view?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BootButton
            disabled={deletionConfirmed}
            variant="danger"
            onClick={() => {
              onDeleteSettingsHandler(settings.id);
              setDeletionConfirmed(true);
            }}
          >
            Yes, Delete
          </BootButton>
          <BootButton variant="secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </BootButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
