import React from 'react';

function HeaderFilter({ filter, filterHandler }) {
  return (
    <>
      <h2>Task Dashboard</h2>
      <div className="TaskDashBoard__options">
        <div className="d-flex">
          {/* <Input
            type="select"
            name="city"
            value={filter.city}
            onChange={(e) => filterHandler(e.target.name, e.target.value)}
          >
            <option>All City</option>
            <option>Holy Trinity</option>
            <option>Niagara Parkway </option>
            <option>Cummington Square</option>
            <option>Niagara Falls ON</option>
          </Input>

          <Input
            type="select"
            name="type"
            value={filter.type}
            onChange={(e) => filterHandler(e.target.name, e.target.value)}
          >
            <option>All Type</option>
            <option>Booking</option>
            <option>Reservation</option>
          </Input>
        </div>
        <div className="d-flex">
          <Input
            type="select"
            name="assigned"
            value={filter.assigned}
            onChange={(e) => filterHandler(e.target.name, e.target.value)}
          >
            <option>All Assigned</option>
            <option>Assigned to all</option>
            <option>You Assigned</option>
          </Input>

          <Input
            type="select"
            name="status"
            value={filter.status}
            onChange={(e) => filterHandler(e.target.name, e.target.value)}
          >
            <option>All Status</option>
            <option>Urgent</option>
            <option>Needs Attention</option>
            <option>Pending</option>
            <option>Done</option>
          </Input> */}
        </div>
      </div>
    </>
  );
}

export default HeaderFilter;
