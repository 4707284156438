import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';

function VehicleForm({ formik, cities, vehicle }) {
  const { values, errors, handleChange } = formik;

  const options = cities?.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  return (
    <form onSubmit={formik.handleSubmit} className="Vehicles__form">
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <InputGroup className={errors.name ? 'is-invalid' : ''}>
          <Form.Control
            id="name"
            name="name"
            type="text"
            onChange={handleChange}
            value={values.name}
            className={errors.name ? 'is-invalid' : ''}
            placeholder="Enter vehicle Name"
          />
        </InputGroup>
        {errors.name ? <div className="Vehicles__error">{errors.name}</div> : null}
      </Form.Group>
      <Form.Group className="Vehicles__form-page1-location">
        <Form.Label>Location</Form.Label>
        <div style={{
          position: 'relative',
          zIndex: 3,
        }}
        >
          <Select
            closeMenuOnSelect
            defaultValue={window.location.pathname.includes('create') ? null : {
              value: vehicle?.city?.id,
              label: vehicle?.city?.name,
            }}
            options={options}
            default="Select Guide Location"
            onChange={(value) => formik.setFieldValue('city_id', value.value)}
          />
        </div>
        {errors.city_id ? <div className="Vehicles__error">{errors.city_id}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Plate Number</Form.Label>
        <InputGroup className={errors.plate_number ? 'is-invalid' : ''}>
          <Form.Control
            id="plate_number"
            name="plate_number"
            type="text"
            onChange={handleChange}
            value={values.plate_number}
            className={errors.plate_number ? 'is-invalid' : ''}
            placeholder="Enter vehicle plate number"
          />
        </InputGroup>
        {errors.plate_number ? <div className="Vehicles__error">{errors.plate_number}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Vin Number</Form.Label>
        <InputGroup className={errors.vin_number ? 'is-invalid' : ''}>
          <Form.Control
            id="vin_number"
            name="vin_number"
            type="text"
            onChange={handleChange}
            value={values.vin_number}
            className={errors.vin_number ? 'is-invalid' : ''}
            placeholder="Enter vehicle vin number"
          />
        </InputGroup>
        {errors.vin_number ? <div className="Vehicles__error">{errors.vin_number}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Model</Form.Label>
        <InputGroup className={errors.make_model ? 'is-invalid' : ''}>
          <Form.Control
            id="make_model"
            name="make_model"
            type="text"
            onChange={handleChange}
            value={values.make_model}
            className={errors.make_model ? 'is-invalid' : ''}
            placeholder="Enter vehicle model"
          />
        </InputGroup>
        {errors.make_model ? <div className="Vehicles__error">{errors.make_model}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Capacity</Form.Label>
        <InputGroup className={errors.capacity ? 'is-invalid' : ''}>
          <Form.Control
            id="capacity"
            name="capacity"
            type="number"
            onChange={handleChange}
            value={values.capacity}
            className={errors.capacity ? 'is-invalid' : ''}
            placeholder="Enter seating capacity"
          />
        </InputGroup>
        {errors.capacity ? <div className="Vehicles__error">{errors.capacity}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Year</Form.Label>
        <InputGroup className={errors.year ? 'is-invalid' : ''}>
          <Form.Control
            id="year"
            name="year"
            type="number"
            onChange={handleChange}
            value={values.year}
            className={errors.year ? 'is-invalid' : ''}
            placeholder="Enter registration year"
          />
        </InputGroup>
        {errors.year ? <div className="Vehicles__error">{errors.year}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Colour</Form.Label>
        <InputGroup className={errors.colour ? 'is-invalid' : ''}>
          <Form.Control
            id="colour"
            name="colour"
            type="text"
            onChange={handleChange}
            value={values.colour}
            className={errors.colour ? 'is-invalid' : ''}
            placeholder="Enter vehicle colour"
          />
        </InputGroup>
        {errors.colour ? <div className="Vehicles__error">{errors.colour}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Notes</Form.Label>
        <InputGroup className={errors.maintenence ? 'is-invalid' : ''}>
          <Form.Control
            id="maintenence"
            as="textarea"
            name="maintenence"
            type="text"
            onChange={handleChange}
            value={values.maintenence}
            className={errors.maintenence ? 'is-invalid' : ''}
            placeholder="Comments..."
          />
        </InputGroup>
        {errors.maintenence ? <div className="Vehicles__error">{errors.maintenence}</div> : null}
      </Form.Group>
      <div className="Vehicles__buttons">
        <button type="submit" className="Vehicles__buttons-save" disabled={!formik.dirty}>Save</button>
        <button
          type="button"
          className="Vehicles__buttons-cancel"
          onClick={() => window.location.reload(false)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default VehicleForm;
