async function fetchFromAPI(API, endpoint, opts) {
  const { method, body } = { method: 'POST', body: null, ...opts };
  const res = await fetch(`${API}/${endpoint}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.status >= 400 && res.status <= 599) {
    throw new Error(res.statusText);
  }
  return res.json();
}

export function capFirst(word) {
  return word[0].toUpperCase() + word.slice(1);
}

export function stringToColour(str, opacity = 1) {
  let hash = BigInt(0);
  for (let i = 0; i < str.length; i += 1) {
    hash = BigInt(str.charCodeAt(i)) + ((hash * BigInt(32)) - hash);
  }
  const seed = Number(hash % BigInt(256));
  const colorHash = BigInt(seed) * BigInt(16777216) + (hash % BigInt(16777216));
  const hexColor = (colorHash % BigInt(16777216)).toString(16).padStart(6, '0');
  const opacityHex = Math.round(opacity * 255).toString(16).padStart(2, '0');
  return `#${hexColor}${opacityHex}`;
}

export default fetchFromAPI;
