/* eslint-disable */
import React, { useState, useEffect } from "react";
import Table from "rc-table";
import { Link } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { FETCH_PRODUCTS } from "../../../api/Product";
import ProductHeader from "./ProductHeader";
import Name from "./Name";
import Availability from "./Availability";
import "../Products.scss";
import { FETCH_CITIES } from "../../../api/city";

function DisplayProducts() {
  const { data, loading } = useQuery(FETCH_PRODUCTS);
  const { data: cities, loading: loadingCities } =
    useSubscription(FETCH_CITIES);
  const [tableData, setTableData] = useState([]);
  const [nameCodeSearch, setNameCodeSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("all");
  const [availabilityFilter, setAvailabilityFilter] = useState("all");
  const [activeStatusFilter, setActiveStatusFilter] = useState("all");
  const [cityFilter, setCityFilter] = useState({ name: "All" });

  const [itineraryStatusFilter, setItineraryStatusFilter] = useState("all");

  useEffect(() => {
    if (data?.products) {
      setTableData(
        data.products.map((product) => ({ ...product, key: product.id }))
      );
    }
  }, [data]);

  const filteredData = tableData.filter((product) => {
    const nameCodeMatch =
      nameCodeSearch === "" ||
      product.name
        .toLowerCase()
        .includes(nameCodeSearch.trim().toLowerCase()) ||
      product.productCode
        .toLowerCase()
        .includes(nameCodeSearch.replace(/\s+/g, "").toLowerCase());

    const typeMatch =
      typeSearch === "all" ||
      (typeSearch === "daytour" && product.type.toLowerCase() === "day tour") ||
      (typeSearch === "privatedaytour" &&
        product.type.toLowerCase() === "private day tour") ||
      (typeSearch === "multidaytour" &&
        product.type.toLowerCase() === "multi-day tour");

    const availabilityMatch =
      availabilityFilter === "all" ||
      (availabilityFilter === "yes" && product?.availabilities.length > 0) ||
      (availabilityFilter === "no" && product?.availabilities.length === 0);

    const activeStatusMatch =
      activeStatusFilter === "all" ||
      (activeStatusFilter === "yes" && product.active) ||
      (activeStatusFilter === "no" && !product.active);

    const itineraryMatch =
      itineraryStatusFilter === "all" ||
      (itineraryStatusFilter === "yes" &&
        product.route_plan_url !== "[]" &&
        product.route_plan_url !== "") ||
      (itineraryStatusFilter === "no" &&
        (product.route_plan_url === "[]" || product.route_plan_url === ""));

    const cityMatch =
      cityFilter === "all" ||
      product?.cities_products?.[0]?.city?.id === cityFilter;

    return (
      nameCodeMatch &&
      typeMatch &&
      availabilityMatch &&
      activeStatusMatch &&
      itineraryMatch &&
      cityMatch
    );
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (_, product) => (
        <Name
          name={product?.name}
          product={product}
          productId={product?.id}
        ></Name>
      ),
    },
    {
      title: "Code",
      dataIndex: "productCode",
      key: "code",
      width: "15%",
      render: (productCode) => <div>{productCode || "####"}</div>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (type) => <div>{type || null}</div>,
    },
    {
      title: "Availability",
      dataIndex: "availabilityType",
      key: "availability",
      width: "25%",
      render: (_, product) => (
        <Availability availabilities={product.availabilities} />
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "status",
      width: "10%",
      render: (active) => <div>{active ? "Active" : "Inactive"}</div>,
    },
    {
      title: "Itinerary",
      dataIndex: "route_plan_url",
      key: "itinerary",
      width: "15%",
      render: (routePlanUrl) => {
        if (routePlanUrl === "[]" || routePlanUrl === "") {
          return "Empty";
        }
        return "Completed";
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      width: "20%",
      render: (id) => (
        <Link
          style={{
            backgroundColor: "#ff6262",
            color: "white",
            marginLeft: "20px",
            marginRight: "20px",
          }}
          to={`/products/edit/${id}`}
        >
          Edit
        </Link>
      ),
    },
  ];

  return (
    <div className="show_table_cont">
      {cities && (
        <ProductHeader
          nameCodeSearch={nameCodeSearch}
          setNameCodeSearch={setNameCodeSearch}
          typeSearch={typeSearch}
          setTypeSearch={setTypeSearch}
          availabilityFilter={availabilityFilter}
          setAvailabilityFilter={setAvailabilityFilter}
          activeStatusFilter={activeStatusFilter}
          setActiveStatusFilter={setActiveStatusFilter}
          itineraryStatusFilter={itineraryStatusFilter}
          setItineraryStatusFilter={setItineraryStatusFilter}
          cityFilter={cityFilter}
          setCityFilter={setCityFilter}
          cities={cities?.boatnew_cities}
        />
      )}
      {!loading && <Table columns={columns} data={filteredData} />}
    </div>
  );
}

export default DisplayProducts;
