import React from 'react';

function InputComponent({
  id,
  name,
  value,
  handleChange,
  type,
  min,
}) {
  return (
    <label
      htmlFor={name.toLowerCase().replace(/ /g, '_')}
      className="BookingModal__info-edit-input"
    >
      <span>{name}</span>
      <input
        id={id || name.toLowerCase().replace(/ /g, '_')}
        name={name.toLowerCase().replace(/ /g, '_')}
        type={type}
        onChange={handleChange}
        value={value}
        onWheel={(e) => e.currentTarget.blur()}
        min={min}
      />
    </label>
  );
}

export default InputComponent;
