import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import './EmailEditor.scss';

const key = process.env.REACT_APP_TEXT_EDITOR_KEY;

function EmailEditor({
  formik,
  showCC,
  setShowCC,
  editorRef,
  setShowEmailTemplate,
  setConfirmation,
}) {
  const options = formik?.values?.contactList?.map((contact) => (
    {
      value: contact.email,
      label: contact.email,
    }));

  const value = formik?.values?.contact
    ? formik.values.contact.map((contact) => (
      {
        value: contact,
        label: contact,
      }))
    : '';
  console.log('value', formik.values);

  const handleSelect = (value) => {
    const contacts = [];
    value?.map((contact) => {
      contacts.push(contact.label);
    });
    formik.setFieldValue('contact', contacts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmation({ show: true, confirmed: false });
  };

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className="Reservations__email-contact">
        <Form.Label>To: </Form.Label>
        <Select
          isMulti
          options={options}
          value={value}
          onChange={(value) => { handleSelect(value); }}
          className="Reservations__email-contact-select"
          styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
        />
        <i
          className="far fa-plus"
          onClick={() => {
            formik.setFieldValue(
              'contactCC',
              'jay@seesight-tours.com, chris@seesight-tours.com, adrian@seesight-tours.com',
            );
            setShowCC(true);
          }}
        />
      </div>
      {showCC && (
        <div className="Reservations__email-contact">
          <Form.Label className="Reservations__email-contact-label">Cc: </Form.Label>
          <Form.Control
            id="contactCC"
            name="contactCC"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.contactCC}
          />
          <i
            className="far fa-times"
            onClick={() => {
              formik.setFieldValue('contactCC', '');
              setShowCC(false);
            }}
          />
        </div>
      )}
      <Form.Control
        id="subject"
        name="subject"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.subject}
        placeholder="Subject"
        className="Reservations__email-subject"
      />
      <Editor
        apiKey={key}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={formik.values.body}
        init={{
          height: 350,
          width: '100%',
          menubar: false,
          toolbar: 'undo redo | formatselect | '
            + 'bold italic backcolor | alignleft aligncenter '
            + 'alignright alignjustify | bullist numlist outdent indent | '
            + 'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      <div className="Reservations__email-btns">
        <button type="submit" disabled={Object.keys(formik.errors).length > 0}>
          Send
        </button>
        <button
          type="button"
          onClick={() => {
            setShowEmailTemplate(false);
            formik.resetForm();
            // setShowCC(false);
          }}
        >
          Close
        </button>
      </div>
    </form>
  );
}

export default EmailEditor;
