import React, { memo } from 'react';
import { useMutation } from '@apollo/client';
import Dayjs from 'dayjs';
import { DELETE_NOTE, UPDATE_NOTE, ADD_NOTE } from '../../../../../../api/notes';
import fetchFromAPI from '../../../../../../utils/helpers';
import Note from './Note';
import NotesAddForm from './NotesAddForm';
import '../../styles/Notes.scss';

const airtableUpdateAPIKey = process.env.REACT_APP_AIRTABLE_UPDATE;

function Notes({ clickedBooking }) {
  const updateAirtable = async (body) => {
    try {
      const res = await fetchFromAPI(airtableUpdateAPIKey, 'update-airtable-notes', {
        body,
      });
    } catch (err) {
      alert(`Airtable was not updated: ${err.message}.`);
    }
  };

  const [deleteNote] = useMutation(DELETE_NOTE, {
    onCompleted: (data) => {
      const body = {
        bookingId: clickedBooking.id,
        notes: data?.deleteNotes?.booking?.bookingNotes,
        source: clickedBooking.source,
      };
      updateAirtable(body);
    },
  });

  const [updateNote] = useMutation(UPDATE_NOTE, {
    onCompleted: (data) => {
      const body = {
        bookingId: clickedBooking.id,
        notes: data?.updateNotes?.booking?.bookingNotes,
        source: clickedBooking.source,
      };
      updateAirtable(body);
    },
  });

  const [addNote] = useMutation(ADD_NOTE, {
    onCompleted: (data) => {
      const body = {
        bookingId: clickedBooking.id,
        notes: data?.addNotes?.booking?.bookingNotes,
        source: clickedBooking.source,
      };
      updateAirtable(body);
    },
  });

  return (
    <div className="Notes__wrapper">
      <p className="BookingModal__header">Guest Services - Notes</p>
      {clickedBooking?.internalNotes?.map((note) => (
        <div className="Notes__note" key={note.id}>
          <Note
            note={note}
            deleteNote={deleteNote}
            updateNote={updateNote}
          />
        </div>
      ))}
      <div className="Notes__note-add-form">
        <NotesAddForm
          addNote={addNote}
          bookingId={clickedBooking.id}
        />
      </div>
    </div>
  );
}

export default memo(Notes);
