import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useSubscription } from "@apollo/client";
import { SUBSCRIBE_ACTIVE_GUIDES } from "../../../api/Guides";
import { SUBSCRIBE_AVAILABLE_VEHICLES } from "../../../api/Vehicles";

function CustomDropDown(props) {
  const { data, params, stateAndActions, field } = props;
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const {
    data: { newValue, setNewValue, isEditing, setIsEditing },
    actions: {
      onCellEditingStarted,
      onCellEditingStopped,
      onCellValueChangeCommitted,
      onCellValueChange,
      onCellFocused,
    },
  } = stateAndActions;

  useEffect(() => {
    setOptions(data);
  }, []);

  const handleChange = (event, newValue) => {
    setIsLoading(true);
    switch (field) {
      case "assignedGuide":
        setNewValue({ column: "assignedGuide", value: newValue?.id });
        break;
      case "assignedVehicle":
        setNewValue({ column: "assignedVehicle", value: newValue?.id });
        break;
      case "group":
        setNewValue({ column: "group", value: newValue?.id });
        break;
      default:
        break;
    }
    // params.stopEditing();
    // Call your API to update the value in the database
    // Once the API call is complete, setIsLoading(false) to hide the loader
  };

  return (
    <Autocomplete
      value={value}
      freeSolo
      // filterOptions={
      //   (
      //     options,
      //     { inputValue },
      //   ) => options.filter(
      //     ({ title }) => title.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
      //   )
      // }
      onChange={handleChange}
      onHighlightChange={handleChange}
      autoHighlight
      options={[{ title: "" }, ...options]}
      getOptionLabel={({ title }) => title || ""}
      filterOptions={(options, { inputValue }) =>
        inputValue.trim() === ""
          ? options
          : options
              ?.filter(({ title }) =>
                title.toLowerCase().includes(inputValue.toLowerCase())
              )
              .slice(0, 7)
      }
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input
            type="text"
            autoFocus
            {...params.inputProps}
            className="ag-cell-edit-input w-100"
          />
        </div>
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>{option?.title}</li>
      )}
      sx={{ width: "100%" }}
    />
  );
}

export default CustomDropDown;
