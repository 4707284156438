import { gql } from '@apollo/client';

export const UPDATE_ASSIGNED_GUIDE = gql`
    mutation updateAssignedGuide(
        $booking_id: String!,
        $guide_id: String!,
    ) {
        insert_boatnew_booking_vehicles(
            objects: [{
                booking_id: $booking_id
                guide_id: $guide_id
            }],
            on_conflict: {
                constraint: booking_vehicles_pkey, update_columns: [guide_id]
            }
        ) {
            affected_rows
        }
        updateBooking: update_boatnew_booking(
          where: { id: { _eq: $booking_id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export const UPDATE_ASSIGNED_VEHICLE = gql`
    mutation updateAssignedVehicle(
        $booking_id: String!,
        $vehicle_id: String!,
    ) {
        insert_boatnew_booking_vehicles(
            objects: [{
                booking_id: $booking_id
                vehicle_id: $vehicle_id
            }],
            on_conflict: {
                constraint: booking_vehicles_pkey, update_columns: [vehicle_id]
            }
        ) {
            affected_rows
        }
        updateBooking: update_boatnew_booking(
          where: { id: { _eq: $booking_id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export const UPDATE_GROUP = gql`
    mutation updateGroup(
        $booking_id: String!,
        $group: String!,
    ) {
        insert_boatnew_booking_vehicles(
            objects: [{
                booking_id: $booking_id
                group: $group
            }],
            on_conflict: {
                constraint: booking_vehicles_pkey, update_columns: [group]
            }
        ) {
            affected_rows
        }
        updateBooking: update_boatnew_booking(
          where: { id: { _eq: $booking_id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export default UPDATE_ASSIGNED_GUIDE;
