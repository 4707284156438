import React, { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Users.scss';
// import { Select, MenuItem } from '@mui/material';
import { permissions } from '../../../utils/users';

export default function UserForm({ formik }) {
  const {
    values, errors, handleChange, dirty, isSubmitting, setFieldValue,
  } = formik;
  const navigate = useNavigate();
  const location = useLocation();

  const handlePermissionChange = (event) => {
    const { name, checked } = event.target;
    let selectedPermissions = values.permissions === '"*"' ? [] : JSON.parse(values.permissions);

    if (selectedPermissions.length === 0 && values?.permissions === '"*"') {
      selectedPermissions = permissions.filter(({ code }) => code !== name).map(({ code }) => code);
    } else if (checked) selectedPermissions.push(name);
    else selectedPermissions = selectedPermissions.filter((code) => code !== name);

    setFieldValue('permissions', selectedPermissions.length === permissions.length ? '"*"' : JSON.stringify(selectedPermissions));
  };

  const selectedPermissions = permissions.filter((permission) => values[permission.code]);

  const isPermissionSelected = (permission) => (values.permissions) === '"*"' || JSON.parse(values.permissions).includes(permission.code);

  return (
    <>
      {/* <Header /> */}
      <main className="Guides">
        <Form onSubmit={formik.handleSubmit} className="Guides__form">
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <InputGroup className={errors.name ? 'is-invalid' : ''}>
              <Form.Control
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                className={errors.name ? 'is-invalid' : ''}
                placeholder="Enter user name"
              />
            </InputGroup>
            {errors.name ? <div className="Guides__error">{errors.name}</div> : null}
          </Form.Group>

          <Form.Group>
            <Form.Label>Email</Form.Label>
            <InputGroup className={errors.email ? 'is-invalid' : ''}>
              <Form.Control
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                value={values.email}
                className={errors.email ? 'is-invalid' : ''}
                placeholder="*****il@seesight-tours.com"
                disabled={window.location.pathname.includes('users/edit/')}
              />
            </InputGroup>
            {errors.email ? <div className="Guides__error">{errors.email}</div> : null}
          </Form.Group>

          <Form.Group>
            <Form.Label>Phone number</Form.Label>
            <InputGroup className={errors.phone ? 'is-invalid' : ''}>
              <Form.Control
                id="phone"
                name="phone"
                type="text"
                onChange={handleChange}
                value={values.phone}
                className={errors.phone ? 'is-invalid' : ''}
                placeholder="Enter Phone"
              />
            </InputGroup>
            {errors.phone ? <div className="Guides__error">{errors.phone}</div> : null}
          </Form.Group>

          <Form.Group>
            <Form.Label>Role</Form.Label>
            <div style={{
              position: 'relative',
              zIndex: 3,
            }}
            >
              <Select
                closeMenuOnSelect
                value={{
                  value: values.role,
                  label: values.role.split('_').join(' '),
                }}
                options={[
                  {
                    value: 'Super_Admin',
                    label: 'Super Admin',
                  },
                  {
                    value: 'Admin',
                    label: 'Admin',
                  },
                  {
                    value: 'Guest_Services',
                    label: 'Guest Services',
                  },
                  {
                    value: 'Tech_Team',
                    label: 'Tech Team',
                  },
                  {
                    value: 'Operations_Manager',
                    label: 'Operations Manager',
                  },
                  {
                    value: 'Accounts_&_Finance',
                    label: 'Accounts & Finance',
                  },
                ]}
                onChange={(value) => formik.setFieldValue('role', value.value)}
              />
            </div>
            {errors.role ? <div className="Guides__error">{errors.role}</div> : null}
          </Form.Group>

          <Form.Group>
            <Form.Label>Permissions</Form.Label>
            {permissions.map((permission) => (
              <div key={permission.code}>
                {permission.parent === null ? (
                  <Form.Check
                    type="checkbox"
                    id={permission.code}
                    label={permission.title}
                    name={permission.code}
                    checked={isPermissionSelected(permission)}
                    onChange={handlePermissionChange}
                  />
                ) : (
                  <div className="ml-3">
                    <Form.Check
                      type="checkbox"
                      id={permission.code}
                      label={permission.title}
                      name={permission.code}
                      checked={isPermissionSelected(permission)}
                      onChange={handlePermissionChange}
                      disabled={!isPermissionSelected(permission.parent)}
                    />
                  </div>
                )}
              </div>
            ))}
          </Form.Group>

          <div className="Guides__buttons">
            <button type="submit" className="Guides__buttons-save" disabled={!dirty || isSubmitting}>
              {location.pathname.includes('edit') ? 'Update' : 'Create'}
              {' '}
              User
            </button>
            <button
              type="button"
              className="Guides__buttons-cancel"
                  // onClick={() => window.location.reload(false)}
              onClick={() => navigate('/users')}
            >
              Cancel
            </button>
          </div>
        </Form>
      </main>
    </>
  );
}
