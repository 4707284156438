import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Alert from 'react-bootstrap/Alert';
import { FETCH_CITIES_WITH_RESERVATIONS } from '../../api/city';
import { ADD_BOOKING_RESERVATIONS, FETCH_RESERVATION_LOGS } from '../../api/reservations';
import fetchFromAPI from '../../utils/helpers';
import Header from '../../components/Header/Header';
import DisplayTable from './components/Table/DisplayTable';
import './Reservations.scss';
import DisplayLogs from './components/Logs/DisplayLogs';
import ConfirmationPopup from './components/Confirmation/ConfirmationPopup';

const emailAPI = process.env.REACT_APP_EMAIL_API;

dayjs.extend(utc);
dayjs.extend(timezone);

const today = dayjs().format('YYYY-MM-DD');

const validate = (values) => {
  const errors = {};
  if (!values.contact || values.contact.length === 0) {
    errors.contact = 'Required';
  }
  return errors;
};

function Reservations() {
  const [city, setCity] = useState('default');
  const [date, setDate] = useState(today);
  const [logsDate, setLogsDate] = useState(today);
  const [checked, setChecked] = useState([]);
  const [bookingReservations, setBookingReservations] = useState([]);
  const [chosenBookings, setChosenBookings] = useState([]);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [variables, setVariables] = useState(null);
  // console.log('the variables', variables);
  const [confirmation, setConfirmation] = useState({
    show: false,
    confirmed: false,
  });

  const [alert, setAlert] = useState({
    show: false,
    colour: '',
    header: '',
    msg: '',
  });

  const editorRef = useRef(null);

  const { data: cities } = useQuery(FETCH_CITIES_WITH_RESERVATIONS);

  const showAlert = (alertObj) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setAlert(alertObj);
    if (alertObj.colour === 'primary') {
      const timer = setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 4000);
      return () => clearTimeout(timer);
    }
  };

  const displayAlert = (data, error) => {
    let alertObj;
    if (error) {
      alertObj = {
        show: true,
        colour: 'danger',
        header: 'Oh snap! You got an error!',
        msg: `Email was sent successfully
         but the reservations table was not updated! Contact dev team. ${error.message}`,
      };
    } else if (data) {
      alertObj = {
        show: true,
        colour: 'primary',
        header: 'Success!',
        msg: 'The email was sent successfully',
      };
    }
    showAlert(alertObj);
  };

  const [addBookingReservations, { data, loading, error }] = useMutation(ADD_BOOKING_RESERVATIONS, {
    refetchQueries: [
      { query: FETCH_RESERVATION_LOGS, variables: { date: logsDate } },
    ],
    onCompleted: () => { displayAlert(data, error); },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowEmailTemplate(false);
    setBookingReservations([]);
    setChosenBookings([]);
    setChecked([]);
    formik.resetForm();
    setVariables({ city, date });
  };

  const sendEmail = async (body, values, email) => {
    try {
      const res = await fetchFromAPI(emailAPI, 'reservations', {
        body,
      });

      const objects = bookingReservations.map((r) => ({ ...r, log_id: res }));

      addBookingReservations({
        variables: {
          objects,
          input: {
            id: res,
            reservation_id: values.id,
            status: 'SENT',
            date: dayjs.utc().tz('America/Toronto').format('YYYY-MM-DD'),
            time: dayjs.utc().tz('America/Toronto').format('HH:mm'),
            content: {
              subject: values.subject,
              contact: values.contact,
              cc: (values.contactCC || '').replace(/\s/g, '').split(','),
              body: email,
            },
          },
        },
      });
    } catch (err) {
      const alertObj = {
        show: true,
        colour: 'danger',
        header: 'Oh snap! You got an error!',
        msg: 'Something went wrong. Email was not sent',
      };
      showAlert(alertObj);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      contact: '',
      subject: 'Ticket reservation',
      body: '',
      contactList: '',
      contactCC: '',
    },
    validateOnChange: true,
    validate,
    onSubmit: (values) => {
      console.log('values', values);
      const body = {
        from: 'info@seesight-tours.com',
        to: values.contact,
        CC: (values.contactCC || '').replace(/\s/g, '').split(','),
        subject: values.subject || 'Reservation Email',
        template: editorRef.current.getContent(),
      };
      sendEmail(body, values, editorRef.current.getContent({ format: 'text' }));
      setShowEmailTemplate(false);
      setBookingReservations([]);
      setChosenBookings([]);
      setChecked([]);
      setConfirmation({ show: false, confirmed: false });
      formik.resetForm();
    },
  });

  return (
    <>
      <Outlet />
      <Header />
      {confirmation.show && (
        <ConfirmationPopup
          confirmation={confirmation}
          setConfirmation={setConfirmation}
          formik={formik}
        />
      )}
      {alert.show && (
        <Alert
          variant={alert.colour}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          <Alert.Heading>{alert.header}</Alert.Heading>
          <p>
            {alert.msg}
          </p>
        </Alert>
      )}
      <main className="Reservations">
        <header className="Reservations__header">
          <h2>Reservations</h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Form.Select
              className="form-input"
              value={city}
              onChange={(e) => { setCity(e.target.value); }}
            >
              <option value="default" disabled>Choose city</option>
              {cities?.cities?.map((c) => (
                <option
                  value={c.id}
                  key={c.id}
                >
                  {c.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control
              className="form-input"
              id="tour_date"
              name="tour_date"
              type="date"
              placeholder="yyyy-mm-dd"
              onChange={(e) => { setDate(e.target.value); }}
              value={date}
            />
            <button disabled={date === '' || city === 'default'} type="submit">Search</button>
          </form>
        </header>
        {variables
          && (
            <DisplayTable
              variables={variables}
              formik={formik}
              date={date}
              chosenBookings={chosenBookings}
              checked={checked}
              setChecked={setChecked}
              setChosenBookings={setChosenBookings}
              setBookingReservations={setBookingReservations}
              bookingReservations={bookingReservations}
              setShowEmailTemplate={setShowEmailTemplate}
              showEmailTemplate={showEmailTemplate}
              editorRef={editorRef}
              setConfirmation={setConfirmation}
            />
          )}
        <DisplayLogs
          logsDate={logsDate}
          setLogsDate={setLogsDate}
        />
      </main>
    </>
  );
}

export default Reservations;
