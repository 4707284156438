import { gql } from "@apollo/client";

const FETCH_TOURS = gql`
  query FETCH_TOURS {
    tours: boatnew_cities(order_by: { name: asc }) {
      id
      name
      timeZone: time_zone
      cities_products(order_by: { product: { name: asc } }) {
        product {
          id
          name
          type
        }
      }
    }
  }
`;

const FETCH_AVAILABILITY = gql`
  query FETCH_AVAILABILITY($id: String!) {
    availabilities: boatnew_availabilities(
      where: { product: { cities_products: { city_id: { _eq: $id } } } }
    ) {
      id
      start_date
      end_date
      start_time
      product {
        name
      }
      availabilities_vehicles {
        vehicle {
          id
          name
        }
      }
    }
  }
`;

const FETCH_AVAILABILITY_SERIES = gql`
  query FETCH_AVAILABILITY_SERIES($parentId: String!) {
    boatnewAvailabilitiesSeries: boatnew_availabilities_series(
      where: { parent_id: { _eq: $parentId } }
      order_by: { start_time: asc }
    ) {
      id
      startTime: start_time
    }
  }
`;

const CHECK_AVAILABILITY = gql`
  query CHECK_AVAILABILITY {
    boatnewAvailabilityCheck: boatnew_availability_view {
      availabilitiesSeriesId: availabilities_series_id
      totalSeats: total_seats
      totalBooked: total_booked
      totalRemaining: total_remaining
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query FETCH_PRODUCTS {
    products: boatnew_products(order_by: { active: desc, updated_at: desc }) {
      id
      name
      productCode: code
      pickupRequired: pickup_required
      active
      type
      slug
      route_plan_url
      cities_products {
        city {
          id
          name
        }
      }
      productUnits: products_units {
        unit {
          id
          label
        }
      }
      rezdy {
        rezdy_id
      }
      availabilities {
        id
        startDate: start_date
        endDate: end_date
        startTime: start_time
        totalSeats: total_seats
      }
    }
  }
`;

const FETCH_CENTRAl_PICKUP = gql`
  query FETCH_PRODUCT($id: String!) {
    product: boatnew_products_by_pk(id: $id) {
      id
      pickup_bounds
      central_pickup_location
    }
  }
`;

const FETCH_PRODUCT = gql`
  query FETCH_PRODUCT($id: String!) {
    product: boatnew_products_by_pk(id: $id) {
      id
      name
      code
      type
      slug
      active
      advertisedPrice: advertised_price
      bookingCutoff: booking_cutoff
      cancelCutoff: cancel_cutoff
      duration
      pickupBounds: pickup_bounds
      pickupRequired: pickup_required
      quantityMax: quantity_max
      quantityMin: quantity_min
      product_prices(where: { currency: { _eq: "USD" } }) {
        id
        prices
      }
      advertised_price
      quantity
      quantity_max
      quantity_min
      productUnits: products_units(order_by: { unit: { label: asc } }) {
        unit_id
        updated_at
      }
      citiesProducts: cities_products {
        city {
          name
          id
          timeZone: time_zone
        }
      }
      rezdy {
        rezdy_id
      }
      availabilities {
        id
        start_date
        end_date
        start_time
        total_seats
      }
    }
    cities: boatnew_cities(order_by: { name: asc }) {
      id
      name
      coordinates
      pickupBounds: pickup_bounds
    }
    vehicles: boatnew_vehicles {
      id
      capacity
    }
  }
`;

const FETCH_ATTRACTION_BY_CITY = gql`
  query FETCH_ATTRACTION_BY_CITY($citiesId: [String!]) {
    attractions: boatnew_attractions(
      where: { city_id: { _in: $citiesId } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;

const FETCH_PRODUCT_UNITS_BOUNDS = gql`
  query FETCH_PRODUCT_UNITS_BOUNDS($id: String!) {
    productUnits: boatnew_products_by_pk(id: $id) {
      units: products_units(order_by: { unit: { label: asc } }) {
        unit {
          id
          label
        }
      }
      quantityMax: quantity_max
    }
    product: boatnew_products_by_pk(id: $id) {
      pickupBounds: pickup_bounds
      citiesProducts: cities_products {
        city {
          coordinates
        }
      }
    }
  }
`;

const ADD_PRODUCT = gql`
  mutation ADD_PRODUCT($input: boatnew_products_insert_input!) {
    insert_boatnew_products_one(object: $input) {
      id
    }
  }
`;

const ADD_ROUTE_PLAN_URL = gql`
  mutation UPDATE_PRODUCT_ROUTE_PLAN_URL(
    $id: String!
    $route_plan_url: String!
  ) {
    update_boatnew_products_by_pk(
      pk_columns: { id: $id }
      _set: { route_plan_url: $route_plan_url }
    ) {
      id
      route_plan_url
    }
  }
`;

const ADD_REZDY_PRODUCT = gql`
  mutation upsert_rezdy($objects: [rezdy_product_insert_input!]!) {
    insert_rezdy_product(
      objects: $objects
      on_conflict: {
        constraint: product_boat_id_key
        update_columns: [rezdy_id]
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UPDATE_PRODUCT($id: String!, $input: boatnew_products_set_input) {
    update_boatnew_products_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`;

const UPDATE_CITIES_PRODUCTS = gql`
  mutation UPDATE_CITIES_PRODUCTS(
    $id: String!
    $objects: [boatnew_cities_products_insert_input!]!
  ) {
    delete_boatnew_cities_products(where: { product_id: { _eq: $id } }) {
      affected_rows
    }
    insert_boatnew_cities_products(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_PRODUCT_UNITS = gql`
  mutation UPDATE_PRODUCT_UNITS(
    $id: String!
    $objects: [boatnew_products_units_insert_input!]!
  ) {
    delete_boatnew_products_units(where: { product_id: { _eq: $id } }) {
      affected_rows
    }
    insert_boatnew_products_units(objects: $objects) {
      affected_rows
    }
  }
`;

const ADD_AVAILABILITY = gql`
  mutation ADD_AVAILABILITY($objects: [boatnew_availabilities_insert_input!]!) {
    insert_boatnew_availabilities(objects: $objects) {
      returning {
        availabilities_series {
          id
        }
        availabilities_vehicles {
          vehicle_id
        }
      }
    }
  }
`;

const ADD_AVAILABILITY_SERIES_VEHICLES = gql`
  mutation ADD_AVAILABILITY_SERIES_VEHICLES(
    $objects: [boatnew_availabilities_series_vehicles_insert_input!]!
  ) {
    insert_boatnew_availabilities_series_vehicles(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_PRODUCT_ATTRACTIONS = gql`
  mutation UPDATE_PRODUCT_ATTRACTIONS(
    $id: String!
    $objects: [boatnew_products_attractions_insert_input!]!
  ) {
    delete_boatnew_products_attractions(where: { product_id: { _eq: $id } }) {
      affected_rows
    }
    insert_boatnew_products_attractions(objects: $objects) {
      affected_rows
    }
  }
`;
const DELETE_REZDY_PRODUCT = gql`
  mutation delete_rezdy_product($id: String!) {
    delete_rezdy_product(where: { rezdy_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
const FETCH_PRODUCT_PRICE = gql`
  query GET_PRODUCT_PRICES($productId: String!) {
    boatnew_products_by_pk(id: $productId) {
      product_prices(where: { currency: { _eq: "USD" } }) {
        id
        prices
      }
      advertised_price
      quantity
      quantity_max
      quantity_min
    }
  }
`;

const UPDATE_PRODUCT_PRICE = gql`
  mutation UPDATE_PRODUCT_PRICING(
    $productId: String!
    $priceArr: jsonb!
    $newPriceArr: jsonb!
    $advertisedPrice: String!
    $advertisedPriceInt: Int!
    $quantity: numrange!
  ) {
    update_boat_products_by_pk(
      pk_columns: { id: $productId }
      _set: {
        price: $newPriceArr
        advertised_price: $advertisedPrice
        quantity: $quantity
      }
    ) {
      id
    }

    update_boatnew_products_by_pk(
      pk_columns: { id: $productId }
      _set: { advertised_price: $advertisedPrice, quantity: $quantity }
    ) {
      id
    }

    insert_boatnew_products_prices(
      objects: [
        {
          product_id: $productId
          advertised: $advertisedPriceInt
          prices: $priceArr
        }
      ]
      on_conflict: {
        constraint: product_prices_pkey
        update_columns: [advertised, prices]
      }
    ) {
      affected_rows
    }
  }
`;

const SUBSCRIBE_PRODUCTS = gql`
  subscription FETCH_PRODUCT($id: String!) {
    product: boatnew_products_by_pk(id: $id) {
      id
      name
      code
      type
      active
      advertisedPrice: advertised_price
      bookingCutoff: booking_cutoff
      cancelCutoff: cancel_cutoff
      duration
      pickupBounds: pickup_bounds
      pickupRequired: pickup_required
      quantityMax: quantity_max
      quantityMin: quantity_min
      product_prices {
        id
        prices
      }
      advertised_price
      quantity
      quantity_max
      quantity_min
      productUnits: products_units(order_by: { unit: { label: asc } }) {
        unit_id
        updated_at
      }
      citiesProducts: cities_products {
        city {
          name
          id
          timeZone: time_zone
        }
      }
      rezdy {
        rezdy_id
      }
      availabilities {
        id
        start_date
        end_date
        start_time
        total_seats
      }
    }
    cities: boatnew_cities(order_by: { name: asc }) {
      id
      name
      coordinates
      pickupBounds: pickup_bounds
    }
    vehicles: boatnew_vehicles {
      id
      capacity
    }
  }
`;

const SAVE_CENTRAL_PICKUP_LOCATION = gql`
  mutation SaveCentralPickupLocation(
    $id: String!
    $location: String!
    $pickupBounds: jsonb!
  ) {
    update_boatnew_products_by_pk(
      pk_columns: { id: $id }
      _set: {
        central_pickup_location: $location
        pickup_bounds: $pickupBounds
      }
    ) {
      id
      pickup_bounds
    }
  }
`;



export {
  FETCH_TOURS,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  ADD_AVAILABILITY,
  ADD_AVAILABILITY_SERIES_VEHICLES,
  UPDATE_CITIES_PRODUCTS,
  UPDATE_PRODUCT_UNITS,
  FETCH_AVAILABILITY,
  FETCH_AVAILABILITY_SERIES,
  UPDATE_PRODUCT_ATTRACTIONS,
  FETCH_ATTRACTION_BY_CITY,
  FETCH_PRODUCT_UNITS_BOUNDS,
  CHECK_AVAILABILITY,
  ADD_REZDY_PRODUCT,
  DELETE_REZDY_PRODUCT,
  FETCH_PRODUCT_PRICE,
  UPDATE_PRODUCT_PRICE,
  SUBSCRIBE_PRODUCTS,
  ADD_ROUTE_PLAN_URL,
  SAVE_CENTRAL_PICKUP_LOCATION,
  FETCH_CENTRAl_PICKUP
};
