import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Accordion, Form, Button, InputGroup } from "react-bootstrap";
import fetchFromAPI from "../../../../../../utils/helpers";
import { validPrice } from "../../../../../../utils/Regex";
import "../../styles/PaymentInfo.scss";

dayjs.extend(timezone);
dayjs.extend(utc);

const API = process.env.REACT_APP_BOAT_API;
const date = dayjs.utc().tz("America/Toronto").format("YYYY-MM-DD HH:mm");

function ChargeNewCard({
  setFormOpen,
  clickedBooking,
  addTransaction,
  insertCustomer,
  updateBookingCustomer,
  stripeCustomerId,
  setStripeCustomerId,
}) {
  const elements = useElements();
  const stripe = useStripe();

  const [processing, setProcessing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [amount, setAmount] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [clientSecret, setClientSecret] = useState(null);

  const getClientSecret = async (body) => {
    try {
      const res = await fetchFromAPI(API, "create-payment-intent", {
        body,
      });
      setClientSecret(res.clientSecret);
      setStripeCustomerId(res.customerId);
      if (body.customer !== res.customerId) {
        // creating the same customer with stripe id
        await insertCustomer({
          variables: {
            input: {
              id: res.customerId,
              contact_name: body.contactName,
              phone: body.phone,
              email: body.email,
              secondary_phone: body.secondaryPhone,
              secondary_email: body.secondaryEmail,
              locales: body.locales,
              country: body.country,
            },
          },
        });
        // updating the customer id in the booking
        await updateBookingCustomer({
          variables: {
            bookingId: clickedBooking.id,
            customerId: res.customerId,
          },
        });
      }
    } catch (err) {
      setErrorMsg(`${err.message}. Error code is: ${err.code}`);
    }
  };

  const addPaymentMethod = async (body) => {
    try {
      const res = await fetchFromAPI(API, "add-payment-method", {
        body,
      });
    } catch (err) {
      setErrorMsg(
        `Payment method was not added: ${err.message}. Error code is: ${err.code}`
      );
    }
  };

  const handleClick = () => {
    setShowForm(true);
    const body = {
      amount: (Number(amount) * 100).toFixed(0),
      customer: clickedBooking.customerId,
      bookingId: clickedBooking.id,
      phone: clickedBooking.customer.phone,
      email: clickedBooking.customer.email,
      contactName: clickedBooking.customer.contactName,
    };
    getClientSecret(body);
  };

  const handleSubmit = async () => {
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
      setup_future_usage: "off_session",
    });

    if (payload.error) {
      setErrorMsg(`Payment Failed: ${payload.error.message}`);
      setProcessing(false);
    } else {
      addPaymentMethod({
        paymentMethod: payload.paymentIntent.payment_method,
        customer: stripeCustomerId,
      });
      addTransaction({
        variables: {
          input: {
            stripe_id: payload.paymentIntent.id,
            date,
            value: amount,
            type: "charge",
            customer_id: stripeCustomerId,
            booking_id: clickedBooking.id,
            payment_method: payload.paymentIntent.payment_method,
          },
        },
      });
      setProcessing(false);
      setShowForm(false);
      setAmount("");
      setErrorMsg("");
      setClientSecret(null);
    }
  };

  const handleChange = (e) => {
    const { error } = e;
    if (error) setErrorMsg(error.message);
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "14px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const displayElements = () =>
    clientSecret ? (
      <div className="BookingModal__payment-info-form-newCard-elements">
        <p>{`Amount: ${amount}$`}</p>
        <CardNumberElement options={cardStyle} onChange={handleChange} />
        <div className="BookingModal__payment-info-form-newCard-elements-cvc">
          <CardExpiryElement options={cardStyle} onChange={handleChange} />
          <CardCvcElement options={cardStyle} onChange={handleChange} />
        </div>
        <Button
          id="new-card-charge-btn"
          type="button"
          disabled={processing}
          onClick={() => handleSubmit()}
        >
          {processing ? "Processing..." : "Charge"}
        </Button>
      </div>
    ) : (
      <div className="BookingModal__payment-info-form-newCard-loader">
        <div id="Bookings-information-loader"> </div>
        <p className="loading">Loading...</p>
      </div>
    );

  return (
    <div className="BookingModal__payment-info-form-newCard">
      <Accordion.Item eventKey="2" onClick={() => setFormOpen("new card")}>
        <Accordion.Header>Charge new card</Accordion.Header>
        <Accordion.Body>
          {errorMsg && (
            <div className="BookingModal__payment-info-form-newCard-elements-error">
              {errorMsg}
            </div>
          )}
          {!showForm ? (
            <div className="BookingModal__payment-info-form-newCard-amount">
              <p>Enter amount:</p>
              <div className="BookingModal__payment-info-form-newCard-amount-container">
                <InputGroup>
                  <InputGroup.Text>US$</InputGroup.Text>
                  <Form.Control
                    value={amount}
                    className={`${
                      amount !== "" &&
                      (Number(amount) > 0 ? "is-valid" : "is-invalid")
                    }`}
                    type="text"
                    placeholder="Amount"
                    onChange={(e) =>
                      validPrice.test(e.target.value) &&
                      setAmount(e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Amount cannot be 0 or less
                  </Form.Control.Feedback>
                </InputGroup>
                {amount && Number(amount) !== 0 && (
                  <i
                    onClick={() => handleClick()}
                    className="far fa-arrow-circle-right"
                  />
                )}
              </div>
            </div>
          ) : (
            displayElements()
          )}
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
}

export default ChargeNewCard;
