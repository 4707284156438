function createPlacesObject(url) {
  if (!url) {
    console.error('URL is undefined or null');
    return null;
  }

  const dirIndex = url.indexOf('/dir/');
  if (dirIndex !== -1) {
    const pathAfterDir = url.substring(dirIndex + 5);
    const coordinatesIndex = pathAfterDir.indexOf('@');

    if (coordinatesIndex !== -1) {
      const pathBeforeCoordinates = pathAfterDir.substring(0, coordinatesIndex);
      const pathArray = pathBeforeCoordinates.split('/');

      const placesObject = {
        startPlace: pathArray[0],
        endPlace: pathArray[pathArray.length - 1],
        intermediatePlaces: pathArray.slice(1, -1),
      };

      const placesArray = [
        placesObject.startPlace,
        ...placesObject.intermediatePlaces,
        placesObject.endPlace,
      ];

      const sanitizedPlacesArray = placesArray.map((place) => place.replace(/[+,]/g, (match) => (match === '+' ? ' ' : '')));
      const filteredPlacesArray = sanitizedPlacesArray.filter((place) => place.trim() !== '');

      let yourLocationCoordinates = null;

      const firstIndex = filteredPlacesArray[0];
      if (isValidCoordinatesFormat(firstIndex)) {
        const coordinates = firstIndex.split(',').map((coord) => coord.trim());
        const [latitude, longitude] = coordinates;

        yourLocationCoordinates = {
          Label: ['Your location'],
          Coordinates: { latitude, longitude },
        };
        filteredPlacesArray.shift();
      }

      const coordinatesPart = pathAfterDir.substring(coordinatesIndex);
      const coordinatesArray = extractCoordinates(coordinatesPart);
      const placesWithCoordinates = filteredPlacesArray.map((place, index) => {
        const [latitude, longitude] = coordinatesArray[index].split(',').map((coord) => coord.slice(0, 16));
        return {
          Label: [decodeURIComponent(place)],
          Coordinates: { latitude, longitude },
        };
      });
      if (yourLocationCoordinates) {
        placesWithCoordinates.unshift(yourLocationCoordinates);
      }

      return placesWithCoordinates;
    }
  }
  return null;
}

function isValidCoordinatesFormat(candidate) {
  const coordinatePattern = /^[-+]?\d*\.\d+,\s*[-+]?\d*\.\d+$/;
  return coordinatePattern.test(candidate);
}

function extractCoordinates(coordinatesPart) {
  const coordinatesPattern1 = /!1d([-+]?\d*\.\d+)!2d([-+]?\d*\.\d+)/g;
  const coordinatesPattern2 = /@([-+]?\d*\.\d+),([-+]?\d*\.\d+),([0-9.]+)z/g;

  const matches1 = coordinatesPart.match(coordinatesPattern1);
  const matches2 = coordinatesPart.match(coordinatesPattern2);
  let coordinatesArray = [];

  if (matches1) {
    coordinatesArray = coordinatesArray.concat(
      matches1.map((match) => {
        const [, latitude, longitude] = match.match(/!1d([-+]?\d*\.\d+)!2d([-+]?\d*\.\d+)/);
        return `${latitude},${longitude}`;
      }),
    );
  }

  if (matches2) {
    coordinatesArray = coordinatesArray.concat(
      matches2.map((match) => {
        const [, latitude, longitude] = match.match(/@([-+]?\d*\.\d+),([-+]?\d*\.\d+),([0-9.]+)z/);
        return `${latitude},${longitude}`;
      }),
    );
  }

  return coordinatesArray;
}

export { createPlacesObject, extractCoordinates, isValidCoordinatesFormat };
