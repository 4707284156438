import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Image, Offcanvas } from "react-bootstrap";
import { NavbarContext } from "../../context/navbar.context";
import "./Nav.scss";
import LogoutButton from "../Authorization/LogoutButton";
import SeeSightWhiteLogo from "../../assets/seesight.logo.white.png";
import usePermissions from "../../hooks/usePermissions";

const MemoizedSidebarLogo = React.memo(({ showNavbar }) => (
  <div className="sidebar-logo">
    <a
      href="#/"
      className={`d-flex align-items-center justify-content-center simple-text ${
        showNavbar ? "sidebar-logo-show" : "sidebar-logo-hide"
      }`}
    >
      <Image src={SeeSightWhiteLogo} className="w-25" />
      <h4 className="m-0 p-2 pt-4">BOAT APP</h4>
    </a>
  </div>
));

const MemoizedSidebarLinks = React.memo(
  ({ navbarLinks, doesHavePermission, toggleSideNav }) => (
    <ul className="sidebar-links-list sidebar-links-icons">
      {navbarLinks
        .filter(({ path }) => doesHavePermission(path))
        .map(({ path, icon, name }) => (
          <li className="sidebar-links" key={name}>
            <NavLink
              end={name === "Home"}
              to={path}
              className={({ isActive }) => (isActive ? "selectedItem" : "")}
              onClick={toggleSideNav}
            >
              <i className={icon} />
              {name}
            </NavLink>
          </li>
        ))}
    </ul>
  )
);

function Nav() {
  const { showNavbar, setShowNavbar } = useContext(NavbarContext);
  const { doesHavePermission } = usePermissions();

  const navbarLinks = [

    { path: "/", icon: "fas fa-chart-line", name: "Dashboard" },
    { path: "/bookings", icon: "fas fa-car", name: "Bookings" },
    { path: "/scheduling", icon: "fas fa-calendar-alt", name: "Scheduling" },
    { path: "/products", icon: "far fa-clipboard-user", name: "Products" },
    { path: "/reservations", icon: "fas fa-columns", name: "Reservations" },
    { path: "/vehicles", icon: "fas fa-truck-pickup", name: "Vehicles" },
    { path: "/guides", icon: "fas fa-hiking", name: "Guides" },
    { path: "/sessions", icon: "fas fa-calendar-alt", name: "Sessions" },
    { path: "/cities", icon: "fas fa-calendar-alt", name: "Cities" },
    {
      path: "/guide-availability",
      icon: "fas fa-calendar-alt",
      name: "Guide Availability",
    },
    { path: "/users", icon: "fas fa-user", name: "Users / Roles" },
    { path: "/news", icon: "fas fa-newspaper", name: "News" },
    { path: "/logs", icon: "fas fa-boxes", name: "Logs" },

  ];

  const toggleSideNav = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <Offcanvas
      className="offcanvas-wrapper"
      show={showNavbar}
      onHide={toggleSideNav}
      scroll
      backdrop
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Boat App</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="wrapper-main">
          <div
            className={`sidebar-main ${
              showNavbar ? "sidebar-main-show" : "sidebar-main-hide"
            }`}
          >
            <MemoizedSidebarLogo showNavbar={showNavbar} />
            <div className="sidebar-wrapper d-flex flex-column justify-content-between">
              <nav id="sidebar">
                <MemoizedSidebarLinks
                  navbarLinks={navbarLinks}
                  doesHavePermission={doesHavePermission}
                  toggleSideNav={toggleSideNav}
                />
              </nav>
              <p className="text-white text-muted">
                <small>
                  <center>Powered by See Sight Tours</center>
                </small>
              </p>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Nav;
