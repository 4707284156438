import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import EditGuideForm from './EditGuideForm';
import '../../Guides.scss';
import { EDIT_GUIDE, FETCH_GUIDES_BY_ID, INSERT_GUIDE_CITY } from '../../../../api/Guides';

function EditGuide() {
  const { guideId } = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState('');
  const [location, setLocation] = useState(null);

  const { data: guide, loading } = useQuery(FETCH_GUIDES_BY_ID, {
    variables: { id: guideId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    let locationHelper = guide?.cities?.find(({ id }) => id === guide?.guide_city[0]?.city_id || '');
    locationHelper = {
      value: locationHelper?.id,
      label: locationHelper?.name,
    };
    setLocation(locationHelper);
  }, [initialValues, guide]);

  const [editGuide] = useMutation(EDIT_GUIDE, {
    onCompleted: () => {
      toast.success('Guide updated.', {
        autoClose: 8000,
        position: 'bottom-right',
      });
      navigate('/guides');
    },
  });

  const [insertGuideCity] = useMutation(INSERT_GUIDE_CITY);

  useEffect(() => {
    if (guide) {
      setInitialValues({
        id: guide?.guide?.id || '',
        name: guide?.guide?.full_name || '',
        phone: guide?.guide
          ?.phone || '',
        email_personal: guide?.guide
          ?.email_personal || '',
        city_id: guide?.guide_city[0]?.city_id || '',
        airtable_id: guide?.guide?.airtable_id || '',
      });
    }
  }, [guide]);

  return (
    !loading && initialValues && location
      && (
        <div className="Vehicles__editVehicle">
          <EditGuideForm
            guide={location}
            initialValues={initialValues}
            cities={guide?.cities}
            editGuide={editGuide}
            insertGuideCity={insertGuideCity}
          />
        </div>
      ));
}

export default EditGuide;
