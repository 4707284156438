import React from 'react';
// import { Input } from 'reactstrap';

function AddNewTask({
  newTask,
  setNewTask,
  save,
  newSubtaskAdd,
  setActiveInput,
  setShowListHandler,
  showList,
  addNewSubtask,
  activeInput,
  unFocused,
}) {
  const status = ['Urgent', 'Need Attention', 'Pending', 'Done'];

  return (
    <div className="AddNewTask__Wrapper">
      <div className="row TaskDashBoard__tasks AddNewTask">
        <div className="taskIcon">
          <i className="far fa-circle" />
        </div>
        <div className="statusStyle__black" />
        <div className="taskName">
          {/* <Input
              type="text"
              value={newTask.name}
              placeHolder="Add New Task"
              onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            /> */}
        </div>
        <div className="d-flex newTask__select">
          {/* <Input
              type="select"
              name="city"
              value={newTask.city}
              onChange={(e) => setNewTask({ ...newTask, city: e.target.value })}
            >
              <option>All City</option>
              <option>Holy Trinity</option>
              <option>Niagara Parkway </option>
              <option>Cummington Square</option>
              <option>Niagara Falls ON</option>
            </Input> */}

          {/* <Input
              type="select"
              name="type"
              value={newTask.type}
              onChange={(e) => setNewTask({ ...newTask, type: e.target.value })}
            >
              <option>All Type</option>
              <option>Booking</option>
              <option>Reservation</option>
            </Input> */}
        </div>
        <div className="plusIcon">
          <i className="fal fa-plus" onClick={() => save()} />
        </div>
      </div>

      {newTask.subtasks.map((item, index) => {
        const textToDisplay = item?.title?.split('').includes('@') && item?.title?.split('@');
        return (
          <div className="d-flex subtask subtask_align">
            {/* {item.subStatus === 'Urgent' ? (
                <i className="fas fa-exclamation-circle urgent" />
              ) : item.subStatus === 'Done' ? (
                <i className="fas fa-check-circle done" />
              ) : item.subStatus === 'Pending' ? (
                <i className="fas fa-circle pending" />
              ) : item.subStatus === 'Need Attention' ? (
                <i className="fas fa-circle need_Attention" />
              ) : (
                <i className="fas fa-circle need_Attention" />
              )} */}
            {/* {activeInput === item.title ? (
                <Input
                  Type="text"
                  value={item.title}
                  onBlur={() => unFocused()}
                  onChange={(e) => newSubtaskAdd({ ...item, title: e.target.value }, index)}
                />
              ) : textToDisplay ? (
                <p onClick={() => setActiveInput(item.title)}>
                  {`${textToDisplay[0]}`}
                  <span
                    style={{ color: 'blue' }}
                  >
                    {`@${textToDisplay[1]}`}
                  </span>
                </p>
              ) : (
                <p onClick={() => setActiveInput(item.title)}>{item.title}</p>
              )} */}
            <i
              className="fal fa-ellipsis-v"
              onClick={() => setShowListHandler(item.title)}
            />

            {showList === item.title && (
              <div className="status_dropDown">
                {status.map((status) => (
                  <div
                    onClick={() => newSubtaskAdd({ ...item, subStatus: status }, index)}
                  >
                    {`${status}`}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}

      <div className="d-flex subtask subtask_align">
        <i className="fas fa-plus" />
        {/* <Input
            type="text"
            value="Add New subTasks"
            onClick={() => addNewSubtask()}
          /> */}
      </div>
    </div>
  );
}

export default AddNewTask;
