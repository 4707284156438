import * as Yup from "yup";

const VehicleSchema = Yup.object().shape({
  name: Yup.string()
  .required("Required"),

  timeZone: Yup.string(),
  locale: Yup.string().required("Required"),
  longitude: Yup.number().required("Required"),
  latitude: Yup.number().required("Required"),
  country: Yup.string().required("Required"),
});

export default VehicleSchema;
