import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function LabelInput({ field, index, handleCustomLabelChange }) {
  const initialLabel = field.label || '';
  const [label, setLabel] = useState(initialLabel.split(':').pop());

  return (
    <InputGroup className="mb-3">
      <InputGroup.Text id={`basic-addon-label-${field.id}`}>Label</InputGroup.Text>
      <Form.Control
        name={`Label${field.id}`}
        type="text"
        required
        value={label || ''}
        onChange={(event) => setLabel(event.target.value)}
        onBlur={(event) => handleCustomLabelChange(event, field, index, label)}
        placeholder="Enter Label"
        aria-label="Label"
        aria-describedby={`basic-addon-label-${field.id}`}
        key={field.id}
      />
    </InputGroup>
  );
}

export default LabelInput;
