const availabilityCheckAPI = process.env.REACT_APP_AVAILABILITY_CHECK;

async function fetchFromAvailabilityAPI(endpoint, opts) {
  const { method, body } = { body: null, ...opts };
  const res = await fetch(`${availabilityCheckAPI}/${endpoint}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.json();
}

export default fetchFromAvailabilityAPI;
