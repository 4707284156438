import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER_NAME = gql`
    mutation updateCustomerName( $id: String!, $val: String! ) {
        updateCustomer: update_boatnew_customers_by_pk(
            pk_columns: {id: $id},
            _set: {
                contact_name: $val
            },
        ) {
            id
        }
        updateBooking: update_boatnew_booking(
          where: { customer_id: { _eq: $id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export const UPDATE_CUSTOMER_EMAIL = gql`
    mutation updateCustomerEmail( $id: String!, $val: String! ) {
        updateCustomer: update_boatnew_customers_by_pk(
            pk_columns: {id: $id},
            _set: {
                email: $val
            },
        ) {
            id
        }
        updateBooking: update_boatnew_booking(
          where: { customer_id: { _eq: $id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export const UPDATE_CUSTOMER_PHONE = gql`
    mutation updateCustomerPhone( $id: String!, $val: String! ) {
        updateCustomer: update_boatnew_customers_by_pk(
            pk_columns: {id: $id},
            _set: {
                phone: $val
            },
        ) {
            id
        }
        updateBooking: update_boatnew_booking(
          where: { customer_id: { _eq: $id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export const UPDATE_CUSTOMER_SECONDARY_EMAIL = gql`
    mutation updateCustomerSecondaryEmail( $id: String!, $val: String! ) {
        updateCustomer: update_boatnew_customers_by_pk(
            pk_columns: {id: $id},
            _set: {
                secondary_email: $val
            },
        ) {
            id
        }
        updateBooking: update_boatnew_booking(
          where: { customer_id: { _eq: $id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export const UPDATE_CUSTOMER_SECONDARY_PHONE = gql`
    mutation updateCustomerSecondaryPhone( $id: String!, $val: String! ) {
        updateCustomer: update_boatnew_customers_by_pk(
            pk_columns: {id: $id},
            _set: {
                secondary_phone: $val
            },
        ) {
            id
        }
        updateBooking: update_boatnew_booking(
          where: { customer_id: { _eq: $id } },
          _set: { assigned_guide: "TBD" },
        ) {
          affected_rows
        }
    }
`;

export default UPDATE_CUSTOMER_NAME;
