/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import {
  Modal, Tab, Nav, TabContent, TabPane, Button, Image,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import {
  useNavigate, useParams, useLocation,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { FETCH_GUIDES_BY_ID, APPROVE_GUIDE } from '../../../api/Guides';
import '../Popup.scss';

const tabKeys = {
  'Profile Picture': 'Profile Picture',
  'ID Verification': 'driving_license',
  // Verify_doc: 'verify_doc',
  'Vehicle Insurance': 'insurance',
  'Verify Face': 'verify_face',
  'Vehicle Registration': 'ownership',
  'Vehicle Photos': 'photos',
};

function GuidePopup() {
  const navigate = useNavigate();
  const { guideId } = useParams();
  const { hash } = useLocation();

  const { data, loading, error } = useQuery(FETCH_GUIDES_BY_ID, {
    variables: { id: guideId },
    fetchPolicy: 'network-only',
  });
  const [activeTab, setActiveTab] = useState('Profile Picture');
  const [selectedImage, setSelectedImage] = useState('');
  const [approveGuide] = useMutation(APPROVE_GUIDE);

  // Initialize images state with empty arrays for all tabs
  const [images, setImages] = useState({
    Verify_doc: [],
    'Profile Picture': [],
    'Vehicle Insurance': [],
    'ID Verification': [],
    'Verify Face': [],
    'Vehicle Registration': [],
    'Vehicle Photos': [],
  });
  console.log('data', data);
  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (data?.guide) {
      try {
        const verifyProfileData = data.guide?.picture;
        const drivingLicenseData = data.guide?.driving_license;
        const verifyFaceData = data.guide?.verf_face;
        const insurance = data.guide?.vehicles[0]?.vehicle?.insurance;
        const ownership = data.guide?.vehicles[0]?.vehicle?.ownership;
        const photos = data.guide?.vehicles[0]?.vehicle?.photos;

        setImages((prevImages) => ({
          ...prevImages,
          'Profile Picture': verifyProfileData
            ? [verifyProfileData]
            : isValidJSON(verifyProfileData)
              ? JSON.parse(verifyProfileData)?.map((item) => item.url) || []
              : [],
          'ID Verification': drivingLicenseData && isValidJSON(drivingLicenseData)
            ? JSON.parse(drivingLicenseData)?.map((item) => item.url) || []
            : [],
          'Verify Face': verifyFaceData && isValidJSON(verifyFaceData)
            ? JSON.parse(verifyFaceData)?.map((item) => item.url) || []
            : [],
          'Vehicle Insurance': insurance && isValidJSON(insurance)
            ? JSON.parse(insurance)?.map((item) => item.url) || []
            : [],
          'Vehicle Registration': ownership && isValidJSON(ownership)
            ? JSON.parse(ownership)?.map((item) => item.url) || []
            : [],
          'Vehicle Photos': photos && isValidJSON(photos)
            ? JSON.parse(photos)?.map((item) => item.url) || []
            : [],
        }));
      } catch (error) {
        console.error('Error parsing data:', error);
        alert('Error parsing data. Please try again.');
      }
    }
  }, [data, loading]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleApproveClick = () => {
    if (data?.guide?.status === 'active') {
      alert('Guide is already approved');
    } else if (
      images['Profile Picture'].length === 0
        || images['ID Verification'].length === 0
        || images['Vehicle Insurance'].length === 0
        || images['Verify Face'].length === 0
        || images['Vehicle Registration'].length === 0
        || images['Vehicle Photos'].length === 0
    ) {
      setShowConfirmationModal(true);
    } else {
      approveGuide({
        variables: { guideId: data?.guide?.id },
        update: (cache, { data: { update_boatnew_guides_by_pk } }) => {
          const updatedGuide = {
            ...data?.guide,
            approved: true,
            status: 'active',
          };
          cache.writeFragment({
            id: `Guide:${data?.guide?.id}`,
            fragment: gql`
                fragment UpdatedGuide on Guide {
                  approved
                  status
                }
              `,
            data: updatedGuide,
          });
          toast.success(
            'Guide has been Approved Successfully',
            {
              autoClose: 8000,
              position: 'bottom-right',
            },
          );
          navigate('..');
        },
      });
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmApproval = () => {
    setShowConfirmationModal(false);
    approveGuide({
      variables: { guideId: data?.guide?.id },
      update: (cache, { data: { update_boatnew_guides_by_pk } }) => {
        const updatedGuide = {
          ...data?.guide,
          approved: true,
          status: 'active',
        };
        cache.writeFragment({
          id: `Guide:${data?.guide?.id}`,
          fragment: gql`
            fragment UpdatedGuide on Guide {
              approved
              status
            }
          `,
          data: updatedGuide,
        });
        toast.success(
          'Guide has been Approved Successfully',
          {
            autoClose: 8000,
            position: 'bottom-right',
          },
        );
        navigate('..');
      },
    });
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    window.open(imageUrl, '_blank');
  };

  return (
    <Modal
      backdrop
      size="lg"
      scrollable
      show={data?.guide}
      onHide={() => navigate('..')}
    >
      <ModalHeader closeButton>
        {`Guide Name: ${data?.guide?.full_name}`}
      </ModalHeader>

      <ModalBody className="BookingModal__body">
        <Tab.Container activeKey={activeTab} onSelect={(newTab) => newTab && setActiveTab(newTab)}>
          <Nav as="ul" justify variant="tabs">
            {Object.entries(tabKeys).map(([key, value]) => (
              <Nav.Item as="li" key={key}>
                <Nav.Link className="BookingModal__tab" eventKey={key}>
                  {key}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content>
            {Object.entries(tabKeys).map(([key, value]) => (
              <Tab.Pane key={key} eventKey={key}>
                <div className="image-column text-center">
                  <div className="image-column">
                    {images[key].length > 0 ? (
                      images[key].map((imageUrl, index) => (
                        <Image
                          key={index}
                          src={imageUrl}
                          onClick={() => handleImageClick(imageUrl)}
                          style={{ cursor: 'pointer' }}
                        />
                      ))
                    ) : (
                      <p>
                        No images available for
                        {' '}
                        {key}
                      </p>
                    )}
                  </div>
                </div>
              </Tab.Pane>
            ))}
            <div className="footer-container">
              <div className="btn-container">
                {data?.guide?.status !== 'active' && (
                <Button
                  className="model-btns"
                  style={{ backgroundColor: '#f24845', border: 'none' }}
                  onClick={handleApproveClick}
                >
                  Approve
                </Button>
                )}
              </div>
            </div>
          </Tab.Content>
          <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do you want to approve the guide without documents?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                Cancel
              </Button>
              <Button style={{ backgroundColor: '#f24845', border: 'none' }} onClick={handleConfirmApproval}>
                Approve without documents
              </Button>
            </Modal.Footer>
          </Modal>
        </Tab.Container>
      </ModalBody>

    </Modal>
  );
}

export default GuidePopup;
