import { useState, useEffect, useContext } from 'react';
import { UserDocContext } from '../components/AccessControl/AccessControl';

const usePermissions = (pageLocation) => {
  const signedUser = useContext(UserDocContext);

  const doesHavePermission = (_code) => {
    if (signedUser?.permissions === undefined) return false;
    if (_code === '/' || signedUser?.permissions === '"*"') return true;
    const permissions = JSON.parse(signedUser?.permissions || '"[]"') || [];
    return (permissions.filter((code) => code === _code).length === 1) || false;
  };

  return { doesHavePermission, signedUser };
};

export default usePermissions;
