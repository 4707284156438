const bookings = {
  REZDY: {
    reseller: 'N/A',
    id: 'N/A',
    resellerReference: 'N/A',
  },
  booking_vehicles: {
    group: 'A',
    guide: {
      name: 'TBD',
    },
    vehicle: {
      name: 'TBD',
    },
  },
};

export default bookings;
