import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { gql } from "@apollo/client";
import { SAVE_CENTRAL_PICKUP_LOCATION, FETCH_CENTRAl_PICKUP } from "../../api/Product";

const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};


const SearchLocationInput = ({ setSelectedLocation, productId }) => {
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState(null);
  const [savedLocation, setSavedLocation] = useState(null);
  const autoCompleteRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const { data: productData, loading: productLoading, error: productError } = useQuery(FETCH_CENTRAl_PICKUP, {
    variables: { id: productId },
    skip: !productId,
    fetchPolicy: "cache-and-network",
  });
  const [saveLocation] = useMutation(SAVE_CENTRAL_PICKUP_LOCATION);

  useEffect(() => {
    if (productData && productData.product) {
      setSavedLocation(productData.product?.central_pickup_location || "");
      setQuery(productData.product?.central_pickup_location || "");
    }
  }, [productData]);

  const handleScriptLoad = () => {
    const autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      types: [],
    });

    autoComplete.addListener("place_changed", async () => {
      const addressObject = autoComplete.getPlace();
      const query = addressObject?.formatted_address || "";
      setQuery(query);

      const latLng = {
        lat: addressObject?.geometry?.location?.lat(),
        lng: addressObject?.geometry?.location?.lng(),
      };

      if (latLng.lat && latLng.lng) {
        setSelectedLocation(latLng);
        setLocation({ location: query, coordinates: latLng });
      } else {
        toast.error("Invalid coordinates. Please select a valid location.");
      }
    });
  };

  const handleSave = () => {
    if (!location) {
      toast.error("Location is empty. Please select a location.");
      return;
    }

    if (!location.coordinates.lat || !location.coordinates.lng) {
      toast.error("Invalid location. Please choose a location from the suggestions.");
      return;
    }

    saveLocation({
      variables: {
        id: productId,
        location: location.location,
        pickupBounds: [location.location],
        // coordinates: location.coordinates,
      },
    })
      .then(() => {
        toast.success("Location saved successfully!");
      })
      .catch((error) => {
        console.error("Error saving location", error);
        toast.error("Error saving location. Please try again.");
      });
  };




  const handleInputFocus = () => {
    if (!scriptLoaded) {
      const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      loadScript(scriptUrl, handleScriptLoad);
      setScriptLoaded(true);
    }
  };

  if (productLoading) return <p>Loading...</p>;
  if (productError) return <p>Error: {productError.message}</p>;

  return (
    <div className="search-location-input">
      <label>Central Pickup</label>
      <input
        ref={autoCompleteRef}
        className="form-control"
        style={{ marginTop: '1rem' }}
        onChange={(event) => setQuery(event.target.value)}
        onFocus={handleInputFocus}
        placeholder="Search Places ..."
        value={query} // Set the value to display in the input field
      />
      <button
        onClick={handleSave}
        style={{
          backgroundColor: "#002b49",
          border: "0.1rem solid #002b49",
          color: "white",
          marginTop: "1rem",
          padding: "0.1rem 1.3rem",
          borderRadius: "5px",
        }}
      >
        Save Location
      </button>
      <ToastContainer />
    </div>
  );
};

export default SearchLocationInput;
