/* eslint-disable */
import '../Products.scss';

function Name({ name, product, productId }) {

  return (
    <div className="name_cont .name-column">
      <div className={product.active ? 'name_active' : 'name_inactive'} />
      <div className="name_text_cont">
        <div>{name}</div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ marginTop: '5px', fontSize: '15px', color: 'rgb(255, 98, 98)' }}>{productId}</div>
        </div>
      </div>
    </div>
  );
}
export default Name;
