import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ShowCities() {
  const navigate = useNavigate();
  return (
    <div className="Vehicles__header">
      <button
        type="button"
        onClick={() => {
          navigate("/cities/create");
        }}
      >
        {" "}
        Create New
      </button>
    </div>
  );
}

export default ShowCities;
