import React, { useState, useEffect } from 'react';
import Column2 from './Column2';
import Column1 from './Column1';
import './ReservationsTable.scss';

function ReservationsTable({
  reservations,
  formik,
  chosenBookings,
  checked,
  setChecked,
  setChosenBookings,
  setBookingReservations,
  bookingReservations,
  setShowEmailTemplate,
  date,
  showEmailTemplate,
}) {
  const [activeReservation, setActiveReservation] = useState('');
  const [sumChecked, setSumChecked] = useState('');
  useEffect(() => {
    if (checked.length === 0 && activeReservation !== '') {
      setActiveReservation('');
    }
  }, [checked, activeReservation]);

  return (
    <table className="Reservations__table">
      <tbody>
        {reservations?.reservations?.map((reservation, i) => {
          if (reservation.productReservations.filter(((product) => (
            product?.product?.bookings.length > 0))).length === 0) return null;
          return (
            <tr key={reservation.id}>
              <Column1
                reservations={reservations}
                setSumChecked={setSumChecked}
                activeReservation={activeReservation}
                reservation={reservation}
                sumChecked={sumChecked}
                chosenBookings={chosenBookings}
                setShowEmailTemplate={setShowEmailTemplate}
                date={date}
                showEmailTemplate={showEmailTemplate}
                formik={formik}
              />
              <Column2
                setChecked={setChecked}
                setChosenBookings={setChosenBookings}
                chosenBookings={chosenBookings}
                setBookingReservations={setBookingReservations}
                bookingReservations={bookingReservations}
                setSumChecked={setSumChecked}
                activeReservation={activeReservation}
                setActiveReservation={setActiveReservation}
                checked={checked}
                reservation={reservation}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ReservationsTable;
