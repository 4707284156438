import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@apollo/client";
import CityHeader from "./CitiesHeader";
import GridComponent from "../../../components/GridComponent/GridComponent";
import {
  // ACTIVE_VEHICLE,
  // DEACTIVE_VEHICLE,
  UPDATE_CITY,
  MOVE_TO_TRASH,
} from "../../../api/city";
import Confirm from "./confirm";

const width = Math.ceil(window.outerWidth / 7);

function EditHandler({ id, status = "" }) {
  const { refetch } = useOutletContext();
  const [modal, setModal] = useState("");
  // const [activeVehicle] = useMutation(ACTIVE_VEHICLE);
  // const [deactiveVehicle] = useMutation(DEACTIVE_VEHICLE);
  const [moveVehicleToTrash] = useMutation(MOVE_TO_TRASH);

  const handleStatusChange = async () => {
    if (status === "active") {
      await activeVehicle({
        variables: {
          vehicleId: id,
          status: "deactive",
          timestamp: moment().format(),
        },
      });
    } else {
      await deactiveVehicle({
        variables: {
          vehicleId: id,
          status: "active",
        },
      });
    }
  };

  const moveToTrash = async () => {
    await moveVehicleToTrash({
      variables: {
        cityId: id,
      },
    });
    await refetch(); // Refetch after moving to trash
  };

  const handleTrigger = async () => {
    if (modal === "status") {
    } else if (modal === "trash") {
      await moveToTrash();
    }
  };

  const handleAction = () => {
    switch (modal) {
      case "status":
        return status;
      case "trash":
        return "trash";
      default:
        return "";
    }
  };

  return id ? (
    <>
      <Link className="Vehicles__editBtn" to={`/cities/edit/${id}`}>
        Edit
      </Link>
      {/* <Link
        className="Vehicles__editBtn ms-3"
        to="/vehicles"
        onClick={() => setModal("status")}
      >
        {status === "active" ? "Deactivate" : "Activate"}
      </Link> */}
      <Link
        className="Vehicles__editBtn ms-3"
        to="/cities"
        onClick={() => setModal("trash")}
      >
        Trash
      </Link>
      <Confirm
        trigger={handleTrigger}
        visibility={modal !== ""}
        setVisibility={(v = false) => setModal(v === false ? "" : v)}
        action={handleAction()}
      />
    </>
  ) : null;
}

function StatusRenderer({ status }) {
  return (
    <p className="text-capitalize">
      {status}
      <span
        className={`ms-1 badge badge-secondary p-1 pb-0 rounded-circle ${
          status === "active"
            ? "bg-success text-success"
            : "bg-danger text-danger"
        }`}
      >
        o
      </span>
    </p>
  );
}

const columnDefs = [
  {
    cellRenderer: (params) => (
      <EditHandler id={params?.data?.id} status={params?.data?.status} />
    ),
    filter: () => null,
    resizable: true,
    width: 230,
  },

  {
    field: "id",
    filter: "agTextColumnFilter",
    resizable: true,
    width: 310,
  },
  {
    field: "City Name",
    valueGetter: (params) => params?.data?.name,

    keyCreator: (params) => params.value,
    filter: "agTextColumnFilter",
    resizable: true,
  },
  {
    field: "Coordinates",
    valueGetter: (params) => {
      const lat = params?.data?.coordinates?.lat;
      const lng = params?.data?.coordinates?.lng;
      return lat && lng ? `${lat}, ${lng}` : "N/A";
    },
    filter: "agTextColumnFilter",
    resizable: true,
    width: 310,
  },
  {
    field: "Time Zones",
    filter: "agTextColumnFilter",
    valueGetter: (params) => params?.data?.time_zone,
    resizable: true,
  },
  {
    field: "Locale",
    filter: "agTextColumnFilter",
    eld: "City Name",
    valueGetter: (params) => params?.data?.locale,
    resizable: true,
  },
  {
    field: "Country",
    filter: "agTextColumnFilter",
    valueGetter: (params) => params?.data?.country,
    resizable: true,
  },
  {
    field: "City status",
    filter: "agTextColumnFilter",
    cellRenderer: (params) => <StatusRenderer status={"active"} />,
    resizable: true,
  },
];

function DisplayCities() {
  const { filteredCities, refetch } = useOutletContext();
  return filteredCities ? (
    <GridComponent
      gridTitle="Cities"
      data={filteredCities}
      columnDefs={columnDefs}
      viewOptions={false}
    >
      <CityHeader />
    </GridComponent>
  ) : null;
}

export default DisplayCities;
