import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { FETCH_ATTRACTION_BY_CITY } from '../../../../api/Product';

function Page5({
  formik,
  selectedAttractions,
  setSelectedAttractions,
}) {
  const { values, errors, handleChange } = formik;

  const getCitiesId = () => {
    const citiesId = [];
    values.locations?.map((city) => {
      citiesId.push(city.city_id);
    });
    return citiesId;
  };

  const { data: attractions } = useQuery(FETCH_ATTRACTION_BY_CITY, {
    variables: { citiesId: getCitiesId() },
  });

  const options = attractions?.attractions?.map((attraction) => ({
    value: attraction.id,
    label: attraction.name,
  }));

  const handleAttractionsChange = (value) => {
    const attractionsArr = [];
    value?.map((attraction) => {
      attractionsArr.push({ attraction_id: attraction.value, product_id: values.id });
    });
    setSelectedAttractions(value);
    formik.setFieldValue('attractions', attractionsArr);
  };

  return (
    <div className="EditForm__form-page5">
      <Form.Group>
        <Form.Label>Attractions</Form.Label>
        <Select
          closeMenuOnSelect
          isMulti
          options={options}
          defaultValue={selectedAttractions}
          onChange={(value) => handleAttractionsChange(value)}
        />
        {errors.attractions ? <div className="EditForm__error">{errors.attractions}</div> : null}
      </Form.Group>
    </div>
  );
}

export default Page5;
