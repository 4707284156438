import { useState, useEffect } from 'react';

export default function useRecentUpdates() {
  const [recentUpdates, setRecentUpdates] = useState([]);

  function registerUpdate(id, column) {
    const currentTime = new Date().getTime();
    setRecentUpdates([...recentUpdates, { id, column, time: currentTime }]);
  }

  function isRecentlyUpdated(id, column = null) {
    let updatesToCheck = recentUpdates.filter((update) => update.id === id);
    if (column) {
      updatesToCheck = updatesToCheck.filter((update) => update.column === column);
    }
    const latestUpdate = updatesToCheck.reduce(
      (latest, update) => (latest.time > update.time ? latest : update),
      { time: 0 },
    );
    const elapsedTime = new Date().getTime() - latestUpdate.time;
    return elapsedTime <= 60000; // 1 minute = 60000 milliseconds
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      // filter out objects that are older than 1 minute
      const filteredUpdates = recentUpdates.filter((update) => {
        const elapsedTime = new Date().getTime() - update.time;
        return elapsedTime <= 60000; // 1 minute = 60000 milliseconds
      });
      setRecentUpdates(filteredUpdates);
    }, 60000);

    return () => {
      clearTimeout(timeout);
    };
  }, [recentUpdates]);

  return { recentUpdates, registerUpdate, isRecentlyUpdated };
}
