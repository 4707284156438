import { gql } from '@apollo/client';

export const UPDATE_PICKUP_TIME = gql`
    mutation updatePickupTime( $id: String!, $val: String! ) {
        updateBooking: update_boatnew_booking_by_pk(
            pk_columns: {id: $id},
            _set: {
                pickup_time: $val
            },
        ) {
            id
        }
    }
`;

export const UPDATE_PICKUP_LOCATION = gql`
    mutation updatePickupLocation( $id: String!, $val: String! ) {
        updateBooking: update_boatnew_booking_by_pk(
            pk_columns: {id: $id},
            _set: {
                pickup_location: $val
            },
        ) {
            id
        }
    }
`;

export default UPDATE_PICKUP_TIME;
