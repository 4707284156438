import React, { useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import { formatCutoffTime } from "../../utils/ProductUtils";
import Page1 from "../ProductWizardPages/Page1";
import Page2 from "../ProductWizardPages/Page2";
import Page3 from "../ProductWizardPages/Page3";
import Page4 from "../ProductWizardPages/Page4";
import Page5 from "../ProductWizardPages/Page5";
import Page6 from "../ProductWizardPages/Page6";
import Page7 from "../ProductWizardPages/Page7";
import Page8 from "../ProductWizardPages/Page8";

function EditForm({
  page,
  setPage,
  validate,
  product,
  initialValues,
  setInitialValues,
  cities,
  addAvailability,
  updateProduct,
  updateCitiesProduct,
  updateAttractions,
  updateProductPrice,
  updateProductUnits,
  availabilitiesSeries,
  units,
  availabilitiesList,
  getSeries,
  addRezdyProduct,
  deleteRezdyProduct,
}) {
  const getSelectedValues = () => {
    const values = [];
    product?.citiesProducts?.map((citiesProduc) => {
      values.push({
        value: citiesProduc.city.id,
        label: citiesProduc.city.name,
      });
    });
    return values;
  };

  const [selectedValues, setSelectedValues] = useState(getSelectedValues());
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const getSelectedUnits = () => {
    const unitsValues = [];
    const getLabel = (unitID) => {
      const unitLabel = units?.units?.filter((unit) => unit.id === unitID);
      return unitLabel[0].label;
    };
    product?.productUnits?.map((unit) => {
      unitsValues.push({
        value: unit.unit_id,
        label: getLabel(unit.unit_id),
      });
    });
    return unitsValues;
  };

  const [selectedUnits, setSelectedUnits] = useState(
    units && getSelectedUnits()
  );

  const getSelectedAttractions = () => {
    const attractionsValues = [];
    product?.productsAttractions?.map((attraction) => {
      attractionsValues.push({
        value: attraction.attraction.id,
        label: attraction.attraction.name,
      });
    });
    return attractionsValues;
  };

  const [selectedAttractions, setSelectedAttractions] = useState(getSelectedAttractions());
  const changePage = (e) => {
    if (!formik.dirty) {
      setPage(Number(e.target.id));
    } else {
      alert('Submit your changes before moving to a different page');
    }
  };

  const getUpdatedValues = (values, initialValuesFormik) => {
    const result = {};
    Object.entries(values).map(([key, value]) => {
      if (initialValuesFormik[key] !== value) {
        if (key === "code" || key === "name") {
          result[key] = value.trim();
        } else if (key === "booking_cutoff") {
          result[key] = formatCutoffTime(value, values.bookingCutoffUnits);
        } else if (key === "cancel_cutoff") {
          result[key] = formatCutoffTime(value, values.cancelCutoffUnits);
        } else if (key === "advertised_price") {
          result[key] = value.toString();
        } else if (key === "paxMin") {
          result.quantity = `[${value},${values.paxMax}]`;
        } else if (key === "paxMax") {
          result.quantity = `[${values.paxMin},${value}]`;
        } else if (key === "pickup_bounds") {
          result[key] = value;
        } else if (
          key === "locations" ||
          key === "product_units" ||
          key === "cancelCutoffUnits" ||
          key === "bookingCutoffUnits"
        ) {
          return null;
        } else {
          result[key] = value;
        }
      }
    });
    return result;
  };

  const formik = useFormik({
    initialValues,
    validate: validate[page],
    validateOnChange: false,
    onSubmit: (values) => {
      if (page === 1 || page === 2 || page === 3) {
        updateProduct({
          variables: {
            id: values.id,
            input: getUpdatedValues(values, formik.initialValues),
          },
        })
          .then((response) => {
            console.log("updateProduct response:", response); // Add logging here
          })
          .catch((e) => {
            console.log(e.message);
          });
        if (values.locations !== formik.initialValues.locations) {
          updateCitiesProduct({
            variables: {
              id: values.id,
              objects: values.locations,
            },
          }).catch((e) => {
            console.log(e);
          });
        }
        if (
          !_.isEqual(values.product_units, formik.initialValues.product_units)
        ) {
          updateProductUnits({
            variables: {
              id: values.id,
              objects: values.product_units,
            },
          }).catch((e) => {
            alert(e);
            console.log(e);
          });
        }
        setPage(page + 1);
      } else if (page === 4) {
        addAvailability({
          variables: {
            objects: [
              {
                start_time: values.start_time,
                start_date: values.start_date,
                end_date: values.end_date,
                juggle: values.juggle,
                product_id: values.id,
                prices: values.prices,
                total_seats: values.total_seats,
                availabilities_series: {
                  data: availabilitiesSeries(
                    values.start_date,
                    values.end_date,
                    values.start_time,
                    values.duration,
                    values.shared_id
                  ),
                },
                availabilities_vehicles: {
                  data: values.vehicles,
                },
              },
            ],
          },
        }).catch((e) => {
          console.log(e);
          alert(e);
        });
        setPage(page + 1);
      } else if (page === 5) {
        updateAttractions({
          variables: {
            id: values.id,
            objects: values.attractions,
          },
        }).catch((e) => {
          alert(e);
          console.log(e);
        });
      } else if (page === 6) {
        if (!_.isEqual(values?.rezdy_id, formik.initialValues.rezdy_id)) {
          if (_.isEmpty(values?.rezdy_id)) {
            console.log("🚀 ~ file: EditForm.jsx ~ line 202");
            deleteRezdyProduct({
              variables: {
                id: formik.initialValues.rezdy_id,
              },
            });
          } else {
            addRezdyProduct({
              variables: {
                objects: [
                  {
                    rezdy_id: values.rezdy_id,
                    boat_code: values.code,
                  },
                ],
              },
            }).catch((e) => {
              alert(e);
              console.log(e);
            });
          }
        }
      } else if (page === 7) {
        const {
          id,
          prices,
          prices: {
            advertised_price: advertisedPrice,
            product_prices: productPrices,
            quantity_max: quantityMax,
            quantity_min: quantityMin,
          },
        } = values;
        const priceArr = Object.fromEntries(
          Object.entries(prices.priceArr || { adults: '', children: '', infants: '' }).map(
            ([category, price]) => {
              if (category.startsWith('custom:')) {
                category = category.replace(/^custom:\d+:/, ''); // Remove the custom:number: prefix
              }
              return price !== '' && [category, price]; // Exclude entries with empty values
            },
          ).filter(Boolean), // Remove any entries that are falsey (i.e., null or undefined)
        );
        const newPriceArr = {
          adults: priceArr.adult,
          children: priceArr.child,
          infants: priceArr.infant,
        };

        updateProductPrice({
          variables: {
            productId: id,
            priceArr,
            newPriceArr,
            advertisedPriceInt: parseInt(advertisedPrice, 10) || 0,
            advertisedPrice,
            quantity: JSON.stringify([quantityMin, quantityMax]),
          },
        })
          .then((e) => alert('Price Updated successfully'))
          .catch((e) => {
            alert(e);
            console.log(e);
          });
      }
      setInitialValues(values);
    },
    enableReinitialize: true,
  });

  return (
    <div className="EditForm">
      <div className="EditForm__product-name">{formik.values.name}</div>
      <div className="EditForm__container">
        <div className="EditForm__navigation">
          <div className="EditForm__navigation-btns">
            <button
              type="button"
              id="1"
              className={`EditForm__navigation-btns-${
                page === 1 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Product
            </button>
            <button
              type="button"
              id="2"
              className={`EditForm__navigation-btns-${
                page === 2 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Product Info
            </button>
            <button
              type="button"
              id="3"
              className={`EditForm__navigation-btns-${
                page === 3 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Location
            </button>
            <button
              type="button"
              id="4"
              className={`EditForm__navigation-btns-${
                page === 4 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Availability
            </button>
            <button
              type="button"
              id="7"
              className={`EditForm__navigation-btns-${
                page === 7 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Pricing
            </button>
            <button
              type="button"
              id="8"
              className={`EditForm__navigation-btns-${
                page === 8 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Itinerary
            </button>
            <button
              type="button"
              id="5"
              disabled
              className={`EditForm__navigation-btns-${
                page === 5 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              Attractions
            </button>
            <button
              type="button"
              id="6"
              className={`EditForm__navigation-btns-${
                page === 6 ? "active" : "inactive"
              }`}
              onClick={(e) => changePage(e)}
            >
              OTA
            </button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="EditForm__form">
          {page === 1 && (
            <Page1
              formik={formik}
              cities={cities}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          )}
          {page === 2 && (
            <Page2
              formik={formik}
              units={units?.units}
              selectedUnits={selectedUnits}
              setSelectedUnits={setSelectedUnits}
            />
          )}
          {page === 3 && <Page3 formik={formik} cities={cities} />}
          {page === 4 && (
            <Page4
              formik={formik}
              availabilities={product.availabilities}
              selectedUnits={selectedUnits}
              units={units?.units}
              availabilitiesList={availabilitiesList}
              getSeries={getSeries}
            />
          )}
          {page === 5 && (
            <Page5
              formik={formik}
              selectedAttractions={selectedAttractions}
              setSelectedAttractions={setSelectedAttractions}
            />
          )}
          {page === 6 && <Page6 formik={formik} />}
          {page === 7 && <Page7 formik={formik} />}
          {page === 8 && <Page8 formik={formik} />}
          {page !== 8 && (
            <div className="EditForm__buttons">
              <button
                type="button"
                className="EditForm__buttons-cancel"
                id="cancel"
                onClick={() => window.location.reload(false)}
              >
                Cancel
              </button>

              <button
                className="EditForm__buttons-submit"
                type="submit"
                id="submit"
                disabled={!formik.dirty}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default EditForm;
