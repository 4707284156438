import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LogsHeader() {
  const navigate = useNavigate();
  return (
    <div className="Vehicles__header" />
  );
}

export default LogsHeader;
