import * as Yup from 'yup';

const GuideSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[a-zA-Z ]+$/g,
      'Name can only contain alphabets.',
    )
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  email_personal: Yup.string().email('Must be a valid email')
    .required('Required'),
  phone: Yup.string()
    .matches(
      /^[0-9 +]+$/g,
      'Only and numbers, space and special characters (+) are allowed',
    )
    .min(5, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Required'),
  city_id: Yup.string()
    .required('Required'),
  airtable_id: Yup.string()
    .matches(
      /^[a-zA-Z0-9 ]+$/g,
      'Airtable id can only contain alphabets and numbers',
    )
    .max(50, 'Too Long!'),
});

export default GuideSchema;
