import React from 'react';
import '../Nav/Nav.scss';

function LogoutButton() {
  return (
    <button type="button">
      <i className="fas fa-sign-out-alt" />
      Logout
    </button>
    // <a onClick={() => logout()}>
    //   {' '}
    //   Logout
    // </a>
  );
}

export default LogoutButton;
