import { gql } from '@apollo/client';
import { FRAGMENT_BOOKING } from './fragments';

const FETCH_AVAILABILITIES_BY_PRODUCT = gql`
  query FETCH_AVAILABILITIES_BY_PRODUCT($id: String!) {
    availabilities: boatnew_availabilities(
      where: { option: { boatnew_product: { id: { _eq: $id } } } }
    ) {
      id
      name
      pax
      date
      exclusion
      repetition
      cityId: city_id
      juggle
      resources {
        type
        seats
        resources
      }
    }
  }
`;

// Name:         ALL_AVAILABILITIES_CITY_ID
// Author:       Kyle Horsley
// Date:         June 16, 2021
// Description:  This will get the availabilities and necessary info from Availabilities table.
const ALL_AVAILABILITIES_CITY_ID = gql`
  query ALL_AVAILABILITIES_CITY_ID($cityId: String!) {
    availabilities: boatnew_availabilities(where: { city_id: { _eq: $cityId } }) {
      id
      name
      juggle
      pax
      repetition
      exclusion
      date
      resources {
        id
        resources
        seats
        type
      }
      option {
        id
        startTime: start_time
        products: boatnew_product {
          id
          city
          name
          bookingTime: booking_cut_off_time
          duration
        }
      }
    }
  }
`;

// Name:         ALL_SESSION_DETAILS
// Author:       Kyle Horsley
// Date:         June 16, 2021
// Description:  This will retrieve all of the session information and its details..
const ALL_SESSION_DETAILS = gql`
  query ALL_SESSION_DETAILS {
    session: boatnew_sessions {
      availabilityId: availability_id
      date
      id
      pax
      resources {
        availability_id
        created_at
        id
        resources
        seats
        type
      }
      availability {
        name
        date
      }
    }
  }
`;

// Name:         QUERY_AVAILABLE_TOURS_BY_DATE
// Author:       Kyle Horsley
// Date:         June 16, 2021
// Description:  Query all available dates by availability id and date..
const QUERY_AVAILABLE_TOURS_BY_DATE = gql`
  query QUERY_AVAILABLE_TOURS_BY_DATE($availabilityId: String!, $date: date!) {
    boatnew_sessions(
      where: { availability_id: { _eq: $availabilityId }, date: { _eq: $date } }
    ) {
      pax
      sessionID: id
      date
      created_at
      sessionResources: resources {
        resources
        seats
      }
      availability {
        date
        name
        availabilityID: id
        bookings: boatnew_bookings {
          products: boatnew_product {
            name
            price
          }
        }
      }
    }
  }
`;

// Name:         UPDATE_AVAILABILITY
// Author:       Kyle Horsley
// Date:         June 16, 2021
// Description:  This one may need tweaked. Was not sure 100% the exact specs.
const UPDATE_AVAILABILITY = gql`
  mutation UPDATE_AVAILABILITY(
    $id: String!
    $name: String!
    $pax: numrange
    $repetition: Boolean
    $date: daterange!
    $exclusion: jsonb
    $juggle: Boolean
    $city: String!
    $option: String
  ) {
    availability: update_boatnew_availabilities_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        pax: $pax
        repetition: $repetition
        date: $date
        juggle: $juggle
        city_id: $city
        exclusion: $exclusion
        option_id: $option
      }
    ) {
      id
    }
  }
`;

// Used when creating a session
// date is YYYY-MM-DD format
// numrange is "[min,max]" format
// availabilityId is the corresponding id that the session is being created from
// resources is an array of resources that were in the original availability id with this format
// [
// {"type": String!, "resources": Int!, "seats": Int!, "availability_id": String!},
// {"type": String!, "resources": Int!, "seats": Int!, "availability_id": String!}, ...
// ]
// Sample Test Case
// {
//   "date": "2021-06-16", "pax": "[4,8]",
//    "availabilityId": "484d2164-ba13-4c90-85cb-6a75ad523dad",
//    "resources": [
//      {
//        "type": "Van",
//        "resources": 2,
//        "seats": 6,
//        "availability_id": "484d2164-ba13-4c90-85cb-6a75ad523dad"
//      }
//    ]
//  }

const INSERT_SESSION = gql`
  mutation INSERT_SESSION(
    $date: date
    $pax: numrange
    $availabilityId: String!
    $resources: [boatnew_resources_insert_input!]!
  ) {
    session: insert_boatnew_sessions_one(
      object: {
        date: $date
        pax: $pax
        availability_id: $availabilityId
        resources: { data: $resources }
      }
    ) {
      id
      pax
      date
      availabilityId: availability_id
      resources {
        id
        type
        resources
        seats
      }
    }
  }
`;

// Query for allowing users to edit the session
// Users can edit passengers and resources only with this query
const EDIT_SESSION = gql`
  mutation EDIT_SESSION(
    $id: String!
    $pax: numrange
    $resources: [boatnew_resources_insert_input!]!
  ) {
    session: update_boatnew_sessions_by_pk(
      pk_columns: { id: $id }
      _set: { pax: $pax, resources: $resources }
    ) {
      id
      pax
      resources {
        id
        type
        resources
        seats
      }
    }
  }
`;

const DELETE_SESSION = gql`
  mutation DELETE_SESSION($id: String!) {
    deleteSession: delete_boatnew_availabilities_series(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

// This query fetches all the details for the popup
// gets the option (start time) related to the availability and the product name and price object
const FETCH_AVAILABILITIES_BY_ID = gql`
  query FETCH_AVAILABILITIES_BY_ID($id: String!) {
    availabilities: boatnew_availabilities(where: { id: { _eq: $id } }) {
      id
      name
      pax
      date
      exclusion
      repetition
      cityId: city_id
      juggle
      resources {
        type
        seats
        resources
      }
      option {
        startTime: start_time
        product: boatnew_product {
          name
          price
        }
      }
    }
  }
`;
// This query fetches all the availablities for the calendar
const FETCH_ALL_AVAILABILITIES = gql`
  query FETCH_ALL_AVAILABILITIES {
    availability: boatnew_availabilities {
      id
      name
      date
      exclusion
      repetition
      pax
      juggle
    }
  }
`;
const FETCH_SPECIFIC_AVAILABILITY_DATA = gql`
query FetchAvailability($availabilitySeriesId: String!) {
  boatnew_availabilities_series_by_pk(id: $availabilitySeriesId) {
    end_time
    bookings {
    ...FragmentBooking
    }
}
}
${FRAGMENT_BOOKING}
`;

export {
  FETCH_AVAILABILITIES_BY_PRODUCT,
  EDIT_SESSION,
  DELETE_SESSION,
  FETCH_AVAILABILITIES_BY_ID,
  FETCH_ALL_AVAILABILITIES,
  ALL_AVAILABILITIES_CITY_ID,
  INSERT_SESSION,
  ALL_SESSION_DETAILS,
  QUERY_AVAILABLE_TOURS_BY_DATE,
  UPDATE_AVAILABILITY,
  FETCH_SPECIFIC_AVAILABILITY_DATA,
};
