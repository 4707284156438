import React, { useEffect, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Autocomplete, TextField } from '@mui/material';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import Draggable from '../Draggable/Draggable';
import './VehiclePane.scss';

function VehiclePane({
  vehicle, getVehicleCapacity, items, guides, vehiclesGuides, setVehiclesGuides, handleModalOpen,
  screenSize,
}) {
  const [collapse, setCollapse] = useState(true);
  const [guide, setGuide] = useState(null);
  const [guideId, setGuideId] = useState('');

  useEffect(() => {
    console.log(items);
    if (items[vehicle.id] && items[vehicle.id].length > 0) {
      const guideid = items[vehicle?.id][0].bookingVehicles?.guideId;
      console.log('update triggered');
      setGuideId(guideid);
    }
  }, []);

  useEffect(() => {
    console.log(guideId);
  }, [guideId]);

  useEffect(() => {
    if (guideId !== '' && typeof vehiclesGuides === 'object') {
      setGuide(
        guides?.filter(({ id }) => id === guideId)?.pop(),
      );

      let newVehiclesGuides = vehiclesGuides;
      const vehicleIndex = vehiclesGuides
        .filter((e) => e !== null)
        .find((e) => e.vehicleId === vehicle?.id);
      if (typeof vehicleIndex === 'undefined') {
        newVehiclesGuides.push({
          vehicleId: vehicle?.id,
          guideId,
        });
      } else {
        newVehiclesGuides = newVehiclesGuides.filter((e) => e !== null).map((e) => {
          if (e.vehicleId === vehicle?.id) {
            return {
              vehicleId: vehicle?.id,
              guideId,
            };
          }
          return e;
        });
      }
      setVehiclesGuides([...newVehiclesGuides.filter((e) => e !== null)]);
    }
  }, [guideId]);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <div className={`Scheduling__droppables-droppable-vehicleInfo
        ${collapse && screenSize === 'lg' ? ' Scheduling__droppables-droppable-vehicleInfoMinimize' : ''}
        ${vehicle?.status === 'deactive' ? ' Scheduling__droppables-droppable-vehicleDeactive' : ''}
      `}
      >

        {guides && (
        <Autocomplete
          disablePortal
          id="guideId"
          name="guideId"
          options={guides}
          className={`VehiclePanes__Pane-autoComplete ${collapse ? '' : 'w-100'}`}
          value={guide}
          disabled={vehicle?.status === 'deactive'}
          getOptionLabel={(option) => option.fullName}
          onChange={(e, { id }) => setGuideId(id)}
          filterOptions={(options, state) => {
            const { inputValue, getOptionLabel } = state;
            // console.log(inputValue);
            // const filteredOptions = [];

            const filteredOptions = options.filter(
              (option) => option.fullName.toLowerCase().includes(inputValue.toLowerCase()),
            );

            // Return only the first 5 options from the filteredOptions array
            return filteredOptions.slice(0, 5);

            // if (inputValue !== '') {
            //   console.log(1);
            //   const MAX_RESULTS = 2;
            //   for (let index = 0, results = 0; index < options.length; index += 1) {
            //     if (getOptionLabel(options[index]).toLocaleLowerCase().includes(inputValue)) {
            //       filteredOptions.push(options[index]);
            //       results += 1;
            //     }
            //     if (results === MAX_RESULTS || filteredOptions.length === MAX_RESULTS) break;
            //   }
            //   console.log([...filteredOptions]);
            //   return [...filteredOptions];
            // }
            // if (inputValue === '') {
            //   console.log(2);
            //   return options;
            // }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Guide"
              className="m-0 p-0"
              style={{
                fontSize: 8,
              }}
            />
          )}
        />
        )}

        <div className="d-flex" style={{ height: 30, fontSize: '.8rem' }}>
          <p className="me-1">
            {`${vehicle?.name} -`}
          </p>
          {getVehicleCapacity(vehicle)}
        </div>

        <div className={collapse ? 'w-25' : 'd-none'} />
      </div>
      <div className={`Scheduling__droppables-droppable-${collapse && screenSize === 'lg' ? 'bookingsMinimize' : 'bookings'} 
      ${screenSize === 'md' ? 'w-100' : ''}`}
      >
        {((screenSize === 'lg' && !collapse) || screenSize === 'md') && items[vehicle.id]?.map((booking) => (
          <Draggable
            key={booking.id}
            booking={booking}
            inVehicle
            handleModalOpen={handleModalOpen}
            screenSize={screenSize}
            disabled={vehicle?.status === 'deactive'}
          />
        ))}
      </div>
      {screenSize === 'lg' && (
      <div>
        <p
          className="Scheduling__droppables-droppable-navIcon"
          onClick={toggleCollapse}
        >
          {collapse ? <FaSortDown /> : <FaSortUp />}
        </p>
      </div>
      )}
    </>
  );
}

export default VehiclePane;
