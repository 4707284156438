import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useSearchParams } from 'react-router-dom';
import './SearchBar.scss';

function SearchBar({
  cities,
  city,
  group,
  setGroup,
  date,
  setDate,
  setVariables,
  getVehicles,
  handleCityChange,
  setSearchParams,
  cityParam,
  dateParam,
}) {
  const getOptions = (time) => {
    const timeSlot = city?.timeSlots?.filter((slot) => slot.group === time.toUpperCase());

    if (!city.timeSlots || timeSlot.length === 0) return time;

    return (
      `${time} (${`${timeSlot[0].startTime.slice(0, 5)} - ${timeSlot[0].endTime.slice(0, 5)}`})`
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getVehicles(({ variables: { citiesId: city.id } }));
    setVariables({ city: city.id, date });
    setSearchParams({ city: city.id, date, group });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form.Select
        className="form-input"
        value={city.id}
        onChange={(e) => handleCityChange(e.target.value)}
      >
        <option value="default" disabled>Choose city</option>
        {cities?.cities?.map((c) => (
          <option
            value={c.id}
            key={c.id}
          >
            {c.name}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        className="form-input"
        value={group}
        onChange={(e) => {
          setGroup(e.target.value);
          setSearchParams({ city: cityParam, date: dateParam, group: e.target.value });
        }}
      >
        {['Morning', 'Afternoon', 'Night', 'All'].map((time) => (
          <option key={time} value={time}>{getOptions(time)}</option>
        ))}
      </Form.Select>
      <Form.Control
        className="form-input"
        id="tour_date"
        name="tour_date"
        type="date"
        placeholder="yyyy-mm-dd"
        onChange={(e) => { setDate(e.target.value); }}
        value={date}
      />
      <button disabled={date === '' || city.id === 'default'} type="submit">Search</button>
    </form>
  );
}

export default SearchBar;
