import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'; import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import {
  DELETE_NEWS,
  ACTIVATE_NEWS,
  ARCHIVE_NEWS,
  SUBSCRIBE_NEWS,
} from '../../api/news';
import Header from '../../components/Header/Header';
import Modal from './components/Modal/Modal';
import './News.scss';
import '../../styles/_style.scss';
import '../../components/Nav/Nav.scss';
import Popup from './components/Popup/Popup';
import Loader from '../../components/Loader/Loader';

function News() {
  const location = useLocation();
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const { data, error, loading } = useSubscription(SUBSCRIBE_NEWS, {
    shouldResubscribe: true,
  });

  useEffect(() => {
    if (loading) return;
    setNewsData(data?.news || []);
  }, [data]);

  const openPopup = (newsItem, reqAction) => {
    setSelectedNews(newsItem);
    setShowPopup(true);
    setAction(reqAction);
  };

  const closePopup = () => {
    setSelectedNews(null);
    setShowPopup(false);
  };

  const openConfirmationModal = (newsItem, reqAction) => {
    setSelectedNews(newsItem);
    setShowConfirmationModal(true);
    setAction(reqAction);
  };

  const closeConfirmationModal = () => {
    setSelectedNews(null);
    setShowConfirmationModal(false);
  };

  const [deleteNewsMutation] = useMutation(DELETE_NEWS);
  const [activateNewsMutation] = useMutation(ACTIVATE_NEWS);
  const [archiveNewsMutation] = useMutation(ARCHIVE_NEWS);

  const confirmAction = async () => {
    if (!selectedNews) return;
    const styles = {
      autoClose: 4000,
      position: 'bottom-right',
    };

    try {
      switch (action) {
        case 'delete':
          await deleteNewsMutation({
            variables: {
              newsId: selectedNews.id,
            },
          });
          toast.success('Successfully deleted!', styles);
          break;

        case 'activate':
          await activateNewsMutation({
            variables: {
              newsId: selectedNews.id,
            },
          });
          toast.success('Successfully activated!', styles);
          break;

        case 'archive':
          await archiveNewsMutation({
            variables: {
              newsId: selectedNews.id,
            },
          });
          toast.success('Successfully archieved!', styles);
          break;

        default:
          break;
      }

      closeConfirmationModal();
      closePopup();
    } catch (error) {
      toast.error(
        'Something went wrong!',
        {
          autoClose: 8000,
          position: 'bottom-right',
        },
      );
      console.error('Error performing database operation:', error);
      // Show error message to the user
    }
  };

  const getHeader = () => {
    const createNewsButton = (
      <button
        type="button"
        className="btn text-white"
        style={{ backgroundColor: 'tomato' }}
        onClick={() => openPopup(null, 'create')}
      >
        Create News
      </button>
    );

    let header = (
      <h2 className="d-flex justify-content-between align-items-center heading_styles bg-tomato">
        <span>News</span>
        {createNewsButton}
      </h2>
    );

    if (location.pathname.includes('edit')) {
      header = (
        <h2 className="d-flex justify-content-between align-items-center heading_styles bg-tomato">
          <i className="far fa-arrow-left" onClick={() => navigate('/products')} />
          <span>Edit News</span>
          {createNewsButton}
        </h2>
      );
    } else if (location.pathname.includes('create')) {
      header = (
        <h2 className="d-flex justify-content-between align-items-center heading_styles bg-tomato">
          <i className="far fa-arrow-left" onClick={() => navigate('/products')} />
          <span>Create News</span>
          {createNewsButton}
        </h2>
      );
    }

    return header;
  };

  const renderBadge = (status) => {
    let badgeColor = '';
    switch (status) {
      case 'ACTIVE':
        badgeColor = 'green';
        break;
      case 'ARCHIVED':
        badgeColor = 'red';
        break;
      default:
        badgeColor = 'blue';
    }
    return <span className={`badge badge-${badgeColor}`}>{status}</span>;
  };

  const renderNewsItemsDesktop = () => {
    if (newsData.length === 0) {
      return <p className="no-data-message">No news available.</p>;
    }

    return (
      <table className="news-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newsData.map((newsItem) => (
            <tr key={newsItem.id}>
              <td>{newsItem.title}</td>
              <td>{newsItem.description}</td>
              <td style={{ minWidth: 200 }}>{format(new Date(newsItem?.created_at), 'MMMM d, yyyy')}</td>
              <td style={{ minWidth: 200 }}>{renderBadge(newsItem.status)}</td>
              <td style={{ minWidth: 300 }}>
                <Tooltip title="Edit">
                  <button
                    className="action-button edit-button"
                    type="button"
                    onClick={() => openPopup(newsItem, 'edit')}
                  >
                    <i className="fas fa-edit" />
                  </button>
                </Tooltip>
                {newsItem.status === 'ACTIVE' ? (
                  <Tooltip title="Archive">
                    <button
                      className="action-button archive-button"
                      type="button"
                      onClick={() => openConfirmationModal(newsItem, 'archive')}
                    >
                      <i className="fas fa-archive" />
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Activate">
                    <button
                      className="action-button activate-button"
                      type="button"
                      onClick={() => openConfirmationModal(newsItem, 'activate')}
                    >
                      <i className="fas fa-toggle-on" />
                    </button>
                  </Tooltip>
                )}
                <Tooltip title="Delete">
                  <button
                    className="action-button delete-button"
                    type="button"
                    onClick={() => openConfirmationModal(newsItem, 'delete')}
                  >
                    <i className="fas fa-trash" />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderNewsItemsMobile = () => {
    if (newsData.length === 0) {
      return <p className="no-data-message">No news available.</p>;
    }

    return newsData.map((newsItem) => (
      <div className="news-item" key={newsItem.id}>
        <div className="news-content">
          <h3 className="news-title">{newsItem.title}</h3>
          <p className="news-description">{newsItem.description}</p>
          <div className="news-info">
            <span className="news-date">{newsItem.date}</span>
            <span className="news-status">{renderBadge(newsItem.status)}</span>
          </div>
        </div>
        <div className="news-actions">
          <button
            className="action-button edit-button"
            type="button"
            onClick={() => openPopup(newsItem, 'edit')}
          >
            <i className="fas fa-edit" />
          </button>
          {newsItem.status === 'ACTIVE' ? (
            <button
              className="action-button archive-button"
              type="button"
              onClick={() => openConfirmationModal(newsItem, 'archive')}
            >
              <i className="fas fa-archive" />
            </button>
          ) : (
            <button
              className="action-button activate-button"
              type="button"
              onClick={() => openConfirmationModal(newsItem, 'activate')}
            >
              <i className="fas fa-toggle-on" />
            </button>
          )}
          <button
            className="action-button delete-button"
            type="button"
            onClick={() => openConfirmationModal(newsItem, 'delete')}
          >
            <i className="fas fa-trash" />
          </button>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Header />
      <div className="News__container">
        <div className="card">
          <div className="card-header">
            {getHeader()}
          </div>
          <main className="News__main">
            {loading
              ? (
                <Loader />
              )
              : (
                <>
                  <div className="desktop-view">
                    {renderNewsItemsDesktop()}
                  </div>
                  <div className="mobile-view">
                    {renderNewsItemsMobile()}
                  </div>
                </>
              )}
            <Outlet />
          </main>
        </div>
      </div>
      <Modal
        action={action}
        open={showConfirmationModal}
        handleClose={closeConfirmationModal}
        handleConfirm={confirmAction}
      />
      <Popup
        action={action}
        open={showPopup}
        handleClose={closePopup}
        handleConfirm={confirmAction}
        selectedNews={selectedNews}
      />
    </>
  );
}

export default News;
