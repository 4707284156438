import { gql } from "@apollo/client";

const INSERT_CUSTOMER = gql`
  mutation INSERT_CUSTOMER($input: boatnew_customers_insert_input!) {
    insert_boatnew_customers_one(object: $input) {
      id
    }
  }
`;

export { INSERT_CUSTOMER };
