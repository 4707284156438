import React, { useState, useEffect } from 'react';
import { statusList } from '../../../../utils/Variables';
import '../../styles/Messaging.scss';
import ConfirmStatusPrompt from './ConfirmStatusPrompt';

function Messaging({ updateStatus, clickedBooking }) {
  const [status, setStatus] = useState();
  const [isChangesConfirmed, setIsChangesConfirmed] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setStatus(clickedBooking.status || 'NO STATUS');
  }, [clickedBooking]);

  const handleUpdateStatus = () => {
    console.log(clickedBooking);
    let bookingInput = {};
    if (status === 'PENDING' && clickedBooking.pickupLocation
      && clickedBooking.customer?.email && clickedBooking.customer?.phone) {
      setStatus('CONFIRMED');
      bookingInput = { status: 'CONFIRMED' };
    } else if (status === 'PENDING' && !(clickedBooking.pickupLocation
      && clickedBooking.customer?.email && clickedBooking.customer?.phone)) {
      bookingInput = { status };
    } else if (status === 'CONFIRMED' && clickedBooking.pickupLocation
      && clickedBooking.customer?.email && clickedBooking.customer?.phone) {
      bookingInput = { status };
    } else if (status === 'CONFIRMED' && !(clickedBooking.pickupLocation
      && clickedBooking.customer?.email && clickedBooking.customer?.phone)) {
      setStatus('PENDING');
      bookingInput = { status: 'PENDING' };
    } else if (status === 'REFUND_OPS' && !(['PENDING', 'CONFIRMED', 'COMPLETED_COMPLAINT'].includes(clickedBooking.status))) {
      bookingInput = { status: 'REFUND_OPS' };
    } else if (status === 'REFUND_GS' && !(['PENDING', 'CONFIRMED', 'COMPLETED_COMPLAINT'].includes(clickedBooking.status))) {
      bookingInput = { status: 'REFUND_GS' };
    } else if (status === 'REFUND_ATTRACTION' && !(['PENDING', 'CONFIRMED', 'COMPLETED_COMPLAINT'].includes(clickedBooking.status))) {
      bookingInput = { status: 'REFUND_ATTRACTION' };
    } else {
      bookingInput = { status };
    }
    return bookingInput;
  };

  useEffect(() => {
    if (isChangesConfirmed) {
      updateStatus({
        variables: {
          id: clickedBooking.id,
          booking_input: handleUpdateStatus(),
        },
      });
      setIsChangesConfirmed(false);
    }
  }, [isChangesConfirmed]);

  return (
    <>
      <ConfirmStatusPrompt
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        status={status}
        setIsChangesConfirmed={setIsChangesConfirmed}
        clickedBooking={clickedBooking}
        setStatus={setStatus}
      />
      <div>
        <div className="BookingModal__fifty">
          <div>Status</div>
          <select
            className="BookingModal__dropdown-list"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setShowConfirmation(true);
            }}
          >
            {statusList.map((status) => (
              <option value={status} key={status}>
                {status.replace(/_/g, ' - ')}
              </option>
            ))}
            <option disabled value="URGENT">URGENT</option>
            <option disabled value="NO STATUS">-- no status --</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default Messaging;
