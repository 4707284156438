import React, { useState } from 'react';
import SubtaskEditForm from './SubtaskEditForm';

const Subtask = ({
  subtask,
  deleteTask,
  updateTask,
}) => {
  const [showEditForm, setShowEditForm] = useState();
  const [showOptions, setShowOptions] = useState();
  const [clickedTask, setClickedTask] = useState({
    taskId: '',
    taskVal: '',
    taskStatus: '',
  });

  return (
    (showEditForm && subtask.subtaskId === clickedTask.taskId)
      ? (
        <SubtaskEditForm
          updateTask={updateTask}
          setShowEditForm={setShowEditForm}
          setShowOptions={setShowOptions}
          clickedTask={clickedTask}
          setClickedTask={setClickedTask}
        />
      )
      : (
        <>
          <div className="Subtask__task-container" key={subtask.subtaskId}>
            <div className={`Subtask__task-status Status-display__${subtask.status.toLowerCase().replace(' ', '')}`}>
              {subtask.status.toLowerCase().replace(' ', '') === 'urgent' && (
                <div className="Subtask__task-status-expl-mark">
                  !
                </div>
              )}
              {subtask.status.toLowerCase().replace(' ', '') === 'completed' && (
                <div className="Subtask__task-status-check-mark">
                  <i className="fas fa-check" />
                </div>
              )}
            </div>
            <div className="Subtask__task-msg">
              {subtask.task}
            </div>
          </div>

          <div
            className="Subtask__task-options"
            onMouseEnter={(e) => {
              setClickedTask({
                ...clickedTask,
                taskId: e.target.getAttribute('id'),
                taskVal: e.target.getAttribute('data-value'),
                taskStatus: e.target.getAttribute('data-status'),
              });
              setShowOptions(true);
            }}
            onMouseLeave={() => {
              setShowOptions(false);
            }}
          >
            {(showOptions && subtask.subtaskId === clickedTask.taskId)
              ? (
                <div className={`Subtask__task-options-container ${showOptions && 'open'}`}>
                  <button
                    type="button"
                    onClick={() => {
                      setShowEditForm(true);
                    }}
                  >
                    <i className="fas fa-pen" />
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      deleteTask({ variables: { id: clickedTask.taskId } });
                    }}
                  >
                    <i className="fas fa-trash" />
                  </button>
                </div>
              )
              : (
                <i
                  className="Subtask__task-options-btn fal fa-ellipsis-v"
                  id={subtask.subtaskId}
                  data-value={subtask.task}
                  data-status={subtask.status}
                />
              )}
          </div>
        </>
      )
  );
};

export default Subtask;
