import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import UserForm from '../UserForm';
import UserSchema from '../../../../yup/user';

function EditUserForm({
  editUser,
  initialValues,
}) {
  const formik = useFormik({
    initialValues,
    // validate,
    validationSchema: UserSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      console.log(values);
      editUser({
        variables: {
          userId: values.id,
          input: {
            name: values.name.trim(),
            phone: values?.phone?.trim(),
            email: values.email.trim(),
            role: values.role.trim(),
            permissions: values.permissions.trim(),
          },
        },
      })
        .catch((e) => {
          alert('Error: The Airtable ID you have entered already exists. Please enter a different Airtable ID and try again');
          console.log(e);
        });
    },
  });

  return (
    <UserForm formik={formik} />
  );
}

export default EditUserForm;
