import React, { useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  Modal,
  Button,
  Form,
} from 'react-bootstrap';
import fetchFromAPI from '../../../../../../utils/helpers';

dayjs.extend(utc);
dayjs.extend(timezone);

const airtableUpdateAPIKey = process.env.REACT_APP_AIRTABLE_UPDATE;
const emailAPI = process.env.REACT_APP_EMAIL_API;

function ConfirmStatusPrompt({
  showConfirmation,
  setShowConfirmation,
  status,
  setIsChangesConfirmed,
  clickedBooking,
  setStatus,
}) {
  const [checked, setChecked] = useState(false);
  const getPax = () => {
    let pax = '';
    clickedBooking.bookingUnits.map((p) => {
      pax += `${p.quantity} ${p.unit.label} `;
    });
    return pax;
  };

  const sendCancelEmail = async (body) => {
    try {
      const res = await fetchFromAPI(emailAPI, 'cancelBooking', {
        body,
      });
      if (res[0].statusCode === 202) alert('A cancellation email has been sent');
    } catch (err) {
      alert(`${err.message}. Error code is: ${err.code}`);
    }
  };

  const handleClick = () => {
    setIsChangesConfirmed(true);
    setShowConfirmation(false);
    if (status.toLowerCase() === 'cancelled') {
      try {
        if (clickedBooking?.source === 'WEBSITE') {
          const cancelRezdyBooking = async () => {
            dayjs.tz.setDefault(clickedBooking?.product?.citiesProducts[0]?.city?.timeZone);
            let minTourStartTime = dayjs(`${clickedBooking.tourDate} ${clickedBooking.tourTime}Z`);
            let maxTourStartTime = dayjs(minTourStartTime).add(1, 'day');
            minTourStartTime = dayjs.tz(minTourStartTime).toISOString();
            maxTourStartTime = dayjs.tz(maxTourStartTime).toISOString();
            const rezdyBookingRequest = {
              productCode: clickedBooking.product.rezdy.rezdy_id,
              minTourStartTime,
              maxDateCreated: minTourStartTime,
              maxTourStartTime,
              bookingId: clickedBooking?.id,
            };
            await axios({
              method: 'POST',
              url: process.env.REACT_APP_CANCEL_BOOKING_HTTP_ENDPOINT, // aws route
              data: rezdyBookingRequest,
            });
          };
          cancelRezdyBooking();
        }
      } catch (err) {
        alert(`${err.message}. Error code is: ${err.code}`);
      }
    }
    if (checked) {
      const body = {
        customerName: clickedBooking.customer.contactName,
        customerEmail: clickedBooking.customer.email,
        customerMobile: clickedBooking.customer.phone,
        tourName: clickedBooking.product.tourName,
        tourDate: clickedBooking.tourDate,
        tourTime: clickedBooking.tourTime,
        bookingID: clickedBooking.id,
        pax: getPax(),
      };
      sendCancelEmail(body);
      setChecked(false);
    }
  };
  const handleClose = () => {
    setStatus(clickedBooking.status);
    setShowConfirmation(false);
  };

  const prompt = {
    PENDING: {
      msg: (
        <>
          <p>Are you sure you want to update the status to PENDING?</p>
          <p>
            If pickup location,primary email and phone are defined
            the status will automatically be changed to CONFIRMED
          </p>
        </>),
    },
    CONFIRMED: {
      msg: (
        <>
          <p>Are you sure you want to update status to CONFIRMED?</p>
          <p>
            Status can be updated to CONFIRMED only if pickup location,
            primary email and phone are defined. Otherwise, status will be changed to PENDING
          </p>
        </>),
    },
    CANCELLED: {
      msg: (
        <>
          <p>Are you sure you want to update status to CANCELLED?</p>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Send cancellation email"
              onClick={() => setChecked(!checked)}
            />
          </Form.Group>
        </>),
    },
    'NO SHOW': {
      msg: 'Are you sure you want to update status to NO SHOW?',
    },
    'NEEDS REVIEW': {
      msg: 'Are you sure you want to update status to NEEDS REVIEW?',
    },
    'INFORMATION REQUESTED': {
      msg: 'Are you sure you want to update status to INFORMATION REQUESTED?',
    },
    'PICKUP TIME SENT': {
      msg: 'Are you sure you want to update status to PICKUP TIME SENT?',
    },
    'COMPLETED - COMPLAINT': {
      msg: 'Are you sure you want to update status to COMPLETED - COMPLAINT?',
    },
    COMPLETED: {
      msg: 'Are you sure you want to update status to COMPLETED?',
    },
    REFUND_OPS: {
      msg: 'Are you sure you want to update status to REFUND_OPS?',
    },
    REFUND_GS: {
      msg: 'Are you sure you want to update status to REFUND_GS?',
    },
    REFUND_ATTRACTION: {
      msg: 'Are you sure you want to update status to REFUND_ATTRACTION?',
    },
  };

  return (
    <div>
      <Modal show={showConfirmation} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Status Update
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="BookingModal__payment-confirmation">
            {status && prompt[status]?.msg}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClick}>
            Update
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmStatusPrompt;
