import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import InputComponent from "./InputComponent";
import Map from "../Map";
import fetchFromAvailabilityAPI from "../../../../utils/helpers";
import ConfirmationPrompt from "./ConfirmationPrompt";
import "../../styles/EditBooking.scss";

function EditBooking({
  setIsEditing,
  clickedBooking,
  getInitialValues,
  handleSubmit,
  validate,
  productUnits,
  vehicles,
  guides,
  calendarDates,
  bookingOptions,
  setBookingOptions,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updateConfirmed, setUpdateConfirmed] = useState(false);
  const [action, setAction] = useState("Cancel");

  const initialValues = getInitialValues();
  console.log('initialValues', initialValues);
  const formik = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: (values) => {
      setAction("Save");
      setShowConfirmation(true);
      if (updateConfirmed) {
        setShowConfirmation(false);
        handleSubmit(values, initialValues);
        formik.resetForm();
        setIsEditing(false);
      }
    },
  });
  // useEffect(() => {
  //   if (formik.values.assigned_vehicle === initialValues.assigned_vehicle
  //     && formik.values.tour_time === initialValues.tour_time
  //     && formik.values.tour_date === initialValues.tour_date
  //   ) {
  //     formik.setFieldValue('capacity', initialValues.capacity);
  //   }
  // }, [formik.values.assigned_vehicle, formik.values.tour_time, formik.values.tour_date]);

  const handlePaxChange = (e) => {
    const arr = formik.values.units;
    const quantity = Number(e.target.value);
    if (arr.some((unit) => unit.unit_id === e.target.id)) {
      const result = arr.map((unit) =>
        unit.unit_id === e.target.id ? { ...unit, quantity } : unit
      );
      formik.setFieldValue("units", result);
    } else {
      arr.push({
        unit_id: e.target.id,
        quantity,
        booking_id: clickedBooking.id,
      });
      formik.setFieldValue("units", arr);
    }
    formik.setFieldValue(e.target.getAttribute("name"), Number(e.target.value));
  };

  const handleDateChange = async (value) => {
    formik.setFieldValue("tour_date", dayjs(value).format("YYYY-MM-DD"));
    // formik.setFieldValue('tour_time', 'default');
    // formik.setFieldValue('capacity', 0);

    // const body = {
    //   productId: formik.values.product_id,
    //   date: value,
    // };

    // if (calendarDates.includes(value.format('YYYY-MM-DD'))
    // || initialValues.tour_date === value.format('YYYY-MM-DD')) {
    //   const res = await fetchFromAvailabilityAPI('availabilityCheckEdit', {
    //     body,
    //     method: 'POST',
    //   });
    //   if (!res.error) setBookingOptions(res);
    // } else {
    //   console.log('Invalid Date');
    // }
  };

  const handleTourTimeChange = (e) => {
    const availability = bookingOptions?.availabilitySeries?.filter(
      (option) =>
        option.start_time === e.target.value &&
        option.assigned_vehicle === formik.values.assigned_vehicle
    );
    formik.setFieldValue(
      "availability_series_id",
      availability[0].availability_series_id
    );
    formik.setFieldValue("maxPax", availability[0].maxPax);
    formik.setFieldValue("tour_time", e.target.value);
    formik.setFieldValue("capacity", 0);
  };

  const handleVehicleChange = (e) => {
    // const availability = bookingOptions?.availabilitySeries?.filter((option) => (
    //   option.start_time === formik.values.tour_time
    //   && option.assigned_vehicle === e.target.value));
    // formik.setFieldValue('maxPax', availability[0].maxPax);
    formik.setFieldValue("assigned_vehicle", e.target.value);
    // formik.setFieldValue('capacity', 0);
  };

  const disableDates = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    if (formik.values.tour_date === formattedDate) return false;
    if (calendarDates.includes(formattedDate)) return false;
    return true;
  };

  return (
    <div className="BookingModal__info-edit-container">
      <h2>Edit Booking</h2>
      <form
        className="BookingModal__info-edit-form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Tour Date"
            inputFormat="yyyy-MM-dd"
            // shouldDisableDate={disableDates}
            disablePast
            value={dayjs(formik.values.tour_date).tz().toISOString()}
            // disabled={!formik.values.product_id}
            mask="____-__-__"
            onChange={(value) => handleDateChange(value)}
            renderInput={(params) => (
              <TextField
                sx={{
                  marginTop: 3,
                  marginBottom: 1,
                  "& .MuiInputLabel-root": {
                    fontSize: "0.9rem",
                    lineHeight: "0.95rem",
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "0.85rem",
                    height: "2.8rem",
                  },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        {formik.errors.tour_date ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.tour_date}
          </div>
        ) : null}
        {/* <label
          htmlFor="tourTime"
          className="BookingModal__info-edit-input"
        >
          <span>Tour Time</span>
          <select
            id="tourTime"
            name="tourTime"
            className="BookingModal__createBooking-dropdown"
            value={formik.values.tour_time}
            onChange={(e) => handleTourTimeChange(e)}
          >
            <option disabled value="default">Choose tour time...</option>
            {bookingOptions?.timeOptions?.map((option) => (
              <option
                value={option}
                key={option}
              >
                {option.slice(0, 5)}
              </option>
            ))}
          </select>
        </label> */}
        <InputComponent
          name="Tour Time"
          value={formik.values.tour_time}
          handleChange={formik.handleChange}
        />
        {formik.errors.tour_time ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.tour_time}
          </div>
        ) : null}
        <InputComponent
          name="Contact Name"
          value={formik.values.contact_name}
          handleChange={formik.handleChange}
        />
        {formik.errors.contact_name ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.contact_name}
          </div>
        ) : null}
        <InputComponent
          name="Phone"
          value={formik.values.phone}
          handleChange={formik.handleChange}
        />
        {formik.errors.phone ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.phone}
          </div>
        ) : null}
        <InputComponent
          name="Email"
          value={formik.values.email}
          handleChange={formik.handleChange}
        />
        {formik.errors.email ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.email}
          </div>
        ) : null}
        <InputComponent
          name="Secondary Phone"
          value={formik.values.secondary_phone}
          handleChange={formik.handleChange}
        />
        {formik.errors.secondary_phone ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.secondary_phone}
          </div>
        ) : null}
        <InputComponent
          name="Secondary Email"
          value={formik.values.secondary_email}
          handleChange={formik.handleChange}
        />
        {formik.errors.secondary_email ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.secondary_email}
          </div>
        ) : null}
        {productUnits?.map((unit) => (
          <div key={unit.unit.id}>
            <InputComponent
              id={unit.unit.id}
              name={unit.unit.label}
              value={formik.values[unit.unit.label.toLowerCase()]}
              handleChange={(e) => handlePaxChange(e)}
              type="number"
              min={0}
            />
            {formik.errors[unit.unit.label.toLowerCase()] ? (
              <div className="BookingModal__info-edit-error">
                {formik.errors[unit.unit.label.toLowerCase()]}
              </div>
            ) : null}
          </div>
        ))}
        {/* <label
          htmlFor="allow_overbooking"
          className="BookingModal__createBooking-overbooking"
        >
          <input
            type="checkbox"
            id="allow_overbooking"
            name="allow_overbooking"
            value={formik.values.allow_overbooking}
            onChange={formik.handleChange}
          />
          <span>Allow overbooking</span>
        </label> */}
        {/* <div className="BookingModal__createBooking-capacity">
          {`Seats available in the vehicle: ${(formik.values.productType.includes('Private')
          && formik.values.capacity !== 0) ? (formik.values.capacity - formik.values.group)
          : formik.values.maxPax}`}
          {formik.errors.maxPax ? <div className="BookingModal__info-edit-error">
          {formik.errors.maxPax}</div> : null}
        </div> */}
        {/* <label
          htmlFor="assigned_vehicle"
          className="BookingModal__info-edit-input"
        >
          <span>Vehicle</span>
          <select
            id="assigned_vehicle"
            disabled
            name="assigned_vehicle"
            className="BookingModal__createBooking-dropdown"
            value={formik.values.assigned_vehicle}
            onChange={(e) => handleVehicleChange(e)}
          >
            <option disabled value="">Choose vehicle...</option>
            {vehicles?.filter((vehicle) => bookingOptions?.vehicleOptions?.includes(vehicle.id))
              .map((vehicle) => (
                (
                  <option
                    value={vehicle.id}
                    key={vehicle.id}
                  >
                    {vehicle.name}
                  </option>
                )
              ))}
          </select>
        </label> */}
        {/* {formik.errors.assigned_vehicle ?
        <div className="BookingModal__info-edit-error">
        {formik.errors.assigned_vehicle}</div> : null} */}
        {/* <label
          htmlFor="assigned_guide"
          className="BookingModal__info-edit-input"
        >
          <span>Guide</span>
          <select
            id="assigned_guide"
            name="assigned_guide"
            className="BookingModal__createBooking-dropdown"
            value={formik.values.assigned_guide}
            onChange={(e) => formik.setFieldValue('assigned_guide', e.target.value)}
          >
            <option value="">Choose guide...</option>
            {guides?.map((guide) => (
              (
                <option
                  value={guide.id}
                  key={guide.id}
                >
                  {guide.fullName}
                </option>
              )
            ))}
          </select>
        </label> */}
        {/* {formik.errors.assigned_guide ?
          <div className="BookingModal__info-edit-error">{formik.errors.assigned_guide}
          </div> : null} */}
        <label
          htmlFor="notes_customer"
          className="BookingModal__info-edit-input"
        >
          <span>Customer Notes</span>
          <textarea
            id="notes_customer"
            name="notes_customer"
            value={formik.values.notes_customer}
            onChange={formik.handleChange}
          />
        </label>
        <InputComponent
          name="Pickup Time"
          value={formik.values.pickup_time}
          handleChange={formik.handleChange}
        />
        {formik.errors.pickup_time ? (
          <div className="BookingModal__info-edit-error">
            {formik.errors.pickup_time}
          </div>
        ) : null}
        {clickedBooking.product?.pickupRequired && (
          <Map clickedBooking={clickedBooking} formik={formik} />
        )}
        <label
          htmlFor="allowBookingOutside"
          className="BookingModal__info-edit-input-checkbox"
        >
          <input
            type="checkbox"
            id="allowBookingOutside"
            name="allowBookingOutside"
            value={formik.values.allowBookingOutside}
            onChange={() =>
              formik.setFieldValue(
                "allowBookingOutside",
                !formik.values.allowBookingOutside
              )
            }
          />
          <span>Allow booking outside the pick up bounds</span>
        </label>
        <div className="BookingModal__info-edit-buttons">
          <button type="submit">Save</button>
          <button
            type="button"
            onClick={() => {
              setShowConfirmation(true);
              setAction("Cancel");
            }}
          >
            Cancel
          </button>
        </div>
      </form>
      <ConfirmationPrompt
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        setIsEditing={setIsEditing}
        action={action}
        setUpdateConfirmed={setUpdateConfirmed}
        formik={formik}
      />
    </div>
  );
}

export default EditBooking;
