import { gql } from '@apollo/client';

const INSERT_SUBTASK = gql`
  mutation INSERT_SUBTASK($input: [boatnew_booking_subtasks_insert_input!]!) {
    subtasks: insert_boatnew_booking_subtasks(objects: $input) {
      affected_rows
      returning {
        subtaskId: id
        bookingId: booking_id
        status
        task
      }
    }
  }
`;
// Example Payload
// {
//   "input": [{
//     "booking_id": "SF64BH",
//     "status": "URGENT",
//     "task": ""
//   }]
// }

const UPDATE_SUBTASK = gql`
  mutation UPDATE_SUBTASK($id: String!, $input: boatnew_booking_subtasks_set_input) {
    subtask: update_boatnew_booking_subtasks_by_pk(pk_columns: {id: $id}, _set: $input) {
      subtaskId: id
      bookingId: booking_id
      status
      task
    }
  }
`;

// Example Payload
// {
//   "id": "bb85385c-9702-4feb-bb48-c68a63a8a2c0",
//   "obj": {
//     "status": "DONE",
//     "task": "0"
//   }
// }

const DELETE_SUBTASK = gql`
  mutation MyMutation($id: String!) {
    subtask: delete_boatnew_booking_subtasks_by_pk(id: $id) {
      id
    }
  }
`;

export {
  INSERT_SUBTASK, DELETE_SUBTASK, UPDATE_SUBTASK,
};
