import React from 'react';
import Select from 'react-select';
import { Form, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Button } from 'bootstrap';

const tourType = ['Day Tour', 'Multi-day Tour', 'Private Day Tour', 'Airport Transfer', 'Custom Experience'];

function Page1({
  formik,
  cities,
  selectedValues,
  setSelectedValues,
}) {
  const { productId } = useParams();
  const { values, errors, handleChange } = formik;
  const options = cities?.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const handlePickupRequired = () => {
    formik.setFieldValue('pickup_bounds', null);
    formik.setFieldValue('pickup_required', !values.pickup_required);
  };

  const handleLocationChange = (value) => {
    const locations = [];
    if (productId) {
      value?.map((location) => {
        locations.push({ city_id: location.value, product_id: values.id });
      });
    } else {
      value?.map((location) => {
        locations.push({ city_id: location.value });
      });
    }
    setSelectedValues(value);
    formik.setFieldValue('pickup_bounds', null);
    formik.setFieldValue('locations', locations);
  };

  return (
    <div className="EditForm__form-page1">
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <InputGroup className={errors.name ? 'is-invalid' : ''}>
          <Form.Control
            id="name"
            name="name"
            placeHolder="Enter Name"
            type="text"
            onChange={handleChange}
            value={values.name}
            className={errors.name ? 'is-invalid' : ''}
          />
        </InputGroup>
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Slug</Form.Label>
        <InputGroup className={errors?.slug ? 'is-invalid' : ''}>
          <Form.Control
            id="slug"
            name="slug"
            placeHolder="Enter slug"
            type="text"
            onChange={handleChange}
            value={values.slug}
            className={errors.slug ? 'is-invalid' : ''}
          />
        </InputGroup>
        <Form.Control.Feedback type="invalid">{errors?.slug}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="EditForm__form-page1-location">
        <Form.Label>Location</Form.Label>
        <Select
          closeMenuOnSelect
          isMulti
          defaultValue={selectedValues}
          options={options}
          onChange={(value) => handleLocationChange(value)}
        />
        {errors.locations ? <div className="EditForm__error">{errors.locations}</div> : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>Code</Form.Label>
        <InputGroup className={errors.code ? 'is-invalid' : ''}>
          <Form.Control
            id="code"
            name="code"
            type="text"
            placeHolder="Enter Code"
            onChange={handleChange}
            value={values.code}
            className={errors.code ? 'is-invalid' : ''}
          />
        </InputGroup>
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="EditForm__form-page1-type">
        <Form.Label>Type</Form.Label>
        {tourType.map((tour) => {
          const t = tour.replace(/\s+/g, '_').toLowerCase();
          return (
            <label key={t} htmlFor={t} className="EditForm__form-page1-type-label">
              <input
                checked={values.type === tour}
                id={t}
                name="type"
                type="radio"
                defaultValue={tour}
                onChange={handleChange}
              />
              {tour}
            </label>
          );
        })}
        {errors.type ? <div className="EditForm__error">{errors.type}</div> : null}
      </Form.Group>
      <Form.Group className="EditForm__form-page1-location">
        <Form.Check
          label="Customer pickup is required"
          id="pickup_required"
          name="pickup_required"
          type="checkbox"
          onChange={() => handlePickupRequired()}
          checked={values.pickup_required}
          className="EditForm__form-page1-pickup"
        />
      </Form.Group>
    </div>
  );
}

export default Page1;
