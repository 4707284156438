import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { FETCH_CITIES } from "../../../../api/city";
import { ADD_VEHICLE } from "../../../../api/Vehicles";
import { positiveNum0 } from "../../../../utils/Regex";
import "../../Vehicles.scss";
import VehicleForm from "../VehicleForm";
import VehicleSchema from "../../../../yup/vehicle";

// const validate = (values) => {
//   const errors = {};
//   if (!values.name || !values.name.trim()) {
//     errors.name = 'Required';
//   }
//   if (!values.city_id) {
//     errors.city_id = 'Required';
//   }
//   if (!values.capacity) {
//     errors.capacity = 'Required';
//   } else if (!positiveNum0.test(values.capacity)) {
//     errors.capacity = 'Enter valid number';
//   }
//   if (values.year !== '' && !positiveNum0.test(values.year)) {
//     errors.year = 'Enter valid year';
//   }
//   return errors;
// };

function CreateVehicle() {
  const navigate = useNavigate();

  const { data } = useSubscription(FETCH_CITIES);
  const [addVehicle] = useMutation(ADD_VEHICLE, {
    onCompleted: () => {
      toast.success("Vehicle created!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000,
      });
      navigate("/vehicles");
    },
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      capacity: "",
      city_id: "",
      make_model: "",
      plate_number: "",
      vin_number: "",
      year: 0,
      colour: "",
      insurance: "",
      ownership: "",
      maintenence: "",
    },
    // validate,
    validateOnChange: true,
    validationSchema: VehicleSchema,
    onSubmit: (values) => {
      addVehicle({
        variables: {
          input: {
            name: values.name.trim(),
            capacity: values.capacity,
            city_id: values.city_id,
            make_model: values.make_model,
            plate_number: values.plate_number,
            vin_number: values.vin_number,
            year: values.year,
            colour: values.colour,
            maintenence: values.maintenence,
          },
        },
      }).catch((e) => {
        alert(e);
        console.log(e);
      });
    },
  });

  return (
    <div className="Vehicles__editVehicle">
      <VehicleForm
        formik={formik}
        cities={data?.boatnew_cities}
        vehicle={null}
      />
    </div>
  );
}

export default CreateVehicle;
