import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import HomePage from '../../components/HomePage/HomePage';
import AbondonedBooking from '../../components/HomePage/AbondonedBooking';
import './Homepage.scss';

function Homepage() {
  const location = useLocation();
  const [selectedOption, setSelectedOptions] = useState(Number(location.search.split('?')[1]) ? Number(location.search.split('?')[1]) : 0);
  // const screen = [<HomePage />, <AbondonedBooking />];
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <HomePage />
      {/* <div className="content">
        <div className="dash-head">
          <button
            type="button"
            onClick={() => {
              navigate('?0');
              setSelectedOptions(0);
            }}
          >
            Bookings

          </button>
          <button
            type="button"
            onClick={() => {
              navigate('?1');
              setSelectedOptions(1);
            }}
          >
            Abondoned Checkouts

          </button>
        </div>
        {screen[selectedOption]}
      </div> */}
    </>
  );
}

export default Homepage;
