import React, { useState } from 'react';
import {
  useSubscription,
  useReactiveVar,
  makeVar,
} from '@apollo/client';
import { FETCH_RESERVATION_TABLE } from '../../../../api/reservations';
import ReservationsTable from './ReservationsTable';
import EmailEditor from '../Editor/EmailEditor';

function DisplayTable({
  variables,
  formik,
  date,
  chosenBookings,
  checked,
  setChecked,
  setChosenBookings,
  setBookingReservations,
  bookingReservations,
  setShowEmailTemplate,
  showEmailTemplate,
  editorRef,
  setConfirmation,
}) {
  const [showCC, setShowCC] = useState(true);

  const initialVar = makeVar(variables);
  const subVariables = useReactiveVar(initialVar);

  const {
    data: reservations,
    loading: reservationsLoading,
    error: reservationsError,
  } = useSubscription(FETCH_RESERVATION_TABLE, {
    shouldResubscribe: true,
    variables: subVariables,
  });

  if (reservationsLoading) {
    return (
      <div className="Reservations__loader">
        <div id="Reservations-loader"> </div>
        <p className="loading">Loading...</p>
      </div>
    );
  }
  if (reservationsError) {
    return (
      <div className="Reservations__table-error">
        <h2>Something went wrong...</h2>
        <p>{`Error: ${reservationsError?.message || ''}`}</p>
      </div>
    );
  }

  return (
    <div className="Reservations__container">

      <ReservationsTable
        reservations={reservations}
        date={date}
        formik={formik}
        chosenBookings={chosenBookings}
        checked={checked}
        setChecked={setChecked}
        setChosenBookings={setChosenBookings}
        setBookingReservations={setBookingReservations}
        bookingReservations={bookingReservations}
        setShowEmailTemplate={setShowEmailTemplate}
        showEmailTemplate={showEmailTemplate}
      />
      {showEmailTemplate
        && (
          <aside className="Reservations__email">
            <EmailEditor
              formik={formik}
              showCC={showCC}
              setShowCC={setShowCC}
              editorRef={editorRef}
              setShowEmailTemplate={setShowEmailTemplate}
              setConfirmation={setConfirmation}
            />
          </aside>
        )}
    </div>
  );
}

export default DisplayTable;
