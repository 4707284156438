import React, { useState, useMemo, useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  useQuery,
  useMutation,
  useSubscription,
  useReactiveVar,
  makeVar,
  useLazyQuery,
} from "@apollo/client";
import Header from "../../components/Header/Header";
import { FETCH_CITIES } from "../../api/city";
import {
  FETCH_BOOKINGS_SCHEDULE,
  UPDATE_BOOKINGS_ASSIGN_VEHICLE,
} from "../../api/scheduling";
import { FETCH_VEHICLES_BY_CITY } from "../../api/Vehicles";
import { FETCH_ACTIVE_GUIDES } from "../../api/Guides";
import Container from "./components/Container";
import SearchBar from "./components/SearchBar/SearchBar";
import "./Scheduling.scss";

const today = dayjs().format("YYYY-MM-DD");

function Scheduling() {
  const [searchParams, setSearchParams] = useSearchParams();

  const cityParam = searchParams.get("city");
  const dateParam = searchParams.get("date");
  const groupParam = searchParams.get("group");

  const handleCityChange = (id) => {
    const currentCity = cities?.cities.find((c) => c.id === id);
    if (currentCity) setCity(currentCity);
  };

  const [cities, setCities] = useState(null);
  const [city, setCity] = useState({ id: "default" });
  const [group, setGroup] = useState("Morning");
  const [date, setDate] = useState(today);
  const [variables, setVariables] = useState(null);

  const initialVar = makeVar(variables);
  const subVariables = useReactiveVar(initialVar);

  const { data: citiesRes } = useSubscription(FETCH_CITIES);
  const { data: guides } = useQuery(FETCH_ACTIVE_GUIDES);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [locations, setLocations] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState("");
  const [products, setProducts] = useState([]);

  const {
    data: bookings,
    loading: bookingsLoading,
    error: bookingsError,
  } = useSubscription(FETCH_BOOKINGS_SCHEDULE, {
    shouldResubscribe: true,
    variables: subVariables,
  });

  useEffect(() => {
    /* Adding All timeslot to cities */
    if (typeof citiesRes === "undefined") return;
    function addAllTimeSlotToAllObjects(myArray) {
      const allTimeSlot = {
        __typename: "boatnew_cities_timeslots",
        group: "ALL",
        startTime: "06:00:00",
        endTime: "23:59:00",
      };

      const newArray = myArray.map((obj) => {
        const newTimeSlots = [...obj.timeSlots, allTimeSlot];
        return { ...obj, timeSlots: newTimeSlots };
      });

      return newArray;
    }

    setCities({
      cities: addAllTimeSlotToAllObjects(citiesRes?.boatnew_cities),
    });
  }, [citiesRes]);

  useEffect(() => {
    /**
     * Filters and colects all Locations
     */
    const Bookings = bookings?.bookings || null;
    let Prods = Bookings;
    let Loc = Bookings;
    if (Bookings === undefined || Bookings === null) return;
    /* Filtering Locations */
    Loc = Array.from(
      new Set(Loc.map(({ pickupLocation }) => pickupLocation))
    ).filter((loc) => ![null, ""].includes(loc));
    setLocations([...Loc]);
    /* Filtering Products */
    Prods = Array.from(
      new Set(Bookings.map(({ product }) => product?.name || ""))
    ).filter((loc) => ![null, ""].includes(loc));
    setProducts([...Prods]);
  }, [bookings]);

  const [vehicles, setVehicles] = useState(null);
  const [getVehicles, { data: rawVehicles = [] }] = useLazyQuery(
    FETCH_VEHICLES_BY_CITY
  );

  const [updateBookingsVehicles, { error }] = useMutation(
    UPDATE_BOOKINGS_ASSIGN_VEHICLE,
    {
      onCompleted: () => {
        alert("Schedule was updated successfully");
      },
      onError: () => {
        alert(`Error! Scheduling was not updated. ${error?.message}`);
      },
    }
  );

  useEffect(() => {
    if (typeof rawVehicles?.vehicles === "object") {
      const vehiclesCopy = rawVehicles.vehicles.slice(); // create a copy of the array
      const sortedVehicles = vehiclesCopy.sort(
        (
          { name: name1, status: status1 },
          { name: name2, status: status2 }
        ) => {
          if (status1 === "active" && status2 === "deactive") {
            return -1; // status1 should come before status2
          }
          if (status1 === "deactive" && status2 === "active") {
            return 1; // status1 should come after status2
          }
          // Both objects have the same status, sort by name
          if (name1 < name2) {
            return -1; // obj1 should come before obj2
          }
          if (name1 > name2) {
            return 1; // obj1 should come after obj2
          }
          return 0; // no sorting needed

          // return name1.localeCompare(name2);
          // return false;
        }
      );

      // sortedVehicles = sortedVehicles.sort((
      //   { name: name1, status: status1 },
      //   { name: name2, status: status2 },
      // ) => {
      //   if (name2.includes(name1)) {
      //     return name1.length - name2.length;
      //   }

      //   // return name1.localeCompare(name2);
      //   return false;
      // });
      setVehicles({ vehicles: sortedVehicles });
    }
  }, [rawVehicles]);

  const timeGroup = useMemo(() => {
    const getTimeGroupBookings = () =>
      bookings?.bookings.filter((booking) =>
        group.toUpperCase() === "ALL"
          ? true
          : booking.timeGroup === group.toUpperCase()
      );

    return getTimeGroupBookings();
  }, [bookings, group]);

  const noTimeGroup = useMemo(() => {
    const getNoTimeGroupBookings = () =>
      bookings?.bookings.filter((booking) => !booking.timeGroup);

    return getNoTimeGroupBookings();
  }, [bookings]);

  if (!variables && cityParam && dateParam && cities) {
    setVariables({ city: cityParam, date: dateParam });
    getVehicles({ variables: { citiesId: cityParam } });
    setDate(dateParam);
    handleCityChange(cityParam);
    setGroup(groupParam || "Morning");
  }

  const displayContainer = () => {
    if (bookingsLoading) {
      return (
        <div className="Scheduling__loader">
          <div id="Scheduling-loader"> </div>
          <p className="loading">Loading...</p>
        </div>
      );
    }
    if (city?.timeSlots?.length < 3) {
      return (
        <div className="Scheduling__no-bookings">
          <p>
            Time Groups are not defined for this city. Please contact the dev
            team.
          </p>
        </div>
      );
    }
    if (timeGroup && timeGroup.length !== 0 && vehicles && guides) {
      return (
        <Container
          timeGroup={timeGroup}
          noTimeGroup={noTimeGroup}
          vehicles={vehicles}
          updateBookingsVehicles={updateBookingsVehicles}
          locations={locations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          guides={guides?.guides}
          products={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      );
    }
    if (bookingsError) {
      return (
        <div className="Scheduling__error">
          <h2>Something went wrong...</h2>
          <p>{`Error: ${bookingsError?.message || ""}`}</p>
        </div>
      );
    }
    return (
      <div className="Scheduling__no-bookings">
        <p>No bookings were found</p>
      </div>
    );
  };

  return (
    <>
      <Outlet />
      <Header />
      <main className="Scheduling__main">
        <div className="Scheduling__wrapper">
          {cities && (
            <SearchBar
              cities={cities}
              city={city}
              setCity={setCity}
              group={group}
              setGroup={setGroup}
              date={date}
              setDate={setDate}
              setVariables={setVariables}
              getVehicles={getVehicles}
              variables={variables}
              handleCityChange={handleCityChange}
              setSearchParams={setSearchParams}
              cityParam={cityParam}
              dateParam={dateParam}
            />
          )}
          {variables && displayContainer()}
        </div>
      </main>
    </>
  );
}

export default Scheduling;
