import React from 'react';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Modal as Popup } from 'react-bootstrap';
import {
  FETCH_TOURS,
  FETCH_PRODUCT_UNITS_BOUNDS,
  CHECK_AVAILABILITY,
} from '../../../../../../api/Product';
import { ADD_BOOKING, FETCH_BOOKINGS } from '../../../../../../api/bookings';
import {
  validPhoneNum,
  positiveNum0,
  positiveNum,
  validEmail,
  validTime,
} from '../../../../../../utils/Regex';
import CreateBookingForm from './CreateBookingForm';

function NewCreateBooking() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setIsEditing } = useOutletContext();

  const { data: tours, loading: toursLoading, error: toursError } = useQuery(FETCH_TOURS);

  const [getProductUnits,
    { data: product, loading }] = useLazyQuery(FETCH_PRODUCT_UNITS_BOUNDS);

  const [addBooking] = useMutation(ADD_BOOKING, {
    onCompleted: (data) => {
      setIsEditing(true);
      navigate(`/bookings/${data.insert_boatnew_booking_one.id}${search}`);
    },
    refetchQueries: [
      { query: FETCH_BOOKINGS },
      { query: CHECK_AVAILABILITY },
    ],
  });

  const validate = (values) => {
    const pax = values.units?.reduce(
      (previousValue, currentValue) => previousValue
        + currentValue.quantity,
      0,
    );

    const errors = {};
    if (!values.product_id) {
      errors.product_id = 'Required';
    }
    if (!values.tour_date) {
      errors.tour_date = 'Required';
    }
    // if (values.tour_time === 'default') {
    //   errors.tour_time = 'Required';
    // }
    if (values.tour_time === '') {
      errors.tour_time = 'Required';
    } else if (!validTime.test(values.tour_time)) {
      errors.tour_time = 'Enter valid time in the format hh:mm';
    }
    if (!values.contact_name) {
      errors.contact_name = 'Required';
    }
    if (!values.phone) {
      errors.phone = 'Required';
    } else if (!validPhoneNum.test(values.phone)) {
      errors.phone = 'Enter valid phone number';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!validEmail.test(values.email)) {
      errors.email = 'Enter valid email';
    }
    if (values.adults === '') {
      errors.adults = 'Required';
    } else if (!positiveNum.test(values.adults)) {
      errors.adults = 'Enter valid number';
    }
    if (values.children !== '' && !positiveNum0.test(values.children)) {
      errors.children = 'Enter valid number';
    }
    if (values.infants !== '' && !positiveNum0.test(values.infants)) {
      errors.infants = 'Enter valid number';
    }
    if (values.students !== '' && !positiveNum0.test(values.students)) {
      errors.students = 'Enter valid number';
    }
    if (values.teenagers !== '' && !positiveNum0.test(values.teenagers)) {
      errors.teenagers = 'Enter valid number';
    }
    if (values.group !== '' && !positiveNum0.test(values.group)) {
      errors.group = 'Enter valid number';
    }
    // if (!values.allow_overbooking && values.maxPax < Number(pax)) {
    //   errors.maxPax = 'Total PAX is exceeding the limit of available seats';
    // } else if (Number(pax) === 0) {
    //   errors.maxPax = 'PAX cannot be 0';
    // }
    if (values.pickup_location && !values.valid_location) {
      errors.valid_location = 'Please enter an address within pickup bounds';
    }
    return errors;
  };

  return (
    <Popup
      show={setIsEditing}
      backdrop
      dialogClassName="BookingModal"
    >
      <div className="BookingModal__booking-modal-container">
        <button
          type="button"
          className="BookingModal__close-btn"
          onClick={() => {
            navigate(`/bookings${search}`);
          }}
        >
          <i className="fas fa-times" />
        </button>

        <CreateBookingForm
          validate={validate}
          toursList={tours?.tours}
          toursLoading={toursLoading}
          toursError={toursError}
          productUnits={product?.productUnits?.units}
          productBounds={product}
          getProductUnits={getProductUnits}
          addBooking={addBooking}
        />
      </div>
    </Popup>
  );
}

export default NewCreateBooking;
