import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';

function Column2({
  setChecked,
  chosenBookings,
  setChosenBookings,
  setBookingReservations,
  bookingReservations,
  setSumChecked,
  activeReservation,
  setActiveReservation,
  checked,
  reservation,
}) {
  const navigate = useNavigate();
  const getPax = (units) => {
    let pax = '';
    units.map((unit) => {
      pax += `${unit.quantity}${unit.unit.label.slice(0, 1)} `;
    });
    return pax.trim();
  };

  const getStatus = (booking, reservationId) => {
    const reservation = booking?.bookingReservations?.filter(
      (res) => res.reservationId === reservationId,
    );
    if (reservation.length > 0) {
      return (reservation[0].status === 'RESERVED'
        ? <span className="Reservations__reserved">{reservation[0].status}</span>
        : <span className="Reservations__sent">{reservation[0].status}</span>
      );
    }
    return 'PENDING';
  };

  const getStartingTime = (reservation, { timeGroup, product }) => {
    const startingTimes = reservation.filter((attraction) => (
      attraction.product.id === product.id))[0].startingTime;

    if (!timeGroup || !startingTimes) return '';
    if (Object.keys(startingTimes).length === 1) {
      return Object.values(startingTimes)[0];
    }
    if (!startingTimes[timeGroup.toLowerCase()]) return '';

    return startingTimes[timeGroup.toLowerCase()];
  };

  const handleCheck = (e, pax, reservation, booking) => {
    const result = bookingReservations.filter((res) => (
      res.booking_id === booking.id && res.reservation_id === reservation.id));

    const chosenBookingsObj = {
      id: booking.id,
      guide: booking.booking_vehicles?.guide?.name || 'noguide',
      units: booking.bookingUnits,
      tourTime: booking.tourTime,
      startTime: getStartingTime(reservation.productReservations, booking),
    };

    const bookingReservationsObj = {
      booking_id: booking.id,
      reservation_id: reservation.id,
      pax,
    };

    if (activeReservation !== reservation.id) {
      setSumChecked('');
      setActiveReservation(reservation.id);
      setBookingReservations([bookingReservationsObj]);
      setChosenBookings([chosenBookingsObj]);
      setChecked([e.target.id]);
    } else if (result.length === 0) {
      setBookingReservations((res) => [...res, bookingReservationsObj]);
      setChosenBookings((bookings) => [...bookings, chosenBookingsObj]);
      setChecked((checkedList) => [...checkedList, e.target.id]);
    } else {
      setBookingReservations(bookingReservations.filter((res) => (
        !(res.booking_id === booking.id && res.reservation_id === reservation.id))));
      setChosenBookings(chosenBookings.filter((b) => (
        !(b.id === booking.id))));
      setChecked(checked.filter((id) => !(id === e.target.id)));
    }
  };

  const handleCheckAll = (e, reservation) => {
    setSumChecked('');
    setBookingReservations([]);
    setChosenBookings([]);
    setChecked([]);
    if (!checked.includes(reservation.id)) {
      setActiveReservation(e.target.id);
      reservation.productReservations?.map((product) => (
        product?.product?.bookings?.map((booking) => {
          setBookingReservations((res) => [...res, {
            booking_id: booking.id,
            reservation_id: e.target.id,
            pax: getPax(booking.bookingUnits),
          }]);
          setChosenBookings((bookings) => [...bookings, {
            id: booking.id,
            guide: booking.guide?.name || 'noguide',
            units: booking.bookingUnits,
            tourTime: booking.tourTime,
            startTime: getStartingTime(reservation.productReservations, booking),
          }]);
          setChecked((checkedList) => [...checkedList, `${booking.id}/${e.target.id}`, e.target.id]);
        })
      ));
    }
  };

  const getTableContent = (reservation, reservationId) => {
    const allBookings = [];
    reservation.productReservations?.map((product) => {
      product.product.bookings.map((booking) => allBookings.push(booking));
    });

    const sortedBookings = _.orderBy(allBookings, [(el) => (
      el.bookingReservations.filter(
        (res) => res.reservationId === reservationId,
      )?.[0]?.status
    ), 'tourTime'], ['desc', 'ask']);

    return (
      sortedBookings?.map((booking) => {
        const pax = getPax(booking.bookingUnits);
        const id = `${booking.id}/${reservation.id}`;

        return (
          <tr
            key={id}
            className={`Reservations-table-tr ${booking.status === 'COMPLETED'
              || booking.status === 'COMPLETED - COMPLAINT'
              || booking.status === 'NO SHOW' ? 'completed-booking' : ''}`}
          >
            <td className="checkbox">
              <input
                type="checkbox"
                checked={checked.includes(id)}
                id={id}
                onChange={(e) => handleCheck(e, pax, reservation, booking)}
              />
            </td>
            <td className="data status">
              {getStatus(booking, reservation.id)}
            </td>
            <td
              className="data id"
              onClick={() => navigate(`/reservations/${booking.id}`)}
            >
              {booking.id}
            </td>
            <td className="data">{booking?.product?.name}</td>
            <td className="data date">{booking?.tourDate}</td>
            <td className="data time">
              {dayjs(`1/1/2022 ${booking?.tourTime}`).format('hh:mm A')}
            </td>
            <td className="data time">
              {dayjs(`1/1/2022 ${getStartingTime(reservation.productReservations, booking)}`).format('hh:mm A')}
            </td>
            <td className="data guide">{booking?.booking_vehicles?.guide?.name || 'TBD'}</td>
            <td className="data pax">{pax}</td>
          </tr>
        );
      })
    );
  };

  return (
    <td className="td-column2">
      <tr>
        <th>
          <input
            type="checkbox"
            checked={checked.includes(reservation.id)}
            id={reservation.id}
            onChange={(e) => handleCheckAll(e, reservation)}
          />
        </th>
        <th>Status</th>
        <th>Booking Id</th>
        <th>Tour</th>
        <th>Tour date</th>
        <th>Tour time</th>
        <th>Start time</th>
        <th>Guide</th>
        <th>PAX</th>
      </tr>
      {getTableContent(reservation, reservation.id)}
    </td>
  );
}

export default Column2;
