/*eslint-disable*/
import React, { useState } from 'react';
import { statusList } from '../../../../utils/Variables';

const NotesAddForm = ({
  addNote,
  bookingId,
  showEditForm,
  setShowEditForm,
}) => {
  const [showAddForm, setShowAddForm] = useState();
  const [newNoteVal, setNewNoteVal] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addNote({
      variables: {
        input: {
          note: newNoteVal.trim(),
          booking_id: bookingId,
        },
      },
    });
    setShowAddForm(false);
    setNewNoteVal('');
  };

  return (
    <div className="Notes__note-new">
      {showAddForm
        ? (
          <form
            className="Notes__note-edit-form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <input
              required
              value={newNoteVal}
              onChange={(e) => setNewNoteVal(e.target.value)}
              autoFocus
            />
            <div className="Notes__note-edit-form-btn">
              <button type="submit">
                <i className="fas fa-save" />
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setNewNoteVal('');
                }}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          </form>
        )
        : (
          <div
            className="Notes__note-add-form-info"
            onClick={(e) => {
              setShowAddForm(true);
            }}
          >
            <div className="Notes__note-status Status-display__cancelled">
              {/* <i className="fas fa-plus Notes__note-status-check-mark" /> */}
            </div>
            Add new note
          </div>
        )}
    </div>
  );
};

export default NotesAddForm;
