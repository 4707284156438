import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  displayStatusColor,
  summaryFormatter,
  tourDateFormatter,
  paxFormatter,
  picLocFormatter,
  picTimeFormatter,
  assignedGuideFormatter,
  assignedVehicleFormatter,
} from '../../../utils/Formatters';
import '../styles/Details.scss';

function Details({
  clickedBooking,
  isEditing,
  setIsEditing,
}) {
  const [copiedText, setCopiedText] = useState(null);
  const displayClickableTags = (value) => (
    <p
      className="BookingModal__info-text copyToClipboard"
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopiedText(value);
        setTimeout(() => {
          setCopiedText(null);
        }, '500');
      }}
    >
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            {!copiedText
              ? 'Click to copy'
              : (
                <>
                  <i className="fas fa-check" />
                  <span>{' Copied'}</span>
                </>
              )}
          </Tooltip>
          )}
      >
        <span>
          {typeof value === 'string' ? value : '-'}
        </span>
      </OverlayTrigger>
    </p>
  );

  return (
    <div className="BookingModal__info">
      <button
        type="button"
        className="BookingModal__info-edit-btn"
        onClick={() => setIsEditing(!isEditing)}
      >
        Edit Booking
        <i className="far fa-edit" />
      </button>
      <div className="BookingModal__info-status">
        {displayStatusColor(clickedBooking?.status)}
        {summaryFormatter(clickedBooking)}
      </div>
      <div className="BookingModal__info-details">
        <div>
          <h2 className="BookingModal__info-header">
            Tour
          </h2>
          {displayClickableTags(clickedBooking?.product?.tourName)}
          {displayClickableTags(tourDateFormatter(clickedBooking))}
        </div>
        <div>
          <h2 className="BookingModal__info-header">Customer Info</h2>
          {displayClickableTags(clickedBooking?.customer?.contactName)}
          {displayClickableTags(clickedBooking?.customer?.phone)}
          {displayClickableTags(clickedBooking?.customer?.email)}

          <h2 className="BookingModal__info-header">
            Secondary phone/email
          </h2>
          {displayClickableTags(clickedBooking?.customer?.secondaryPhone)}
          {displayClickableTags(clickedBooking?.customer?.secondaryEmail)}
        </div>
        <div>
          <h2 className="BookingModal__info-header">Booking ID</h2>
          <div className="BookingModal__info-externalLink">
            {displayClickableTags(clickedBooking?.id)}
            <a
              href={`https://www.seesight-tours.com/my-tours/${clickedBooking?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-external-link-alt" />
            </a>
          </div>
        </div>
        {clickedBooking?.REZDY?.length > 0
          && (
            <>
              <div>
                <h2 className="BookingModal__info-header">Source ID</h2>
                <div className="BookingModal__info-text">
                  {`${clickedBooking.source}: `}
                  {displayClickableTags(clickedBooking.REZDY[0].id)}
                </div>
              </div>

              <div>
                <h2 className="BookingModal__info-header">OTA ID</h2>
                <p className="BookingModal__info-text">
                  {`${clickedBooking.REZDY[0].reseller ?? 'None'} ${clickedBooking.REZDY[0].resellerReference ?? 'None'}`}
                </p>
              </div>
            </>
          )}
        <div>
          <h2 className="BookingModal__info-header">PAX</h2>
          <p className="BookingModal__info-text">
            {paxFormatter(clickedBooking?.bookingUnits)}
          </p>
        </div>
        {clickedBooking.product?.pickupRequired
          ? (
            <div>
              <h2 className="BookingModal__info-header">Pickup Location</h2>
              {displayClickableTags(picLocFormatter(clickedBooking))}
            </div>
          )
          : (
            <div>
              <h2 className="BookingModal__info-header">Meetup Location</h2>
              {displayClickableTags(clickedBooking.product?.pickupBounds?.[0])}
            </div>
          )}

        <div>
          <h2 className="BookingModal__info-header">Pickup Time</h2>
          {displayClickableTags(picTimeFormatter(clickedBooking))}
        </div>
        {clickedBooking?.REZDY?.length > 0
          && (
            <div>
              <h2 className="BookingModal__info-header">OTA Pickup Location</h2>
              <p className="BookingModal__info-text">
                {clickedBooking.REZDY[0].pickupLocation}
              </p>
            </div>
          )}
        <div>
          <h2 className="BookingModal__info-header">Assigned Guide</h2>
          <p className="BookingModal__info-text">
            {clickedBooking?.booking_vehicles?.[0]?.guide?.name || 'TBD'}
          </p>
        </div>
        <div>
          <h2 className="BookingModal__info-header">Assigned Vehicle</h2>
          <p className="BookingModal__info-text">
            {clickedBooking?.booking_vehicles?.[0]?.vehicle?.name || 'TBD'}
          </p>
        </div>
        <div>
          <h2 className="BookingModal__info-header">Customer Notes</h2>
          <p className="BookingModal__info-text-notes">
            {clickedBooking?.notes}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Details;
