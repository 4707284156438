import React from 'react';

function Subtask({
  item,
  idx,
  textHandler,
  activeInput,
  isSubTaskOpen,
  unFocused,
  setActiveInput,
  setShowListHandler,
  showList,
  statusHandler,
  addSubtask,
}) {
  const status = ['Urgent', 'Need Attention', 'Pending', 'Done'];

  // return (
  //   <Collapse isOpen={isSubTaskOpen}>
  //     <Card className="card">
  //       <CardBody>
  //         {item.subtasks.map((subtask, index) => {
  //           const textToDisplay = subtask?.title?.split('').includes('@')
  //             && subtask?.title?.split('@');
  //           return (
  //             <div className="d-flex subtask_align">
  //               {/* {subtask.subStatus === 'Urgent' ? (
  //                 <i className="fas fa-exclamation-circle urgent" />
  //               ) : subtask.subStatus === 'Done' ? (
  //                 <i className="fas fa-check-circle done" />
  //               ) : subtask.subStatus === 'Pending' ? (
  //                 <i className="fas fa-circle pending" />
  //               ) : subtask.subStatus === 'Need Attention' ? (
  //                 <i className="fas fa-circle need_Attention" />
  //               ) : (
  //                 <i className="fas fa-circle need_Attention" />
  //               )} */}
  //               {/* {activeInput === subtask.title ? (
  //                 <Input
  //                   Type="text"
  //                   value={subtask.title}
  //                   onChange={(e) => textHandler(
  //                     index,
  //                     {
  //                       ...subtask,
  //                       title: e.target.value,
  //                     },
  //                     idx,
  //                   )}
  //                   onBlur={() => unFocused()}
  //                 />
  //               ) : textToDisplay ? (
  //                 <p onClick={() => setActiveInput(subtask.title)}>
  //                   {`${textToDisplay[0]}`}
  //                   <span
  //                     style={{ color: 'blue' }}
  //                   >
  //                     {`@${textToDisplay[1]}`}
  //                   </span>
  //                 </p>
  //               ) : (
  //                 <p onClick={() => setActiveInput(subtask.title)}>
  //                   {subtask.title}
  //                 </p>
  //               )} */}
  //               <i
  //                 className="fal fa-ellipsis-v"
  //                 onClick={() => setShowListHandler(subtask.title)}
  //               />

  //               {showList === subtask.title && (
  //                 <div className="status_dropDown">
  //                   {status.map((status) => (
  //                     <div
  //                       onClick={() => statusHandler(index, status, idx)}
  //                     >
  //                       {`${status}`}
  //                     </div>
  //                   ))}
  //                 </div>
  //               )}
  //             </div>
  //           );
  //         })}

  //         <div className="d-flex subtask_align" onClick={() => addSubtask(idx)}>
  //           <i className="fas fa-plus" />
  //           <Input type="text" value="Add New subTasks" />
  //         </div>
  //       </CardBody>
  //     </Card>
  //   </Collapse>
  // );
}

export default Subtask;
