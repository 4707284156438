import React, { useState } from 'react';
import './taskDasboard.scss';
import moment from 'moment';
import items from './items';
import Header from '../../components/Header/Header';
import HeaderFilter from './components/headerFilter';
import AddNewTask from './components/addNewTask';
import Tasks from './components/task/index';

function TaskDashBoard() {
  const status = ['Urgent', 'Need Attention', 'Pending', 'Done'];
  const [data, setData] = useState(items);
  const [active, setActive] = useState([]);
  const [isSubTaskOpen, setIsSubTaskOpen] = useState('');
  const [activeInput, setActiveInput] = useState('');
  const [showList, setShowList] = useState('dont display');
  const [filter, setFilter] = useState({
    city: 'All City',
    type: 'All Type',
    status: 'All Status',
    assigned: 'All Assigned',
  });
  const [newTask, setNewTask] = useState({
    name: '',
    timeStamp: '',
    city: '',
    type: '',
    status: '',
    subtasks: [],
  });

  const save = () => {
    const timeStamp = moment(new Date()).format('LL hh:mm');
    const x = Math.floor(Math.random() * 4);
    const newStatus = status[x];
    setData([...data, { ...newTask, timeStamp, status: newStatus }]);
    setNewTask({
      name: '',
      timeStamp: '',
      city: '',
      type: '',
      status: '',
      subtasks: [],
    });
  };

  const newSubtaskAdd = (updated, index) => {
    setActiveInput(updated.title);
    const updateObj = newTask;
    const updateArr = updateObj.subtasks.slice('');
    updateArr[index] = updated;
    updateObj.subtasks = [...updateArr];
    setNewTask(updateObj);
    setShowList('dont display');
  };

  const addNewSubtask = () => {
    setNewTask({
      ...newTask,
      subtasks: [...newTask.subtasks, { title: '', subStatus: '' }],
    });
  };

  const filterHandler = (filterType, value) => {
    const item = { ...filter };
    item[filterType] = value;
    setFilter(item);
  };

  const subTaskOpenHandler = (name) => {
    if (isSubTaskOpen === '') {
      setIsSubTaskOpen(name);
    } else {
      setIsSubTaskOpen('');
    }
  };

  const setShowListHandler = (name) => {
    if (showList === 'dont display') {
      setShowList(name);
    } else {
      setShowList('dont display');
    }
  };

  const unFocused = () => {
    setActiveInput('');
  };

  const statusHandler = (index, substatus, idx) => {
    const item = data.slice('');
    const subTask = item[idx];
    const subtaskStatus = subTask.subtasks[index];
    subtaskStatus.subStatus = substatus;
    subTask.subtasks[index] = subtaskStatus;
    item[idx] = subTask;
    setData(item);
    setShowList('dont display');
  };

  const textHandler = (index, updated, idx) => {
    setActiveInput(updated.title);
    const item = data.slice('');
    const itemObj = item[idx];
    const subtaskArr = itemObj.subtasks;
    subtaskArr[index] = updated;
    itemObj.subtasks = subtaskArr;
    item[idx] = itemObj;
    setData(item);
  };

  const addSubtask = (idx) => {
    const item = data.slice('');
    const subTask = item[idx];
    subTask.subtasks.push({ title: '', subStatus: '' });
    item[idx] = subTask;
    setData(item);
  };

  const activeDiv = (name) => {
    const itemCheck = active.filter((a) => a === name);
    if (itemCheck.length === 0) {
      setActive([...active, name]);
    } else {
      const update = [...active];
      active.forEach((item, index) => {
        if (item === name) {
          update.splice(index, 1);
        }
      });
      setActive(update);
    }
  };

  const selectAll = () => {
    let update = [];
    if (active.length === 0) {
      data.forEach((item) => {
        update = [...update, item.name];
      });
    }
    setActive(update);
  };

  let filterItem = data.slice('');

  if (filter.city !== 'All City') {
    filterItem = filterItem.filter((item) => filter.city === item.city);
  }
  if (filter.type !== 'All Type') {
    filterItem = filterItem.filter((item) => filter.type === item.type);
  }

  if (filter.status !== 'All Status') {
    filterItem = filterItem.filter((item) => filter.status === item.status);
  }

  return (
    <>
      <Header />
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  {' '}
                  <HeaderFilter filter={filter} filterHandler={filterHandler} />
                </div>
                <div className="card-body">
                  <div>
                    <div className="TaskDashBoard__header">
                      <div>
                        {/* {active.length === 0 ? (
                          <i
                            className="far fa-circle"
                            onClick={() => selectAll()}
                          />
                        ) : active.length === data.length ? (
                          <i
                            className="fas fa-check-circle"
                            onClick={() => selectAll()}
                          />
                        ) : (
                          <i
                            className="fas fa-minus-circle"
                            onClick={() => selectAll()}
                          />
                        )} */}
                        {/* <Label>
                          Task
                          {' '}
                          <span>
                            Status
                            <i className="fal fa-chevron-down" />
                          </span>
                        </Label> */}
                      </div>
                      <div className="city_type">
                        <span>City</span>
                        <span>Type</span>
                      </div>
                    </div>

                    <div className="taskWrapper">
                      <Tasks
                        filterItem={filterItem}
                        activeDiv={activeDiv}
                        subTaskOpenHandler={subTaskOpenHandler}
                        isSubTaskOpen={isSubTaskOpen}
                        textHandler={textHandler}
                        unFocused={unFocused}
                        setActiveInput={setActiveInput}
                        setShowListHandler={setShowListHandler}
                        statusHandler={statusHandler}
                        addSubtask={addSubtask}
                        active={active}
                        activeInput={activeInput}
                        showList={showList}
                      />

                      <AddNewTask
                        newTask={newTask}
                        setNewTask={setNewTask}
                        save={save}
                        newSubtaskAdd={newSubtaskAdd}
                        setActiveInput={setActiveInput}
                        setShowListHandler={setShowListHandler}
                        showList={showList}
                        addNewSubtask={addNewSubtask}
                        activeInput={activeInput}
                        unFocused={unFocused}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskDashBoard;
