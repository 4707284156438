import React from 'react';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import '../../styles/Reservation.scss';

function Reservation({ clickedBooking, updateReservationStatus }) {
  const handleChange = (e, reservationId) => {
    updateReservationStatus({
      variables: {
        bookingId: clickedBooking.id,
        reservationId,
        status: e.target.value,
      },
    });
  };

  return (
    <div className="Reservation">
      {
        clickedBooking?.reservations?.map((reservation) => (
          <div key={reservation.id} className="Reservation-log">
            <div className="Reservation-log-header">
              <h2>{reservation.reservation.attraction}</h2>
              <Form.Select
                className="form-input"
                value={reservation.status}
                onChange={(e) => { handleChange(e, reservation.id); }}
              >
                <option value="RESERVED">
                  RESERVED
                </option>
                <option value="SENT">
                  SENT
                </option>
              </Form.Select>
            </div>
            {reservation.log && (
              <div>
                <p>{`Email Status: ${reservation.log.status}`}</p>
                <p>{dayjs(`${reservation.log.date} ${reservation.log.time}`).format('YYYY-MM-DD HH:mm')}</p>
                <p>{`To: ${reservation.log.content?.contact}`}</p>
                {reservation.log.content?.contactCC && <p>{`Cc: ${reservation.log.content?.contactCC}`}</p>}
                <p>{reservation.log.content?.subject}</p>
                <p>{reservation.log.content?.body}</p>
              </div>
            )}
          </div>
        ))
      }
    </div>
  );
}

export default Reservation;
