import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Divider, IconButton, Modal, TextField, Typography,
} from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import SchedulingUpdateBookingSchema from '../../../yup/schedulingUpdateBooking';
import { UPDATE_PICKUP } from '../../../api/bookings';

function EditBooking({
  editModalOpen, handleModalClose, initialValues, screenSize, vehicles,
  moveBooking, items,
}) {
  function getArrayKey(id) {
    const keys = Object.keys(items);
    for (let i = 0; i < keys.length; i += 1) {
      const array = items[keys[i]];
      for (let j = 0; j < array.length; j += 1) {
        if (array[j].id === id) {
          return keys[i];
        }
      }
    }
    return null; // id not found in any array
  }

  const [updating, setUpdating] = useState(false);
  const [updatePickupAndGuide] = useMutation(UPDATE_PICKUP);
  const [selectedVehicle, setSelectedVehicle] = useState(
    null,
  );
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const assignedVehicle = getArrayKey(initialValues?.bookingId);
    console.log(assignedVehicle);
    setSelectedVehicle(assignedVehicle);
  }, []);

  const handleVehicleChange = (event, value) => {
    const oldId = selectedVehicle;
    const newId = value?.id;
    moveBooking({ oldId, newId, bookingId: initialValues?.bookingId });
    setSelectedVehicle(newId);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const {
    values, errors, handleChange, dirty, isSubmitting, handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: SchedulingUpdateBookingSchema,
    validateOnChange: true,
    onSubmit: ({ bookingId, pickupTime, pickupLocation }) => {
      setUpdating(true);
      updatePickupAndGuide({
        variables: {
          id: bookingId,
          pickup_time: pickupTime,
          pickup_location: pickupLocation,
        },
      }).then(() => {
        toast.success('Booking Updated', {
          autoClose: 2000,
          position: 'bottom-right',
        });
        handleModalClose();
      }).catch((error) => {
        toast.error(`Can't update Booking: ${error}`, {
          autoClose: 2000,
          position: 'bottom-right',
        });
      })
        .finally(() => {
          setUpdating(false);
        });
    },
  });

  const handleClose = async (signalToUpdate = false) => {
    setUpdating(signalToUpdate);
    await handleModalClose(signalToUpdate);
    setUpdating(false);
  };

  return (
    <Dialog open={editModalOpen} onClose={handleClose}>
      <DialogTitle style={{ width: screenSize === 'lg' ? '30vw' : '90vw' }}>
        <Typography variant="h5" align="center">
          Edit Booking
        </Typography>
        <IconButton style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleClose}>
          <FaTimes size={20} />
        </IconButton>
      </DialogTitle>
      <DialogContent className="pb-5 pt-3">
        <form onSubmit={handleSubmit}>

          <Form.Group>
            <Form.Label>Pickup Time</Form.Label>
            <InputGroup className={errors.pickupTime ? 'is-invalid' : ''}>
              <Form.Control
                id="pickupTime"
                name="pickupTime"
                type="text"
                onChange={handleChange}
                value={values.pickupTime}
                className={errors.pickupTime ? 'is-invalid' : ''}
                placeholder="Enter Pickup Time"
              />
            </InputGroup>
            {errors.pickupTime ? <div className="Booking__error">{errors.pickupTime}</div> : null}
          </Form.Group>

          <Form.Group>
            <Form.Label>Pickup Location</Form.Label>
            <InputGroup className={errors.pickupLocation ? 'is-invalid' : ''}>
              <Form.Control
                id="pickupLocation"
                name="pickupLocation"
                type="text"
                onChange={handleChange}
                value={values.pickupLocation}
                className={errors.pickupLocation ? 'is-invalid' : ''}
                placeholder="Enter Pickup Location"
              />
            </InputGroup>
            {errors.pickupLocation ? <div className="Booking__error">{errors.pickupLocation}</div> : null}
          </Form.Group>
        </form>
      </DialogContent>
      <DialogActions className="mb-4 me-4">
        <button
          type="button"
          onClick={handleSubmit}
          className="Guides__buttons-save"
          disabled={updating}
        >
          Update
        </button>
        <button
          type="button"
          onClick={() => handleClose()}
          className="Guides__buttons-cancel"
        >
          Cancel
        </button>
      </DialogActions>
      { typeof selectedVehicle === 'string' && (
      <DialogContent>
        <Divider />
        <Autocomplete
          options={[{ id: 'unassigned', name: 'Unassigned' }, ...vehicles]}
          getOptionLabel={(option) => option.name}
          // getOptionSelected={(option, value) => selectedVehicle === value.id}
          value={vehicles.find(({ id }) => id === selectedVehicle)}
          onChange={handleVehicleChange}
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select vehicle"
              InputProps={{
                ...params.InputProps,
                startAdornment: null,
                endAdornment: null,
                className: 'form-control',
              }}
              onChange={handleSearch}
            />
          )}
          classes={{
            paper: 'bg-light border rounded-0',
            option: 'py-2 px-3 w-100',
            listbox: 'border rounded-0',
          }}
        />
      </DialogContent>
      )}
    </Dialog>
  );
}

export default EditBooking;
