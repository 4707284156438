import React from 'react';
import './Loader.css';

function Loader() {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <svg className="circle-1" viewBox="0 0 120 120">
          <circle cx="60" cy="60" r="30" fill="transparent" stroke="tomato" strokeWidth="5" strokeDasharray="3, 5" />
        </svg>
        <svg className="circle-2" viewBox="0 0 120 120">
          <circle cx="60" cy="60" r="20" fill="transparent" stroke="tomato" strokeWidth="5" strokeDasharray="3, 5" />
        </svg>
        <svg className="circle-3" viewBox="0 0 120 120">
          <circle cx="60" cy="60" r="10" fill="transparent" stroke="tomato" strokeWidth="5" strokeDasharray="3, 5" />
        </svg>
      </div>
    </div>
  );
}

export default Loader;
