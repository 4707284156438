import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { FETCH_CITIES } from "../../../../api/city";
import { ADD_CITIES } from "../../../../api/city";
import "../../cities.scss";
import CityForm from "../CityForm";
import CitySchema from "../../../../yup/city";

function CreateCity() {
  const navigate = useNavigate();

  const { data } = useSubscription(FETCH_CITIES);
  const [addCities] = useMutation(ADD_CITIES, {
    onCompleted: () => {
      toast.success("City created!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000,
      });
      navigate("/cities");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      coordinates: "",
      timeZone: "",
      longitude: "",
      latitude: "",
      locale: "",
      country: "",
    },
    // validate,
    validateOnChange: true,
    validationSchema: CitySchema,
    onSubmit: (values) => {
      addCities({
        variables: {
          input: {
            name: values.name,
            coordinates: { lat: values.latitude, lng: values.longitude },
            time_zone: values.time_zone,
            locale: values.locale,
            country: values.country,
          },
        },
      }).catch((e) => {
        alert(e);
        console.log(e);
      });
    },
  });

  return (
    <div className="Vehicles__editVehicle">
      <CityForm formik={formik} cities={data?.boatnew_cities} vehicle={null} />
    </div>
  );
}

export default CreateCity;
