import React, { useState } from "react";
import dayjs from "dayjs";
import "./Event.scss";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { EventProps } from "react-big-calendar";
import { useMutation } from "@apollo/client";
import { Box, Button, Modal } from "@mui/material";
import { AvailabilityType, Event as CapsuleEvent } from "./types";
import ContextMenu from "./ContextMenu";
import { DELETE_SESSION } from "../../api/Sessions";
import {
  FETCH_AVAILABILITY_SERIES,
  FETCH_AVAILABILITY_VIEW,
  UPDATE_AVAILABILITIES_SERIES,
} from "../../api/calendar";
import styles from "./Sessions.module.scss";

const deleteConfirmationStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

dayjs.extend(localizedFormat);
export default function Event({
  event: {
    color,
    totalRemaining,
    start,
    confirmedBookings,
    cancelled,
    id,
    totalSeats,
    productsSelected,
    monthSelected,
    availableseats,
  },
}: EventProps<CapsuleEvent>) {
  const [deleteSession] = useMutation(DELETE_SESSION);
  const [blockSession] = useMutation(UPDATE_AVAILABILITIES_SERIES);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [openBlockConfirmation, setOpenBlockConfirmation] =
    useState<boolean>(false);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleShowBlockConfirmation = (): void => {
    handleClose();
    setOpenBlockConfirmation(true);
  };
  const handleShowDeleteConfirmation = (): void => {
    handleClose();
    setOpenDeleteConfirmation(true);
  };
  const handleHideBlockConfirmation = (): void => {
    setOpenBlockConfirmation(false);
  };
  const handleHideDeleteConfirmation = (): void => {
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteSession = (event: any) => {
    if (id && monthSelected) {
      handleClose();
      const availabilities: AvailabilityType[] = [];
      productsSelected?.forEach((product) =>
        product?.availabilities?.forEach((availability) => {
          availabilities.push(availability);
        })
      );
      const beginningOfMonth = dayjs()
        .month(monthSelected)
        .startOf("month")
        .toISOString();
      const endOfMonth = dayjs(beginningOfMonth).endOf("month").toISOString();
      deleteSession({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: FETCH_AVAILABILITY_SERIES,
            variables: {
              filters: {
                _or: availabilities.map((avail) => ({
                  parent_id: { _eq: avail.id },
                })),
                start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
              },
            },
          },
          {
            query: FETCH_AVAILABILITY_VIEW,
            variables: {
              id,
            },
          },
        ],
      });
    }
  };
  const handleBlockSession = (): void => {
    if (id && monthSelected) {
      const availabilities: AvailabilityType[] = [];
      productsSelected?.forEach((product) =>
        product?.availabilities?.forEach((availability) => {
          availabilities.push(availability);
        })
      );
      const beginningOfMonth = dayjs()
        .month(monthSelected)
        .startOf("month")
        .toISOString();
      const endOfMonth = dayjs(beginningOfMonth).endOf("month").toISOString();
      blockSession({
        variables: {
          id,
          set: {
            exception: { total_remaining: 0 },
          },
        },
        refetchQueries: [
          {
            query: FETCH_AVAILABILITY_SERIES,
            variables: {
              filters: {
                _or: availabilities.map((avail) => ({
                  parent_id: { _eq: avail.id },
                })),
                start_time: { _gte: beginningOfMonth, _lte: endOfMonth },
              },
            },
          },
          {
            query: FETCH_AVAILABILITY_VIEW,
            variables: {
              id,
            },
          },
        ],
        onCompleted: () => {
          setOpenBlockConfirmation(false);
        },
      });
    }
  };
  return (
    <div
      onContextMenu={(e) => handleContextMenu(e)}
      className="capsule"
      style={{ background: color }}
    >
      <div className="capsule__metaInfo">
        <p
          className="capsule__cancelled capsule__text "
          style={{ width: "90px" }}
        >{` ${availableseats} available`}</p>

        {/* must convert date to string to display */}
        <p className="capsule__text">{dayjs(start).format("LT").toString()}</p>
      </div>

      <div
        className="capsule__statuses "
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <p className="capsule__text  ">{`${totalRemaining ?? 0} consumed`}</p>
        <p
          className="capsule__green capsule__text"
          style={{ width: "50px" }}
        >{`${totalSeats} total`}</p>
      </div>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        handleShowDeleteConfirmation={handleShowDeleteConfirmation}
        handleShowBlockConfirmation={handleShowBlockConfirmation}
      />
      <Modal
        open={openDeleteConfirmation}
        onClose={handleHideDeleteConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteConfirmationStyles}>
          <h3 className={styles.deleteConfirmationHeader}>
            Are you sure you wish to delete this session?
          </h3>
          <div className={styles.deleteConfirmationButtons}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteSession}
            >
              Delete
            </Button>
            <Button variant="outlined" onClick={handleHideDeleteConfirmation}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openBlockConfirmation}
        onClose={handleHideBlockConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteConfirmationStyles}>
          <h3 className={styles.deleteConfirmationHeader}>
            Are you sure you wish to stop accepting bookings for this day?
          </h3>
          <div className={styles.deleteConfirmationButtons}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleBlockSession}
            >
              Block
            </Button>
            <Button variant="outlined" onClick={handleHideDeleteConfirmation}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
