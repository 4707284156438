import { gql } from '@apollo/client';

const FRAGMENT_BOOKING = gql`
fragment FragmentBooking on boatnew_booking{
  id
  status
  tourDate: tour_date
  tourTime: tour_time
  customerId: customer_id
  notes: notes_customer
  source
  availabilitySeriesId: availability_series_id
  assignedVehicle: assigned_vehicle
  assignedGuide: assigned_guide
  pickupTime: pickup_time
  pickupLocation: pickup_location
  updatedAt: updated_at
  createdAt: created_at
  bookingUnits: booking_units (order_by: {unit: {label: asc}}){
    quantity
    price
    unit {
      label
      id
    }
  }
  availability {
      availability {
        id
      }
    }
  internalNotes: booking_notes {
    id
    note
    updatedAt: updated_at
    createdAt: created_at
  }
  reservationNotes: reservation_notes {
    id
    note
    updatedAt: updated_at
    createdAt: created_at
  }
  notesGuide: booking_notes_guides {
    id
    note
    updatedAt: updated_at
    createdAt: created_at
  }
  product {
    tourName: name
    pickupBounds: pickup_bounds
    tourId: id
    type
    citiesProducts: cities_products {
      city {
        id
        name
        coordinates
        timeZone: time_zone
      }
    }
  }
  guide {
      name: full_name
    }
  vehicle {
      name
    }
  customer {
    customerId: id
    contactName: contact_name
    email
    phone
    secondaryEmail: secondary_email
    secondaryPhone: secondary_phone
  }
  REZDY: rezdy {
    id
    reseller
    resellerReference: reseller_reference
    pickupLocation: pickup_location
  }
  booking_vehicles {
    assignedGuide: guide_id
    assignedVehicle: vehicle_id
    group
    guide {
      name: full_name
    }
    vehicle {
      name
    }
  }
}
`;

const FRAGMENT_VEHICLES = gql`
fragment FragmentVehicles on boatnew_vehicles {
      id
      name
      capacity
      city {
        name
        id
      }
      model: make_model
      plateNumber: plate_number
      vinNumber: vin_number
      year
      colour
      insurance
      ownership
      notes: maintenence
      cityId: city_id
}
`;

const FRAGMENT_BOOKING_STATUS = gql`
fragment FragmentBookingStatus on boatnew_booking_status_sort{
  id
  status
  tourDate: tour_date
  tourTime: tour_time
  customerId: customer_id
  notes: notes_customer
  source
  availabilitySeriesId: availability_series_id
  assignedVehicle: assigned_vehicle
  assignedGuide: assigned_guide
  pickupTime: pickup_time
  pickupLocation: pickup_location
  updatedAt: updated_at
  createdAt: created_at
  timeGroup: time_group
  booking_vehicles {
    assignedGuide: guide_id
    assignedVehicle: vehicle_id
    group
    guide {
      name: full_name
    }
    vehicle {
      name
    }
  }
  bookingUnits: booking_units (order_by: {unit: {label: asc}}){
    quantity
    price
    unit {
      label
      id
    }
  }
  availability {
      availability {
        id
      }
    }
  internalNotes: booking_notes {
    id
    note
    updatedAt: updated_at
    createdAt: created_at
  }
  reservationNotes: reservation_notes {
    id
    note
    updatedAt: updated_at
    createdAt: created_at
  }
  notesGuide: booking_notes_guides {
    id
    note
    updatedAt: updated_at
    createdAt: created_at
  }
  product {
    tourName: name
    pickupBounds: pickup_bounds
    tourId: id
    type
    citiesProducts: cities_products {
      city {
        id
        coordinates
        name
      }
    }
  }
  guide {
      name: full_name
    }
  vehicle {
      name
    }
  customer {
    customerId: id
    contactName: contact_name
    email
    phone
    secondaryEmail: secondary_email
    secondaryPhone: secondary_phone
  }
  REZDY: rezdy {
    id
    reseller
    resellerReference: reseller_reference
    pickupLocation: pickup_location
  }
}
`;

const FRAGMENT_ONGOING_BOOKINGS = gql`
fragment FragmentOngoingBookings on boatnew_ongoing_bookings{
  id
  status
  tourDate: tour_date
  tourTime: tour_time
  customerId: customer_id
  notes: notes_customer
  source
  availabilitySeriesId: availability_series_id
  assignedVehicle: assigned_vehicle
  assignedGuide: assigned_guide
  pickupTime: pickup_time
  pickupLocation: pickup_location
  updatedAt: updated_at
  createdAt: created_at
  booking_vehicles {
    assignedGuide: guide_id
    assignedVehicle: vehicle_id
    group
    guide {
      name: full_name
    }
    vehicle {
      name
    }
  }
  bookingUnits: booking_units (order_by: {unit: {label: asc}}){
    quantity
    price
    unit {
      label
      id
    }
  }
  availability {
      availability {
        id
      }
    }
  internalNotes: booking_notes {
      id
      note
      updatedAt: updated_at
      createdAt: created_at
    }
    notesGuide: booking_notes_guides {
      id
      note
      updatedAt: updated_at
      createdAt: created_at
    }
  product {
    tourName: name
    pickupBounds: pickup_bounds
    tourId: id
    type
    citiesProducts: cities_products {
      city {
        id
        coordinates
        name
      }
    }
  }
  guide {
      name: full_name
    }
  vehicle {
      name
    }
  customer {
    customerId: id
    contactName: contact_name
    email
    phone
    secondaryEmail: secondary_email
    secondaryPhone: secondary_phone
  }
  REZDY: rezdy {
    id
    reseller
    resellerReference: reseller_reference
    pickupLocation: pickup_location
  }
}
`;

const FRAGMENT_BOOKING_LATEST_STATUS = gql`
fragment FragmentBookingLatestStatus on boatnew_booking_latest_status_sort{
  id
  status
  tourDate: tour_date
  tourTime: tour_time
  customerId: customer_id
  notes: notes_customer
  source
  availabilitySeriesId: availability_series_id
  assignedVehicle: assigned_vehicle
  assignedGuide: assigned_guide
  pickupTime: pickup_time
  pickupLocation: pickup_location
  updatedAt: updated_at
  createdAt: created_at
  booking_vehicles {
    assignedGuide: guide_id
    assignedVehicle: vehicle_id
    group
    guide {
      name: full_name
    }
    vehicle {
      name
    }
  }
  bookingUnits: booking_units (order_by: {unit: {label: asc}}){
    quantity
    price
    unit {
      label
      id
    }
  }
  availability {
      availability {
        id
      }
    }
  internalNotes: booking_notes {
      id
      note
      updatedAt: updated_at
      createdAt: created_at
    }
    notesGuide: booking_notes_guides {
      id
      note
      updatedAt: updated_at
      createdAt: created_at
    }
  product {
    tourName: name
    pickupBounds: pickup_bounds
    tourId: id
    type
    citiesProducts: cities_products {
      city {
        id
        coordinates
        name
      }
    }
  }
  guide {
      name: full_name
    }
  vehicle {
      name
    }
  customer {
    customerId: id
    contactName: contact_name
    email
    phone
    secondaryEmail: secondary_email
    secondaryPhone: secondary_phone
  }
  REZDY: rezdy {
    id
    reseller
    resellerReference: reseller_reference
    pickupLocation: pickup_location
  }
}
`;

const FRAGMENT_GUIDES = gql`
fragment FragmentGuides on boatnew_guides {
  id
  full_name
  email_personal
  phone
  updated_at
  created_at
  airtable_id
  approved
  status
  picture
  verf_doc
  driving_license
  verf_face
  vehicles {
    vehicle {
           insurance
           ownership
           photos
    }
}

}
`;

export {
  FRAGMENT_BOOKING,
  FRAGMENT_VEHICLES,
  FRAGMENT_BOOKING_STATUS,
  FRAGMENT_GUIDES,
  FRAGMENT_BOOKING_LATEST_STATUS,
  FRAGMENT_ONGOING_BOOKINGS,
};
