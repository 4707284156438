export const permissions = [
  {
    title: "Bookings",
    description: "",
    code: "/bookings",
    parent: null,
  },
  {
    title: "Scheduling",
    description: "",
    code: "/scheduling",
    parent: null,
  },
  {
    title: "Products",
    description: "",
    code: "/products",
    parent: null,
  },
  {
    title: "Reservations",
    description: "",
    code: "/reservations",
    parent: null,
  },
  {
    title: "Vehicles",
    description: "",
    code: "/vehicles",
    parent: null,
  },
  {
    title: "Guides",
    description: "",
    code: "/guides",
    parent: null,
  },
  //   {
  //     title: 'Create Guide',
  //     description: 'User can create guide.',
  //     code: '/guides/create',
  //     parent: '/guides',
  //   },
  //   {
  //     title: 'Edit Guide',
  //     description: '',
  //     code: '/guides/edit',
  //     parent: '/guides',
  //   },
  {
    title: "Sessions",
    description: "",
    code: "/sessions",
    parent: null,
  },
  {
    title: "Users",
    description: "",
    code: "/users",
    parent: null,
  },
  {
    title: "News",
    description: "",
    code: "/news",
    parent: null,
  },
  {
    title: "Logs",
    description: "",
    code: "/logs",
    parent: null,
  },
];

export function getPermissionsString(params) {
  params = JSON.parse(params);
  if (params === "*") return "All";
  if (Array.isArray(params)) {
    const selectedPermissions = params
      .map((code) => {
        const permission = permissions.find((item) => item.code === code);
        if (permission) {
          return permission;
        }
      })
      .filter(Boolean);

    const permissionTitles = selectedPermissions.map((permission) => {
      let { title } = permission;
      let parentCode = permission.parent;
      const childPermissions = [];

      while (parentCode) {
        const parentPermission = permissions.find(
          (item) => item.code === parentCode
        );
        if (parentPermission) {
          childPermissions.push(title);
          title = parentPermission.title;
          parentCode = parentPermission.parent;
        } else {
          break;
        }
      }

      if (childPermissions.length > 0) {
        return `${title} (${childPermissions.reverse().join(", ")})`;
      }
      return title;
    });

    return permissionTitles.join(", ");
  }
  return "Something went wrong! Please take a video or screenshot and report to the tech team as soon as possible.";
}

export default permissions;
