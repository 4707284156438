import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import newsSchema from '../../../../yup/news';
import { CREATE_NEWS_MUTATION, EDIT_NEWS_MUTATION } from '../../../../api/news';
import './Popup.css';

function ConfirmationModal({
  action, open, handleClose, handleConfirm, selectedNews,
}) {
  const [errors, setErrors] = useState({});

  const [createNews] = useMutation(CREATE_NEWS_MUTATION);
  const [editNews] = useMutation(EDIT_NEWS_MUTATION);

  const formik = useFormik({
    initialValues: {
      title: '',
      news: '',
    },
    validationSchema: newsSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        if (action === 'create') {
          await createNews({
            variables: {
              title: values.title,
              news: values.news,
            },
          });
          toast.success(
            'Successfully created!',
            {
              autoClose: 4000,
              position: 'bottom-right',
            },
          );
        } else if (action === 'edit') {
          await editNews({
            variables: {
              newsId: selectedNews?.id || '',
              title: values.title,
              news: values.news,
            },
          });
          toast.success(
            'Successfully Edited!',
            {
              autoClose: 4000,
              position: 'bottom-right',
            },
          );
        }
        handleCloseModal();
      } catch (error) {
        toast.error(
          'Something went wrong!',
          {
            autoClose: 4000,
            position: 'bottom-right',
          },
        );
        console.error('Error creating news:', error);
      }
    },
  });

  useEffect(() => {
    if (action === 'edit' && selectedNews) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        title: selectedNews.title,
        news: selectedNews.description,
      }));
    } else if (action === 'create') {
      formik.resetForm();
    }
  }, [selectedNews]);

  const handleTitleChange = (e) => {
    formik.handleChange(e);
  };

  const handleNewsChange = (e) => {
    formik.handleChange(e);
  };

  const handleResetForm = () => {
    formik.resetForm();
  };

  const handleCloseModal = () => {
    handleResetForm();
    handleClose();
  };

  const isTitleInvalid = !!formik.errors.title;
  const isNewsInvalid = !!formik.errors.news;

  return (
    <Dialog open={open} onClose={handleCloseModal} className="confirmation-modal">
      <DialogTitle className="text-center">
        <span className="text-capitalize">
          {action}
        </span>
        {' '}
        News
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="title"
            name="title"
            placeholder="Title"
            value={formik.values.title}
            onChange={handleTitleChange}
            error={isTitleInvalid}
            helperText={isTitleInvalid && formik.errors.title}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            id="news"
            name="news"
            placeholder="Type News here ..."
            className="mt-3"
            value={formik.values.news}
            onChange={handleNewsChange}
            error={isNewsInvalid}
            helperText={isNewsInvalid && formik.errors.news}
          />
          <DialogActions>
            <Button className="button" onClick={handleCloseModal} color="secondary">
              Cancel
            </Button>
            <Button className="button" type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationModal;
