import * as Yup from 'yup';

const VehicleSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[a-zA-Z0-9 ]+$/g,
      'Name can only contain alphabets.',
    )
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  city_id: Yup.string()
    .required('Required'),
  plate_number: Yup.string()
    .matches(
      /^[a-zA-Z0-9 -]+$/g,
      'Name can only contain alphabets, numbers and hyphen(-)',
    )
    .min(5, 'Too Short!')
    .max(8, 'Too Long!')
    .required('Required'),
  vin_number: Yup.string()
    .matches(
      /^[a-zA-Z0-9]+$/g,
      'Name can only contain alphabets and numbers',
    )
    .min(15, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  make_model: Yup.string()
    .matches(
      /^[a-zA-Z0-9 ]+$/g,
      'Name can only contain alphabets and numbers',
    )
    .min(5, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  capacity: Yup.number()
    .min(5, 'Too Short!')
    .max(99, 'Too Long!')
    .required('Required'),
  year: Yup.number()
    .min(1920, 'Too Short!')
    .max(new Date().getFullYear(), 'Too Long!')
    .required('Required'),
  colour: Yup.string()
    .matches(
      /^[a-zA-Z ]+$/g,
      'Name can only contain alphabets and numbers',
    )
    .min(3, 'Too Short!')
    .max(10, 'Too Long!')
    .required('Required'),
  maintenence: Yup.string()
    .max(500, 'Too Long!'),
});

export default VehicleSchema;
