import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { FETCH_PRODUCT_UNITS_BOUNDS } from '../../../../../../api/Product';
import { FETCH_VEHICLES_BY_CITY } from '../../../../../../api/Vehicles';
import { FETCH_GUIDES } from '../../../../../../api/Guides';
import { UPDATE_BOOKINGS, UPDATE_BOOKING_UNITS } from '../../../../../../api/bookings';
import fetchFromAPI from '../../../../../../utils/helpers';
import EditBookingForm from './EditBookingForm';
import fetchFromAvailabilityAPI from '../../../../utils/helpers';
import { validPhoneNum, validTime, validEmail } from '../../../../../../utils/Regex';

const airtableUpdateAPIKey = process.env.REACT_APP_AIRTABLE_UPDATE;

const validate = (values) => {
  const pax = values.units?.reduce(
    (previousValue, currentValue) => previousValue
      + currentValue.quantity,
    0,
  );
  const capacity = values.capacity === 0 ? values.maxPax : values.capacity;

  const errors = {};

  if (!values.tour_date) {
    errors.tour_date = 'Required';
  }
  if (!values.contact_name) {
    errors.contact_name = 'Required';
  }
  if (values.phone && !validPhoneNum.test(values.phone)) {
    errors.phone = 'Enter valid phone number';
  }
  if (values.email && !validEmail.test(values.email)) {
    errors.email = 'Enter valid email';
  }
  if (values.secondary_phone && !validPhoneNum.test(values.secondary_phone)) {
    errors.secondary_phone = 'Enter valid phone number';
  }
  if (values.secondary_email && !validEmail.test(values.secondary_email)) {
    errors.secondary_email = 'Enter valid email';
  }
  if (!values.allow_overbooking && (Number(pax) > capacity)
  ) {
    errors.maxPax = 'Total PAX is exceeding the limit of available seats';
  } else if (Number(pax) === 0) {
    errors.maxPax = 'PAX cannot be 0';
  }
  if (values.pickupRequired) {
    if (values.pickup_location && !values.valid_location && !values.allowBookingOutside) {
      errors.valid_location = 'Please enter an address within pickup bounds';
    }
  }
  return errors;
};

function EditBooking({
  setIsEditing,
  clickedBooking,
}) {
  const [calendarDates, setCalendarDates] = useState([]);
  const [bookingOptions, setBookingOptions] = useState(null);
  const getAvailableDates = async () => {
    const res = await fetchFromAvailabilityAPI(`calendarCheck/${clickedBooking.product?.tourId}`, {
      method: 'GET',
    });
    setCalendarDates(res);
  };

  const getTimeOptions = async () => {
    const body = {
      productId: clickedBooking.product?.tourId,
      date: clickedBooking.tourDate,
    };
    const res = await fetchFromAvailabilityAPI('availabilityCheckEdit', {
      body,
      method: 'POST',
    });
    if (!res.error) setBookingOptions(res);
  };

  // useEffect(() => {
  //   getAvailableDates();
  //   getTimeOptions();
  // }, []);

  const { data: vehicles } = useQuery(FETCH_VEHICLES_BY_CITY, {
    variables: { citiesId: [clickedBooking?.product.citiesProducts[0].city.id] },
  });

  const { data: productUnits, loading: loadingProductUnits } = useQuery(
    FETCH_PRODUCT_UNITS_BOUNDS,
    {
      variables: { id: clickedBooking.product?.tourId },
    },
  );

  const { data: guides } = useQuery(FETCH_GUIDES);

  const updateAirtable = async (body) => {
    try {
      const res = await fetchFromAPI(airtableUpdateAPIKey, 'update-airtable', {
        body,
      });
    } catch (err) {
      console.log(`Airtable was not updated: ${err.message}.`);
    }
  };

  const [updateBooking] = useMutation(UPDATE_BOOKINGS);

  const [updateBookingUnits] = useMutation(UPDATE_BOOKING_UNITS);

  const getMaxPax = () => {
    const option = bookingOptions?.availabilitySeries?.filter((option) => (
      option.availability_series_id === clickedBooking.availabilitySeriesId
      && option.assigned_vehicle === clickedBooking.assignedVehicle
    ));
    return option?.[0]?.maxPax;
  };

  const getUnits = () => {
    const units = [];
    clickedBooking.bookingUnits.map((unit) => (
      units.push({ unit_id: unit.unit.id, booking_id: clickedBooking.id, quantity: unit.quantity })
    ));
    return units;
  };

  const getCapacity = () => {
    let capacity = 0;
    clickedBooking.bookingUnits.map((unit) => (
      capacity += unit.quantity
    ));

    const option = bookingOptions?.availabilitySeries?.filter((option) => (
      option.availability_series_id === clickedBooking.availabilitySeriesId
      && option.assigned_vehicle === clickedBooking.assignedVehicle
    ));
    if (clickedBooking?.product?.type?.includes('Private')) {
      capacity = option?.[0]?.capacity;
    } else {
      capacity += Number(option?.[0]?.maxPax);
    }
    return capacity;
  };

  const getInitialValues = () => {
    let initialValues = {
      product_id: clickedBooking.product?.tourId || '',
      tour_date: clickedBooking.tourDate || '',
      // tour_time: `${clickedBooking.tourTime}+00` || '',
      tour_time: `${clickedBooking.tourTime}` || '',
      pickup_location: clickedBooking.pickupLocation || '',
      pickup_coordinates: clickedBooking.pickupCoordinates || '',
      pickup_time: clickedBooking.pickupTime || '',
      contact_name: clickedBooking.customer?.contactName || '',
      phone: clickedBooking.customer?.phone || '',
      email: clickedBooking.customer?.email || '',
      secondary_email: clickedBooking.customer?.secondaryEmail || '',
      secondary_phone: clickedBooking.customer?.secondaryPhone || '',
      availability_series_id: clickedBooking.availabilitySeriesId || '',
      assigned_vehicle: clickedBooking.assignedVehicle || '',
      assigned_guide: clickedBooking.assignedGuide || '',
      maxPax: bookingOptions && getMaxPax(),
      productType: clickedBooking?.product?.type,
      valid_location: false,
      allow_overbooking: false,
      units: getUnits(),
      notes_customer: clickedBooking.notes || '',
      allowBookingOutside: false,
      pickupRequired: clickedBooking?.product?.pickupRequired,
      // capacity: getCapacity(),
    };
    const bookingUnits = {};
    productUnits?.productUnits?.units.map((unit) => {
      const quantity = clickedBooking?.bookingUnits?.filter((q) => (
        unit.unit.label.toLowerCase() === q.unit.label.toLowerCase()));
      bookingUnits[unit.unit.label.toLowerCase()] = quantity[0]?.quantity || 0;
    });

    initialValues = { ...initialValues, ...bookingUnits };
    return initialValues;
  };

  const handleSubmit = (values, initialValues) => {
    const bookingInput = {};
    const customerInput = {};
    const keys = Object.keys(initialValues);
    keys.map((key) => {
      if (initialValues[key] !== values[key]) {
        if (key === 'contact_name'
          || key === 'phone'
          || key === 'email'
          || key === 'secondary_email'
          || key === 'secondary_phone'
        ) {
          customerInput[key] = values[key];
        } else if (!(key === 'maxPax'
          || key === 'valid_location'
          || key === 'pickupRequired'
          || key === 'allow_overbooking'
          || key === 'allowBookingOutside'
          || key === 'units'
          || key === 'adults'
          || key === 'children'
          || key === 'infants'
          || key === 'teenagers'
          || key === 'students'
          || key === 'group'
          || key === 'capacity'
        )) {
          bookingInput[key] = values[key];
        }
      }
    });
    if (!(_.isEqual(values.units, initialValues.units))) {
      updateBookingUnits({
        variables: {
          units: values.units,
        },
      });
    }
    updateBooking({
      variables: {
        id: clickedBooking.id,
        booking_input: bookingInput,
        customerId: clickedBooking.customerId,
        customer_input: customerInput,
      },
    })
      .then(() => {
        const body = {
          bookingId: clickedBooking.source === 'REZDY' ? clickedBooking.REZDY?.[0]?.id : clickedBooking.id,
          bookingInput,
          customerInput,
          units: _.isEqual(values.units, initialValues.units) ? [] : values.units,
        };
        console.log(body);
        updateAirtable(body);
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    // !(bookingOptions === null)
    !loadingProductUnits
    && (
      <EditBookingForm
        setIsEditing={setIsEditing}
        clickedBooking={clickedBooking}
        updateBooking={updateBooking}
        validate={validate}
        getInitialValues={getInitialValues}
        productUnits={productUnits?.productUnits?.units}
        vehicles={vehicles?.vehicles}
        guides={guides?.guides}
        handleSubmit={handleSubmit}
        calendarDates={calendarDates}
        bookingOptions={bookingOptions}
        setBookingOptions={setBookingOptions}
      />
    )
  );
}

export default EditBooking;
