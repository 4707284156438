/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import Aggregates, { AGGREGATETYPES } from '../../api/dashboards';
import LOGO from '../../assets/seesight-logo.svg';
import './HomePage.scss';

import DateRange from '../../utils/daterangepicker';
import BookingCreated from './BookingsCreated';
import BookingTourDate from './BookingTourDate';

interface TodaysBooking {
  total: number;
  totalPax: number;
  adults: number;
  children: number;
  infants: number;
}
interface CityBookings {
  name: string;
  pax: number;
}
interface BOOKINGTOURDATETODAY {
  website:{
    count:number,
    title:string,
  }
  rezdy:{
    count:number,
    title:string,
  }
  cancelled:{
    count:number,
    title:string
  }
  completed:{
    count:number,
    title:string
  }
  adults:{
    count:number,
    title:string
  }
  children:{
    count:number,
    title:string
  }
  infants:{
    count:number,
    title:string
  }
}
function HomePage() {
  const [fetchBookingStates, { data }] = useLazyQuery<AGGREGATETYPES>(Aggregates);
  const [dateRange, setDateRange] = useState<string[]>(['', '']);
  const [bookingDiagram, setBookingDiagram] = useState<any>();
  const [PaxDiagram, setPaxDiagram] = useState<any>();
  useEffect(() => {
    fetchBookingStates({
      variables: {
        startDate: dateRange[0],
        endDate: dateRange[1],
        date: dayjs().format('YYYY-MM-DD'),
      },
    });
  }, [dateRange]);

  const [tourDateBookings, setTourDateBookings] = useState<BOOKINGTOURDATETODAY>();
  const state = {
    options: {
      labels: ['Rezdy', 'Website', 'Cancelled', 'Completed'],
      colors: ['#4C4D9F', '#95A4FC', '#D10000', '#FC6B21'],
    },
  };
  const state2 = {
    options: {
      labels: ['Adults', 'Children', 'Infants'],
      colors: ['#A1E3CB', '#B1E3FF', '#BCBDC2'],
    },
  };

  const [citiesData, setCitiesData] = useState<TodaysBooking | undefined>();
  const [cityKeys, setCityData] = useState<string[]>([]);
  const [perCity, setPerCity] = useState<any>();

  const [todayBooking, setTodayBookings] = useState<
  TodaysBooking | undefined
  >();
  const [todayCityKeys, todaySetCityData] = useState<string[]>([]);
  const [todayPerCity, setTodayPerCity] = useState<any>();
  const [tourDataToday, setTourDateToday] = useState();

  useEffect(() => {
    if (data?.aggregates[0]) {
      const adults = data?.aggregates.reduce((acc, obj) => {
        if (obj.bookingUnits[0] && obj.bookingUnits[0].unit.label === 'Adults') {
          return acc + obj.bookingUnits[0].quantity;
        }
        return 0;
      }, 0) || 0;
      let children = 0;
      let infants = 0;

      data.aggregates.map((item) => {
        item.bookingUnits.map((item) => {
          if (item.unit.label === 'Children') {
            children += item.quantity;
          }
          if (item.unit.label === 'Infants') {
            infants = +item.quantity;
          }
        });
      });
      setCitiesData(() => ({
        total: data?.aggregates.length || 0,
        totalPax: data?.aggregates.reduce((acc, obj) => acc + obj.pax, 0) || 0,
        adults,
        children,
        infants,
      }));
      const citiesList = data.aggregates.map((city) => {
        let child = 0;

        let infants = 0;

        city.bookingUnits.map((item) => {
          if (item.unit.label === 'Children') {
            child = +item.quantity;
          }
          if (item.unit.label === 'Infants') {
            infants = +item.quantity;
          }
        });

        return {
          name: city.product.citiesProduct[0].city.name,
          pax: city.pax,
          infants,
          children: child,
          adults:
            city.bookingUnits[0] && city.bookingUnits[0].unit.label === 'Adults'
              ? city.bookingUnits[0].quantity
              : 0,
        };
      });
      const seperateByCity = {
        [citiesList[0].name]: {
          name: citiesList[0].name,
          pax: citiesList[0].pax,
          total: 0,
          infants: citiesList[0].infants,
          children: citiesList[0].children,
          adults: citiesList[0].adults,
        },
      };

      citiesList.map((city) => {
        if (seperateByCity[city.name]) {
          seperateByCity[city.name].pax += city.pax;
          seperateByCity[city.name].total += 1;
          seperateByCity[city.name].adults += city.adults;
          seperateByCity[city.name].infants += city.infants;
          seperateByCity[city.name].children += city.children;

          return;
        }
        seperateByCity[city.name] = {
          name: city.name,
          pax: city.pax,
          total: 1,
          infants: city.infants,
          adults: city.adults,
          children: city.children,
        };
      });

      setPerCity(seperateByCity);
      const keys = Object.keys(seperateByCity);

      setCityData(keys);
      setBookingDiagram([data?.rezdyTodayBooked.aggregate.count, data?.websiteTodayBooked.aggregate.count, data?.bookingsCancelled.aggregate.count, data?.bookingsCompleted.aggregate.count]);
      setPaxDiagram([adults, children, infants]);
    }
  }, [data]);

  useEffect(() => {
    if (data?.happeningToday[0]) {
      const adults = data?.happeningToday.reduce((acc, obj) => {
        if (obj.bookingUnits[0].unit.label === 'Adults') {
          return acc + obj.bookingUnits[0].quantity;
        }
        return 0;
      }, 0) || 0;
      let children = 0;
      let infants = 0;

      data.happeningToday.map((item) => {
        item.bookingUnits.map((item) => {
          if (item.unit.label === 'Children') {
            children += item.quantity;
          }
          if (item.unit.label === 'Infants') {
            infants = +item.quantity;
          }
        });
      });
      setTodayBookings(() => ({
        total: data?.happeningToday.length || 0,
        totalPax:
          data?.happeningToday.reduce((acc, obj) => acc + obj.pax, 0) || 0,
        adults,
        children,
        infants,
      }));
      const citiesList = data.happeningToday.map((city) => {
        let child = 0;

        let infants = 0;

        city.bookingUnits.map((item) => {
          if (item.unit.label === 'Children') {
            child = +item.quantity;
          }
          if (item.unit.label === 'Infants') {
            infants = +item.quantity;
          }
        });

        return {
          name: city.product.citiesProduct[0].city.name,
          pax: city.pax,
          infants,
          children: child,
          adults:
            city.bookingUnits[0].unit.label === 'Adults'
              ? city.bookingUnits[0].quantity
              : 0,
        };
      });
      const seperateByCity = {
        [citiesList[0].name]: {
          name: citiesList[0].name,
          pax: citiesList[0].pax,
          total: 0,
          infants: citiesList[0].infants,
          children: citiesList[0].children,
          adults: citiesList[0].adults,
        },
      };

      citiesList.map((city) => {
        if (seperateByCity[city.name]) {
          seperateByCity[city.name].pax += city.pax;
          seperateByCity[city.name].total += 1;
          seperateByCity[city.name].adults += city.adults;
          seperateByCity[city.name].infants += city.infants;
          seperateByCity[city.name].children += city.children;

          return;
        }
        seperateByCity[city.name] = {
          name: city.name,
          pax: city.pax,
          total: 1,
          infants: city.infants,
          adults: city.adults,
          children: city.children,
        };
      });
      setTodayPerCity(seperateByCity);
      const keys = Object.keys(seperateByCity);

      todaySetCityData(keys);
    }
  }, [data]);

  return (
    <div className="booking-totals">
      <BookingCreated />
      <BookingTourDate />
    </div>
  );
}

export default HomePage;
