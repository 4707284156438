import { gql } from '@apollo/client';

const DELETE_RESERVATION_NOTE = gql`
mutation DELETE_NOTE($id: uuid!) {
  deleteNotes: delete_boatnew_booking_notes_reservations_by_pk(id: $id) {
    booking {
      bookingNotes: reservation_notes {
        id
      }
    }
  }
}
`;

const UPDATE_RESERVATION_NOTE = gql`
mutation UPDATE_NOTE(
  $id: uuid!, 
  $input: boatnew_booking_notes_reservations_set_input!) {
  updateNotes: update_boatnew_booking_notes_reservations_by_pk(pk_columns: {
    id: $id}, 
    _set: $input) {
      booking {
        bookingNotes: reservation_notes {
        id
      }
    }
  }
}
`;

const ADD_RESERVATION_NOTE = gql`
mutation ADD_NOTE($input: boatnew_booking_notes_reservations_insert_input!) {
  addNotes: insert_boatnew_booking_notes_reservations_one(object: $input) {
    booking {
      bookingNotes: booking_notes {
        id
      }
    }
  }
}
`;

const DELETE_NOTE = gql`
  mutation DELETE_NOTE($id: String!) {
  deleteNotes: delete_boatnew_booking_notes_by_pk(id: $id) {
    booking {
      bookingNotes: booking_notes {
        id
        note
      }
    }
  }
}
`;

const UPDATE_NOTE = gql`
mutation UPDATE_NOTE(
  $id: String!, 
  $input: boatnew_booking_notes_set_input!) {
  updateNotes: update_boatnew_booking_notes_by_pk(pk_columns: {
    id: $id}, 
    _set: $input) {
      booking {
        bookingNotes: booking_notes {
        id
        note
      }
    }
  }
}
`;

const ADD_NOTE = gql`
mutation ADD_NOTE($input: boatnew_booking_notes_insert_input!) {
  addNotes: insert_boatnew_booking_notes_one(object: $input) {
    booking {
      bookingNotes: booking_notes {
        id
        note
      }
    }
  }
}
`;

const DELETE_GUIDE_NOTE = gql`
  mutation DELETE_NOTE($id: String!) {
  deleteNotes: delete_boatnew_booking_notes_guides_by_pk(id: $id) {
    booking {
      notesGuide: booking_notes_guides {
        id
        note
      }
    }
  }
}
`;

const UPDATE_GUIDE_NOTE = gql`
mutation UPDATE_NOTE(
  $id: String!, 
  $input: boatnew_booking_notes_guides_set_input!) {
  updateNotes: update_boatnew_booking_notes_guides_by_pk(pk_columns: {
    id: $id}, 
    _set: $input) {
      booking {
        notesGuide: booking_notes_guides {
        id
        note
      }
    }
  }
}
`;

const ADD_GUIDE_NOTE = gql`
mutation ADD_NOTE($input: boatnew_booking_notes_guides_insert_input!) {
  addNotes: insert_boatnew_booking_notes_guides_one(object: $input) {
    booking {
      notesGuide: booking_notes_guides {
        id
        note
      }
    }
  }
}
`;

export {
  DELETE_NOTE,
  UPDATE_NOTE,
  ADD_NOTE,
  DELETE_GUIDE_NOTE,
  UPDATE_GUIDE_NOTE,
  ADD_GUIDE_NOTE,
  DELETE_RESERVATION_NOTE,
  UPDATE_RESERVATION_NOTE,
  ADD_RESERVATION_NOTE,
};
