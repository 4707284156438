import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { TabPane } from 'react-bootstrap';
import { FETCH_BOOKING_BY_ID } from '../../api/bookings';
import { DELETE_SUBTASK, INSERT_SUBTASK, UPDATE_SUBTASK } from '../../api/subtasks';
import BookingContext from '../../context/booking';
import Subtasks from '../../pages/Bookings/components/Modal/components/Subtasks/Subtasks';
import GuideNotes from '../../pages/Bookings/components/Modal/components/GuideNotes/GuideNotes';
import Notes from '../../pages/Bookings/components/Modal/components/Notes/Notes';
import ReservationNotes from '../../pages/Bookings/components/Modal/components/ReservationNotes/ReservationNotes';

function BookingNotes() {
  const booking = useContext(BookingContext);
  const [deleteTask] = useMutation(DELETE_SUBTASK);
  const [updateTask] = useMutation(UPDATE_SUBTASK);
  const [addTask] = useMutation(INSERT_SUBTASK);

  return (
    <TabPane
      eventKey="Notes"
      title="Notes"
    >
      <div className="BookingModal__container">
        { booking
          && (
            <>
              <Subtasks
                clickedBooking={booking}
                deleteTask={deleteTask}
                updateTask={updateTask}
                addTask={addTask}
              />
              <Notes
                clickedBooking={booking}
              />
              <GuideNotes
                clickedBooking={booking}
              />
              <ReservationNotes
                clickedBooking={booking}
              />
            </>
          )}
      </div>
    </TabPane>
  );
}

export default BookingNotes;
