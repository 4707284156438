/* eslint-disable radix */
import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link } from 'react-router-dom';
import styles from '../Bookings.module.scss';
import CustomDropDown from '../../../components/GridComponent/CustomDropDown/CustomDropDown';
import CustomTextField from '../../../components/GridComponent/CustomTextField/CustomTextField';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const dateComparator = (date1, date2, type) => {
  const d1 = dayjs(date1, type === 'time' && 'HH:mm:ss');
  const d2 = dayjs(date2, type === 'time' && 'HH:mm:ss');

  if (d1.isAfter(d2)) return 1;

  if (d1.isBefore(d2)) return -1;

  return 0;
};

export const tourDateFormatter = (cell) => {
  if (!cell) return null;

  return `${dayjs(cell?.tourDate).format('ddd, MMM DD, YYYY')}`;
};

export const tourTimeFormatter = (cell) => {
  if (!cell) return null;

  const { tourDate, tourTime } = cell;

  if (!tourDate || !tourTime) return 'TBD';

  return (
    `${dayjs(`${tourDate} ${tourTime}`).format('hh:mm A')}`
  );
};

export const customerFormatter = (cell) => (
  cell.customer?.contactName
);

export const assignedGuideFormatter = (cell) => cell?.guide?.name || (typeof cell === 'undefined' ? '' : 'TBD');
export const assignedVehicleFormatter = (cell) => cell?.vehicle?.name || (typeof cell === 'undefined' ? '' : 'TBD');

export const picLocFormatter = (cell) => {
  if (!cell) return null;
  return cell?.pickupLocation || 'TBD';
};

export const picTimeFormatter = (cell) => {
  if (!cell) return null;

  return cell?.pickupTime || 'TBD';
};

export const summaryFormatter = (cell) => {
  const {
    bookingUnits, tourDate, tourTime, product: { tourName },
  } = cell;

  if (!tourDate || !tourTime || !tourName || !tourDate) return 'Booking details not found';

  const summaryMessage = `${dayjs(`${tourDate} ${tourTime}`).format('MMM D, YYYY hh:mm A')}`;
  return summaryMessage;
};

export const paxFormatter = (bookingUnits) => {
  if (bookingUnits?.length === 0 || !bookingUnits) return null;

  const total = bookingUnits.reduce((a, c) => a + c.quantity, 0);

  let paxMessage = `${Number(total)}: (`;
  bookingUnits.forEach((unit, index) => {
    paxMessage += `${unit.quantity}${unit.unit.label.charAt(0)}`;
    if (!(index === bookingUnits.length - 1)) paxMessage += '-';
  });
  paxMessage += ')';
  return String(paxMessage).toUpperCase();
};

export const passengerFormatter = (bookingUnits, unitName) => {
  if (bookingUnits?.length === 0 || !bookingUnits) return null;

  if (unitName === 'PAX') {
    return bookingUnits.reduce(
      (accumulator, currentValue) => accumulator + currentValue.quantity,
      0,
    );
  }

  const unit = bookingUnits.filter((unit) => (unit?.unit?.label === unitName));

  if (unit.length === 0) return 0;
  return unit[0].quantity;
};

export const displayStatusColor = (cell) => {
  const status = cell ? cell.toLowerCase().replace(/ /g, '') : '';
  return (
    <div className={`${styles['Bookings__status-display']} ${styles[`Status-display__${status}`]}`}>
      {status === 'urgent' && (
        <div className={styles['Bookings__expl-mark']}>
          !
        </div>
      )}
    </div>
  );
};

export const ota = (cell) => {
  if (cell?.REZDY?.reseller) {
    return cell.REZDY.reseller;
  }
  return null;
};

export const otaID = (cell) => {
  if (cell?.REZDY?.resellerReference) {
    return cell.REZDY.resellerReference;
  }
  return null;
};

export const source = (cell) => cell.source;

export const statusFormatter = (status) => (
  <div className={styles['Bookings__column-display']}>
    <div className={styles['Bookings__column-display-status']}>
      {displayStatusColor(status)}
      {status?.replace(/_/g, ' - ')}
    </div>
  </div>
);

export const updatedAtFormatter = (cell) => {
  if (!cell) return null;
  return dayjs(cell?.updatedAt).tz('America/Toronto').format('YYYY-MM-DD HH:mm');
};

export const createdAtFormatter = (cell) => {
  if (!cell) return null;
  return dayjs(cell?.createdAt).tz('America/Toronto').format('YYYY-MM-DD HH:mm');
};

export const notesValueFormatter = (cell) => {
  if (!cell) {
    return null;
  }
  if (cell?.internalNotes?.length === 0) return null;
  return (
    cell?.internalNotes.map((e) => e.note)
  );
};

export const notesFormatter = (cell) => {
  if (!cell) {
    return null;
  }
  if (cell?.internalNotes?.length === 0) return null;

  return (
    <ul className={styles.Bookings__notes}>
      {cell?.internalNotes.map((e) => <li className={styles['Booking__notes-item']} key={e.id}>{e.note}</li>)}
    </ul>
  );
};

export const guideNotesValueFormatter = (cell) => {
  if (!cell) {
    return null;
  }
  if (cell?.notesGuide?.length === 0) return null;
  return (
    cell?.notesGuide?.map((e) => e.note)
  );
};

export const guideNotesFormatter = (cell) => {
  if (!cell) {
    return null;
  }
  if (cell?.notesGuide?.length === 0) return null;

  return (
    <ul className={styles.Bookings__notes}>
      {cell?.notesGuide?.map((e) => <li className={styles['Booking__notes-item']} key={e.id}>{e.note}</li>)}
    </ul>
  );
};

export function TableHeader({ checked, setchecked }) {
  const checkHandle = () => {
    setchecked(!checked);
  };

  return (
    <div className={styles.header_cont}>
      <input
        type="checkbox"
        id="input_border_header"
        onClick={checkHandle}
      />
      {checked && (
        <>
          <select name="email" id="email">
            <option value="email" selected>
              Email
            </option>
          </select>
          <select name="sms" id="sms">
            <option value="sms" selected>
              SMS
            </option>
          </select>
          <select name="status" id="status">
            <option value="status" selected>
              Status
            </option>
          </select>
        </>
      )}
      <select name="add_notes" id="add_notes" className={styles.add_notes_styles}>
        <option value="add_notes" selected>
          Add notes
        </option>
      </select>
    </div>
  );
}

async function getTours() {
  const headers = new Headers({ 'Content-Type': 'application/json' });
  headers.append('x-hasura-admin-secret', process.env.REACT_APP_CLIENT_VALUE);
  const response = await fetch(process.env.REACT_APP_URI, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `
    query FETCH_TOURS {
      tours: boatnew_cities(order_by: { name: asc }) {
    id
    name
    timeZone: time_zone
    cities_products(order_by: { product: { name: asc } }) {
          product {
        id
        name
        type
      }
    }
  }
}
`,
    }),
  });
  const tours = await response.json();
  return tours;
}

const viewHandler = (id) => (
  id ? <Link className="Vehicles__editBtn" to={`/bookings/${id}`}>View</Link> : null
);

export const BookingsColumnDefs = (guides = [], vehicles = [], stateAndActions = {}) => {
  const {
    data: {
      newValue, setNewValue, isEditing, setIsEditing,
    },
    actions: {
      onCellEditingStarted,
      onCellEditingStopped,
      onCellValueChangeCommitted,
      onCellValueChange,
      onCellFocused,
    },
  } = stateAndActions;

  return [
    {
      field: 'id',
      headerName: '',
      cellRenderer: (args) => viewHandler(args.data?.id),
      width: 80,
      filter: false,
      sortable: false,
      lockPosition: true,
      suppressMovable: true,
      lockVisible: true,
      pinned: true,
      resizable: false,
      lockPinned: true,
    },
    {
      field: 'status',
      cellRenderer: (params) => statusFormatter(params.value),
      filter: 'agSetColumnFilter',
      hide: false,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
    },
    {
      field: 'internalNotes',
      filter: 'agTextColumnFilter',
      headerName: 'Reception Notes',
      valueGetter: (params) => notesValueFormatter(params?.data),
      cellRenderer: (params) => notesFormatter(params?.data),
      editable: false,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'notesGuide',
      headerName: 'Guide Notes',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => guideNotesValueFormatter(params?.data),
      cellRenderer: (params) => guideNotesFormatter(params?.data),
      editable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'tour',
      valueGetter: (params) => params.data?.product?.tourName,
    },
    {
      field: 'tourDate',
      filter: 'agDateColumnFilter',
      comparator: dateComparator,
      filterParams: {
        comparator: dateComparator,
      },
      valueGetter: (params) => tourDateFormatter(params?.data),
    },
    {
      field: 'customerName',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => params.data?.customer?.contactName,
      editable: true,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="customerName"
          {...params}
        />
      ),
    },
    {
      field: 'customerEmail',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => params.data?.customer?.email,
      editable: true,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="customerEmail"
          {...params}
        />
      ),
    },
    {
      field: 'secondaryEmail',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => params.data?.customer?.secondaryEmail,
      editable: true,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="secondaryEmail"
          {...params}
        />
      ),
    },
    {
      field: 'customerPhone',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => params.data?.customer?.phone,
      editable: true,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="customerPhone"
          {...params}
        />
      ),
    },
    {
      field: 'secondaryPhone',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => params.data?.customer?.secondaryPhone,
      editable: true,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="secondaryPhone"
          {...params}
        />
      ),
    },
    {
      field: 'tourTime',
      filter: 'agTextColumnFilter',
      comparator: (timeA, timeB) => {
        const dateA = new Date(`1970-01-01 ${timeA}`);
        const dateB = new Date(`1970-01-01 ${timeB}`);

        if (dateA < dateB) {
          return -1;
        } if (dateA > dateB) {
          return 1;
        }
        return 0;
      },
      valueGetter: (params) => tourTimeFormatter(params.data),
      width: 120,
    },
    {
      field: 'pickupTime',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => picTimeFormatter(params.data),
      editable: true,
      width: 140,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="pickupTime"
          {...params}
        />
      ),
    },
    {
      field: 'PAX',
      filter: 'agTextColumnFilter',
      headerName: 'PAX',
      footerName: 'Total PAX',
      enableValue: true,
      valueGetter: (params) => {
        if (!params.data) return null;
        return paxFormatter(params.data?.bookingUnits);
      },
      aggFunc: (params) => {
        let totalPAX = 0; let adultPAX = 0; let childPAX = 0; let
          infantPAX = 0;
        params.values.forEach((paxString) => {
          if (typeof paxString !== 'string') return;
          // eslint-disable-next-line radix
          const paxTotalCount = parseInt((paxString.split(':')[0] || 0).trim()) || 0;
          totalPAX += paxTotalCount;

          const parenthesisPAX = (paxString.split(':').pop().split('(').pop()
            .split(')')
            .shift() || '')
            .split('-');

          for (let i = 0; i < parenthesisPAX.length; i += 1) {
            const element = parenthesisPAX[i];

            if (element.includes('A')) {
              const intValue = parseInt(element);
              adultPAX += intValue;
            }

            if (element.includes('C')) {
              const intValue = parseInt(element);
              childPAX += intValue;
            }

            if (element.includes('I')) {
              const intValue = parseInt(element);
              infantPAX += intValue;
            }
          }
        });
        let result = `${totalPAX}: (`;
        if (adultPAX > 0) {
          result += `${adultPAX}A`;
          if (childPAX > 0 || infantPAX > 0) {
            result += '-';
          }
        }
        if (childPAX > 0) {
          result += `${childPAX}C`;
          if (infantPAX > 0) {
            result += '-';
          }
        }
        if (infantPAX > 0) {
          result += `${infantPAX}I`;
        }
        result += ')';

        return result;
      },
    },
    {
      field: 'Adults',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        if (!params.data) return null;
        return passengerFormatter(params.data?.bookingUnits, 'Adults');
      },
      aggFunc: 'sum',
    },
    {
      field: 'Children',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        if (!params.data) return null;
        return passengerFormatter(params.data?.bookingUnits, 'Children');
      },
      aggFunc: 'sum',
    },
    {
      field: 'Infants',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        if (!params.data) return null;
        return passengerFormatter(params.data?.bookingUnits, 'Infants');
      },
      aggFunc: 'sum',
    },
    {
      field: 'Total PAX',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        if (!params.data) return null;
        return passengerFormatter(params.data?.bookingUnits, 'PAX');
      },
      aggFunc: 'sum',
    },
    {
      field: 'pickupLocation',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => picLocFormatter(params.data),
      editable: true,
      cellEditorFramework: (params) => (
        <CustomTextField
          stateAndActions={stateAndActions}
          field="pickupLocation"
          {...params}
        />
      ),
    },
    {
      field: 'id',
      filter: 'agTextColumnFilter',
      headerName: 'Booking ID',
    },
    {
      field: 'REZDY',
      headerName: 'OTA',
      valueGetter: (params) => ota(params.data),
    },
    {
      field: 'REZDY',
      filter: 'agTextColumnFilter',
      headerName: 'OTA ID',
      valueGetter: (params) => otaID(params.data),
    },
    {
      field: 'source',
      headerName: 'Source',
    },
    {
      field: 'REZDY',
      filter: 'agTextColumnFilter',
      headerName: 'Source ID',
      valueGetter: (params) => params.data?.REZDY?.id,
    },
    {
      field: 'assignedGuide',
      valueGetter: (params) => assignedGuideFormatter(params.data?.booking_vehicles),
      sortable: true,
      filter: true,
      editable: true,
      // rowGroup: true,
      // groupIncludeFooter: true,
      cellEditorFramework: (params) => (
        <CustomDropDown
          data={guides?.map((guide) => ({
            ...guide,
            title: guide?.fullName,
          }))}
          params={params}
          stateAndActions={stateAndActions}
          field="assignedGuide"
        />
      ),
    },
    {
      field: 'assignedVehicle',
      valueGetter: (params) => assignedVehicleFormatter(params.data?.booking_vehicles),
      sortable: true,
      filter: true,
      editable: true,
      // rowGroup: true,
      cellEditorFramework: (params) => (
        <CustomDropDown
          data={vehicles?.map((vehicle) => ({
            ...vehicle,
            title: vehicle?.name,
          }))}
          params={params}
          stateAndActions={stateAndActions}
          field="assignedVehicle"
        />
      ),
    },
    {
      field: 'group',
      headerName: 'Juggling',
      valueGetter: (params) => params.data?.booking_vehicles?.group || (typeof params.data === 'undefined' ? '' : 'A'),
      sortable: true,
      filter: true,
      editable: true,
      cellEditorFramework: (params) => (
        <CustomDropDown
          data={[
            {
              id: 'A',
              title: 'A',
            },
            {
              id: 'B',
              title: 'B',
            },
            {
              id: 'C',
              title: 'C',
            },
          ]}
          params={params}
          stateAndActions={stateAndActions}
          field="group"
        />
      ),
    },
    {
      field: 'shift',
      headerName: 'Shift',
      valueGetter: (params) => params.data?.timeGroup,
      sortable: true,
      filter: true,
      comparator: (valueA, valueB) => {
        const sortOrder = ['MORNING', 'AFTERNOON', 'NIGHT'];
        const indexA = sortOrder.indexOf(valueA);
        const indexB = sortOrder.indexOf(valueB);

        if (indexA < indexB) {
          return -1;
        } if (indexA > indexB) {
          return 1;
        }
        return 0;
      },
    },
    {
      field: 'otaPickUp',
      headerName: 'OTA Pickup',
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        const data = params.data?.REZDY?.pickupLocation;
        let parsed;
        if (data) {
          try {
            parsed = JSON.parse(data);
            return `${parsed.label} ${parsed?.value} `;
          } catch (e) {
            return data;
          }
        }
        return data;
      },
    },
    {
      field: 'updatedAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (dateFromFilter, cellValue) => dateComparator(dateFromFilter, cellValue),
      },
      valueGetter: (params) => updatedAtFormatter(params.data),
    },
    {
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (dateFromFilter, cellValue) => dateComparator(dateFromFilter, cellValue),
      },
      valueGetter: (params) => createdAtFormatter(params.data),
    },
    {
      field: 'city',
      headerName: 'City',
      filter: 'agSetColumnFilter',
      valueGetter: (params) => {
        if (!params.data) return null;
        return params?.data?.product?.citiesProducts[0]?.city?.name;
      },
    },
  ];
};
