import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import LabelInput from "./InputLable/Index";
import "./PageStyling/Page7.scss";

function Page7({ formik }) {
  const {
    errors,
    values: {
      type,
      prices,
      id,
      prices: { advertised_price, quantity_max, quantity_min, product_prices },
    },
    setFieldValue,
  } = formik;
  const supportedProductTypes = ["Day Tour", "Multi-day Tour"];
  const isPrivateDayTour = supportedProductTypes.includes(type);
  const [passengerTypes, setPassengerTypes] = useState(() => {
    if (type === "Day Tour" || type === "Multi-day Tour") {
      return ["adult", "child", "infant"];
    }
    const types = ["adult", "child", "infant"];

    if (prices) {
      const additionalPassengerTypes = Object.keys(
        prices?.product_prices?.[0]?.prices || {}
      ).filter((passengerType) => !types.includes(passengerType));
      types.push(...additionalPassengerTypes);
    }
    return types;
  });
  useEffect(() => {
    if (prices) {
      if (!passengerTypes.includes("Custom")) {
        setPassengerTypes((prevPassengerTypes) => [...prevPassengerTypes]);
      }
    }
  }, [prices]);

  const initialPricingData = prices || {};
  const [customLabel, setCustomLabel] = useState("");
  // const [customPrice, setCustomPrice] = useState('');
  // const [isLabelTrue, setIsLabelTrue] = useState(false);
  // const [label, setLabel] = useState('');

  const passengerTypeMapping = {};
  passengerTypes.forEach((passengerType) => {
    passengerTypeMapping[passengerType.toLowerCase()] = passengerType;
  });

  const [priceArr, setPriceArr] = useState(() => ({ ...initialPricingData }));
  // const [newPriceArr, setNewPriceArr] = useState(() => ({ ...initialPricingData }));
  const updatePriceArray = () => {
    const updatedPrices = {};
    passengerTypes.forEach((passengerType) => {
      updatedPrices[passengerType] =
        initialPricingData[passengerTypeMapping[passengerType.toLowerCase()]] ||
        "";
    });
    return updatedPrices;
  };
  // const updatePriceArr = () => {
  //   const updatedPrices = updatePriceArray();
  //   setPriceArr(updatedPrices);
  // };

  useEffect(() => {
    const updatedPrices = updatePriceArray();
    setPriceArr(updatedPrices);
  }, []);

  const [formFields, setFormFields] = useState(() => {
    let fields = [
      {
        id: "adult",
        passengerType: "adult",
        value: priceArr.Adults,
      },
    ];

    if (passengerTypes.length > 0) {
      fields = passengerTypes
        .filter((passengerType) => {
          const value = priceArr[passengerType];
          return value !== null && value !== undefined && value !== "";
        })
        .map((passengerType) => {
          if (passengerType.startsWith("Custom")) {
            const [customLabel, value] = passengerType.split(":");
            return {
              id: passengerType,
              passengerType: customLabel,
              value: priceArr[passengerType],
              label: customLabel,
            };
          }
          return {
            id: passengerType,
            passengerType,
            value: priceArr[passengerType],
            label: passengerType === "Custom Price" ? "" : passengerType,
          };
        });
    }

    return fields;
  }, [priceArr]);

  if (formFields.length === 0) {
    const adultsField = {
      id: "adult",
      passengerType: "adult",
      value: priceArr.Adults,
    };
    formFields.push(adultsField);
  }

  const [isCustomPricing, setIsCustomPricing] = useState(false);
  const handleAddField = () => {
    let newPassengerType = "";

    if (isCustomPricing && customLabel) {
      const nextCustomPriceIndex =
        formFields.filter((field) =>
          field.passengerType.startsWith("Custom Price")
        ).length + 1;

      newPassengerType = `Custom Price ${nextCustomPriceIndex}`;
    } else if (type === "Private Day Tour") {
      newPassengerType = `Custom ${formFields.length + 1}`;
    } else {
      const availablePassengerTypes = passengerTypes.filter(
        (type) =>
          !formFields.some(({ passengerType }) => passengerType === type)
      );

      if (availablePassengerTypes.length > 0) {
        [newPassengerType] = availablePassengerTypes;
      } else {
        alert(
          type === "Private Day Tour"
            ? "You have reached the limit of select options for Private Day Tours"
            : "You have reached the limit of select options"
        );
        return;
      }
    }
    const newField = {
      id: newPassengerType,
      passengerType: newPassengerType,
      value: "",
    };

    const updatedFields = [...formFields, newField];
    setFormFields(updatedFields);
  };

  const handleRemoveField = (passengerType) => {
    const updatedFields = formFields.filter(
      (field) => field.passengerType !== passengerType
    );
    const updatedPriceArr = { ...priceArr };
    delete updatedPriceArr[passengerType];
    setFormFields(updatedFields);
    setPriceArr(updatedPriceArr);
    setFieldValue("prices.priceArr", updatedPriceArr);
  };

  const handleInputChange = (event, passengerType, value) => {
    if (!passengerType) return;

    const updatedPriceArr = { ...priceArr };
    let updatedFields = [...formFields];

    if (passengerType.startsWith("Custom")) {
      updatedFields.forEach((formField, index) => {
        if (formField.passengerType === passengerType) {
          updatedFields[index] = {
            ...formField,
            label: customLabels[passengerType] || "",
            value,
          };
        }
      });

      updatedPriceArr[passengerType] = value;
    } else if (value === "") {
      updatedFields = updatedFields.filter(
        (formField) => formField.passengerType !== passengerType
      );
      delete updatedPriceArr[passengerType];
    } else {
      updatedFields.forEach((formField, index) => {
        if (formField.passengerType === passengerType) {
          updatedFields[index] = {
            ...formField,
            value,
          };
        }
      });
      updatedPriceArr[passengerType] = value;
    }

    setFormFields(updatedFields);
    setPriceArr(updatedPriceArr);
    setFieldValue("prices.priceArr", updatedPriceArr);
  };

  const handleQuantityChange = (type, step) => {
    let newMin = quantity_min;
    let newMax = quantity_max;

    if (type === "min") {
      newMin += step;
      if (newMin < 0) {
        newMin = 0;
      }
    } else if (type === "max") {
      newMax += step;
      if (newMax < 0) {
        newMax = 0;
      }
    }

    setFieldValue("prices.quantity_min", newMin);
    setFieldValue("prices.quantity_max", newMax);
    setFieldValue("prices.priceArr", priceArr);
  };

  const handleManualInput = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setFieldValue("prices.quantity_max", newValue);
    setFieldValue("prices.priceArr", priceArr);
  };

  const handleMinManualInput = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setFieldValue("prices.quantity_min", newValue);
    setFieldValue("prices.priceArr", priceArr);
  };

  const [customLabels, setCustomLabels] = useState({});

  const handlePassengerTypeChange = (event, field) => {
    const selectedPassengerType = event.target.value;

    if (selectedPassengerType !== field.passengerType) {
      if (
        formFields.some(
          (formField) => formField.passengerType === selectedPassengerType
        )
      ) {
        alert(`"${selectedPassengerType}" is already selected.`);
        return;
      }

      const updatedFields = formFields.map((formField) => {
        if (formField.id === field.id) {
          const label =
            selectedPassengerType === "Custom Price"
              ? ""
              : selectedPassengerType;
          return {
            ...formField,
            passengerType: selectedPassengerType,
            id: selectedPassengerType,
            label,
          };
        }
        return formField;
      });

      const updatedPriceArr = { ...priceArr };
      updatedPriceArr[selectedPassengerType] = field.value;
      if (field.passengerType) {
        delete updatedPriceArr[field.passengerType];
      }
      setFormFields(updatedFields);
      setPriceArr(updatedPriceArr);
      setFieldValue("prices.priceArr", updatedPriceArr);
    }
  };

  const handleCustomLabelChange = (event, field, index, label) => {
    const newCustomLabel = label;
    const updatedCustomLabels = { ...customLabels };
    updatedCustomLabels[field.passengerType] = newCustomLabel;
    setCustomLabels(updatedCustomLabels);

    const updatedFields = formFields.map((formField) => {
      if (formField.id === field.id) {
        return {
          ...formField,
          passengerType: `custom:${index}:${newCustomLabel}`,
          id: `custom:${index}:${newCustomLabel}`,
          label: newCustomLabel,
        };
      }
      return formField;
    });

    const updatedPriceArr = { ...priceArr };
    updatedPriceArr[`custom:${newCustomLabel}`] = field.value;

    setFormFields(updatedFields);
    setPriceArr(updatedPriceArr);
    setFieldValue("prices.priceArr", updatedPriceArr);
  };

  return (
    <div
      className="EditForm__form-page6"
      style={{ padding: "0px 0px 30px 0px" }}
    >
      <div>
        {formFields.map((field, index) => (
          <div
            key={`${field.id}-${index}`}
            style={{ display: "flex", marginBottom: "10px" }}
          >
            <Form.Group style={{ display: "flex" }}>
              <div>
                <Form.Select
                  name={`selectPassengerType${field.id}`}
                  className="value custom-options"
                  aria-label={`Select Passenger Type ${field.id}`}
                  onChange={(event) => {
                    handlePassengerTypeChange(event, field);
                  }}
                  value={field.passengerType}
                >
                  {!["Day Tour", "Multi-day Tour"].includes(type) && (
                    <option
                      key={`option-CustomPrice-${field.id}`}
                      value="Custom Price"
                    >
                      Custom Price
                    </option>
                  )}
                  {passengerTypes
                    .filter(
                      (passengerType) =>
                        passengerType !== "Custom Price" ||
                        passengerType.startsWith("custom:")
                    )
                    .filter((passengerType) => {
                      if (!["Day Tour", "Multi-day Tour"].includes(type)) {
                        return !passengerType.startsWith("Custom");
                      }
                      return true;
                    })
                    .map((passengerType, index, array) => (
                      <option
                        key={`option-${passengerType}-${field.id}`}
                        value={passengerType}
                      >
                        {passengerType.startsWith("custom:")
                          ? `Custom${index}`
                          : passengerType}
                      </option>
                    ))}
                </Form.Select>
              </div>
              {![
                "adult",
                "child",
                "infant",
                "Passenger 1",
                "Passenger 2",
                "Passenger 3",
                "Passenger 4",
                "Passenger 5",
                "Passenger 6",
                "Passenger 7",
              ].includes(field.passengerType) && (
                <div style={{ marginLeft: "30px" }}>
                  <LabelInput
                    field={field}
                    index={index}
                    handleCustomLabelChange={handleCustomLabelChange}
                  />
                </div>
              )}

              <div style={{ marginLeft: "30px" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id={`basic-addon-price-${field.id}`}>
                    US $
                  </InputGroup.Text>
                  <Form.Control
                    name={`inputPassengerPrice${field.id}`}
                    type="number"
                    required
                    value={field.value}
                    onChange={(event) => {
                      handleInputChange(
                        event,
                        field.passengerType,
                        event.target.value
                      );
                    }}
                    placeholder="Enter Price"
                    aria-label="Price"
                    aria-describedby={`basic-addon-price-${field.id}`}
                  />
                </InputGroup>
              </div>
              <div style={{ marginLeft: "10px" }}>
                {index === 0 ? (
                  <Button
                    variant="outline-secondary"
                    id={`button-addon-${field.id}`}
                    onClick={handleAddField}
                  >
                    +
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outline-secondary"
                      id={`button-addon-${field.id}-1`}
                      onClick={() => handleRemoveField(field.passengerType)}
                    >
                      -
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="outline-secondary"
                      id={`button-addon-${field.id}-2`}
                      onClick={handleAddField}
                    >
                      +
                    </Button>
                  </>
                )}
              </div>
              {errors.priceArr && (
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        ))}
      </div>

      <div style={{ width: "300px" }}>
        <Form.Label>Advertised Price</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">US $</InputGroup.Text>
          <Form.Control
            name="prices.advertised_price"
            value={advertised_price || ""}
            type="number"
            onChange={(event) => {
              setFieldValue("prices.advertised_price", event.target.value);
              setFieldValue("prices.priceArr", priceArr);
            }}
            placeholder="Enter Advertised Price"
            aria-label="Advertised Price"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <div style={{ color: "red" }}>{errors.advertised_price}</div>
      <div>
        <Form.Label>Quantity</Form.Label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              marginRight: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Label>min</Form.Label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
              }}
            >
              <InputGroup className="mb-3">
                <Button
                  variant="outline-secondary"
                  id="button-addon1"
                  onClick={() => handleQuantityChange("min", -1)}
                >
                  -
                </Button>
                <Form.Control
                  name="prices.quantity_min"
                  value={quantity_min}
                  type="number"
                  onChange={handleMinManualInput}
                  placeholder="Min Quantity"
                  aria-label="Min Quantity"
                  aria-describedby="basic-addon1"
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon1"
                  onClick={() => handleQuantityChange("min", 1)}
                >
                  +
                </Button>
              </InputGroup>
            </div>
          </div>
          <div
            style={{
              marginRight: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Label>max</Form.Label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
              }}
            >
              <InputGroup className="mb-3">
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => handleQuantityChange("max", -1)}
                >
                  -
                </Button>
                <Form.Control
                  name="prices.quantity_max"
                  value={quantity_max}
                  type="number"
                  onChange={handleManualInput}
                  placeholder="Max Quantity"
                  aria-label="Max Quantity"
                  aria-describedby="basic-addon2"
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => handleQuantityChange("max", 1)}
                >
                  +
                </Button>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      <div style={{ color: "red" }}>{errors.quantity_min}</div>
    </div>
  );
}

export default Page7;
