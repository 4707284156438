import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import VehicleForm from '../VehicleForm';

function EditVehicleForm({
  vehicle,
  cities,
  updateVehicle,
  initialValues,
  validate,
}) {
  const navigate = useNavigate();

  const getUpdatedValues = (values, initialValuesFormik, result) => {
    const initValues = Object.values(initialValuesFormik);
    for (const [key, value] of Object.entries(values)) {
      if (!initValues.includes(value)) {
        result[key] = value;
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: (values) => {
      const result = {};
      getUpdatedValues(values, formik.initialValues, result);
      updateVehicle({
        variables: {
          id: values.id,
          input: result,
        },
      })
        .catch((e) => {
          alert(e);
        });
    },
  });

  return (
    <VehicleForm formik={formik} cities={cities} vehicle={vehicle} />
  );
}

export default EditVehicleForm;
