import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import {
  GoogleMap,
  LoadScript,
  DrawingManager,
  Polygon,
  Marker,
} from '@react-google-maps/api';
import SearchLocationInput from "../../../../components/CentralPickup/Index";

const libraries = ['places', 'geometry', 'drawing'];
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

function Page3({ formik, cities }) {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectVal, setSelectVal] = useState(formik?.values?.locations[0]?.city_id);
  const [polygonShape, setPolygonShape] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 28.7041,
    lng: 77.1025,
  });
  const isWalkingTour = formik?.values?.name.includes('Walking');

  const containerStyle = {
    width: '100%',
    height: '300px',
  };

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: [window?.google?.maps?.drawing?.OverlayType?.POLYGON],
    },
    polygonOptions: { strokeColor: 'red', fillColor: '#ff4c4cdc' },
  };

  const locations = formik.values.locations.map((location) => location.city_id);

  const getCoords = useCallback(() => {
    const coords = cities?.find((city) => city.id === selectVal)?.coordinates;
    return coords ? { lat: coords.lat, lng: coords.lng } : { lat: 0, lng: 0 };
  }, [cities, selectVal]);

  const [markerPosition, setMarkerPosition] = useState(getCoords);

  const setDefaultBounds = useCallback(() => {
    const city = cities?.find((city) => city.id === selectVal);
    if (city) {
      formik.setFieldValue('pickup_bounds', city.pickupBounds);
    }
  }, [cities, formik, selectVal]);

  const resetBounds = () => {
    formik.setFieldValue('pickup_bounds', null);
    if (polygonShape) {
      polygonShape.setMap(null);
    }
  };

  const handlePolygonComplete = (polygon) => {
    const polygonCoords = polygon.getPath().getArray();
    const bounds = polygonCoords.map((coord) => ({
      lat: coord.lat(),
      lng: coord.lng(),
    }));
    formik.setFieldValue('pickup_bounds', bounds);
    setPolygonShape(polygon);
  };

  const handleMarkerDragEnd = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const address = await getAddressFromCoords(lat, lng);
    if (isWalkingTour) {
      formik.setFieldValue('pickup_bounds', [address]);
    } else {
      formik.setFieldValue('pickup_bounds', { lat, lng });
    }
  };

  const getAddressFromCoords = async (lat, lng) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleApiKey}`);
      const data = await response.json();
      return data.status === 'OK' ? data.results[0].formatted_address : 'Address not found';
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  const getCoordsFromAddress = async (address) => {
    try {
      const encodedAddress = encodeURIComponent(address);
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${googleApiKey}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error('Geocoding API error:', data.status, data.error_message);
        return { lat: 0, lng: 0 };  // Ensure valid default coordinates
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return { lat: 0, lng: 0 };  // Ensure valid default coordinates
    }
  };

  useEffect(() => {
    let isMounted = true;

    const updateMarkerPosition = async () => {
      if (formik.values.pickup_bounds && formik.values.pickup_bounds.length > 0) {
        console.log('formik.values.pickup_bounds[0]', formik.values.pickup_bounds[0]);
        if (isWalkingTour) {
          const coords = await getCoordsFromAddress(formik.values.pickup_bounds[0]);
          if (isMounted) {
            setMarkerPosition(coords);
          }
        } else {
          if (isMounted) {
            setMarkerPosition(getCoords());
          }
        }
      }
    };

    updateMarkerPosition();

    return () => {
      isMounted = false;
    };
  }, [formik.values.pickup_bounds, selectVal, isWalkingTour, getCoords]);

  useEffect(() => {
    setSelectVal(formik?.values?.locations[0]?.city_id);
  }, [formik.values.locations]);

  useEffect(() => {
    setMarkerPosition(getCoords());
  }, [selectVal, getCoords]);

  return (
    <div className="EditForm__form-page3">
      <div style={{ width: "100%" }}>
        <SearchLocationInput setSelectedLocation={setSelectedLocation} productId={formik?.values?.id} />
      </div>
      <Form.Group className="EditForm__form-page3-header">
        <Form.Label>Pickup bounds:</Form.Label>
        <div className="EditForm__form-page3-select">
          <select
            onChange={(e) => setSelectVal(e.target.value)}
            value={selectVal}
          >
            {cities?.map((city) => (
              locations.includes(city.id) && (
                <option value={city.id} key={city.id}>
                  {city.name}
                </option>
              )
            ))}
          </select>
          <button type="button" onClick={resetBounds}>Reset</button>
          <button type="button" onClick={setDefaultBounds}>Default Bounds</button>
        </div>
        {formik.errors.pickup_bounds ? <div className="EditForm__error">{formik.errors.pickup_bounds}</div> : null}
      </Form.Group>

      <LoadScript
        googleMapsApiKey={googleApiKey}
        libraries={libraries}
        onLoad={() => setMapLoaded(true)}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerPosition}
          zoom={11
          }
        >
          {isWalkingTour ? (
            <Marker
              position={markerPosition}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          ) : (
            <>
              <DrawingManager
                onPolygonComplete={handlePolygonComplete}
                options={mapLoaded && options}
              />
              {formik.values.pickup_bounds && (
                <Polygon
                  paths={formik.values.pickup_bounds}
                  options={{ strokeColor: 'red', fillColor: '#FF0000' }}
                />
              )}
            </>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default Page3;
