import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmationPrompt({
  showConfirmation,
  setShowConfirmation,
  setIsEditing,
  action,
  setUpdateConfirmed,
  formik,
}) {
  const handleClick = () => {
    setUpdateConfirmed(true);
    setShowConfirmation(false);
    formik.handleSubmit();
    if (action === 'Cancel') {
      setIsEditing(false);
    }
  };
  const handleClose = () => setShowConfirmation(false);

  const prompt = {
    save: {
      btn: 'Save',
      msg: 'Are you sure you want to save the changes?',
      title: 'Confirm booking update',
    },
    cancel: {
      btn: 'Confirm',
      msg: 'Are you sure you want close the editor? Your changes will not be saved.',
      title: 'Cancel all changes',
    },
  };

  return (
    <div>
      <Modal show={showConfirmation} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {action === 'Save' ? prompt.save.title : prompt.cancel.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="BookingModal__payment-confirmation">
            {action === 'Save' ? prompt.save.msg : prompt.cancel.msg}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClick}>
            {action === 'Save' ? prompt.save.btn : prompt.cancel.btn}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmationPrompt;
