import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import Header from '../../components/Header/Header';
import './Logs.scss';
import { SUBSCRIBE_LOGS } from '../../api/logs';
import { SUBSCRIBE_GUIDES } from '../../api/Guides';

function Logs() {
  const { data, refetch } = useSubscription(SUBSCRIBE_LOGS);

  useEffect(() => console.log('==>', data), [data]);

  const getHeader = () => {
    let header = '';
    header = (
      <h2 className="heading_styles">
        Logs
      </h2>
    );
    return header;
  };

  return (
    <>
      <Header />
      <div className="Vehicles__container">
        <div className="card">
          <div className="card-header">
            {getHeader()}
          </div>
          <main className="Vehicles__main">
            <Outlet context={{ data, refetch }} />
          </main>
        </div>
      </div>
    </>
  );
}

export default Logs;
